import { Fetch, getErrorMessage } from "./common";
import { toast } from 'react-toastify';

export const logError = (err, info) => {
    const url = "/logger/";
    const options = {
        data: JSON.stringify({
            error: err,
            info: info
        })
    };
    return Fetch(url, "POST", options, false)
        .then(({ status, data = {} }) => {
            if (status === 201) {
                toast.success(data.message);
                return data;
            }
            throw new Error(data.message);
        }).catch(error => {
            console.log(error);
            toast.error(getErrorMessage(error));
            throw error;
        });
};