import React, { useEffect } from "react";
import { Row, Col, Select, Pagination, Spin, Radio } from "antd";
import { AiOutlineInbox } from "react-icons/ai";
import { Helmet } from 'react-helmet-async';

import TwrCard from "../../common/twrCard";
import BannerSlider from "./bannerSlider";
import "./styles.scss";
import { connect, useDispatch, useSelector } from "react-redux";
import { getSearch, setSearchDetail, searchStore } from "../../actions/searchDetail";
import { bindActionCreators } from "redux";
import TwrCard2 from "../../common/twrCard/twrCard2";
const { Option } = Select;

const filters = [
    {
        value: "ALWAYS_READY", str: "Always Ready",
    }, {
        value: "PREPARED_ASAP", str: "Make to Order",
    }, {
        value: "COLLECTING_ORDERS_TO_COOK", str: "Collecting Orders",
    }, {
        value: "ON_DEMAND_COOKING", str: "On-demand Cooking",
    }, {
        value: "EVENT_CATERING", str: "Event Catering",
    }
];

function SearchDetail({ getSearch, searchStore }) {

    const { address_from_ip: address } = useSelector(st => st.app);
    const { isAuthenticated } = useSelector(st => st.user);
    const { isLoading, searchResults, total, currentPage, filter, type, limit, searchType } = useSelector(st => st.searchDetail);
    const dispatch = useDispatch();

    useEffect(() => {
        if (searchType === 'STORE') {
            searchStore();
        } else {
            getSearch();
        }
    }, [address, getSearch, isAuthenticated, searchType, searchStore]);

    const handleSortBy = (value) => {
        dispatch(setSearchDetail({ filter: value }));
        getSearch();
    };

    const handlePagination = (page) => {
        if (searchType === 'STORE') {
            searchStore({ page });
        } else {
            getSearch({ page });
        }
    };

    return (
        <div className="container mt-3">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Search | Foodelys</title>
            </Helmet>

            <BannerSlider />

            <Row className="result-count my-3" gutter={[16, 16]}>
                <Col xs={24} md={6} lg={5} >
                    <label style={{ fontWeight: 600 }} className='mb-2'>Preparation Category</label>
                    <Select
                        onChange={handleSortBy}
                        placeholder='Filter'
                        value={filter}
                        style={{ minWidth: 200 }}
                    >
                        <Option value='' key='all' data-id={`option--all`}>All</Option>
                        {
                            filters.map((e, i) => (
                                <Option value={e.value} key={e.value} data-id={`option-${i}`}>{e.str}</Option>
                            ))
                        }
                    </Select>
                </Col>
                <Col>
                    <label style={{ fontWeight: 600 }} className='mb-2 d-block'>Filter</label>
                    <Radio.Group
                        onChange={(e) => {
                            dispatch(setSearchDetail({ searchType: e.target.value }));
                        }}
                        value={searchType}>
                        <Radio value='DISH'>
                            Items
                        </Radio>
                        <Radio value='STORE'>
                            Stores
                        </Radio>
                    </Radio.Group>
                </Col>
            </Row>

            <Spin size="large" spinning={isLoading}>
                {
                    isLoading ?
                        <div className='h-100 w-100'></div>
                        :
                        <Row gutter={[16, 16]} className='mb-4' justify='center' align='middle'>
                            {
                                searchType !== 'STORE' ?
                                    searchResults.map(({
                                        _id: id,
                                        display_name,
                                        store_name,
                                        rating,
                                        total_reviews,
                                        distance,
                                        picture,
                                        store_cover_picture
                                    }, key) => (
                                        <TwrCard
                                            key={key}
                                            id={id}
                                            type={type}
                                            rating={(Math.round(rating * 2)) / 2}
                                            total_reviews={total_reviews}
                                            picture={picture}
                                            store_name={store_name}
                                            display_name={display_name}
                                            distance={distance}
                                        />
                                    ))
                                    :
                                    searchResults.map(({
                                        _id: id,
                                        display_name,
                                        store_name,
                                        rating,
                                        total_reviews,
                                        distance,
                                        store_cover_picture
                                    }, key) => (
                                        <TwrCard2
                                            key={key}
                                            id={id}
                                            type={type}
                                            rating={(Math.round(rating * 2)) / 2}
                                            total_reviews={total_reviews}
                                            picture={store_cover_picture}
                                            store_name={store_name}
                                            display_name={display_name}
                                            distance={distance}
                                        />
                                    ))
                            }
                            {
                                Array(3 - (searchResults.length % 3)).fill('').map((e, i) => <Col xs={0} md={0} lg={8} key={i}></Col>)
                            }
                            {
                                (!searchResults.length) && !isLoading &&
                                <div className="empty-search h-100 w-100 d-flex">
                                    <div className='m-auto text-center'>
                                        <AiOutlineInbox size={75} />
                                        <h1 className='text-center'>No exact matches found for your search</h1>
                                    </div>
                                </div>
                            }
                        </Row>
                }
            </Spin>

            {
                (limit < total || currentPage > 1) &&
                <div className="text-center">
                    <Pagination
                        defaultCurrent={currentPage}
                        total={total}
                        pageSize={limit}
                        onChange={handlePagination}
                    />
                </div>
            }
        </div>
    );
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getSearch,
    searchStore
}, dispatch);

export default connect(null, mapDispatchToProps)(SearchDetail);