import React from 'react';
import { Spin, Comment, Tooltip, Rate } from 'antd';
import './styles.scss';
import Avatar from 'antd/lib/avatar/avatar';
import moment from 'moment';

function getImage(photo_urls) {
    let image = "";
    if (Array.isArray(photo_urls) && photo_urls.length) {
        image = photo_urls[0].url;
    }

    return image;
}

export default function ReviewTab({ loading, reviews }) {

    if (loading)
        return (
            <div
                style={{ height: 500 }}
                className='w-100 d-flex justify-content-center align-items-center'
            >
                <Spin size='large'></Spin>
            </div>
        );

    return (
        Array.isArray(reviews) && reviews.length ?
            <React.Fragment>

                <h5>Reviews</h5>
                <div className='d-flex flex-column mb-5'>
                    {
                        reviews.map((e, i) => (
                            e.reviewee ?
                                <Comment key={i}
                                    actions={null}
                                    author={e.reviewee.display_name}
                                    avatar={
                                        <Avatar
                                            src={getImage(e.reviewee.image_urls) ? window._env.REACT_APP_PUBLIC_URL_HOST + getImage(e.reviewee.image_urls) : ''}
                                            alt={e.reviewee.display_name}
                                        >
                                            {e.reviewee.display_name[0]}
                                        </Avatar>
                                    }
                                    content={
                                        <div>
                                            <Rate allowHalf disabled defaultValue={e.rating} style={{ fontSize: 15 }} /><br />
                                            {
                                                Array.isArray(e.reviews) && e.reviews.length >= 2 ?
                                                    e.reviews[1].text
                                                    :
                                                    null
                                            }
                                        </div>
                                    }
                                    datetime={
                                        e.created_at ?
                                            <Tooltip title={moment(e.created_at).format('YYYY-MM-DD HH:mm:ss')}>
                                                <span>{moment(e.created_at).fromNow()}</span>
                                            </Tooltip>
                                            :
                                            null
                                    }
                                />
                                :
                                null
                        ))
                    }
                </div>
            </React.Fragment>
            :
            null

    );
}