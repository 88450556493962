import React, { useState } from 'react';
import { Modal, Input } from 'antd';
import { useDispatch } from 'react-redux';
import { cancelOrder } from '../../../actions/order';

export default function CancelOrderForm({ id, visible, setVisible, refresh }) {

    const [reason, setReason] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState("");

    let dispatch = useDispatch();

    function onReasonChange(ev) {
        setReason(ev.target.value);
    }

    async function onSubmit() {
        setLoading(true);
        try {
            await cancelOrder({ id, reason: reason.trim() })(dispatch);
            refresh();
        } catch (err) {
            setError(err.message);
        }
        setLoading(false);
    }

    return (
        <div>

            <Modal
                title="Cancel Order"
                visible={visible}
                okText="Cancel Order"
                okButtonProps={{ loading: loading }}
                onOk={onSubmit}
                onCancel={() => loading ? '' : setVisible(false)}
                wrapClassName="modal-content-wrapper"
            >

                <div className={`form-field`}>

                    <label>
                        Please provide a reason for cancel order.
                    </label>

                    <Input.TextArea rows={4} onChange={onReasonChange} />

                    {
                        error.length > 0 &&
                        <span className="error">
                            {error}
                        </span>
                    }

                </div>

            </Modal>

        </div>
    );

}