import React, { useState, useEffect } from 'react';
import { Modal, Slider, Grid, Row, Col } from 'antd';
import AvatarEditor from 'react-avatar-editor';

const { useBreakpoint } = Grid;

function ResizeModal({ handleSubmit, handleCancel, modalVisible, file, width, height }) {

    const [visible, setVisible] = useState(false);
    const [scale, setScale] = useState(1);
    const [position, setPosition] = useState({ x: 0.5, y: 0.5 });
    let editor = null;

    useEffect(() => {
        setVisible(modalVisible);
    }, [modalVisible]);

    const setEditorRef = (e) => editor = e;

    const screens = useBreakpoint();
    function onSubmit(ev) {
        if (editor) {
            /**
             * This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
             * drawn on another canvas, or added to the DOM.
             */
            editor.getImageScaledToCanvas().toBlob((Blob) => {
                handleSubmit(Blob);
            });
        }
    }

    const style = {
        display: 'flex',
        alignItems: 'center'
    };

    function handlePositionChange(position) {
        setPosition(position);
    }
    
    return (
        <div>
            <Modal
                title="Crop Image"
                visible={visible}
                okText="Submit"
                okButtonProps={{ htmlType: "submit" }}
                onOk={onSubmit}
                onCancel={handleCancel}
                wrapClassName="modal-content-wrapper"
                width={`${width + 100 + 200}px`}
            >
                <Row gutter={[0, 24]}>
                    <Col xs={24} md={19} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        {
                            file &&
                            <AvatarEditor
                                ref={setEditorRef}
                                image={file}
                                width={width}
                                height={height}
                                border={50}
                                scale={scale}
                                onPositionChange={handlePositionChange}
                                position={position}
                                disableHiDPIScaling={true}
                                style={{width: screens.xs ? 250 : width , height: screens.xs ? 250*height/width : height, }}
                            />
                        }
                    </Col>

                    <Col xs={24} md={{span: 3, offset: 2}} style={style}>
                        <Slider style={{width: '100%'}}
                            vertical={screens.md}
                            min={0.5} max={2} step={0.1}
                            defaultValue={1} onChange={(val) => setScale(val)}
                        />
                    </Col>
                </Row>
            </Modal>
        </div>
    );

}


export default ResizeModal;
