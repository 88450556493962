import React, { useEffect, useState } from "react";
import { Button, Spin, Divider, Select, Row, Col, Pagination } from "antd";
import { AiOutlineInbox } from "react-icons/ai";
import { HiOutlinePlus } from 'react-icons/hi';
import { useHistory } from "react-router-dom";

import ProjectItem from "./projectItem";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { checkUserProjectLimit, getAllProjects, setProjectDetail, removeProject } from "../../../actions/project";

const statusOptions = [
    { text: "All", value: "" },
    { text: "Published", value: "PUBLISHED" },
    { text: "Active", value: "DRAFT" },
    { text: "Deleted", value: "DELETED" }
];

export default function Projects() {

    const [filter, setFilter] = useState('');
    const [page, setPage] = useState(1);
    const pageSize = 12;

    const dispatch = useDispatch();
    const history = useHistory();

    const { projects, isLoading, totalProjects } = useSelector(st => st.project);

    useEffect(() => {
        getAllProjects(filter, (page - 1), pageSize)(dispatch);
    }, [dispatch, filter, page]);

    function handlePageClick(data) {
        setPage(data);
    }

    function handleStatusFilter(status) {
        setFilter(status);
    }

    async function serviceLimitValidator() {
        const result = await checkUserProjectLimit()(dispatch);
        if (result && result.isUserAllow) {
            setProjectDetail({});
            history.push('/seller/project/create');
        }
    }

    async function refresh() {
        await getAllProjects(filter, (page - 1), pageSize)(dispatch);
    }

    return (
        <Spin spinning={isLoading}>
            <div className="dashboard-services">

                <Row type="flex" justify="space-between" className="add-service" gutter={[0, 24]}>
                    <Col xs={12}>
                        <Select
                            value={filter}
                            onChange={handleStatusFilter}
                            className="status-filter"
                        >
                            {
                                statusOptions.map((item, key) =>
                                    <Select.Option value={item.value} key={key} data-id={`option-${key}`}>
                                        {item.text}
                                    </Select.Option>
                                )
                            }
                        </Select>
                    </Col>
                    <Col xs={12}>
                        <Button shape='round' className="ant-btn-primary" onClick={serviceLimitValidator}
                            icon={<HiOutlinePlus size={20} />}
                        >
                            Add
                        </Button>
                    </Col>
                </Row>

                <Divider />

                {
                    projects.length === 0 &&
                    <div className="no-service-found">
                        <AiOutlineInbox size={70} />
                        <span>No Events found</span>
                    </div>
                }

                <Row gutter={[16, 16]} className='align-items-stretch'>
                    {
                        Array.isArray(projects) && projects.map((item, key) =>
                            <ProjectItem
                                key={key}
                                {...item}
                                onRemove={removeProject}
                                refresh={refresh}
                                projectDetail={item}
                            />
                        )
                    }
                    {
                        // this maintains the layout if there are odd no. of projects. DO NOT REMOVE
                        Array.isArray(projects) && projects.length % 2 !== 0 &&
                        <Col xs={24} md={12}>
                        </Col>
                    }
                </Row>

                {
                    totalProjects > pageSize
                        ?
                        <Pagination onChange={handlePageClick} defaultCurrent={1} pageSize={pageSize} total={totalProjects} />
                        :
                        null
                }

            </div>
        </Spin>
    );
}