import React from 'react';
import { Row, Col, Avatar } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

import image1 from '../../images/how-it-works5.svg';
import image2 from '../../images/how-it-works6.svg';
import image3 from '../../images/how-it-works7.svg';
import CloudImg from '../../images/landingPage/desktop/Source-17.svg';
import './style.scss';

export default function Section2() {

    let breakpoint = useBreakpoint();
    const data = [
        {
            img: image1,
            title: 'ORDER PLACED/RECEIVED',
            description: 'This is where foodies place the order and food-partners receive the orders.'
        },
        {
            img: image2,
            title: 'PAYMENT',
            description: "Foodies make the payment according to the food-partner's preferred payment mode."
        },
        {
            img: image3,
            title: 'ORDER DELIVERY/ PICK-UP',
            description: 'Food is picked-up/delivered as per the terms of the order.'
        },
    ];

    return (
        <div className='position-relative'>

            <img className="d-none d-md-inline-block position-absolute" style={{ width: '6vw', top: 0, right: 0 }}
                src={CloudImg} alt="Welcome To Foodelys" />

            <Row className="d-none d-md-flex" style={{ marginTop: '5rem', marginBottom: '5rem' }}>

                <Col xs={24} md={{ span: 20, offset: 2 }} xl={{ span: 16, offset: 4 }} xxl={{ span: 12, offset: 6 }}>
                    {
                        data.map((x, i) => {
                            return (
                                <Row
                                    key={i} className='my-4' align='middle'
                                    style={{ boxShadow: '1px 1px 5px 1px #ccc', height: '15rem', borderRadius: 10 }}
                                >
                                    <Col md={8} className='pl-3 py-3 h-100 d-flex'>
                                        <img src={x.img} alt="icon" className='img img-fluid' />
                                    </Col>
                                    <Col md={16} className='pr-3 py-3'>
                                        <h4 className={breakpoint.lg ? "section-heading-2" : "section-heading"} >{x.title}</h4>
                                        <div className={breakpoint.lg ? 'section-content-2' : "section-content"}>{x.description}</div>
                                    </Col>
                                    <Avatar
                                        size={breakpoint.lg ? 50 : 30}
                                        style={{
                                            backgroundColor: '#ff9f0d',
                                            color: 'white',
                                            fontSize: breakpoint.lg ? '1.6rem' : '0.9rem',
                                            left: breakpoint.lg ? -25 : -15,
                                            top: breakpoint.lg ? 'calc(7.5rem - 25)' : 'calc(7.5rem - 15)'
                                        }}
                                        className='position-absolute'
                                    >
                                        {5 + i}
                                    </Avatar>
                                </Row>
                            );
                        })
                    }
                </Col>
            </Row>
            <>
                <div className="top-wave d-md-none">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 100" preserveAspectRatio="none">
                        <path className="wavePath-haxJK1"
                            d="M826.337463,25.5396311 C670.970254,58.655965 603.696181,68.7870267 447.802481,35.1443383 C293.342778,1.81111414 137.33377,1.81111414 0,1.81111414 L0,150 L1920,150 L1920,1.81111414 C1739.53523,-16.6853983 1679.86404,73.1607868 1389.7826,37.4859505 C1099.70117,1.81111414 981.704672,-7.57670281 826.337463,25.5396311 Z"
                            fill="currentColor">
                        </path>
                    </svg>
                </div>
                <Row type="flex" justify="center" align="middle"
                    id='buy_services_online' className="d-md-none" style={{ backgroundColor: 'rgb(249 216 222)', borderRadius: 10 }}>

                    <Col className="d-flex flex-column align-items-center justify-content-center" >
                        {data.map((x, i) => {
                            return (
                                <div className='my-4 px-2' key={i} >
                                    <Row className='py-3'
                                        align='middle' style={{ boxShadow: '1px 1px 5px 1px #fcb5c2', backgroundColor: '#fff' }}>

                                        <Col xs={24}>
                                            <h4 className="section-heading d-flex align-items-center px-1">
                                                <div>
                                                    <Avatar
                                                        size={breakpoint.lg ? 50 : 30}
                                                        style={{ backgroundColor: '#ff9f0d', color: 'white', fontSize: breakpoint.lg ? '1.6rem' : '0.9rem' }}
                                                        className='mr-3'
                                                    >
                                                        {5 + i}
                                                    </Avatar>
                                                </div>
                                                {x.title}
                                            </h4>
                                        </Col>
                                        <Col xs={8}>
                                            <img src={x.img} alt="icon" className='img img-fluid' />
                                        </Col>
                                        <Col xs={16} className='p-3'>
                                            <div className='section-content-mobile'>{x.description}</div>
                                        </Col>
                                    </Row>
                                </div>
                            );
                        })
                        }
                    </Col>
                </Row>
            </>
        </div>
    );

}
