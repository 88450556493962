import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Home from '../components';
import { setSearchDetail, getSearch } from "../actions/searchDetail";
import {emailDisposal} from '../actions/user';
import { page_visit } from '../actions/analytics';

const mapStateToProps = ({searchDetail, user, app}) => ({user, ...searchDetail, address: app.address_from_ip});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    setSearchDetail,
    getSearch,
    emailDisposal,
    page_visit
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Home);