import React, { useEffect } from "react";
import Section0 from "./section0";
import Section1 from "./section1";
import Section2 from "./section2";
import Section3 from "./section3";
import Section4 from "./section4";
import Section5 from "./section5";
import Section6 from "./section6";
import "./style.scss";
import { Helmet } from 'react-helmet-async';


function Home({ page_visit }) {

    useEffect(() => {
        page_visit('/home');
    }, [page_visit]);

    return (
        <div className="homepage">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Foodelys</title>
                <meta
                    name="description"
                    content={window._env.REACT_APP_SEO_DESCRIPTION}
                />
                <meta
                    name="keywords"
                    content={window._env.REACT_APP_SEO_META_TAGS}
                />
            </Helmet>
            <Section0 />
            <Section1 />
            <Section2 />
            <Section3 />
            <Section4 />
            <Section5 />
            <Section6 />
        </div>
    );

}

export default Home;
