import React, { Component } from "react";
import { Field } from "redux-form";

import {
    Input,
    validate,
    Rate
} from "../../../../common/redux-form";

const defaultFields = [
    {
        factor: "How do you rate your client?",
        text: "",
        rating_score: 0,
        type: "RATING"
    },
    {
        factor: "Please share your comments.",
        text: "",
        rating_score: 0,
        type: "TEXTAREA"
    },
];

class ReviewAndRatingFields extends Component {

    componentDidMount() {
        const { fields } = this.props;
        if (!fields.length)
            defaultFields.forEach(item => {
                let { factor, text, rating_score } = item;
                fields.push({ factor, text, rating_score });
            });
    }


    render() {
        const {
            fields,
            disabled
        } = this.props;

        return fields.map((fieldItem, key) => (

            <div className="rating-block" key={key}>
                {
                    defaultFields[key].type === "RATING"
                    &&
                    <Field
                        name={`${fieldItem}.rating_score`}
                        component={Rate}
                        label={defaultFields[key].factor}
                        validate={validate.requireNumber}
                        disabled={disabled}
                        style={{fontSize : "10px"}}
                        width="0.5em"
                    />
                }
                {
                    defaultFields[key].type === "TEXTAREA"
                    &&
                    <Field
                        name={`${fieldItem}.text`}
                        component={Input}
                        label={defaultFields[key].factor}
                        type="textarea"
                        validate={validate.required}
                        props={{ disabled: disabled }}
                    />
                }
            </div>
        )
        );

    }
}

export default ReviewAndRatingFields;
