import React, { Component } from "react";
import { Row, Col, Button } from 'antd';
import { reduxForm, Field, SubmissionError } from "redux-form";
import { validate, Input, Checkbox as ReduxCheckbox } from "../../common/redux-form";

class Deactivate extends Component {

    handleDeactivate = async (values) => {
        const { deactivate } = this.props;
        try {
            if (values.checked) {
                deactivate(values.deactivate);
            }
            else {
                throw new Error("click the checkbox");
            }
        } catch (error) {
            throw new SubmissionError({ _error: error.message });
        }
    };

    render() {

        const { handleSubmit, error, user } = this.props;

        return (

            <div className="billing">
                <Row className="tab-header">
                    <Col span={24}>
                        <h3 className="title">
                            <span>Account Deactivation</span>
                        </h3>
                    </Col>
                </Row>

                <Row type='flex' justify='center' className='tab-body'>
                    <Col>
                        <h4 style={{ color: "black", fontWeight: "bold" }}>
                            Are you sure you want to deactivate your account?
                        </h4>
                        <p>
                            When you deactivate your account, you will lose access to all of your data
                        </p>
                        <form onSubmit={handleSubmit(this.handleDeactivate)}>
                            <Field
                                name="deactivate"
                                component={Input}
                                label=""
                                type="textarea"
                                placeholder="Reason for deactivation of your account(optional)"
                                validate={validate.maxLength300}
                            />

                            <Field
                                name="checked"
                                component={ReduxCheckbox}
                                label="I want to deactivate my account"
                            />
                            <div className="form-error">{error}</div>
                            <Button shape='round'
                                className="btn-primary "
                                type="primary" size={'large'}
                                style={{ marginTop: "15px" }}
                                loading={user.submitting || user.isLoading}
                                htmlType="submit"
                            >
                                Deactivate Account
                            </Button>
                        </form>
                    </Col>
                </Row>
            </div>
        );
    }
}

Deactivate.defaultProps = {
    form: {},
    onDeactivate: (values) => values
};

export default reduxForm({
    form: "deactivate",
    enableReinitialize: true
})(Deactivate);

