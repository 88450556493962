import React, { Component } from "react";
import {  Row, Col, Spin, Button } from 'antd';
import { AiOutlineMessage, AiOutlineArrowLeft } from "react-icons/ai";

import ReviewAndRatingForm from "./form";

import "./styles.scss";

class ProReviewAndRating extends Component {

    componentDidMount() {
        this.getReviewAndRating();
    }

    getReviewAndRating = () => {
        const { ProGetReviewByTaskId, ProGetReviewByJobId, match: { params: { uid, product } } } = this.props;
        if(!uid) {
            return;
        }
        try {
            if(product === 'SR')
                ProGetReviewByTaskId(uid);
            else ProGetReviewByJobId(uid);
        } catch(e) {
            this.handleBack();
        }
    };

    handleBack = () => {
        const { history } = this.props;
        history.goBack();
    };

    componentWillUnmount() {
        this.props.setReviewAndRating({ reviewAndRating: null });
    }

    render() {
        const {
            isLoading,
            ProSubmitReviewRating,
            ProSubmitServiceGigReviewRating,
            reviewAndRating,
            match: { params: { uid, product } }
        } = this.props;

        return (
            <div className="review-and-rating">
                <Row type='flex' justify="center">
                    <Col sm={24}>
                        <div className="title-block">
                            <Button shape='round'
                                icon={<AiOutlineArrowLeft size={20} />}
                                onClick={this.handleBack}
                                className="back-btn"
                            >
                                Back
                            </Button>
                            <AiOutlineMessage size={20}/>
                            <span className="title">Review &amp; Rating</span>
                            <div className="clear-both" />
                        </div>
                    </Col>
                    <Col sm={22} lg={15}>
                        <Spin spinning={isLoading}>
                            <ReviewAndRatingForm
                                uid={uid}
                                product={product}
                                ProSubmitReviewRating={ProSubmitReviewRating}
                                ProSubmitServiceGigReviewRating={ProSubmitServiceGigReviewRating}
                                goBack={this.handleBack}
                                reviewAndRating={reviewAndRating}
                                initialValues={reviewAndRating}
                            />
                        </Spin>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default ProReviewAndRating;
