import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AccountManagement from '../components/accountManagement';
import * as userActions from '../actions/user';
import { getUserDetail, supportEmail } from '../actions/user';
import { onSubCategorySearch } from "../actions/subCategory";
import { getAllCategories } from "../actions/category";
import { getServiceArea, onServiceSearch } from "../actions/serviceArea";
import { serviceOptionSelector } from '../selector';
import { getManualDataByName } from '../actions/manual';
import { page_visit } from '../actions/analytics';

const mapStateToProps = (state) => {
    const { user, manual: { manualData }, category: { subCategories, categories } } = state;
    
    return ({
        user,
        serviceArea: serviceOptionSelector(state),
        subCategories,
        categories,
        manualData
    });
};


const mapDispatchToProps = (dispatch) => bindActionCreators({
    ...userActions,
    getUserDetail,
    getAllCategories,
    onSubCategorySearch,
    getServiceArea,
    onServiceSearch,
    supportEmail,
    getManualDataByName,
    page_visit
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AccountManagement);
