import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { deactivate } from "../../actions/user";
import Deactivate  from '../../components/accountManagement/deactivate';
const mapStateToProps = ({
    user: { isAuthenticated, isLoading },
    app:  { isResetPasswordMode }
}) => ({
    isAuthenticated,
    isLoading,
    isResetPasswordMode
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    deactivate
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Deactivate);