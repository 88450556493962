import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { AiOutlineClose, AiOutlineBars, AiOutlineShoppingCart } from "react-icons/ai";
import AuthHeader from "./authHeader";
import DefaultHeader from "./defaultHeader";
import Logo from "../../images/logo.png";
import AuthMobileMenu from "./authMobileMenu";
import SearchBox from "./search";
import { Badge, Col, Row } from "antd";
import { useCookies } from "react-cookie";
import { decode } from "../../common/order_utilities";
import { primaryColor } from "../../common/theme";
import { useDispatch, useSelector } from "react-redux";
import { getOrderCount } from "../../actions/order";

const forbidden_status = ["REGISTRATION_PENDING", "DELETED", "ACCOUNT_CLOSED", "ADMIN_DELETED"];

export default function Header({
    onLogout,
    toggleSideMenu,
    onSetSearch,
    getSearch,
    searchStore,
    getUnreadMessageCount
}) {


    const { status, scope, isAuthenticated, first_name, image_urls } = useSelector(st => st.user);
    const { unreadCount } = useSelector(st => st.messages);
    const { isOpenSideMenu } = useSelector(st => st.app);
    let { searchText } = useSelector(st => st.searchDetail);
    let dispatch = useDispatch();

    const [orderCount, setOrderCount] = useState(0);
    const [showSearchBar, setShowSearchBar] = useState(false);
    const [cookies] = useCookies(['order']);
    const menuClass = isOpenSideMenu ? "open" : "";
    const isRegistrationDone = !forbidden_status.includes(status);

    let location = useLocation();

    const handleCloseOnLink = () => {
        if (isOpenSideMenu) {
            toggleSideMenu();
        }
    };

    useEffect(()=>{
        if(scope === 'PROVIDER') {
            getOrderCount()(dispatch);
        }
    }, [scope, dispatch]);

    useEffect(() => {
        if (cookies?.order) {
            let order = decode(cookies.order);
            setOrderCount(Array.isArray(order?.lineItems) ? order.lineItems.length : 0);
        } else setOrderCount(0);
    }, [cookies]);

    const handleCallLogo = async () => {
        const body = {
            isAdvanceSearch: "",
            searchText: "",
            type: "",
            postal_code: "",
            skip: 0
        };
        await onSetSearch(body);
        await getSearch();
    };

    const handleToggleMobSearch = () => {
        setShowSearchBar(!showSearchBar);
    };

    return (
        <header>
            <Row className="container wrapper" align="middle" justify="space-around">

                <Col xs={3} md={2} lg={0} className='text-left'>
                    {
                        isOpenSideMenu ?
                            <AiOutlineClose onClick={toggleSideMenu} size={25} />
                            :
                            <AiOutlineBars onClick={toggleSideMenu} size={25} />
                    }
                </Col>

                <Col className="logo" xs={showSearchBar ? 0 : 15} sm={showSearchBar ? 0 : 8} md={7} lg={5}>
                    {
                        isAuthenticated && scope === "CONSUMER"
                            ? location.pathname === '/search' ?
                                <div onClick={handleCallLogo}>
                                    <img src={Logo} alt="Logo" className='img img-fluid' />
                                </div> :
                                <Link to='/search'>
                                    <img src={Logo} alt="Logo" className='img img-fluid' />
                                </Link>
                            :
                            isAuthenticated && scope === "PROVIDER" ?
                                <Link to='/seller/orders'>
                                    <img src={Logo} alt="Logo" className='img img-fluid' />
                                </Link> :
                                <Link to='/'>
                                    <img src={Logo} alt="Logo" className='img img-fluid' />
                                </Link>
                    }
                </Col>

                <Col xs={showSearchBar ? 18 : 3} sm={showSearchBar ? 18 : 10} md={13} lg={!isAuthenticated || scope === 'PROVIDER' ? 0 : 6}>
                    <SearchBox
                        searchText={searchText}
                        onSetSearch={onSetSearch}
                        getSearch={getSearch}
                        handleToggleMobSearch={handleToggleMobSearch}
                        showSearchBar={showSearchBar}
                        searchStore={searchStore}
                    />
                </Col>

                <Col xs={3} md={2} lg={0} className='text-center'>
                    <Badge count={orderCount} color={primaryColor}>
                        <Link to='/buyer/checkout'>
                            <AiOutlineShoppingCart size={25} color='#444' />
                        </Link>
                    </Badge>
                </Col>

                <Col xs={0}
                    lg={{
                        span: isAuthenticated ? (scope === 'PROVIDER' ? (isRegistrationDone ? 16 : 4) : 13) : 8,
                        offset: isAuthenticated ? (scope === 'PROVIDER' ? (isRegistrationDone ? 3 : 15) : 0) : 6
                    }}
                    xl={{
                        span: isAuthenticated ? (scope === 'PROVIDER' ? (isRegistrationDone ? 14 : 4) : 11) : 11,
                        offset: isAuthenticated ? (scope === 'PROVIDER' ? (isRegistrationDone ? 5 : 15) : 2) : 3
                    }}
                >
                    <ul className={`menu ${menuClass}`}>
                        {
                            isAuthenticated ?
                                <AuthHeader
                                    onLogout={onLogout}
                                    onClose={handleCloseOnLink}
                                    userImage={Array.isArray(image_urls) && image_urls.length ? image_urls[0] : ''}
                                    firstName={first_name}
                                    scope={scope}
                                    location={location}
                                    unreadCount={unreadCount}
                                    {...{
                                        getUnreadMessageCount
                                    }}
                                    isRegistrationDone={isRegistrationDone}
                                />
                                :
                                <DefaultHeader onClose={handleCloseOnLink} />
                        }
                    </ul>
                </Col>

                <Col xs={24} lg={0}>
                    <ul className={`menu side-menu ${menuClass}`}>
                        {
                            isAuthenticated ?
                                <AuthMobileMenu
                                    onLogout={onLogout}
                                    onClose={handleCloseOnLink}
                                    userImage={Array.isArray(image_urls) && image_urls.length ? image_urls[0] : ''}
                                    firstName={first_name}
                                    scope={scope}
                                    location={location}
                                    userStatus={status}
                                    unreadCount={unreadCount}
                                />
                                :
                                <DefaultHeader onClose={handleCloseOnLink} />
                        }
                    </ul>
                </Col>

            </Row>
        </header>
    );

}