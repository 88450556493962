import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import React, { useState } from 'react';
import { AiOutlineClose, AiOutlineBars } from "react-icons/ai";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Button } from 'antd';

import Foodelys from '../../images/logo.png';
import Foodelys_white from '../../images/Source-white.png';

import './alt-styles.scss';

export default function HeaderAlt({ type }) {

    const breakpoint = useBreakpoint();
    const [menuOpen, setMenuOpen] = useState(false);

    let history = useHistory();

    return (
        <React.Fragment>
            <div className={menuOpen && !breakpoint.lg ? 'drawer-open' : 'drawer-close'}>
                <div className='w-100 d-flex'>
                    <AiOutlineClose size={25} onClick={() => setMenuOpen(false)} className='ml-auto m-2' />
                </div>
                <ul className='w-100 h-100 d-flex flex-column align-items-stretch list-unstyled'>
                    <li id='hiwm-seller'>
                        <Link to={'/how-it-works-for-provider'}
                            className="home-page-link" >
                            How it works - Seller
                        </Link>
                    </li>
                    <li id='hiwm-buyer'>
                        <Link to={'/how-it-works-for-seeker'}
                            className="home-page-link" >
                            How it works - Buyer
                        </Link>
                    </li>
                    <li id='m-faqs'>
                        <Link to={("faq")} className="home-page-link" >
                            FAQs
                        </Link>
                    </li>
                    <li id='m-sign-in'>
                        <Button shape='round' className="sign-in-mobile-button" onClick={() => history.push('/login')}>
                            <strong>Sign in</strong>
                        </Button>
                    </li>
                </ul>
            </div>

            <Row align='middle' justify='space-between' className='pt-3 pr-0 pr-xl-4'>
                <Col xl={{ offset: 1 }} className='pl-3 d-flex flex-row align-items-center'>
                    {breakpoint.lg ? null : <AiOutlineBars size={25} color={type === 'DARK' ? '#000' : '#fff'} onClick={() => setMenuOpen(true)} />}
                    <img className="img img-fluid ml-2 pointer" onClick={() => history.push('/')}
                        src={breakpoint.lg || type === 'DARK' ? Foodelys : Foodelys_white} alt="Welcome To Foodelys" style={{ minWidth: breakpoint.lg ? 180 : 110 }}
                    />
                </Col>
                <Col className='pr-3'>
                    <ul className='d-none d-lg-flex align-items-center ml-auto mb-0 list-unstyled section-link'>
                        <li id='hiwd-seller'>
                            <Link to={'/how-it-works-for-provider'}
                                className="home-page-link" >
                                How it works - Seller
                            </Link>
                        </li>
                        <li id='hiwd-buyer'>
                            <Link to={'/how-it-works-for-seeker'}
                                className="home-page-link" >
                                How it works - Buyer
                            </Link>
                        </li>
                        <li id='hiwd-faqs'>
                            <Link to={("faq")} className="home-page-link" >
                                FAQs
                            </Link>
                        </li>
                        <li id='hiwd-signin'>
                            <Button shape='round' className="sign-in-button mr-2" onClick={() => history.push('/login')}>
                                <strong>Sign in</strong>
                            </Button>
                        </li>
                    </ul>
                    <Button shape='round' className='sign-in-mobile-button-2 d-inline-block d-lg-none' onClick={() => history.push('/login')}>
                        Sign In
                    </Button>
                </Col>
            </Row>
        </React.Fragment>
    );
}