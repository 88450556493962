import { toast } from 'react-toastify';
import { Fetch } from "./common";

/**
 * Set review and rating data
 * @param {object} payload app data
 * @returns {object} updated payload
 */
export const setReviewAndRating = (payload = {}) => ({
    type: 'SET_REVIEW_AND_RATING',
    payload
});

/**
 * get provider review and rating data
 * @param {String} taskId task uid
 * @param {String} sellerUserId seller mongo id
 */
export const getReviewByOrderId = (invoice_id) => dispatch => {

    if (!invoice_id) {
        return false;
    }

    const url = `/review?invoice_id=${invoice_id}`;
    dispatch(setReviewAndRating({ isLoading: true }));
    return Fetch(url, "GET").then(({ status, data = {} }) => {
        if (status === 200 || status === 201) {
            dispatch(setReviewAndRating({ reviewAndRating: data, isLoading: false }));
            return data;
        }
        throw new Error(data.message);
    }).catch((error) => {
        dispatch(setReviewAndRating({ reviewAndRating: null, isLoading: false }));
    });
};

/**
 * Set review and rating data
 * @param {object} params request params
 */
export const submitReviewRating = (params = {}) => dispatch => {

    const url = `/review`;
    const options = {
        data: JSON.stringify(params)
    };

    dispatch(setReviewAndRating({ isLoading: true }));
    return Fetch(url, "POST", options).then(({ status, data = {} }) => {
        if (status === 201) {
            toast.success("Review recorded successfully");
            dispatch(setReviewAndRating({ isLoading: false }));
            return data;
        }

        throw new Error(data.message);
    }).catch((error) => {
        dispatch(setReviewAndRating({ isLoading: false }));
        throw new Error(error.message);
    });
};