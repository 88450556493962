const initialState = {
    isLoading: false,
    searchText: "",
    limit: 30,
    skip: 0,
    searchResults: [],
    total: 0,
    currentPage: 1,
    sort_by: "-1",
    postal_code: "",
    filter: "",
    city: "",
    type: "",
    lat: "",
    lng: "",
    isAdvanceSearch: false,
    searchLocation: "",
    searchType: "DISH"
};

const searchDetail = (state = initialState, action) => {
    const { type, payload } = action;
    switch(type) {
        case "SET_SEARCH_DETAIL":
            return { ...state, ...payload };

        default:
            return state;
    }
};

export default searchDetail;
