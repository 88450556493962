import React, { Component } from "react";
import {
    Input as AntInput,
    Popover
} from "antd";
import { AiFillQuestionCircle } from "react-icons/ai";

const { TextArea } = AntInput;

class Input extends Component {

    render() {

        const {
            input,
            placeholder,
            size,
            meta: { touched, error, warning },
            className,
            errorClass,
            label,
            labelClass,
            type,
            isRequired,
            autoSize,
            tooltipText,
            tooltipPlacement,
            tooltipIcon,
            readOnly,
            disabled,
            defaultVal,
            rows,
            maxLength,
        } = this.props;

        const isError = touched && (error || warning);
        const errorText = error || warning;
        const fieldError = isError ? "field-error" : "";
        const noError = !isError ? "no-error" : "";
        const ViewType = type === "textarea" ? TextArea : AntInput;
        const extraProps = {};
        const { size: iconSize, color, Icon } = tooltipIcon;

        if (type === "textarea") {
            extraProps.autoSize = autoSize;
        }

        return (
            <div className={`form-field ${className} ${fieldError}`}>
                {
                    label &&
                    <label className={labelClass}>
                        {label}
                        {isRequired ? <span className="required-label"><sup>{" * "}</sup></span> : ""}
                        {' '}
                        {
                            tooltipText &&
                            <Popover content={tooltipText} trigger="hover" placement={tooltipPlacement}>
                                <span>
                                    <Icon color={color} size={iconSize}/>
                                </span>
                            </Popover>
                        }
                    </label>
                }
                {defaultVal ? <AntInput defaultValue={defaultVal} disabled={disabled} size={size} /> :
                    <ViewType
                        {...input}
                        {...extraProps}
                        size={size}
                        placeholder={placeholder}
                        type={type}
                        readOnly={readOnly}
                        disabled={disabled}
                        rows={rows}
                        maxLength={maxLength}
                    />
                }
                {
                    <span className={`error ${errorClass} ${noError}`}>
                        {isError && errorText}
                    </span>
                }
            </div>
        );
    }
}

Input.defaultProps = {
    input: {},
    meta: {},
    placeholder: "",
    size: "large",
    className: "",
    errorClass: "",
    label: "",
    labelClass: "",
    type: "text",
    isRequired: false,
    autosize: { minRows: 5, maxRows: 5 },
    tooltipPlacement: "rightTop",
    readOnly: false,
    disabled: false,
    maxLength: null,
    tooltipIcon:{
        size: 20,
        color: '',
        Icon: AiFillQuestionCircle
    }
};

export default Input;
