import { reducer as formReducer } from "redux-form";
import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import { reducers } from "./reducers";
import { CheckAccessToken } from "./services/common";

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["form"], // only form and user state will be persisted
    // blacklist: [] // put state in blacklist will not be persisted
};

const appReducer = combineReducers({
    form: formReducer,
    ...reducers,
});

// Reset state on logout
const rootReducer = (state, action) => {
    if (action.type === "LOGOUT") {
        state = undefined;
    }
  
    return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(
    persistedReducer,
    composeEnhancer(applyMiddleware(thunk))
);
const persist_store = persistStore(store);



export const AppStore = () => {
    if (!CheckAccessToken()) {
        persist_store
            .purge()
            .then((removePS) => {
            })
            .catch((error) => {
            });
    }
    return { store, persist_store };
};
