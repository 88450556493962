import React, { useState } from "react";
import { Button } from "antd";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import { useDispatch } from "react-redux";

import "./style.scss";
import OrderStatusReasonForm from "./OrderStatusReasonForm";

import { OrderStatus } from "../../../constants/commonContext";

import DataCard from "../../../common/dataCard";
import ContactUsModal from "../../common/ContactUsModal";
import OrderDetail from "../../common/orderDetail";

import { createContactUsRequest, getChatsByUserids } from "../../../actions/message";

import { allowedActions } from "./context";
import { updateOrder } from "../../../actions/order";

export default function OrderCard({ order, refresh }) {

    const [modalVisible, setModalVisible] = useState(false);
    const [new_status, setNewStatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [statusLoading, setStatusLoading] = useState(false);
    const [showOrderDetail, setShowOrderDetail] = useState(false);

    let history = useHistory();
    let dispatch = useDispatch();

    const {
        _id,
        invoice_id,
        created_at,
        seller: {
            _id: seller_id
        },
        buyer,
        status,
        invoice_detail: {
            invoice_total,
            lineItems,
            payment_reference
        },
        delivery_method,
        delivery_address: da,
        pickup_address: pa,
        delivery_date_time,
        order_picture,
        allergies,
        distance
    } = order;

    const header_data = [{
        label: `Order #`,
        value: invoice_id,
        visible: true
    }, {
        label: 'Order Date',
        value: moment(created_at).format("ll"),
        visible: true
    }, {
        label: 'Status',
        value: OrderStatus[status],
        visible: true
    }, {
        label: 'Total',
        value: '$' + invoice_total,
        visible: true
    }];

    const data = [{
        label: 'Buyer',
        value: buyer.name,
        visible: true
    }, {
        label: 'Type',
        value: delivery_date_time ? <span>Plan ahead — <strong>{delivery_method === 'DELIVERY' ? 'Delivery' : 'Pickup'}</strong></span> : delivery_method === 'DELIVERY' ? 'Delivery' : 'Pickup',
        visible: true
    }, {
        label: 'Allergies',
        value: allergies,
        visible: allergies
    }, {
        label: delivery_method === 'DELIVERY' ? 'Delivery Date' : 'Pickup Date',
        value: delivery_date_time ? moment(delivery_date_time).format('lll') : moment(created_at).format('ll'),
        visible: true
    }];

    if (delivery_method === 'DELIVERY') {
        data.push({
            label: 'Delivery Address',
            value: (
                <span>
                    {
                        `${da.street_1}${da.street_2 ? `, ${da.street_2}` : ''}${da.city ? `, ${da.city}` : ''}, ${da.state}, ${da.postal_code} `
                    }
                    <strong>{distance ? `(approx ${distance} miles)` : ''}</strong>
                </span>
            ),
            visible: delivery_method === 'DELIVERY' && da
        });
    } else {
        data.push({
            label: 'Pickup Loc.',
            value: pa.display_address,
            visible: delivery_method === 'PICKUP' && pa
        });
    }

    const image = order_picture ? order_picture : '';


    async function createChat(message) {
        if (message.trim().length === 0) return;
        const body = {
            seller: seller_id,
            buyer: buyer._id,
            messages: [{
                sender: seller_id,
                receiver: buyer._id,
                text: message
            }]
        };
        try {
            setLoading(true);
            await createContactUsRequest(body)(dispatch);
            setModalVisible(false);
        } catch (e) {
            toast.error("Some error occurred, please try again");
        } finally {
            setLoading(false);
        }
    }

    async function openChat() {
        setLoading(true);
        try {
            const data = await getChatsByUserids(buyer._id, seller_id)(dispatch);
            if (data) history.push(`/seller/messages?chat=${data._id}`);
            else setModalVisible(true);
        } catch (e) {
            console.log(e);
            toast.error("Some error occurred, please try again");
        } finally {
            setLoading(false);
        }
    }

    async function updateOrderStatus(status) {
        try {
            setStatusLoading(true);
            await updateOrder({ id: _id, status })(dispatch);
            refresh();
        } catch (err) {
            console.log(err);
        }
        setStatusLoading(false);
    }

    const Action = () => (
        <React.Fragment>

            {

                allowedActions(status, 'REJECT') ?
                    <Button shape='round'
                        className="button-style" loading={statusLoading}
                        onClick={() => setNewStatus('ACCEPTED')}
                    >
                        Accept
                    </Button>
                    :
                    null
            }

            {

                allowedActions(status, 'REJECT') ?
                    <Button shape='round'
                        className="button-style" loading={statusLoading}
                        onClick={() => setNewStatus('REJECTED')}
                    >
                        Decline
                    </Button>
                    :
                    null
            }

            {

                allowedActions(status, 'CANCEL') ?
                    <Button shape='round'
                        className="button-style"
                        onClick={() => setNewStatus('CANCEL')}
                    >
                        Cancel
                    </Button>
                    :
                    null
            }

            {
                allowedActions(status, 'READY')
                    ?
                    <Button shape='round'
                        className="button-style" loading={statusLoading}
                        onClick={() => updateOrderStatus('READY')}
                    >
                        Order Ready
                    </Button>
                    :
                    null
            }

            {
                allowedActions(status, 'FULFILLED')
                    ?
                    <Button shape='round'
                        className="button-style" loading={statusLoading}
                        onClick={() => updateOrderStatus('FULFILLED')}
                    >
                        Order Fulfilled
                    </Button>
                    :
                    null
            }

            {
                allowedActions(status, 'CONTACT')
                    ?
                    <Button shape='round'
                        className="button-style"
                        onClick={openChat} loading={loading}
                    >
                        Contact Buyer
                    </Button>
                    :
                    null
            }

            <Button shape='round'
                className="button-style"
                onClick={() => setShowOrderDetail(true)}
            >
                View Details
            </Button>

        </React.Fragment>
    );

    return (
        <React.Fragment>
            <DataCard data={data} header_data={header_data} Action={Action} image={image} />
            <ContactUsModal
                visible={modalVisible} closeModal={setModalVisible}
                onSubmit={createChat} isLoading={loading}
            />
            <OrderStatusReasonForm id={_id} refresh={refresh} status={new_status} setVisible={() => setNewStatus('')} />

            <OrderDetail
                order={{
                    invoice_total, lineItems, payment_method: payment_reference?.tag, delivery_method,
                    da, pa, allergies, delivery_date_time, buyer, distance
                }}
                isVisible={showOrderDetail} handleClose={() => setShowOrderDetail(false)}
            />
        </React.Fragment>
    );
}
