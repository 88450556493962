import { Fetch } from "./common";
import { getUserDetail } from "./user";
import { USER_SCOPE } from "../components/registration/context";


export const setRegistration = (payload = {}) => ({
    type: "SET_REGISTRATION",
    payload
});


export const setRegistrationForm = (payload = {}) => ({
    type: "SET_REGISTRATION_FORM",
    payload
});



export const updateRegistration = (registrationData = {}, history) => (dispatch, getState) => {
    const {
        user: { username, scope }
    } = getState();

    const url =
        scope === USER_SCOPE.PROVIDER
            ? `/user/${username}/regprovider`
            : `/user/${username}/regconsumer`;


    const options = {
        data: JSON.stringify({ ...registrationData })
    };

    dispatch(setRegistration({ isLoading: true }));
    return Fetch(url, "PATCH", options).then(({ status, data = {} }) => {
        if (status === 200 || status === 201) {
            history.push('/');
            dispatch(setRegistration({ isLoading: false, formValue: {} }));
            dispatch(getUserDetail());
            return data;
        }

        throw new Error(data.message);
    }).catch((error) => {
        dispatch(setRegistration({ isLoading: false }));
    });
};
