import React, { Component } from "react";
import { Input, Avatar, Button, Spin,  Layout } from "antd";
import { AiOutlineRight } from "react-icons/ai";
import moment from "moment";
import { Scrollbars } from "react-custom-scrollbars";


const { Header, Footer, Content } = Layout;
const Message = ({ message, userId }) => {
    const isSender = message.sender._id === userId;
    const messageTime2 = moment(message.time).from(new Date());
    return (
        <React.Fragment>
            <div className={`${isSender ? "right" : "left"} flex`}>
                <div className={`message ${isSender ? "user" : "sender"}`}>
                    <p className="text text-break">{message.text}</p>
                </div>
                {
                    isSender ?
                        <div className="time-user">{messageTime2}</div>
                        :
                        <div className="time-sender">{messageTime2}</div>
                }
            </div>
            <div className="clear-both"></div>
        </React.Fragment>
    );
};

class ChatHistory extends Component {

    constructor(props) {
        super(props);
        this.scrollbars = React.createRef();
    }

    componentDidMount() {
        if (this.scrollbars.current) {
            this.scrollbars.current.scrollToBottom();
        }
    }

    handleSendMessage = (event) => {
        event.preventDefault();
        const { messages, user } = this.props;
        const { selectedUsers } = messages;
        const { scope = "" } = user;

        if (messages.chatInput.trim().length === 0) return;

        const sender = scope === "PROVIDER" ? selectedUsers.seller._id : selectedUsers.buyer._id;
        const receiver = scope === "PROVIDER" ? selectedUsers.buyer._id : selectedUsers.seller._id;

        const body = {
            id: messages.selectedUsers._id,
            sender,
            receiver,
            text: messages.chatInput
        };

        this.props.sendMessage(body);
    };

    hnadleInput = (e) => {
        const { handleChatInputChange } = this.props;
        handleChatInputChange(e.target.value);
    };

    render() {
        const { messages, user } = this.props;
        const { scope = "" } = user;
        const { selectedUsers : su, loadingChats } = messages;
        const selected_id = scope === "PROVIDER" ? "buyer" : "seller";

        const chats = su ? su.messages : [];
        const chatPersonName = su ? `${su[selected_id].display_name || ""}` : "";

        if (loadingChats) {
            return (
                <Spin spinning={loadingChats}>
                    <div style={{ height: "70vh", width: "100%" }}>
                    </div>
                </Spin>
            );
        }

        return (
            <>
                {su &&
                    <Layout className="message-block">
                        <Header className="bg-white">
                            <Avatar 
                                src={window._env.REACT_APP_PUBLIC_URL_HOST+su[selected_id].image_urls[0].url}>
                                <span className="name">{chatPersonName[0]}</span>
                            </Avatar>
                            <span style={{ marginLeft: "10px" }}>{chatPersonName}</span>
                        </Header>
                        <Content className="msg-container">
                            <Scrollbars style={{ minHeight: "380px", display: "flex" }} ref={this.scrollbars}>
                                {chats.length !== -1 && chats.map((message) => (
                                    <Message
                                        key={message._id}
                                        message={message}
                                        userId={user._id}
                                        chatPersonName={chatPersonName}
                                    />
                                ))}
                            </Scrollbars>
                        </Content>
                        <Footer className="send-message">
                            <form className="form">
                                <Input
                                    name="text"
                                    value={messages.chatInput}
                                    onChange={this.hnadleInput}
                                    autoComplete="off"
                                    placeholder="Type your message here ..."
                                    maxLength={100}
                                />
                                <Button shape='round' htmlType="submit" onClick={this.handleSendMessage}>
                                    <AiOutlineRight size={20} />
                                </Button>
                            </form>
                        </Footer>
                    </Layout>
                }
            </>
        );
    }
}

export default ChatHistory;