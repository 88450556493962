module.exports.encode = function (val) {
    return `${val.category}:${val.seller}:${val.lineItems.map(val => `${val.uid},${val.quantity}`).join(';')}`;
};

module.exports.decode = function (val) {

    if (!val) return {};

    let category = val.split(':')[0];
    let seller = val.split(':')[1];
    let lineItems = val.split(':')[2]?.split(';');
    if (!seller || !Array.isArray(lineItems)) return null;

    lineItems = lineItems.map(e => (
        {
            uid: parseInt(e.split(',')[0], 10),
            quantity: parseInt(e.split(',')[1], 10)
        }
    ));

    return ({
        category,
        seller,
        lineItems
    });

};