import { Button, Divider } from "antd";
import React, { Fragment } from "react";
import { Link, useHistory } from "react-router-dom";

const DefaultHeader = ({ onClose }) => {

    let history = useHistory();

    return (
        <Fragment>
            <li key="1" className="menu-item d-flex ml-2 mt-4 mt-lg-0 sign-up-button-container">
                <Button shape='round' type='primary' size='large' className="sign-up-partner-button my-auto" onClick={() => history.push('/pro-signup')}>
                    <strong>Food partner sign up</strong>
                </Button>
                {/* <Link className='sign-up-partner-button'
                    onClick={onClose}
                    to='/pro-signup'

                >
                    <strong>Food partner sign up</strong>
                </Link> */}
            </li>

            <Divider className='d-block d-lg-none my-3' />

            <li key="2" className="menu-item d-flex ml-2 sign-up-button-container">
                <Button shape='round' className="sign-up-button my-auto" size='large' onClick={() => history.push('/signup')}>
                    <strong>Foodie sign up</strong>
                </Button>
                {/* <Link className='sign-up-button'
                    onClick={onClose}
                    to='/signup'
                >
                    <strong>Foodie sign up</strong>
                </Link> */}
            </li>

            <Divider className='d-block d-lg-none mt-3 mb-0' />

            <li key="3" className="menu-item">
                <Link className='text-decoration-none'
                    onClick={onClose}
                    to='/login'
                >
                    <strong>Login</strong>
                </Link>
            </li>
        </Fragment>
    );
};

export default DefaultHeader;
