import sanitizeHtml from 'sanitize-html';

const options = {
    allowedTags: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'a', 'ul', 'ol',
        'nl', 'li', 'b', 'i', 'strong', 'em', 'hr', 'br', 'div','span', 'u',
        'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'pre'],
    disallowedTagsMode: 'discard',
    allowedStyles: {
        '*': {
            // Match HEX and RGB
            'color': [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
            'text-align': [/^left$/, /^right$/, /^center$/],
            // Match any number with px, em, or %
            'font-size': [/^\d+(?:px|em|%)$/]
        }
    },
};

export const sanitize = (dirty) => ({
    __html: sanitizeHtml(
        dirty,
        options
    )
});