import React from 'react';
import { Link } from "react-router-dom";
import { } from '../routes';
import { Row, Col } from 'antd';
import Foodelys from '../images/Source-white.png';
import { FaFacebookSquare, FaTwitterSquare, FaYoutubeSquare, FaLinkedin, FaInstagramSquare } from 'react-icons/fa';
import { SiNextdoor } from 'react-icons/si';

const career_page_enabled = JSON.parse(window._env.REACT_APP_ENABLE_CAREER_PAGE);
const linkedin_logo_enabled = JSON.parse(window._env.REACT_APP_ENABLE_LINKEDIN);
const blog_link_enabled = JSON.parse(window._env.REACT_APP_ENABLE_BLOG_LINK);
const instagram_link = window._env.REACT_APP_INSTAGRAM_LINK;
const next_door_link = window._env.REACT_APP_NEXTDOOR_LINK;
const youtube_link = window._env.REACT_APP_YOUTUBE_LINK;
class Footer extends React.Component {

    render() {
        return (
            <div className="mt-auto">
                <footer className="footer">
                    <Row type="flex" justify="center" align='top' gutter={32}>
                        <Col xs={{ span: 8, order: 1 }} lg={{ span: 4, order: 0 }}>

                            <Link to='/'>
                                <img className="img img-fluid d-none d-lg-inline-block"
                                    src={Foodelys} alt="Welcome To Foodelys" style={{ minWidth: 180 }}
                                />
                            </Link>

                            <img className="img img-fluid d-lg-none"
                                src={Foodelys} alt="Welcome To Foodelys" style={{ minWidth: 90 }}
                            />

                            <ul className='d-flex flex-wrap align-items-center'>
                                <li className="footer-item text-center mx-1">
                                    <a className="footer-link" target="_blank" rel="noopener noreferrer"
                                        href={window._env.REACT_APP_FACEBOOK_LINK} >
                                        <FaFacebookSquare size={30} />
                                    </a>
                                </li>
                                <li className="footer-item text-center mx-1">
                                    <a className="footer-link" target="_blank" rel="noopener noreferrer"
                                        href={window._env.REACT_APP_TWITTER_LINK} >
                                        <FaTwitterSquare size={30} />
                                    </a>
                                </li>
                                {
                                    youtube_link &&
                                    <li className="footer-item text-center mx-1">
                                        <a className="footer-link" target="_blank" rel="noopener noreferrer"
                                            href={youtube_link} >
                                            <FaYoutubeSquare size={30} />
                                        </a>
                                    </li>
                                }
                                {
                                    linkedin_logo_enabled &&
                                    <li className="footer-item text-center mx-1">
                                        <a className="footer-link" target="_blank" rel="noopener noreferrer"
                                            href={window._env.REACT_APP_LINKEDIN_LINK} >
                                            <FaLinkedin size={30} />
                                        </a>
                                    </li>
                                }
                                {
                                    instagram_link &&
                                    <li className="footer-item text-center mx-1">
                                        <a className="footer-link" target="_blank" rel="noopener noreferrer"
                                            href={instagram_link} >
                                            <FaInstagramSquare size={30} />
                                        </a>
                                    </li>
                                }
                                {
                                    next_door_link &&
                                    <li className="footer-item text-center mx-2">
                                        <a className="footer-link bg-white d-flex align-items-center justify-content-center" target="_blank" rel="noopener noreferrer"
                                            href={next_door_link} style={{minWidth: 27, height: 27, borderRadius: 3}}
                                        >
                                            <SiNextdoor size={24} color="#c1395f"/>
                                        </a>
                                    </li>
                                }
                            </ul>
                        </Col>
                        <Col xs={{ span: 16, order: 0 }} lg={{ span: 16, offset: 4, order: 1 }} xl={{ span: 14, offset: 6, order: 1 }}>
                            <Row align='middle' justify='space-between'>
                                <Col xs={24} lg={8} xxl={3}>
                                    <Link to={'/ourVision'} className="footer-link" >
                                        About Us
                                    </Link>
                                </Col>
                                <Col xs={24} lg={8} xxl={5}>
                                    <Link to={'/how-it-works-for-provider'}
                                        className="footer-link" >
                                        How it works - Seller
                                    </Link>
                                </Col>
                                <Col xs={24} lg={8} xxl={5}>
                                    <Link to={'/how-it-works-for-seeker'}
                                        className="footer-link" >
                                        How it works - Buyer
                                    </Link>
                                </Col>
                                {
                                    career_page_enabled ?
                                        <Col xs={24} lg={8} xxl={3}>
                                            <Link to='/career' className="footer-link" >
                                                Careers
                                            </Link>
                                        </Col>
                                        :
                                        null
                                }
                                <Col xs={24} lg={8} xxl={4}>
                                    <Link to='/contact-us' className="footer-link" >
                                        Contact Us
                                    </Link>
                                </Col>
                                {
                                    blog_link_enabled ?
                                        <Col xs={24} lg={8} xxl={2}>
                                            <a className="footer-link" target="_blank" rel="noopener noreferrer"
                                                href={window._env.REACT_APP_BLOG_LINK} >
                                                Blogs
                                            </a>
                                        </Col>
                                        :
                                        null
                                }
                            </Row>
                        </Col>

                    </Row>

                    <Row justify='end' className='mt-4'>
                        <Col xs={24} lg={12} xl={7} xxl={6} className='d-flex flex-column flex-sm-row justify-content-between align-items-sm-center'>
                            <div className="footer-item">
                                <Link to="/termsandcondition" className="footer-link footer-link-sm" >
                                    Terms Of Use
                                </Link>
                            </div>
                            <div className='text-white px-2 d-none d-sm-inline-block'>
                                |
                            </div>
                            <div className="footer-item">
                                <Link to="/privacypolicy" className="footer-link footer-link-sm" >
                                    Privacy Policy
                                </Link>
                            </div>
                            <div className='text-white px-2 d-none d-sm-inline-block'>
                                |
                            </div>
                            <div className="footer-item">
                                <Link to='/beta-release-disclaimer' className="footer-link footer-link-sm" >
                                    Beta Agreement
                                </Link>
                            </div>
                        </Col>
                    </Row>

                    <Row className="copyRights" justify="center" align="middle">
                        <span>&nbsp;{window._env.REACT_APP_COPY_RIGHT_YEAR} @ Foodelys. All Rights Reserved</span>
                    </Row>
                </footer>

            </div>
        );
    }
}

export default Footer;