let initialValue = {
    activeTab: "1"
};

const productTabs = (state = initialValue, action) => {
    switch (action.type) {
        case 'CHANGE_TAB': {
            return {activeTab: action.payload};
        }
        default:
            return state;
    }
};

export default productTabs;