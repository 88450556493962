import React, { useEffect, useState } from "react";
import { Row, Col, Spin, Divider } from "antd";
import "./style.scss";
import sanitize from "sanitize-html";

const BetaReleaseDisclaimer = (props) => {
    const { getManualDataByName, manualData, isManualLoading, page_visit } = props;
    page_visit('/beta-release-disclaimer');

    const [loadContent, setLoadContent] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            let res = await getManualDataByName(
                window._env.REACT_APP_STATIC_MANUAL_NAME_BETA_RELEASE_DISCLAIMER
            );
            if (res) {
                setLoadContent(false);
            }
        };
        fetchData();
    }, [getManualDataByName]);
    if (loadContent) {
        return (
            <Spin
                size="large"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
                spinning={isManualLoading}
            ></Spin>
        );
    } else {
        return (
            <React.Fragment>
                <Row justify="center" align="center" style={{ backgroundColor: "white" }}>
                    <Col span={18}>
                        <div className="terms-heading">Beta Release Disclaimer</div>
                    </Col>
                </Row>
                <Row justify="center" align="center" style={{ backgroundColor: "white" }}>
                    <Col span={18} justifyContent="center" alignItems="center">
                        {manualData.chapters.map((chapter, index) => {
                            return chapter.sections.map((section, idx) => {
                                return (
                                    <div key={idx} className="terms-container">
                                        <strong className="terms-title">
                                            {section.section_title}
                                        </strong>
                                        <span className="manual-description span-style" 
                                            dangerouslySetInnerHTML={{ __html: sanitize(section.section_description,
                                                { allowedTags: ['p','strong','a'], allowedAttributes: {'a': ['href']}})}}>
                                        </span>
                                        <Divider />
                                    </div>
                                );
                            });
                        })}
                    </Col>
                    <Col span={3}></Col>
                </Row>
            </React.Fragment>
        );
    }
};

export default BetaReleaseDisclaimer;
