import React from 'react';
import { Field, FormSection } from "redux-form";
import { Col, Modal, Popover } from 'antd';
import { AiFillQuestionCircle, AiOutlineCustomerService } from "react-icons/ai";
import {
    validate,
    SelectBox,
    Radio,
    Checkbox
} from "../../../common/redux-form";

import { SERVICE_DAY, TARGET_TYPE_OPTIONS } from '../../registration/context';
import { projectType } from '../../../constants/commonContext';

const isEmpty = (arr) => (arr.length === 0 ? "Required" : false);

const title = (
    <div>
        <AiOutlineCustomerService style={{ paddingRight: '10px' }} size={20} />
        Service Detail
    </div>
);

function EditServiceDetail({
    isVisible,
    onSubmit,
    onClose,
    error,
    isLoading,
    onSubCategorySearch,
    subCategories,
    onServiceSearch,
    serviceArea,
    categories,
    form
}) {

    let sub_category = [];
    Array.isArray(categories) && categories.forEach(e => {
        subCategories[e].forEach(item => {
            sub_category.push(({ key: item, value: item, label: item }));
        });
    }
    );

    return (
        <Modal
            title={title}
            visible={isVisible}
            okButtonProps={{ htmlType: "submit", loading: isLoading }}
            cancelButtonProps={{ disabled: isLoading }}
            okText="Save"
            onCancel={onClose}
            onOk={onSubmit}
            keyboard={false}
        >
            <form onSubmit={onSubmit}>
                <FormSection name="provider_attributes">

                    <Col span={24}>
                        <Field
                            name="expertise"
                            placeholder="Skills"
                            component={SelectBox}
                            label="Specialties"
                            mode="multiple"
                            onSearch={onSubCategorySearch}
                            options={sub_category}
                            validate={[validate.required, isEmpty]}
                            isRequired={true}
                            tooltipText={
                                <div className="info-popup">
                                    <span className="desc">Choose expertise.</span>
                                </div>
                            }
                        />
                    </Col>

                    <Col span={24}>
                        <Field
                            name="preferred_events"
                            placeholder="Events"
                            component={SelectBox}
                            label="Preferred Events"
                            mode="multiple"
                            onSearch={onSubCategorySearch}
                            options={projectType.map(e => ({ value: e, label: e }))}
                            validate={[validate.required, isEmpty]}
                            isRequired={true}
                            tooltipText={
                                <div className="info-popup">
                                    <span className="desc">Events you prefer to offer catering service.</span>
                                </div>
                            }
                        />
                    </Col>

                    <Col span={24}>
                        <div className='mb-3'>
                            <strong>What services does your store offer?&nbsp;</strong>
                        </div>
                        <Field
                            name="serving_options.delivery"
                            component={Checkbox}
                            label={
                                <span>
                                    We make food and deliver it to foodies.
                                    <Popover
                                        content='Select this option if you are a chef/cook/caterer/baker/grocer or dairy farmer who prepares dishes/meals and delivers them to the customers.'
                                        trigger="hover"
                                        placement='topRight'
                                    >
                                        <span className='ml-1'><AiFillQuestionCircle size={20} /></span>
                                    </Popover>
                                </span>
                            }
                            className='mb-2'
                        />
                        <Field
                            name="serving_options.pickup"
                            component={Checkbox}
                            className='mb-2'
                            label={
                                <span>
                                    We make food and provide curbside pickup.
                                    <Popover
                                        content='Select this option if you are a chef/cook/caterer/baker/grocer or dairy farmer who cooks dishes/meals and let the foodies pick their orders'
                                        trigger="hover"
                                        placement='topRight'
                                    >
                                        <span className='ml-1'><AiFillQuestionCircle size={20} /></span>
                                    </Popover>
                                </span>
                            }
                        />
                        <Field
                            name="serving_options.on_demand_cooking"
                            component={Checkbox}
                            className='mb-2'
                            label={
                                <span>
                                    We provide on-demand cooking and private chef service.
                                    <Popover
                                        content='Select this option if you can travel on-site to cook food for foodies’ gig and gathering'
                                        trigger="hover"
                                        placement='topRight'
                                    >
                                        <span className='ml-1'><AiFillQuestionCircle size={20} /></span>
                                    </Popover>
                                </span>
                            }
                        />
                        <Field
                            name="serving_options.on_site_catering"
                            component={Checkbox}
                            className='mb-2'
                            label={
                                <span>
                                    We provide full-service on-site catering solutions.
                                    <Popover
                                        content='Select this option if you are a full-service catering company for small and large events'
                                        trigger="hover"
                                        placement='topRight'
                                    >
                                        <span className='ml-1'><AiFillQuestionCircle size={20} /></span>
                                    </Popover>
                                </span>
                            }
                        />
                        <Field
                            name="serving_options.food_truck"
                            component={Checkbox}
                            className='mb-2'
                            label={
                                <span>
                                    We provide specialty food trucks.
                                    <Popover
                                        content='Select this option if you provide specialty food trucks.'
                                        trigger="hover"
                                        placement='topRight'
                                    >
                                        <span className='ml-1'><AiFillQuestionCircle size={20} /></span>
                                    </Popover>
                                </span>
                            }
                        />

                    </Col>

                    <Col span={24}>
                        <Field
                            name="type"
                            component={Radio}
                            direction='horizontal'
                            label="What defines you better?"
                            validate={validate.required}
                            isRequired={true}
                            options={TARGET_TYPE_OPTIONS}
                        />
                    </Col>

                    <Col span={24}>
                        <Field
                            name="service_area"
                            placeholder="Serving Area"
                            component={SelectBox}
                            label="Serving Area"
                            mode="multiple"
                            onSearch={onServiceSearch}
                            options={serviceArea}
                            validate={[validate.required, isEmpty]}
                            isRequired={true}
                            tooltipText={(
                                <div className="info-popup">
                                    <span className="desc">
                                        Provide name of cities or location where you serve.
                                    </span>
                                </div>)}
                        />
                    </Col>

                    <Col span={24}>
                        <Field
                            name="service_days"
                            placeholder=""
                            component={SelectBox}
                            label="Serving Days"
                            mode="multiple"
                            options={SERVICE_DAY}
                            validate={[validate.required, isEmpty]}
                            isRequired={true}
                            tooltipText={(
                                <div className="info-popup">
                                    <span className="desc">Pick days you prefer to work.</span>
                                </div>)}
                        />
                    </Col>

                </FormSection>

                <div className="form-error">{error}</div>
            </form>
        </Modal>
    );
}



export default EditServiceDetail;
