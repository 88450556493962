import React, { useEffect, useState } from 'react';
import { reduxForm, Field, SubmissionError } from "redux-form";
import { Row, Col, Button, Modal } from "antd";
import { AiOutlineCloseCircle, AiOutlineLogin } from "react-icons/ai";
import { Link, useHistory } from "react-router-dom";
import { Helmet } from 'react-helmet-async';

import {
    Checkbox,
    Input,
    validate
} from "../../common/redux-form";
import "./styles.scss";
import { load } from "recaptcha-v3";
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

function Login({
    login,
    submitting,
    handleSubmit,
    error,
    googleCaptchaKey,
    isLoading,
    recaptchaEnabled,
    resend_confirmation_email,
    page_visit
}) {

    const history = useHistory();
    const { isAuthenticated } = useSelector(st => st.user);
    const [captcha, setCaptcha] = useState(null);
    const [email, setEmail] = useState('');
    const [isVisible, setIsVisible] = useState(false);

    if (isAuthenticated) {
        history.push('/');
    }

    const getCaptchaToken = async () => {

        if (captcha) {
            let token = await captcha.execute('login');
            return token;
        } else {
            toast.info('Please wait for captcha to load');
            throw Error('Please wait for captcha to load');
        }
    };

    useEffect(() => {
        page_visit('/login');
    }, [page_visit]);

    useEffect(() => {
        async function loadCaptchaScript() {
            if (window._env.REACT_APP_CAPTCHAV3_SITE_KEY) {
                const _captcha = await load(window._env.REACT_APP_CAPTCHAV3_SITE_KEY);
                setCaptcha(_captcha);
            }
        }
        loadCaptchaScript();
    }, []);

    const initialRoutingLogin = (scope, status) => {
        if (status === 'REGISTRATION_PENDING' && scope !== 'ADMIN') {
            const userPath = scope === "PROVIDER" ? "/seller/registration" : "/search";
            history.push(userPath);
        } else if (status === 'REGISTRATION_PENDING_INVITE_PROVIDER' && scope === 'PROVIDER') {
            history.push("/seller/registration");
        } else {
            history.push(scope === 'ADMIN' ? '' : scope === "PROVIDER" ? "/seller/orders" : "/search");
        }
    };

    const _handleSubmit = async (credential) => {
        try {
            // Token valid for 2 mins
            if (recaptchaEnabled) {
                let token = await getCaptchaToken();
                credential.RecaptchaToken = token;
            }
            credential.username = credential.username.trim().toLowerCase();
            setEmail(credential.username);
            const { recaptchaIssue, status, scope } = await login(credential);
            if (recaptchaIssue) {
                await _handleSubmit(credential);
            } else if (status && scope) {
                initialRoutingLogin(scope, status);
            }
        } catch (error) {
            if (error.name === 'SubmissionError')
                throw new SubmissionError({ _error: error.errors._error });
            if (error.message === 'Your account is not verified!') {
                setIsVisible(true);
            }
            throw new SubmissionError({ _error: error.message });
        }
    };

    const resendConfirmationEmail = async (ev) => {

        ev.preventDefault();
        ev.stopPropagation();

        let body = { username: email };

        if (recaptchaEnabled && captcha) {
            let token = await getCaptchaToken();
            body.RecaptchaToken = token;
        }

        try {
            await resend_confirmation_email(body);
        } catch (err) {
        }
    };


    return (
        <Row className="auth-wrapper mb-5" type="flex" justify="center">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Sign In | Foodelys</title>
            </Helmet>
            <Col span={6} xs={22} sm={17} md={15} lg={10} xl={9} className="form-wrapper">
                <h4 className="title">
                    <AiOutlineLogin size={20} />
                    Sign In
                </h4>
                <div className="form-content">
                    <form onSubmit={handleSubmit(_handleSubmit)}>
                        <Field
                            name="username"
                            placeholder="Enter email"
                            component={Input}
                            label="Email Address"
                            validate={[validate.required, validate.email]}
                            isRequired={true}
                        />
                        <Field
                            name="password"
                            placeholder="Enter password"
                            component={Input}
                            label="Password"
                            type="password"
                            validate={[validate.required]}
                            isRequired={true}
                        />
                        <Field
                            name="rememberMe"
                            component={Checkbox}
                            label="Remember Me"
                        />
                        {
                            googleCaptchaKey &&
                            <div className="google-captcha">
                                {/* <ReCAPTCHA
                                    onChange={handleCaptcha}
                                    sitekey={googleCaptchaKey}
                                /> */}
                            </div>
                        }
                        <div className="form-error">{error}</div>
                        <Button shape='round'
                            className="btn-primary btn-submit"
                            type="primary"
                            size={"large"}
                            htmlType="submit"
                            icon={<AiOutlineLogin size={20} />}
                            loading={submitting || isLoading}
                            disabled={recaptchaEnabled && !captcha}
                        >
                            Sign In
                        </Button>
                    </form>
                    <Row className="other-options">
                        <Col span={24}>
                            {/* <h3>Or Sign In with</h3> 
                            <div className="social">
                                <Button shape='round'
                                    className="social-btn btn-facebook"
                                    size={"large"}
                                >
                                    <Icon type="facebook" theme="filled" />
                                    Facebook
                                </Button>
                                <Button shape='round'
                                    className="social-btn btn-google"
                                    size={"large"}
                                >
                                    <Icon type="google" />
                                    Google
                                </Button>
                                <Button shape='round'
                                    className="social-btn btn-linkedin"
                                    size={"large"}
                                >
                                    <Icon type="linkedin" theme="filled" />
                                    LinkedIn
                                </Button>
                            </div> */}
                            <p>Not Registered Yet? <Link to='/signup'>Signup </Link></p>
                            <p>
                                Forgot Password?&nbsp;
                                <Link to='/forgot-password'>Click Here to reset</Link>
                            </p>
                        </Col>
                    </Row>
                </div>
            </Col>

            <Modal
                title='Almost There !!'
                visible={isVisible}
                closable
                centered
                onCancel={() => setIsVisible(false)}
                closeIcon={<AiOutlineCloseCircle size={20} color='#fff' />}
                destroyOnClose={true}
                footer={
                    <React.Fragment>
                        <Button onClick={resendConfirmationEmail} type='primary' shape='round' loading={isLoading} >
                            Resend Confirmation Email
                        </Button>
                    </React.Fragment>
                }
                width={450}
            >
                <p>Please verify email address by clicking on the link sent in the confirmation email.</p>
                <p><strong>Note: Please check your spam and promotional folder.</strong></p>
            </Modal >
        </Row>
    );
}

export default reduxForm({
    form: "login",
    enableReinitialize: true
})(Login);
