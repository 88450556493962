import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Col, Row, Spin } from 'antd';
import { BsCardChecklist } from 'react-icons/bs';
import { BiCheckShield } from 'react-icons/bi';
import { HiOutlineIdentification } from 'react-icons/hi';
import { load } from "recaptcha-v3";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import './styles.scss';
import ProjectTab from './ProjectTab';
import ReviewTab from './ReviewTab';
import { reduxForm, SubmissionError, Field } from 'redux-form';
import { createLead } from '../../actions/proProfile';
import { validate, Input } from '../../common/redux-form';
import { primaryColor } from '../../common/theme';
import { event } from '../../actions/analytics';

const recaptchaEnabled = JSON.parse(window._env.REACT_APP_CAPTCHAV3_ENABLED);

function StoreInfo({ loading, projects, reviews, attr, handleSubmit, reset, _id, allow_contact }) {

    console.log(allow_contact);

    const [captcha, setCaptcha] = useState(null);
    const [creatingLead, setCreatingLead] = useState(false);
    const [sendEnabled, setSendEndabled] = useState(false);
    let dispatch = useDispatch();
    const user = useSelector(st => st.user);

    const getCaptchaToken = async () => {

        if (captcha) {
            let token = await captcha.execute('login');
            return token;
        } else {
            toast.info('Please wait for captcha to load');
            throw Error('Please wait for captcha to load');
        }
    };

    useEffect(() => {
        async function loadCaptchaScript() {
            if (window._env.REACT_APP_CAPTCHAV3_SITE_KEY) {
                const _captcha = await load(window._env.REACT_APP_CAPTCHAV3_SITE_KEY);
                setCaptcha(_captcha);
            }
        }
        loadCaptchaScript();
    }, []);

    const _handleSubmit = async (values) => {
        try {

            setCreatingLead(true);

            // Token valid for 2 mins
            if (recaptchaEnabled) {
                let token = await getCaptchaToken();
                values.RecaptchaToken = token;
            }

            values.seller_id = _id;

            const { recaptchaIssue, status, message } = await createLead(values);
            if (recaptchaIssue) {
                await _handleSubmit(values);
            } else if (status === 200) {
                dispatch(event('Lead', 'create', _id));
                toast.success(message);
                setCreatingLead(false);
                reset();
            } else {
                toast.error(message);
            }
        } catch (error) {
            setCreatingLead(false);
            toast.error(error.message);
            if (error.name === 'SubmissionError')
                throw new SubmissionError({ _error: error.errors._error });
            throw new SubmissionError({ _error: error.message });
        }
    };

    if (loading)
        return (
            <div
                style={{ height: 500 }}
                className='w-100 d-flex justify-content-center align-items-center'
            >
                <Spin size='large'></Spin>
            </div>
        );

    return (

        <div>

            <Row gutter={[16, 16]}>

                <Col xs={24} md={12} lg={16}>
                    {
                        attr ?
                            <React.Fragment>
                                <h5>About Us</h5>
                                <p>{attr.bio}</p>

                                <h5 className='mt-5'>Specialties</h5>
                                {
                                    attr?.expertise ?
                                        <React.Fragment>
                                            <h6>Cuisines</h6>
                                            <p className='pl-4'>{attr?.expertise?.join(', ')}</p>
                                        </React.Fragment>
                                        :
                                        null
                                }
                                {
                                    attr?.preferred_events ?
                                        <React.Fragment>
                                            <h6>Events</h6>
                                            <p className='pl-4'>{attr?.preferred_events?.join(', ')}</p>
                                        </React.Fragment>
                                        :
                                        null
                                }

                                <h5 className='mt-5'>Overview</h5>

                                <h6>Services</h6>
                                <ul>{
                                    [
                                        attr.serving_options?.pickup ? 'Off-site catering and delivery' : false,
                                        attr.serving_options?.delivery ? 'Off-site catering and curbside pickup' : false,
                                        attr.serving_options?.on_site_catering ? 'On-site event catering' : false,
                                        attr.serving_options?.on_demand_cooking ? 'On-Demand Cooking/private chef' : false,
                                        attr.serving_options?.food_truck ? 'Food truck service' : false
                                    ].filter(e => e).map(e => (
                                        <li>
                                            {e}
                                        </li>
                                    ))
                                }
                                </ul>

                                {
                                    Array.isArray(attr.service_days) ?
                                        <React.Fragment>
                                            <h6>Service Days</h6>
                                            <p className='pl-4'>{attr.service_days.join(", ")}</p>
                                        </React.Fragment>
                                        :
                                        null
                                }

                                {
                                    attr.service_hours?.end && attr.service_hours?.start ?
                                        <React.Fragment>
                                            <h6>Store Timings</h6>
                                            <p className='pl-4'>{attr.service_hours.start} - {attr.service_hours.end}</p>
                                        </React.Fragment>
                                        :
                                        null
                                }

                                {
                                    Array.isArray(attr.faq) && attr.faq.length ?
                                        <React.Fragment>
                                            <h6>FAQ</h6>
                                            {
                                                attr.faq.map(e => (
                                                    <div className="pl-4 mb-3">
                                                        <div style={{ fontWeight: 600 }}>{e.question}</div>
                                                        <div>{e.answer}</div>
                                                    </div>
                                                ))
                                            }
                                        </React.Fragment>
                                        :
                                        null
                                }

                                {
                                    attr.licensed || attr.insured || attr.background_check_status || attr.safety_mantra
                                        ?
                                        <React.Fragment>
                                            <h5 className='mt-5'>Credentials</h5>
                                            {
                                                attr.licensed ?
                                                    <div className='d-flex flex-row mb-2 pl-4'>
                                                        <span>
                                                            <BsCardChecklist size={20} />
                                                        </span>
                                                        <span className='ml-2'>
                                                            <strong>Seller has indicated</strong> that they have valid license
                                                        </span>
                                                    </div>
                                                    :
                                                    null
                                            }

                                            {
                                                attr.insured ?
                                                    <div className='d-flex flex-row mb-2 pl-4'>
                                                        <span>
                                                            <HiOutlineIdentification size={20} />
                                                        </span>
                                                        <span className='ml-2'>
                                                            <strong>Seller has indicated</strong> that they have valid liability insurance
                                                        </span>
                                                    </div>
                                                    :
                                                    null
                                            }

                                            {
                                                attr.background_check_status ?
                                                    <div className='d-flex flex-row mb-3 pl-4'>
                                                        <span>
                                                            <BiCheckShield size={20} />
                                                        </span>
                                                        <span className='ml-2'>
                                                            Background Check Completed
                                                        </span>
                                                    </div>
                                                    :
                                                    null
                                            }

                                            {
                                                attr.safety_mantra ?
                                                    <div style={{ backgroundColor: `${primaryColor}10`, border: `1px solid ${primaryColor}` }} className='p-3 rounded'>
                                                        <div>
                                                            <h6 className='mb-1'>
                                                                Seller has indicated that they reviewed CDC safety guidelines for COVID-19 and pledged to:
                                                            </h6>
                                                            <ul>
                                                                <li>Maintain 6 feet of distance from customers</li>
                                                                <li>Wear masks during the job</li>
                                                                <li>Wear gloves during the job</li>
                                                                <li>Disinfect surfaces touched during the job</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </React.Fragment>
                                        :
                                        null
                                }

                            </React.Fragment>
                            :
                            null
                    }
                </Col>

                {
                    JSON.parse(window._env.REACT_APP_CONTACT_FORM_ENABLED) && (!user?.scope || user.scope !== 'PROVIDER') && allow_contact ?
                        <Col xs={24} md={12} lg={8}>
                            <div className='rounded' style={{ background: `${primaryColor}10`, border: `1px solid ${primaryColor}` }}>
                                <h5 className='contact-form-header rounded-top'>
                                    Contact Form
                                </h5>
                                <form onSubmit={handleSubmit(_handleSubmit)} className='p-3'>
                                    <Field
                                        name="name"
                                        placeholder="Enter your full name"
                                        component={Input}
                                        label="Full Name"
                                        validate={[
                                            validate.required,
                                            validate.name,
                                        ]}
                                        isRequired={true}
                                    />
                                    <Field
                                        name="mobile"
                                        placeholder="Enter your phone number"
                                        component={Input}
                                        label="Phone Number"
                                        validate={[
                                            validate.required,
                                            validate.phoneNumber,
                                        ]}
                                        isRequired={true}
                                    />
                                    <Field
                                        type='textarea'
                                        rows={3}
                                        name="message"
                                        placeholder="Enter your message"
                                        component={Input}
                                        autoSize={{ minRows: 3, maxRows: 3 }}
                                        label="Message"
                                        validate={[
                                            validate.required,
                                            validate.maxLength100,
                                            validate.minLength10
                                        ]}
                                        isRequired={true}
                                    />

                                    <Checkbox onChange={(ev) => setSendEndabled(ev.target.checked)}>
                                        <span dangerouslySetInnerHTML={{ __html: window._env.REACT_APP_CONTACT_FORM_CONSENT_MSG }} />
                                    </Checkbox>

                                    <Button
                                        type='primary' htmlType='submit' shape='round'
                                        className='w-100 mt-3' loading={creatingLead}
                                        disabled={!sendEnabled}
                                    >
                                        Submit
                                    </Button>

                                </form>
                            </div>
                        </Col>
                        :
                        null
                }
            </Row>

            <ProjectTab {...{ loading, projects }} />

            <ReviewTab reviews={reviews} loading={loading} />

        </div>
    );
}

export default reduxForm({
    form: "new_lead",
    enableReinitialize: true
})(StoreInfo);