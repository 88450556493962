import React from 'react';
import { Row, Col, Divider } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

import welcome from '../../images/hiw-seller/how-it-works1.svg';
import welcome_mobile from '../../images/hiw-seller/Source-26.webp';
import CloudImg from '../../images/how-it-works-cloud1.svg';
import CloudImg2 from '../../images/how-it-works-cloud3.svg';

import HeaderAlt from "../header/header-alt";

import './style.scss';
import Avatar from 'antd/lib/avatar/avatar';

export default function Section1() {

    const breakpoint = useBreakpoint();

    return (
        <div className='position-relative'>

            <img className="d-none d-lg-inline-block  position-absolute" style={{ left: 0, top: 0, width: breakpoint.xl ? '25vw' : '23vw' }}
                src={CloudImg} alt="Welcome To Foodelys" />

            <img className="d-none d-lg-inline-block  position-absolute" style={{ right: 0, top: 0, width: breakpoint.xl ? '20vw' : '23vw' }}
                src={CloudImg2} alt="Welcome To Foodelys" />

            <HeaderAlt type='DARK' />

            <Row type="flex" justify="center" align="middle" style={{ marginTop: breakpoint.xxl ? '14rem' : breakpoint.xl ? '8rem' : breakpoint.lg ? '6rem' : '1rem' }} >

                <Col xs={0} lg={1} xl={3}>
                </Col>

                <Col xs={24} md={0} className='p-3'>
                    <img className="img img-fluid"
                        src={welcome_mobile} alt="Welcome To Foodelys" />
                </Col>

                <Col xs={24} md={12} xl={9}>

                    <div className="my-5 p-3 d-flex">
                        <div>
                            <Avatar
                                size={breakpoint.lg ? 50 : 30}
                                style={{ backgroundColor: '#c1395f', color: 'white', fontSize: breakpoint.lg ? '1.6rem' : '0.9rem' }}
                                className='mr-3'
                            >
                                1
                            </Avatar>
                        </div>
                        <div>
                            <h4 className='section-heading mb-3'>
                                Sign Up As A Food-partner
                            </h4>
                            <div className={`${breakpoint.lg ? 'section-content' : 'section-content-mobile'}`}>
                                Using the name of your business, your name and your email-id.
                            </div>
                        </div>
                    </div>

                    {breakpoint.lg ? <Divider /> : null}

                    <div className="my-5 p-3 d-flex">
                        <div>
                            <Avatar
                                size={breakpoint.lg ? 50 : 30}
                                style={{ backgroundColor: '#c1395f', color: 'white', fontSize: breakpoint.lg ? '1.6rem' : '0.9rem' }}
                                className='mr-3'
                            >
                                2
                            </Avatar>
                        </div>
                        <div>
                            <h4 className='mb-3 d-flex align-items-center section-heading' >
                                Complete Your Profile
                            </h4>
                            <div className={`${breakpoint.lg ? 'section-content' : 'section-content-mobile'}`}>
                                By entering details like bio of your business, your (pickup) address, cover photos, your contact details and preferred payment options.
                            </div>
                        </div>
                    </div>

                </Col>
                <Col xs={0} md={10} xl={9} className='text-center'>
                    <img className="img img-fluid"
                        src={welcome} alt="Welcome To Foodelys" />
                </Col>
                <Col xs={0} lg={1} xl={3}>
                </Col>

            </Row>
        </div>
    );
}