import { Modal } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Field, reduxForm, reset, initialize } from 'redux-form';
import { InputNumber, validate } from '../../../common/redux-form';

function InventoryModal({
    visible,
    createInventory,
    updateInventory,
    setVisible,
    handleSubmit,
    inventoryItem,
    isLoading,
    refresh,
    item,
    updateStockProduct,
    moq
}) {

    let dispatch = useDispatch();

    useEffect(() => {
        dispatch(initialize('inventory', { inStock: inventoryItem?.inStock ? inventoryItem?.inStock : 0 }));
    }, [dispatch, inventoryItem]);

    async function submit(values) {
        try {
            if (values.inStock < moq) {
                setVisible(false);
                return;
            }
            if (!inventoryItem._id)
                await dispatch(createInventory({ productId: item._id, inStock: values.inStock }));
            else await dispatch(updateInventory({ _id: inventoryItem._id, inStock: values.inStock }));
            updateStockProduct();
            refresh();
            dispatch(reset('inventory'));
            setVisible(false);
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <form onSubmit={handleSubmit(submit)}>
            <Modal
                onCancel={() => {
                    dispatch(reset('inventory'));
                    setVisible(false);
                }}
                onOk={handleSubmit(submit)}
                visible={visible}
                title="Do you want to add/update stock?"
                confirmLoading={isLoading}
            >
                <Field
                    name="inStock"
                    component={InputNumber}
                    min={moq}
                    label="Available Stock"
                    validate={[validate.required]}
                    isRequired={true}
                />
            </Modal>
        </form>
    );
}

export default reduxForm({
    form: 'inventory', // a unique identifier for this form
})(InventoryModal);
