import React, { Component } from "react";
import { Row, Col, Card, Spin } from "antd";
import { AiOutlineInbox } from "react-icons/ai";
import ContactList from "./ContactList";
import ChatHistory from "./ChatHistory";
import "./style.scss";
import PIConcernMsg from "../../common/PIConcernMsg";


export class Messages extends Component {
    componentDidMount() {
        this.props.fetchContactRequest();
    }

    render() {
        const { loading, contactList } = this.props.messages;
        return (
            <React.Fragment>
                <PIConcernMsg containerStyle={{marginBottom : 20}}/>
                <Card bodyStyle={{ padding: 0}}>
                    <Spin spinning={loading}>
                        <>
                            {Array.isArray(contactList) &&
                                contactList.length !== 0 && (
                                <Row type="flex">
                                    <Col xs={24} sm={6} className="message-border">
                                        <ContactList {...this.props} />
                                    </Col>
                                    <Col xs={24} sm={18} className="message-border">
                                        <ChatHistory {...this.props} />
                                    </Col>
                                </Row>
                            )}

                            {Array.isArray(contactList) &&
                            contactList.length === 0 && (
                                <Row gutter={24}>
                                    <div className="no-agreement-found">
                                        <AiOutlineInbox size={20} />
                                        <span>No Contact found</span>
                                    </div>
                                </Row>
                            )}
                        </>
                    </Spin>
                </Card>
            </React.Fragment>
        );
    }
}

export default Messages;
