import React from "react";
import { Helmet } from 'react-helmet-async';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";

import Messages from "../../containers/shared/messages";
import AccountManagement from "../../containers/accountManagement";
import ReviewAndRating from "../../containers/consumer/reviewAndRating";
import MyOrderComponent from "./myorder";
import "./styles.scss";
import Checkout from "./checkout";

function Consumer() {

    let { path } = useRouteMatch();
    let history = useHistory();
    let location = useLocation();
    let props = { history, location };

    return (
        <div className="d-flex flex-column flex-fill container p-2 p-md-3 p-lg-4">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Dashboard | Foodelys</title>
            </Helmet>
            <Switch>
                <Route path={`${path}/my-order`} exact><MyOrderComponent {...props} /></Route>
                <Route path={`${path}/profile`}><AccountManagement {...props} /></Route>
                <Route path={`${path}/messages`} exact><Messages {...props} /></Route>
                <Route path={`${path}/review/:invoice_id`} exact><ReviewAndRating {...props} /></Route>
                <Route path={`${path}/checkout`} exact><Checkout /></Route>
            </Switch>
        </div>
    );
}

export default Consumer;