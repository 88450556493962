import React, { useState } from "react";
import { Button, Popconfirm, Tooltip, Col, Row } from "antd";
import { useHistory } from "react-router-dom";
import { AiOutlineEye, AiOutlineEdit, AiOutlineDelete, AiOutlineCloudUpload, AiOutlineCloudDownload } from 'react-icons/ai';
import { useDispatch } from "react-redux";

import { ServiceActionsRule } from "../../../constants/commonContext";
import { setProjectDetail, updateProject } from "../../../actions/project";
import ProjectCard from "../../../common/projectCard";
import ProjectPopup from "../../projectPopup";

function ServiceItem({
    refresh,
    projectDetail
}) {

    async function updateStatus(status) {
        let product = { _id: projectDetail._id, status, isStatusUpdate: true };
        await updateProject(product)(dispatch);
        await refresh();
    }

    const text = 'Are you sure you want to delete this event?';
    const dispatch = useDispatch();
    const [showProduct, setShowProduct] = useState(false);
    const history = useHistory();

    const Action = () => (
        <Row gutter={[8, 12]} className='mt-auto'>
            <Col span={6} className='text-center'>
                <Tooltip title='View'>
                    <Button
                        icon={<AiOutlineEye size={20} />}
                        disabled={ServiceActionsRule[projectDetail.status]["View"]}
                        shape='circle'
                        onClick={() => {
                            dispatch(setProjectDetail(projectDetail));
                            setShowProduct(true);
                        }}
                    />
                </Tooltip>
            </Col>
            <Col span={6} className='text-center'>
                <Tooltip title='Edit this food-item'>
                    <Button
                        onClick={() => history.push(`/seller/project/${projectDetail._id}`)}
                        icon={<AiOutlineEdit size={20} />}
                        disabled={ServiceActionsRule[projectDetail.status]["Edit"]}
                        shape='circle'
                    />
                </Tooltip>
            </Col>
            <Col span={6} className='text-center'>
                <Tooltip title='Click this to make the event visible to the consumer on Foodelys platform'>
                    <Button
                        icon={<AiOutlineCloudUpload size={20} />}
                        onClick={() => updateStatus("PUBLISHED")}
                        disabled={ServiceActionsRule[projectDetail.status]["Publish"]}
                        shape='circle'
                    />
                </Tooltip>
            </Col>
            <Col span={6} className='text-center'>
                <Tooltip title='Click this to hide the event from the consumer on Foodelys platform'>
                    <Button
                        icon={<AiOutlineCloudDownload size={20} />}
                        onClick={() => updateStatus("DRAFT")}
                        disabled={ServiceActionsRule[projectDetail.status]["Unpublish"]}
                        shape='circle'
                    />
                </Tooltip>
            </Col>
            <Col span={6} className='text-center'>
                <Tooltip title='Delete'>
                    <Popconfirm
                        placement="top"
                        title={text}
                        onConfirm={() => updateStatus("DELETED")}
                        okText="Yes"
                        cancelText="No"
                        disabled={ServiceActionsRule[projectDetail.status]["Delete"]}
                        className="delete"
                    >
                        <Button
                            icon={<AiOutlineDelete size={20} />}
                            disabled={ServiceActionsRule[projectDetail.status]["Delete"]}
                            shape='circle'
                            style={ServiceActionsRule[projectDetail.status]["Delete"] ? { width: 'inherit' } : {}}
                        />
                    </Popconfirm>
                </Tooltip>
            </Col>
        </Row>
    );

    return (
        <React.Fragment>
            <ProjectCard projectDetail={projectDetail} Action={Action} />
            <ProjectPopup handleClose={() => setShowProduct(false)} isVisible={showProduct} />
        </React.Fragment>
    );

}

export default ServiceItem;
