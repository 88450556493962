import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Login from "../../components/auth/login";
import {
    login,
    setUser,
    resend_confirmation_email
} from "../../actions/user";
import { page_visit } from '../../actions/analytics';

class Container extends Component {
  
    constructor(props) {
        super(props);
        this.username = localStorage.getItem("signup-mail");
        localStorage.removeItem("signup-mail");
    }
    render() {
        const username = this.username;

        return (
            <Login
                {...this.props}
                initialValues={{ username }}
            />
        );
    }
}

const mapStateToProps = ({
    user: { isAuthenticated, isLoading,status, scope },
    app: { googleCaptchaKey, googleCaptchaKeyV3, recaptchaEnabled }
}) => ({
    isAuthenticated,
    isLoading,
    status,
    googleCaptchaKey,
    googleCaptchaKeyV3,
    recaptchaEnabled,
    scope
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ login, setUser, page_visit, resend_confirmation_email}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Container);
