import React, { Component } from "react";
import {
    Upload,
    Popover,
    message
} from "antd";
import { AiFillQuestionCircle, AiOutlineClose, AiOutlineLoading } from "react-icons/ai";

import { fileUpload as onFileUpload } from "../../actions/common";
import ResizeModal from "./ResizeModal";
import { HiPlus } from "react-icons/hi";
import imageCompression from 'browser-image-compression';

class FileUpload extends Component {

    state = {
        isLoading: false,
        file: null,
        showResizeModal: false
    };

    handleBeforeUpload = (file) => {
        const {
            fileSizeType,
            maxFileSize,
            fileTypes
        } = this.props;
        const isValidFileType = fileTypes.includes(file.type);
        let size = file.size / 1024;
        if (fileSizeType === "mb") {
            size = size / 1024;
        }
        const isValidSize = (size <= maxFileSize) || /image\/*/.test(file.type);

        if (!isValidFileType) {
            message.error("Invalid file type");
        }

        if (!isValidSize) {
            message.error(`File must smaller than ${maxFileSize}${fileSizeType}!`);
        }

        const isValidFile = isValidSize && isValidFileType;
        this.setState({ isValidFile });

        return isValidSize && isValidFileType;
    };

    handleAction = (file) => {

        const { resize } = this.props;
        const isValidFile = this.handleBeforeUpload(file);

        if (!isValidFile) {
            return false;
        }

        this.setState({ file: file }, () => {
            if (!resize) {
                this.upload();
            }
            this.setState({ showResizeModal: true });
        });
        return false;
    };

    upload = async () => {

        const { input: { onChange }, onUpload, context } = this.props;
        let file = this.state.file;

        try {
            console.log(file.type);
            if (/image\/*/.test(file.type)) {
                const options = {
                    maxSizeMB: 1
                };
                file = await imageCompression(file, options);
            }
            const formData = new FormData();
            let name = (new Date().valueOf()).toString(16) + parseInt(Math.random() * 1000).toString(16) + '.png';
            formData.append("file", file, name);
            this.setState({ isLoading: true });
            const { Location } = await onFileUpload(formData, context);
            this.setState({ isLoading: false });
            onChange(Location);
            file.url = Location;

            if (typeof onUpload === "function") {
                onUpload(file);
            }
            return file;
        } catch (e) {
            console.log(e.message);
            message.error("File not uploaded, please try again");
            this.setState({ isLoading: false });
            return false;
        }
    };

    handleCancel = () => {
        this.setState({ showResizeModal: false, file: "" });
    };

    handleSubmit = (blob) => {
        this.setState({ file: blob, showResizeModal: false }, () => {
            this.upload();
        });
    };

    handleRemove = () => {
        const { input: { onChange } } = this.props;
        onChange("");
    };

    render() {

        const {
            input: { name, value },
            meta: { touched, error, warning },
            className,
            errorClass,
            label,
            labelClass,
            isRequired,
            tooltipText,
            tooltipPlacement,
            listType,
            width,
            height,
            alt,
            accept
        } = this.props;

        const isError = touched && (error || warning);
        const errorText = error || warning;
        const fieldError = isError ? "field-error" : "";
        const noError = !isError ? "no-error" : "";
        const isValidFile = value ? true : false;
        return (
            <div className={`form-field file-upload-field ${className} ${fieldError}`}>
                {
                    label &&
                    <label className={labelClass}>
                        {label}
                        {isRequired ? <span className="required-label"><sup>{" * "}</sup></span> : ""}
                        {' '}
                        {
                            tooltipText &&
                            <Popover content={tooltipText} trigger="hover" placement={tooltipPlacement}>
                                <span><AiFillQuestionCircle size={20} /></span>
                            </Popover>
                        }
                    </label>
                }
                <div className="clearfix">
                    {
                        isValidFile ?
                            <div className="upload-file-preview">
                                <img src={window._env.REACT_APP_PUBLIC_URL_HOST + value} alt={alt ? alt : value} />
                                <AiOutlineClose onClick={this.handleRemove} className="remove" size={20} />
                            </div>
                            :
                            <Upload
                                name={name}
                                listType={listType}
                                showUploadList={false}
                                beforeUpload={this.handleAction}
                                accept={accept}
                            >
                                <div>
                                    {
                                        this.state.isLoading ?
                                            <AiOutlineLoading size={20} />
                                            :
                                            <HiPlus />
                                    }
                                    <div className="ant-upload-text">Upload</div>
                                </div>
                            </Upload>
                    }
                </div>
                {
                    <span className={`error ${errorClass} ${noError}`}>
                        {isError && errorText}
                    </span>
                }

                <ResizeModal
                    modalVisible={this.state.showResizeModal}
                    handleCancel={this.handleCancel}
                    handleSubmit={this.handleSubmit}
                    file={this.state.file}
                    width={width}
                    height={height}
                />

            </div>
        );
    }
}

FileUpload.defaultProps = {
    input: {},
    meta: {},
    className: "",
    errorClass: "",
    label: "",
    labelClass: "",
    listType: "picture-card",
    isRequired: false,
    tooltipPlacement: "rightTop",
    maxFileSize: 500,
    fileSizeType: "kb",
    fileTypes: ["image/jpg", "image/jpeg", "image/png"],
    resize: false,
    context: "",
    width: 400,
    height: 300
};

export default FileUpload;
