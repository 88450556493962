import React from 'react';
import { Row, Col } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

import welcome from '../images/landingPage/desktop/Source-06.svg';
import welcome_mobile from '../images/landingPage/mobile/Source-11.svg';
import cloud from '../images/landingPage/mobile/Source-10.svg';

import './style.scss';

export default function Section4() {

    const breakpoint = useBreakpoint();

    return (

        <Row type="flex" justify="center" align="middle" className='position-relative section-top-margin'
        >

            <img className="d-lg-none position-absolute" style={{ width: '10%', top: 0, left: 0 }} src={cloud} alt="" />

            <Col xs={{ span: 24, order: 1 }} lg={{ span: 12, order: 0 }} xl={{ span: 11, offset: 1 }} xxl={{ span: 8, offset: 4 }} className="p-3">
                <h2 className="section-heading">
                    Baking the world a Better place
                </h2>
                <div className={breakpoint.md ? "section-content mt-4" : "section-content-mobile mt-3"}>
                    Explore a wide range of oven-fresh bakery items at your fingertips. Skip the line, order online!
                </div>

            </Col>
            <Col className='text-center'
                xs={{ span: 20, order: 0 }} lg={{ span: 11, offset: 1, order: 1 }} xl={{ span: 9, offset: 2 }} xxl={{ span: 6, offset: 2 }}
            >
                <img className="img img-fluid"
                    src={breakpoint.lg ? welcome : welcome_mobile} alt="Welcome To Foodelys" />
            </Col>

            <Col xs={0} xl={1} xxl={{ span: 4, order: 2 }}></Col>

        </Row>
    );
}