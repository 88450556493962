import React, { useState } from "react";
import { Divider, Spin, Row, Col, Button, Pagination } from 'antd';
import { AiOutlineInbox } from "react-icons/ai";
import { HiOutlinePlus } from 'react-icons/hi';
import AddonItem from "./AddonItem";
import './style.scss';
import CreateAddon from './addAddon';

export default function Addons({
    isLoading,
    addons,
    totalAddons,
    fetchAddonsByProviderId,
    createAddons,
    updateAddons,
    deleteAddonsById
}) {

    const pageSize = 5;
    const [visible, setVisible] = useState(false);
    const [selectedAddon, setSelectedAddon] = useState({});
    const [page, setPage] = useState(1);

    // fetch contract belonging to this provider via its mongo id
    const onPageChange = async (page, status, sort) => {
        setPage(page);
        await fetchAddonsByProviderId((page - 1) * pageSize, pageSize);
    };

    async function refresh() {
        fetchAddonsByProviderId((page - 1) * pageSize, pageSize);
    }

    return (
        <Spin spinning={isLoading}>
            <Row type="flex" justify="end" align="middle" gutter={[0, 24]}>
                <Col>
                    <Button shape='round'
                        className="ant-btn-primary" icon={<HiOutlinePlus size={20} />}
                        onClick={() => {
                            setSelectedAddon({});
                            setVisible(true);
                        }}
                    >
                        Add
                    </Button>
                </Col>

            </Row>

            <Divider />

            {
                addons && Array.isArray(addons) && addons.length === 0 &&
                <Row justify="center">
                    <Col span={24} className="text-center">
                        <AiOutlineInbox size={70} />
                    </Col>
                    <div>No Addon found</div>
                </Row>
            }

            <Row gutter={[16, 16]} className="service-list mb-2" align='stretch'>
                {
                    Array.isArray(addons) && addons.slice(0, pageSize).map((addon, index) => (
                        <AddonItem
                            key={index}
                            {...addon}
                            updateAddons={updateAddons}
                            isLoading={isLoading}
                            setSelectedAddon={setSelectedAddon}
                            setVisible={setVisible}
                            deleteAddonsById={deleteAddonsById}
                            refresh={refresh}
                        />
                    ))
                }
            </Row>


            {
                totalAddons > pageSize ?
                    <Pagination onChange={onPageChange} defaultCurrent={1} pageSize={pageSize} total={totalAddons} />
                    :
                    null
            }
            <CreateAddon
                visible={visible}
                createAddons={createAddons}
                updateAddons={updateAddons}
                setVisible={setVisible}
                refresh={refresh}
                item={selectedAddon}
                isLoading={isLoading}
            />
        </Spin>
    );
}