const initialState = {
    isLoading: false,
    searchText: "",
    data: [],
};

const subCategory = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case "FETCH_SUB_CATEGORY":
            return { ...state, ...payload };
        case "SUB_CATEGORY_SEARCH":
            return { ...state, searchText: payload };
        default:
            return state;
    }
};

export default subCategory;