const initialState = {
    isLoading: false,
    addons: [],
    totalAddons: 0
};

const addons = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {

        case "SET_ADDONS":
            return { ...state, ...payload };

        default:
            return state;
    }
};

export default addons;
