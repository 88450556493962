import React, { useState } from "react";
import { Button } from "antd";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import { useDispatch } from "react-redux";

import "./style.scss";
import CancelOrderForm from "./cancelOrderModel";

import { OrderStatus } from "../../../constants/commonContext";

import ContactUsModal from "../../common/ContactUsModal";
import DataCard from "../../../common/dataCard";
import OrderDetail from "../../common/orderDetail";

import { allowedActions } from "./context";

import { createContactUsRequest, getChatsByUserids } from "../../../actions/message";

export default function OrderCard({ refresh, order }) {

    const [modalVisible, setModalVisible] = useState(false);
    const [cancelModalVisible, setCancelModalVisible] = useState(false);
    const [showOrderDetail, setShowOrderDetail] = useState(false);
    const [loading, setLoading] = useState(false);

    const {
        _id,
        invoice_id,
        created_at,
        seller: {
            store_name,
            _id: seller_id
        },
        buyer,
        status,
        invoice_detail: {
            invoice_total,
            lineItems,
            payment_reference
        },
        delivery_method,
        delivery_address: da,
        pickup_address: pa,
        delivery_date_time,
        order_picture,
        allergies
    } = order;

    const header_data = [{
        label: `Order #`,
        value: invoice_id,
        visible: true
    }, {
        label: 'Order Date',
        value: moment(created_at).format("ll"),
        visible: true
    }, {
        label: 'Status',
        value: OrderStatus[status],
        visible: true
    }, {
        label: 'Total',
        value: '$' + invoice_total,
        visible: true
    }];

    const data = [{
        label: 'Store',
        value: store_name,
        visible: true
    }, {
        label: 'Delivery Date',
        value: moment(delivery_date_time).format('lll'),
        visible: delivery_date_time
    }];

    if (delivery_method === 'DELIVERY') {
        data.push({
            label: 'Delivery Addr.',
            value: `${da.street_1}${da.street_2 ? `, ${da.street_2}` : ''}${da.city ? `, ${da.city}` : ''}, ${da.state}, ${da.postal_code}`,
            visible: delivery_method === 'DELIVERY' && da
        });
    } else {
        data.push({
            label: 'Pickup Loc.',
            value: pa.display_address,
            visible: delivery_method === 'PICKUP' && pa
        });
    }

    const image = order_picture ? order_picture : '';

    let history = useHistory();
    let dispatch = useDispatch();

    async function createChat(message) {
        if (message.trim().length === 0) return;
        const body = {
            seller: seller_id,
            buyer: buyer._id,
            messages: [{
                sender: buyer._id,
                receiver: seller_id,
                text: message
            }]
        };
        try {
            setLoading(true);
            await createContactUsRequest(body)(dispatch);
            setModalVisible(false);
        } catch (e) {
            toast.error("Some error occurred, please try again");
        } finally {
            setLoading(false);
        }
    }

    async function openChat() {
        setLoading(true);
        try {
            const data = await getChatsByUserids(buyer._id, seller_id)(dispatch);
            if (data) history.push(`/buyer/messages?chat=${data._id}`);
            else setModalVisible(true);
        } catch (e) {
            console.log(e);
            toast.error("Some error occurred, please try again");
        } finally {
            setLoading(false);
        }
    }

    const Action = () => (
        <React.Fragment>
            {

                status === 'ORDER_PLACED' ?
                    <Button shape='round'
                        className="button-style"
                        onClick={() => setCancelModalVisible(true)}
                    >
                        Cancel
                    </Button>
                    :
                    null
            }

            {
                allowedActions(status, 'CONTACT')
                    ?
                    <Button shape='round'
                        className="button-style"
                        onClick={openChat} loading={loading}
                    >
                        Contact Seller
                    </Button>
                    :
                    null
            }
            
            {
                allowedActions(status, 'REVIEW')
                    ?
                    <Button shape='round'
                        className="button-style"
                        onClick={() => history.push(`/buyer/review/${invoice_id}`)} loading={loading}
                    >
                        Review
                    </Button>
                    :
                    null
            }

            <Button shape='round'
                className="button-style"
                onClick={()=>setShowOrderDetail(true)}
            >
                View Details
            </Button>

        </React.Fragment>
    );

    return (
        <React.Fragment>
            <DataCard data={data} header_data={header_data} Action={Action} image={image} />
            <ContactUsModal
                visible={modalVisible} closeModal={setModalVisible}
                onSubmit={createChat} isLoading={loading}
            />
            <CancelOrderForm id={_id} refresh={refresh} visible={cancelModalVisible} setVisible={setCancelModalVisible} />

            <OrderDetail
                order={{
                    invoice_total, lineItems, payment_method: payment_reference?.tag, delivery_method,
                    da, pa, allergies, delivery_date_time, buyer
                }}
                isVisible={showOrderDetail} handleClose={()=>setShowOrderDetail(false)}
            />

        </React.Fragment>
    );
}
