import React, { Component } from "react";
import { Row, Spin } from 'antd';
import EditAddress from "./editAddress";
import AddressBlock from "./addressBlock";
import { HiOutlinePlus } from 'react-icons/hi';

const initialAddressVal = {
    street_1: "",
    street_2: "",
    postal_code: "",
    city: "",
    state: "",
    country: "",
    is_primary: false
};

class Address extends Component {

    state = {
        isUpdateMode: false,
        updateType: "edit",
        addressDetail: undefined
    };

    handleToggleMode = (isUpdateMode) => {
        this.setState({ isUpdateMode });
    };

    handleAdd = () => {
        this.handleAddUpdate(initialAddressVal);
    };

    handleAddUpdate = (addressDetail) => {
        const { user: { consumer_attribute, address: userAddress = [] } } = this.props;
        const is_primary = addressDetail.is_primary ?
            addressDetail.is_primary :
            (userAddress.length === 0 || (addressDetail._id && userAddress.length === 1));

        this.setState({
            isUpdateMode: true,
            addressDetail: { ...addressDetail, consumer_attribute, is_primary }
        });
    };

    handleCancel = () => {
        this.setState({
            addressDetail: undefined
        });
        this.handleToggleMode(false);
    };

    render() {
        const {
            user = {},
            addUpdateAddress,
            removeAddress,
            updateUserDetail
        } = this.props;
        const { address = [], isLoadingAddress, scope } = user;
        const { isUpdateMode, addressDetail } = this.state;

        return (
            <div className="address">
                <div>
                    <div className="tab-header">
                        <h3 className="title">
                            <span>Address</span>
                            {
                                ((Array.isArray(address) && address.length < 5 && scope !== "PROVIDER") &&
                                    <HiOutlinePlus onClick={this.handleAdd} size={20} />
                                )}
                        </h3>
                    </div>

                    <div className="tab-body">
                        <Spin spinning={isLoadingAddress || user.isLoading}>
                            <Row className="address-list" type='flex' gutter={16}>
                                {
                                    Array.isArray(address) &&
                                    address.map((add, key) =>
                                        <AddressBlock
                                            index={key}
                                            key={key}
                                            item={add}
                                            onUpdate={this.handleAddUpdate}
                                            onRemove={removeAddress}
                                        />
                                    )
                                }
                            </Row>
                        </Spin>
                    </div>
                </div>
                <EditAddress
                    updateUserDetail={updateUserDetail}
                    addUpdateAddress={addUpdateAddress}
                    onClose={this.handleCancel}
                    isVisible={isUpdateMode}
                    isLoading={isLoadingAddress || user.isLoading}
                    initialValues={addressDetail}
                    userscope={scope}
                    address={address}
                    consumer_attribute={user.consumer_attribute}
                />
            </div>
        );
    }
}

export default Address;
