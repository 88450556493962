import React from "react";
import { Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import { useSelector } from "react-redux";

import Product from "./myProducts/index";

import Registration from "../../containers/registration";
import AccountManagement from "../../containers/accountManagement";
import Messages from "../../containers/shared/messages";
import ProReviewAndRating from "../../containers/provider/reviewAndRating";
import CreateProduct from "../products";
import CreateProject from "../Project";
import OrderComponent from "./orders";

import "./style.scss";

export default function Provider() {

    let { path } = useRouteMatch();
    let history = useHistory();
    let location = useLocation();
    let user = useSelector(st => st.user);
    let props = { history, location };

    return (
        <div className="container d-flex flex-column flex-fill p-2 p-md-3 p-lg-4">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Dashboard | Foodelys</title>
            </Helmet>
            {
                !["REGISTRATION_PENDING_INVITE_PROVIDER", "REGISTRATION_PENDING"].includes(user.status) ?
                    <Switch>
                        <Route path={`${path}/product/create`} exact><CreateProduct {...props} /></Route>
                        <Route path={`${path}/product/:id`} exact><CreateProduct {...props} /></Route>
                        <Route path={`${path}/project/create`} exact><CreateProject {...props} /></Route>
                        <Route path={`${path}/project/:id`} exact><CreateProject {...props} /></Route>
                        <Route path={`${path}/profile`}><AccountManagement {...props} /></Route>
                        <Route path={`${path}/messages`} exact><Messages {...props} /></Route>
                        <Route path={`${path}/review/:product/:uid`} exact><ProReviewAndRating {...props} /></Route>
                        <Route path={`${path}/my-products`} exact><Product {...props} /></Route>
                        <Route path={`${path}/orders`} exact><OrderComponent {...props} /></Route>
                    </Switch>
                    :
                    <Route path={`${path}/registration`} exact><Registration {...props} /></Route>
            }
        </div>
    );
}