import React, { Component, Fragment } from 'react';
import { Row, Col, Button } from 'antd';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { AiOutlineEdit } from "react-icons/ai";

import {
    Input,
    validate
} from '../../common/redux-form';

class Password extends Component {

    state = {
        isUpdateMode: false
    };

    handleFormSubmit = async (values) => {
        const { changePassword } = this.props;
        const {
            currentPassword,
            newPassword,
            confirmNewPassword
        } = values;

        if (newPassword && (newPassword !== confirmNewPassword)) {
            throw new SubmissionError({
                confirmNewPassword: 'Password must be match with new password'
            });
        }

        const isSameAsOld = (currentPassword === newPassword) ||
            (currentPassword === confirmNewPassword);

        if (currentPassword && isSameAsOld) {
            throw new SubmissionError({
                newPassword: 'New password must be different than current'
            });
        }

        try {
            await changePassword({
                new_password: newPassword,
                old_password: currentPassword
            });
            this.handleCancel();
        } catch ({ message }) {
            throw new SubmissionError({ confirmNewPassword: message });
        }
    };

    handleToggleMode = (isUpdateMode) => {
        this.setState({ isUpdateMode });
    };

    handleCancel = () => {
        this.handleToggleMode(false);
    };

    handleUpdate = () => {
        const { reset } = this.props;
        this.handleToggleMode(true);
        reset();
    };

    render() {
        const { handleSubmit, submitting, error } = this.props;
        const { isUpdateMode } = this.state;

        return (
            <div className='password'>
                <form onSubmit={handleSubmit(this.handleFormSubmit)} >
                    <Row className='tab-header'>
                        <Col span={24}>
                            <h3 className='title'>
                                <span>Change Password</span>
                                <AiOutlineEdit onClick={this.handleUpdate}  size={20}/>
                            </h3>
                        </Col>
                    </Row>

                    <Row type='flex' justify='center' className='tab-body'>
                        <Col span={15}>
                            {
                                isUpdateMode ?
                                    (
                                        <Fragment>
                                            <Field
                                                name='currentPassword'
                                                placeholder='Enter current password'
                                                component={Input}
                                                label='Current Password'
                                                type='password'
                                                validate={[validate.required]}
                                            />
                                            <Field
                                                name='newPassword'
                                                placeholder='Enter new password'
                                                component={Input}
                                                label='New Password'
                                                type='password'
                                                validate={[validate.required]}
                                            />
                                            <Field
                                                name='confirmNewPassword'
                                                placeholder='Enter confirm new password'
                                                component={Input}
                                                label='Confirm New Password'
                                                type='password'
                                                validate={[validate.required]}
                                            />
                                            <div className="form-error">{error}</div>
                                            <div className='form-action'>
                                                <Button shape='round'
                                                    onClick={this.handleCancel}
                                                    className='btn-default'
                                                    size={'large'}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button shape='round'
                                                    className='btn-primary btn-submit'
                                                    type='primary'
                                                    size={'large'}
                                                    htmlType='submit'
                                                    loading={submitting}
                                                >
                                                    Save
                                                </Button>
                                            </div>
                                        </Fragment>
                                    ) : (
                                        <div style={{ margin: '15px 0' }}>
                                            <b>Password:</b>
                                            <span style={{ paddingLeft: '10px' }}>**********</span>
                                        </div>
                                    )
                            }
                        </Col>
                    </Row>
                </form>
            </div>
        );
    }
}

export default reduxForm({
    form: 'change-password',
    enableReinitialize: true
})(Password);
