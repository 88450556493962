import React, { PureComponent } from "react";
import { Field } from "redux-form";
import { toast } from 'react-toastify';
import { Row, Col, Button, Modal } from "antd";

import {
    Input,
    validate,
    Checkbox
    // Radio
} from "../../../../common/redux-form";
import NumericInput from "../../../../common/control/NumericInput";
import { Fetch } from "../../../../actions/common";

const PHONE_VERIFICATION_REQUIRED = JSON.parse(
    window._env.REACT_APP_PHONE_VERIFICATION_REQUIRED
);
const TEST_OTP = window._env.REACT_APP_TEST_OTP;

const phoneValidate = validate.isVerified;

class MobileFields extends PureComponent {
    state = {
        loading: false,
        visible: false,
        isVerified: false,
        phoneNumber: "",
        otp: "",
        otpSubmitEnable: false,
        sendingOTP: false
    };

    componentDidMount() {
        const { fields } = this.props;
        if (!fields.length) {
            fields.push();
        }
    }

    handleAddField = () => {
        const { max, fields } = this.props;
        if (fields.length < max) {
            fields.push();
        }
    };

    handleRemoveField = index => {
        const { fields } = this.props;
        fields.remove(index);
    };

    onChange = value => {
        this.setState({ otp: value, otpSubmitEnable: (value + "").length === 6 });
    };

    isVerifyDisabled = (event, currentValue, prevValue) => {
        if (
            this.props.initialValues.phone_number[0].number &&
            this.props.initialValues.phone_number[0].is_verified
        ) {
            const oldNumber = this.props.initialValues.phone_number[0].number;
            this.setState({ phoneNumber: oldNumber });
        }

        if (this.state.phoneNumber !== currentValue) {
            this.props.formChangeValue(`phone_number[0].is_verified`, false);
        } else if (this.state.phoneNumber) {
            this.props.formChangeValue(`phone_number[0].is_verified`, true);
        }

        // this.setState({ isVerified: FormValue.phone_number[0].is_verified })

        // return undefined
    };

    handleVerifyMobile = (fieldItem, key) => () => {
        const data = fieldItem.get(key);
        const phoneNumber = data.number;
        if (/^([0-9]{10})$/.test(phoneNumber)) {
            const phoneComponents = {
                IDDCC: '+1', // phoneNumber.substring(0, phoneNumber.length - 10),
                NN: phoneNumber.substring(0, phoneNumber.length)
            };

            const options = {
                data: JSON.stringify({
                    countryCode: phoneComponents.IDDCC,
                    phoneNumber: phoneComponents.NN
                })
            };

            if (phoneComponents.IDDCC !== '+1') return;

            if (PHONE_VERIFICATION_REQUIRED) {
                const url = `/user/verifyPhone/start`;
                this.setState({ sendingOTP: true });
                Fetch(url, "POST", options)
                    .then(res => {
                        this.setState({ sendingOTP: false });
                        if (res.status === 200) {
                            this.setState({
                                visible: true,
                                phoneNumber: data.number
                            });
                        }
                    })
                    .catch(error => {
                        this.setState({ sendingOTP: false });
                        toast.error("Invalid Code");
                    });
            } else {
                if (data.number) {
                    this.setState({
                        visible: true,
                        phoneNumber: data.number
                    });
                }
            }
        }
    };

    handleVerifyCode = () => {
        this.setState({ loading: true });
        const { phoneNumber, otp } = this.state;

        const phoneComponents = {
            IDDCC: '+1', // phoneNumber.substring(0, phoneNumber.length - 10),
            NN: phoneNumber.substring(0, phoneNumber.length)
        };

        const options = {
            data: JSON.stringify({
                countryCode: phoneComponents.IDDCC,
                phoneNumber: phoneComponents.NN,
                verificationCode: otp
            })
        };

        if (PHONE_VERIFICATION_REQUIRED) {
            const url = `/user/verifyPhone/check`;
            Fetch(url, "POST", options)
                .then(res => {
                    if (res.status === 200) {
                        this.props.formChangeValue(`phone_number[0].is_verified`, true);
                        this.setState({
                            loading: false,
                            visible: false
                        });
                    }
                })
                .catch(error => {
                    toast.error("Invalid Code");
                    this.setState({ loading: false });
                });
        } else {
            if (otp === TEST_OTP) {
                this.props.formChangeValue(`phone_number[0].is_verified`, true);
                this.setState({
                    loading: false,
                    visible: false
                });
            }
        }
    };

    handleCancel = () => {
        this.setState({
            visible: false,
            loading: false,
            otp: ""
        });
    };

    render() {
        const {
            visible,
            loading,
            phoneNumber,
            otpSubmitEnable,
            sendingOTP,
            error
        } = this.state;
        const { fields } = this.props;

        return (
            <>
                <Row className="group-field-wrapper">
                    <Col span={24} >
                        {fields.map((fieldItem, key) => {
                            return (
                                <div key={key}>
                                    <Row gutter={[16, 16]} type="flex" align='bottom'>
                                        <Col xs={24} sm={15} >
                                            <Field
                                                name={`${fieldItem}.number`}
                                                type="text"
                                                component={Input}
                                                label="Mobile Number"
                                                validate={[validate.required, validate.phone]}
                                                isRequired={true}
                                                onChange={this.isVerifyDisabled}
                                                className='mb-0 w-max'
                                                tooltipText={
                                                    <div className="info-popup">
                                                        <span className="desc">
                                                            Please enter a valid mobile no. to communicate.
                                                        </span>
                                                    </div>
                                                }
                                            />
                                        </Col>
                                        <Col xs={24} sm={6}>
                                            <Field
                                                name={`${fieldItem}.is_verified`}
                                                component={Checkbox}
                                                label="Verified"
                                                disabled={true}
                                                validate={phoneValidate}
                                                className='mb-0'
                                            />
                                        </Col>
                                        <Col span={24}>
                                            <Button shape='round'
                                                disabled={this.state.isVerified}
                                                type="primary"
                                                onClick={this.handleVerifyMobile(fields, key)}
                                                loading={sendingOTP}
                                            >
                                                Verify
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row className="form-error">
                                        {error}
                                    </Row>
                                    {/* {PHONE_VERIFICATION_REQUIRED &&
                                                (<Button shape='round'
                                                    disabled={this.state.isVerified}
                                                    type="primary"
                                                    onClick={this.handleVerifyMobile(fields, key)}
                                                >
                                                    Verify
                                            </Button>)
                                            } */}
                                </div>
                            );
                        })}
                    </Col>
                </Row>
                <div>
                    <Modal
                        visible={visible}
                        title="Verify Mobile"
                        onOk={this.handleVerifyCode}
                        onCancel={this.handleCancel}
                        destroyOnClose={true}
                        footer={[
                            <Button shape='round' key="back" onClick={this.handleCancel}>
                                Return
                            </Button>,
                            <Button shape='round'
                                key="submit"
                                type="primary"
                                loading={loading}
                                onClick={this.handleVerifyCode}
                                disabled={!otpSubmitEnable}
                            >
                                Submit
                            </Button>
                        ]}
                    >
                        <span>We have sent you an OTP on {phoneNumber}</span>
                        <br />
                        <br />
                        <NumericInput
                            maxLength={6}
                            value={this.state.otp}
                            onChange={this.onChange}
                            allow_start_from_zero={true}
                        />
                    </Modal>
                </div>
            </>
        );
    }
}

export default MobileFields;
