import React, { useEffect, useState } from "react";
import { Row, Col, Button, Spin, Popover } from 'antd';
import { Field, FieldArray, reduxForm } from 'redux-form';

import { projectType } from "../../constants/commonContext";

import { Input, SelectBox, validate } from "../../common/redux-form";

import FileUpload from "./fileUpload";
import './styles.scss';
import { AiFillQuestionCircle, AiOutlineArrowLeft } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { createProject, getProject, updateProject } from "../../actions/project";
import { toast } from "react-toastify";

const Tooltip2 = ({ text }) => (
    <Popover
        content={
            <div className="info-popup">
                <span className="desc">
                    {text}
                </span>
            </div>
        }
        trigger="hover" placement='rightTop'
    >
        <span><AiFillQuestionCircle size={20} /></span>
    </Popover>
);

const Mandatory_picture = JSON.parse(window._env.REACT_APP_PICTURE_MANDATORY);

const acceptNumbers = (isInt = true, min = undefined, max = undefined) => (val, previousVal) => {
    let regex = new RegExp(/^\d*\.?\d*$/);

    if (!val) return '';
    if (!regex.test(val)) return previousVal;

    let _val = Number(val);

    if (isInt) {
        val = parseInt(val).toString();
        _val = parseInt(val);
    }

    if (!isNaN(min) && min > _val) return previousVal;
    if (!isNaN(max) && max < _val) return previousVal;

    return _val;

};

function Project({ error, handleSubmit, initialize }) {

    const dispatch = useDispatch();
    let history = useHistory();
    const [_loading, setLoading] = useState(false);
    const params = useParams();
    const max_pics = parseInt(window._env.REACT_APP_MAX_EVENT_PICS) || 10;

    useEffect(() => {

        async function fetchProject() {
            if (params.id) {
                setLoading(true);
                try {
                    let data = await getProject(params.id)(dispatch);
                    console.log(data);
                    initialize({ ...data });
                } catch (err) {
                    console.log(err);
                } finally {
                    setLoading(false);
                }
            }
        }

        fetchProject();

    }, [params.id, dispatch, initialize]);

    async function submit(values) {
        setLoading(true);
        if((values?.duration?.value && !values?.duration.unit) || (!values?.duration?.value && values?.duration.unit)) {
            toast.error('Please fill both fields for event duration');
            return setLoading(false);
        }
        if (!values._id)
            await createProject(values)(dispatch);
        else await updateProject(values)(dispatch);
        setLoading(false);
        history.goBack();
    }

    return (
        <Spin spinning={_loading}>

            <Button shape='round' type="default" icon={<AiOutlineArrowLeft size={15} />} onClick={() => history.goBack()} size='small'>
                Back
            </Button>
            <h5 className='mb-3 mt-3'>Details</h5>
            <form onSubmit={handleSubmit(submit)} className='d-flex flex-column product-form'>

                <Row gutter={[16, 16]} className='p-3 rounded'>

                    {/* Title */}

                    <Col xs={24} md={5} xl={4}>
                        <label htmlFor='rent.amount' className='section-heading'>
                            Title
                            <span className='text-danger'><sup>{" * "}</sup></span>
                            <Tooltip2 text='Enter the name of the event' />
                        </label>
                    </Col>
                    <Col xs={24} md={19} xl={13}>
                        <Field
                            component={Input}
                            name="title"
                            validate={[validate.required, validate.maxLength40]}
                            size='default'
                            className='mb-0'
                            isRequired={true}
                            placeholder='Short description of event'
                            id='title'
                        />
                    </Col>
                    <Col xs={0} xl={6}></Col>

                    {/* Description */}

                    <Col xs={24} md={5} xl={4}>
                        <label htmlFor='description' className='section-heading'>
                            Description
                            <span className='text-danger'><sup>{" * "}</sup></span>
                            <Tooltip2 text='Give a description to help people understand the event' />
                        </label>
                    </Col>
                    <Col xs={24} md={19} xl={14}>
                        <Field
                            component={Input}
                            type="textarea"
                            name='description'
                            placeholder='A brief summary of what you did in the event'
                            className='mb-0'
                            id="description"
                            validate={[validate.required, validate.maxLength500]}
                            size='default'
                        />
                    </Col>
                    <Col xs={0} xl={6}></Col>

                    {/* category */}
                    <Col xs={24} md={5} xl={4}>
                        <label htmlFor='type' className='section-heading'>Event Type</label>
                        <span className='text-danger'><sup>{" * "}</sup></span>
                        <Tooltip2 text='Enter the type of the event' />
                    </Col>
                    <Col xs={24} md={19} xl={14}>
                        <Row gutter={[16, 8]}>
                            <Col xs={24} md={12} className='d-flex flex-column'>
                                <Field
                                    component={SelectBox}
                                    name='type'
                                    validate={[validate.required]}
                                    options={projectType.map(e => ({ value: e, label: e }))}
                                    style={{ width: '100%' }}
                                    placeholder='Event type'
                                    className='mb-0'
                                    size='default'
                                    isRequired={true}
                                    id="type"
                                    tooltipText={
                                        <div className="info-popup">
                                            <span className="desc">
                                                Choose a relevant category for the event
                                            </span>
                                        </div>
                                    }
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={0} xl={6}></Col>

                    {/* location */}
                    <Col xs={24} md={5} xl={4}>
                        <label htmlFor='location' className='section-heading'>Event Location</label>
                        <span className='text-danger'><sup>{" * "}</sup></span>
                        <Tooltip2 text='Enter the location of the event' />
                    </Col>
                    <Col xs={24} md={19} xl={14}>
                        <Row gutter={[16, 8]}>
                            <Col xs={24} md={12} className='d-flex flex-column'>
                                <Field
                                    component={Input}
                                    type="text"
                                    name='location'
                                    validate={[validate.required, validate.maxLength50]}
                                    style={{ width: '100%' }}
                                    className='mb-0'
                                    size='default'
                                    isRequired={true}
                                    id="location"
                                    tooltipText={
                                        <div className="info-popup">
                                            <span className="desc">
                                                Enter where the event was organized
                                            </span>
                                        </div>
                                    }
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={0} xl={6}></Col>

                    {/* quantity, calorie count, servings, price */}
                    <Col xs={24} md={5} xl={4}>
                        <label htmlFor='description' className='section-heading'>Event Info</label>
                    </Col>
                    <Col xs={24} md={19} xl={14}>
                        <Row gutter={[16, 8]} align='bottom'>
                            <Col xs={24} md={12} className='d-flex flex-column'>
                                <Field
                                    component={Input}
                                    type="text"
                                    normalize={acceptNumbers(true, 1, 1000)}
                                    name='audience_size'
                                    label='Audience Size'
                                    placeholder='Ex. 1 lb, 16 oz, 12 ct'
                                    className='mb-0'
                                    size='default'
                                    id="audience_size"
                                    tooltipText={
                                        <div className="info-popup">
                                            <span className="desc">
                                                Enter the number of people that attended the event
                                            </span>
                                        </div>
                                    }
                                />
                            </Col>
                            <Col xs={24} md={12} className='d-flex flex-column'>
                                <Field
                                    component={Input}
                                    type='text'
                                    name="price"
                                    normalize={acceptNumbers(false)}
                                    size='default'
                                    className='mb-0'
                                    label='Cost of Event'
                                    id='price'
                                    tooltipText={
                                        <div className="info-popup">
                                            <span className="desc">
                                                Mention how much you charged for this event.
                                            </span>
                                        </div>
                                    }
                                    isRequired={true}
                                />
                            </Col>
                            <Col xs={12} md={6} className='d-flex flex-column'>
                                <Field
                                    component={Input}
                                    name="duration.value"
                                    size='default'
                                    className='mb-0'
                                    label='Duration'
                                    id='duration'
                                    placeholder='Duration of event'
                                    normalize={acceptNumbers(true, 1)}
                                    tooltipText={
                                        <div className="info-popup">
                                            <span className="desc">
                                                Mention the duration of event. Example, 1 hour, 1 day, etc.
                                            </span>
                                        </div>
                                    }
                                />
                            </Col>
                            <Col xs={12} md={6} className='d-flex flex-column'>
                                <Field
                                    component={SelectBox}
                                    name="duration.unit"
                                    placeholder='Duration of event'
                                    size='default'
                                    style={{ width: '100%' }}
                                    className='mb-0'
                                    options={[{ label: 'hour', value: 'hour' }, { label: 'day', value: 'day' }]}
                                />
                            </Col>
                            <Col xs={24} md={12} className='d-flex flex-column'>
                                <Field
                                    component={Input}
                                    type='text'
                                    name="year"
                                    normalize={acceptNumbers(true)}
                                    size='default'
                                    className='mb-0'
                                    label='Event Year'
                                    id='year'
                                    tooltipText={
                                        <div className="info-popup">
                                            <span className="desc">
                                                Mention the year in which this event was organized.
                                            </span>
                                        </div>
                                    }
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={0} xl={6}></Col>

                    <Col xs={24} md={5} xl={4}>
                        <label htmlFor='photo_urls' className='section-heading'>
                            Photos
                            <span className='text-danger'><sup>{" * "}</sup></span>
                            <Tooltip2 text={`Upload picture of the event. You can maximum upload ${max_pics} pictures `} />
                        </label>
                    </Col>
                    <Col xs={24} md={19} xl={14}>
                        <FieldArray
                            component={FileUpload}
                            name="photo_urls"
                            id='photo_urls'
                            validate={Mandatory_picture ? [validate.required] : []}
                            resize={true}
                            width={800}
                            height={600}
                            context='projects'
                            maxLimit={max_pics}
                            fileTypes={["image/jpg", "image/jpeg", "image/png"]}
                        />
                    </Col>
                    <Col xs={0} xl={6}></Col>


                    <Col xs={0} md={5} xl={4}>
                    </Col>
                    <Col xs={24} md={19} xl={14}>
                        {error && <p className='text-danger'>{error}</p>}

                        <Popover
                            content={
                                <div className="info-popup">
                                    <span className="desc">
                                        Click to {params.id ? 'update' : 'add'} event
                                    </span>
                                </div>
                            }
                            trigger="hover" placement='bottom'
                        >
                            <Button shape='round' type='primary' htmlType='submit' className='mt-2' loading={_loading}>
                                {params.id ? 'Save' : 'Submit'}
                            </Button>
                        </Popover>
                    </Col>
                    <Col xs={0} xl={6}></Col>

                </Row>

            </form >
        </Spin>
    );
}

export default reduxForm({
    form: 'project_form',
    onSubmitFail: () => window.scrollTo(0, 0)
})(Project);