import React, { useEffect, useState } from "react";
import { Row, Col } from 'antd';
import { Field, reduxForm } from 'redux-form';
import { setUser, updateUserDetail } from '../../../actions/user';
import { FileUpload } from "../../../common/redux-form";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineEdit, AiOutlineUser } from "react-icons/ai";
import Modal from "antd/lib/modal/Modal";

function CoverPhotos({ error, handleSubmit, initialize }) {

    const dispatch = useDispatch();
    const loading = useSelector(st => st.user.isLoading);
    const store_cover_picture = useSelector(st => st.user.store_cover_picture);
    const user = useSelector(st => st.user);

    const submit = async (values) => {
        await dispatch(updateUserDetail(values));
        dispatch(setUser({ ...user, ...values }));
    };

    useEffect(() => {
        if (store_cover_picture)
            initialize({ store_cover_picture: store_cover_picture });
    }, [store_cover_picture, initialize]);

    const [showUpdateForm, setShowUpdateForm] = useState(false);

    const title = (
        <div>
            <AiOutlineUser style={{ paddingRight: "10px" }} size={20} /> Add/Update Cover Photos
        </div>
    );

    return (
        <div>
            <form onSubmit={handleSubmit(submit)} className='d-flex flex-column property-form'>
                <Row className="tab-header">
                    <Col span={24}>
                        <h3 className="title">
                            <span>Store Cover Photo</span>
                            <AiOutlineEdit
                                onClick={() => setShowUpdateForm(true)}
                            />
                        </h3>
                    </Col>
                </Row>
                <Row gutter={[16, 12]} className='p-3 rounded mb-3'>
                    <Col span={24}>
                        <Row>
                            <Col xs={24} className='d-flex flex-wrap'>
                                {store_cover_picture ?

                                    <img
                                        className='m-1 img img-fluid rounded'
                                        src={window._env.REACT_APP_PUBLIC_URL_HOST + store_cover_picture}
                                        alt='cover_photo'
                                    />
                                    :
                                    <div>You have not uploaded a store cover picture yet</div>
                                }

                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Modal
                    title={title}
                    visible={showUpdateForm}
                    okButtonProps={{ htmlType: "submit", loading: loading }}
                    cancelButtonProps={{ disabled: loading }}
                    okText="Save"
                    onCancel={() => setShowUpdateForm(false)}
                    onOk={handleSubmit(submit)}
                    keyboard={false}
                >
                    <Row type="flex" justify="center" className="tab-body">
                        <Col span={24}>
                            <Field
                                name="store_cover_picture"
                                component={FileUpload}
                                label="Store Cover Picture"
                                context='store'
                                props={{ resize: true, width: 988, height: 233 }}
                            />
                            <div className="form-error">{error}</div>
                        </Col>
                    </Row>
                </Modal>
            </form >
        </div>
    );
}

export default reduxForm({
    enableReinitialize: true,
    form: 'store_cover_picture'
})(CoverPhotos);