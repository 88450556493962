import React, { Component } from "react";
import {
    Popover,
    
    Rate as RateField
} from "antd";
import { AiFillQuestionCircle } from "react-icons/ai";

class Rate extends Component {

    handleChange = (value) => {
        const {input: { onChange } } = this.props;
        onChange(value);
    };

    render() {

        const {
            input: { value },
            meta: { touched, error, warning },
            className,
            errorClass,
            label,
            labelClass,
            isRequired,
            tooltipText,
            tooltipPlacement,
            count,
            allowHalf,
            disabled
        } = this.props;

        const isError = touched && (error || warning);
        const errorText = error || warning;
        const fieldError = isError ? "field-error" : "";
        const noError = !isError ? "no-error" : "";

        return (
            <div className={`form-field ${className} ${fieldError}`}>
                {
                    label &&
                    <label className={labelClass}>
                        {label}
                        {isRequired ? <span className="required-label"><sup>{" * "}</sup></span> : ""}
                        {' '}
                        {
                            tooltipText &&
                            <Popover content={tooltipText} trigger="hover" placement={tooltipPlacement}>
                                <span><AiFillQuestionCircle  size={20}/></span>
                            </Popover>
                        }
                    </label>
                }
                <RateField
                    onChange={this.handleChange}
                    count={count}
                    value={value}
                    allowHalf={allowHalf}
                    disabled={disabled}
                />
                {
                    <span className={`error ${errorClass} ${noError}`}>
                        {isError && errorText}
                    </span>
                }
            </div>
        );
    }
}

Rate.defaultProps = {
    input: {},
    meta: {},
    placeholder: "",
    className: "",
    errorClass: "",
    label: "",
    labelClass: "",
    isRequired: false,
    tooltipPlacement: "rightTop",
    count: 5,
    allowHalf: true,
    disabled : false,
};

export default Rate;
