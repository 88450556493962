import { toast } from 'react-toastify';
import { initialize } from "redux-form";
import { Fetch, getErrorMessage } from "./common";

/**
 * Set service data
 * @param {object} payload service data
 * @returns {object} updated payload
 */
export const setProduct = (payload = {}) => ({
    type: "SET_SERVICE_SKU",
    payload
});

/**
 * Set service form data
 * @param {object} payload service form data
 * @returns {object} updated payload
 */
export const setProductForm = (payload = {}) => ({
    type: "SET_SERVICE_SKU_FORM",
    payload
});

/**
 * Set service filter data
 * @param {object} payload service filter
 * @returns {object} updated payload
 */
export const setProductFilter = (payload = {}) => ({
    type: "SET_SERVICE_SKU_FILTER",
    payload
});

/**
 * Create product
 * @param {object} serviceData service object
 * @returns {{<Promise>}} promise
 */
export const createProduct = (productData = {}) => (dispatch) => {

    const url = "/product";
    const options = {
        data: JSON.stringify({
            ...productData
        })
    };

    dispatch(setProduct({ isLoading: true }));
    return Fetch(url, "POST", options)
        .then(({ status, data = {} }) => {
            if (status === 201) {
                toast.success("Product Created Successfully");
                dispatch(setProduct({ isLoading: false }));
                return data;
            }
            throw new Error(data.message);
        })
        .catch((error) => {
            dispatch(setProduct({ isLoading: false }));
            throw new Error(getErrorMessage(error, "Something wrong try again"));
        });

};

/**
 * Get product
 * @returns {{<Promise>}} promise
 */
export const getProduct = (productId) => (dispatch, getState) => {
    if (!productId) {
        return true;
    }

    const url = `/product/detail/${productId}`;

    dispatch(setProduct({ isProductDetail: true }));
    return Fetch(url, "GET").then(({ status, data = {} }) => {
        if (status === 200 || status === 201) {
            dispatch(setProduct({ productDetail: data, isProductDetail: false }));
            return data;
        }

        dispatch(setProduct({ isProductDetail: false }));
        throw new Error(data.message);
    }).catch((error) => {
        dispatch(setProduct({ isProductDetail: false, productDetail: {} }));
    });
};

/**
 * 
 * @param {Object} params Parameters to be passed in query
 * @param {Number} params.skip number of pages to skip
 * @param {Number} params.limit page size
 * @param {String} params.id mongo id of product 
 * @param {[String]} params.UIDs uids of product 
 * @param {String} params.seller mongo id of seller
 * @param {Boolean} params.includeSellerInfo should the response include seller info
 * @param {Boolean} params.includeOrderCount should the response include order count per dish
 * @returns 
 */
export const getProductV2 = (params) => (dispatch) => {
    return Fetch('/product/v2', "GET", { params })
        .then(({ status, data = {} }) => {
            if (status === 200) {
                let totalProducts = data.totalProducts;
                data = data.data || [];
                dispatch(setProduct({ isLoadingSubRoute: false, products: data, totalServices: totalProducts }));
                return data;
            }

            throw new Error(data.message);
        }).catch((error) => {
            dispatch(setProduct({ isLoadingSubRoute: false, products: [] }));
        });
};

export const getAllProducts = (type = 'GIG', status = '', skip = 0, limit = 5) => (dispatch, getState) => {

    let params = {
        type, status, skip, limit
    };

    const url = '/product';
    dispatch(setProduct({ isLoadingSubRoute: true }));
    return Fetch(url, "GET", { params })
        .then(({ status, data = {} }) => {
            if (status === 200 || status === 201) {
                let totalServices = data.totalServices;
                data = data.data || [];
                dispatch(setProduct({ isLoadingSubRoute: false, products: data, totalServices }));
                return data;
            }

            throw new Error(data.message);
        }).catch((error) => {
            dispatch(setProduct({ isLoadingSubRoute: false, products: [] }));
        });
};

export const removeProduct = (type = 'GIG', productId) => (dispatch) => {

    if (!productId) {
        toast.error("Invalid product id");
        return false;
    }

    const url = `/product/${productId}`;
    dispatch(setProduct({ isLoadingSubRoute: true }));
    return Fetch(url, "DELETE").then(({ status, data = {} }) => {
        if (status === 200 || status === 201) {
            dispatch(getAllProducts(type));
            // if(data.message) toast.success(data.message);
            return data;
        }

        throw new Error(data.message);
    }).catch((error) => {
        const message = getErrorMessage(error);
        if (message) {
            toast.error(message);
        }
        dispatch(setProduct({ isLoadingSubRoute: false, products: [] }));
    });
};

/**
 * Get edit product
 * @param {string} productId
 * @returns {{<Promise>}} promise
 */
export const getEditProduct = (productId) => (dispatch, getState) => {
    if (!productId) {
        return true;
    }

    const url = `/product/detail/${productId}?should_not_populate_addons=true`;

    dispatch(setProduct({ isEditLoading: true }));
    return Fetch(url, "GET").then(({ status, data = {} }) => {
        if (status === 200 || status === 201) {
            dispatch(setProduct({ isEditLoading: false, productDetail: data }));
            dispatch(initialize("service", data));
            return data;
        }

        dispatch(initialize("service", {}));
        dispatch(setProduct({ isEditLoading: false }));
        throw new Error(data.message);
    }).catch((error) => {
        dispatch(setProduct({ isEditLoading: false, productDetail: {} }));
    });
};

/**
 * Update product
 * @param {object} product updated product
 * @returns {{<Promise>}} promise
 */
export const updateProduct = (product) => (dispatch, getState) => {
    if (!product || !product._id) {
        return true;
    }

    const url = `/product`;
    const options = {
        data: JSON.stringify(product)
    };

    dispatch(setProduct({ isLoading: true, isLoadingSubRoute: true }));
    return Fetch(url, "PATCH", options).then(({ status, data = {} }) => {
        if (status === 200) {
            dispatch(setProduct({ isLoading: false, isLoadingSubRoute: false }));
            toast.success(data.message);
            return data.service;
        }
        dispatch(setProduct({ isLoading: false, isLoadingSubRoute: false }));
        throw new Error(data.message);

    }).catch((error) => {
        toast.error(getErrorMessage(error));
        dispatch(setProduct({ isLoading: false, productDetail: {} }));
    });
};

/**
 * Reset product form
 * @returns {{<Promise>}} promise
 */
export const resetProductForm = () => (dispatch, getState) => {

    dispatch(initialize("service", {}));
    dispatch({
        type: "RESET_SERVICE_FORM"
    });
};

export const checkUserProductLimit = () => (dispatch, getState) => {

    const url = `/product/limit_check`;
    dispatch(setProduct({ isProductDetail: true }));
    return Fetch(url, "GET").then(({ status, data = {} }) => {
        if (!data.isUserAllow) {
            toast.error(data.message);
        } else {
            toast.success(data.message);
        }
        dispatch(setProduct({ isProductDetail: false }));
        return data;

    }).catch((error) => {
        toast.error(error.message);
        dispatch(setProduct({ isProductDetail: false }));
    });
};

