import React, { useEffect } from "react";
import { Alert, Tabs, Tooltip } from 'antd';
import './style.scss';
import Products from '../products';
import AddOns from '../../../containers/provider/addons';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { primaryColor } from "../../../common/theme";
import { fetchAddonsByProviderId } from "../../../actions/addons";
import Projects from "../projects";

const { TabPane } = Tabs;

const MyProduct = () => {
    const activeKey = useSelector(st => st.productTabs.activeTab);
    const dispatch = useDispatch();
    const { address, pickupLocation, paymentOption, provider_attributes: pa } = useSelector(st => st.user);
    const { addons } = useSelector(st => st.addons);
    const history = useHistory();

    useEffect(() => {
        fetchAddonsByProviderId(0, 20)(dispatch);
    }, [dispatch]);

    return (
        <div>

            {
                !Array.isArray(address) || address.every(e => !e.street_1) ?
                    <Alert type='error' className='mb-3'
                        message={
                            <span>
                                Please add an&nbsp;
                                <span
                                    style={{ color: primaryColor }} className='pointer'
                                    onClick={() => history.push('/seller/profile/address')}
                                >
                                    address from your profile&nbsp;
                                </span>
                                to publish your product(s)&nbsp;
                            </span>
                        }
                    />
                    :
                    null
            }
            {
                pa?.serving_options?.pickup && (!Array.isArray(pickupLocation) || !pickupLocation.some(e => e.enable)) ?
                    <Alert type='error' className='mb-3'
                        message={
                            <span>
                                Please add/enable a&nbsp;
                                <span
                                    style={{ color: primaryColor }} className='pointer'
                                    onClick={() => history.push('/seller/profile/pickup-location')}
                                >
                                    pickup location from your profile&nbsp;
                                </span>
                                to publish your product(s)&nbsp;
                            </span>
                        }

                    />
                    :
                    null
            }
            
            {
                pa?.serving_options?.delivery && (!Array.isArray(addons) || !addons.some(e => e.delivery_addon && e.user_enabled && e.enabled)) ?
                    <Alert type='error' className='mb-3'
                        message={
                            <span>
                                Please add/enable a&nbsp;
                                <span
                                    style={{ color: primaryColor }} className='pointer'
                                    onClick={() => dispatch({ type: 'CHANGE_TAB', payload: "2" })}
                                >
                                    delivery addon from Addons tab&nbsp;
                                </span>
                                to publish your product(s)&nbsp;
                            </span>
                        }

                    />
                    :
                    null
            }
            
            {
                !Array.isArray(paymentOption) || !paymentOption.some(e => e.isEnabled) ?
                    <Alert type='error' className='mb-3'
                        message={
                            <span>
                                Please add/enable a&nbsp;
                                <span
                                    style={{ color: primaryColor }} className='pointer'
                                    onClick={() => history.push('/seller/profile/payment-option')}
                                >
                                    payment option from your profile&nbsp;
                                </span>
                                to publish your product(s)&nbsp;
                            </span>
                        }

                    />
                    :
                    null
            }

            <Tabs className="my-product_div" type="card"
                activeKey={activeKey ? activeKey : "1"}
                destroyInactiveTabPane={true}
                onChange={(key) => dispatch({ type: 'CHANGE_TAB', payload: key })}
            >
                <TabPane
                    tab={(
                        <Tooltip title={window._env.REACT_APP_TAB_TOOLTIP_MESSAGE_GIG} placement="bottomRight">
                            <span>Menu</span>
                        </Tooltip>
                    )}
                    key="1">
                    <Products />
                </TabPane>
                <TabPane
                    tab={(
                        <Tooltip title={window._env.REACT_APP_TAB_TOOLTIP_MESSAGE_ADDON} placement="bottomRight">
                            <span>Add-Ons</span>
                        </Tooltip>
                    )}
                    key="2">
                    <AddOns />
                </TabPane>
                <TabPane
                    tab={(
                        <Tooltip title={window._env.REACT_APP_TAB_TOOLTIP_MESSAGE_ADDON} placement="bottomRight">
                            <span>Featured Events</span>
                        </Tooltip>
                    )}
                    key="3">
                    <Projects />
                </TabPane>
            </Tabs>
        </div>
    );
};

export default MyProduct;