// const googleCaptchaKey = window._env.NODE_ENV === "production" ? window._env.REACT_APP_CAPTCHA_SITE_KEY : null;
const googleCaptchaKey = window._env.REACT_APP_CAPTCHA_SITE_KEY;
const googleCaptchaKeyV3 = window._env.REACT_APP_CAPTCHAV3_SITE_KEY;
const recaptchaEnabled = JSON.parse(window._env.REACT_APP_CAPTCHAV3_ENABLED);

const isResetPasswordMode = Boolean(localStorage.getItem("reset-username"));
const initialState = {
    isOpenSideMenu: false,
    googleCaptchaKey,
    googleCaptchaKeyV3,
    recaptchaEnabled,
    isResetPasswordMode,
    isAppLoader: true,
    address_from_ip: {}
};

const app = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case "SET_APP":
            return { ...state, ...payload };

        default:
            return state;
    }
};

export default app;