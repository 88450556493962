import { Fetch, getErrorMessage } from "./common";
import { toast } from 'react-toastify';
import { reset } from "redux-form";

export const setAddons = (payload = {}) => ({
    type: "SET_ADDONS",
    payload,
});

export const fetchAddonsByProviderId = (skip, limit) => (dispatch, getState) => {
    // eslint-disable-next-line max-len
    const url = `/addons?${skip || skip === 0 ? `&skip=${skip}` : ''}${limit || limit === 0 ? `&limit=${limit}` : ''}`;
    dispatch(setAddons({ isLoading: true }));
    return Fetch(url, "GET")
        .then(({ status, data = {} }) => {
            if (status === 200) {
                dispatch(
                    setAddons({
                        isLoading: false,
                        addons: data.addons,
                        totalAddons: data.totalAddons
                    })
                );
                return data;
            }

            throw new Error(data.message);
        })
        .catch((error) => {
            dispatch(
                setAddons({ isLoading: false, addons: [] })
            );
        });
};

export const getAddons = (seller_id) => (dispatch, getState) => {
    // eslint-disable-next-line max-len
    const url = `/addons`;
    dispatch(setAddons({ isLoading: true }));
    return Fetch(url, "GET", {params: { seller_id }})
        .then(({ status, data = {} }) => {
            if (status === 200) {
                dispatch(
                    setAddons({
                        isLoading: false,
                        addons: data.addons,
                        totalAddons: data.totalAddons
                    })
                );
                return data.addons;
            }

            throw new Error(data.message);
        })
        .catch((error) => {
            dispatch(
                setAddons({ isLoading: false, addons: [] })
            );
        });
};

export const createAddons = (formData = {}) => (dispatch, getState) => {
    const url = `/addons`;
    const options = {
        data: JSON.stringify({ ...formData }),
    };
    dispatch(setAddons({ isLoading: true }));
    return Fetch(url, "POST", options)
        .then(({ status, data = {} }) => {
            if (status === 200 || status === 201) {
                toast.success(data.message || "Addon created successfully");
                dispatch(reset("addon"));
                dispatch(setAddons({ isLoading: false }));
                return data;
            }

            toast.warning(data.message);
            throw new Error(data.message);
        })
        .catch((error) => {
            toast.error(getErrorMessage(error));
            dispatch(setAddons({ isLoading: false }));
        });
};

export const updateAddons = (formData = {}) => (dispatch, getState) => {
    const url = `/addons`;
    const options = {
        data: JSON.stringify({ ...formData }),
    };
    dispatch(setAddons({ isLoading: true }));
    return Fetch(url, "PATCH", options)
        .then(({ status, data = {} }) => {
            if (status === 200 || status === 201) {
                toast.success(data.message || "Addon updated successfully" );
                dispatch(reset("addon"));
                dispatch(setAddons({ isLoading: false }));
                return data;
            }

            toast.warning(data.message);
            throw new Error(data.message);
        })
        .catch((error) => {
            toast.error(getErrorMessage(error));
            dispatch(setAddons({ isLoading: false }));
        });
};

export const deleteAddonsById = (id) => (dispatch, getState) => {
    // eslint-disable-next-line max-len
    const url = `/addons?id=${id}`;
    dispatch(setAddons({ isLoading: true }));
    return Fetch(url, "DELETE")
        .then(({ status, data = {} }) => {
            if (status === 200) {
                dispatch(
                    setAddons({
                        isLoading: false,
                    })
                );
                toast.success('Addon removed successfully');
                return data;
            }
            toast.error(data.message);
            return;
        })
        .catch((error) => {
            toast.error(error.message);
            dispatch(
                setAddons({ isLoading: false })
            );
        });
};

export const resetAddonsForm = () => (dispatch) => {
    dispatch(reset("addon"));
};