import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Button, Spin, Popover } from 'antd';
import { Field, FieldArray, formValueSelector, reduxForm } from 'redux-form';

import { originType, dietType } from "../../constants/commonContext";

import { Input, SelectBox, validate, Checkbox, DatePicker } from "../../common/redux-form";

import FileUpload from "./fileUpload";
import './styles.scss';
import { AiFillQuestionCircle, AiOutlineArrowLeft } from "react-icons/ai";
import { createProduct, getProduct, updateProduct } from "../../actions/product";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { getAllCategories } from "../../actions/category";
import TagInput from "./tagInput";
import IngredientInput from './ingredientInput';
import moment from 'moment';
import ProductPopup from "../productPopup";

const Tooltip2 = ({ text }) => (
    <Popover
        content={
            <div className="info-popup">
                <span className="desc">
                    {text}
                </span>
            </div>
        }
        trigger="hover" placement='rightTop'
    >
        <span><AiFillQuestionCircle size={20} /></span>
    </Popover>
);

const Mandatory_picture = JSON.parse(window._env.REACT_APP_PICTURE_MANDATORY);

const acceptNumbers = (isInt = true, min = undefined, max = undefined) => (val, previousVal) => {
    let regex = new RegExp(/^\d*\.?\d*$/);

    if (!val) return '';
    if (!regex.test(val)) return previousVal;

    let _val = Number(val);

    if (isInt) {
        val = parseInt(val).toString();
        _val = parseInt(val);
    }

    if (!isNaN(min) && min > _val) return previousVal;
    if (!isNaN(max) && max < _val) return previousVal;

    return _val;

};

function Product({ error, handleSubmit, reset, form, initialize }) {

    const dispatch = useDispatch();
    let history = useHistory();
    const [isVisible, setIsVisible] = useState(false);
    const [_loading, setLoading] = useState(false);
    const [disclaimer, setDisclaimer] = useState(false);
    const user = useSelector(st => st.user);
    const { categories, subCategories, tag_suggestions, isLoading: categoryLoading } = useSelector(st => st.category);
    const { kind, ordering_restriction } = useSelector(st => formValueSelector(form)(st, 'kind', 'ordering_restriction'));
    const params = useParams();

    const fetchProduct = useCallback(async () => {
        if (params.id) {
            setLoading(true);
            try {
                let data = await getProduct(params.id)(dispatch);
                setDisclaimer(data?.dietary_guidelines?.food_allergy_disclaimer);
                initialize({ ...data });
            } catch (err) {
                console.log(err);
            } finally {
                setLoading(false);
            }
        } else {
            initialize({ ordering_restriction: { type: 'ALWAYS_READY' }, moq: 1 });
        }
    }, [params.id, initialize, dispatch]);

    useEffect(() => {
        fetchProduct();
        getAllCategories()(dispatch);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function submit(values) {

        if (ordering_restriction?.type === 'ON_DEMAND_COOKING' || ordering_restriction?.type === 'EVENT_CATERING') {
            values.stock_mgnt_required = false;
        }

        setLoading(true);
        if (!values._id)
            await createProduct(values)(dispatch);
        else await updateProduct(values)(dispatch);
        setLoading(false);
        history.goBack();
    }

    return (
        <Spin spinning={_loading || categoryLoading}>

            <Button shape='round' type="default" icon={<AiOutlineArrowLeft size={15} />} onClick={() => history.goBack()} size='small'>
                Back
            </Button>
            <h5 className='mb-3 mt-3'>Details</h5>
            <form onSubmit={handleSubmit(submit)} className='d-flex flex-column product-form'>

                <Row gutter={[16, 16]} className='p-3 rounded'>

                    {/* Title */}

                    <Col xs={24} md={5} xl={4}>
                        <label htmlFor='rent.amount' className='section-heading'>
                            Title/Name
                            <span className='text-danger'><sup>{" * "}</sup></span>
                            <Tooltip2 text='Enter the name of the food-item' />
                        </label>
                    </Col>
                    <Col xs={24} md={19} xl={13}>
                        <Field
                            component={Input}
                            name="title"
                            validate={[validate.required, validate.maxLength40]}
                            size='default'
                            className='mb-0'
                            isRequired={true}
                            placeholder='Name of your food product'
                            id='title'
                        />
                    </Col>
                    <Col xs={0} xl={6}></Col>

                    {/* Description */}

                    <Col xs={24} md={5} xl={4}>
                        <label htmlFor='description' className='section-heading'>
                            Description
                            <span className='text-danger'><sup>{" * "}</sup></span>
                            <Tooltip2 text='Give a description to help people understand the food-item' />
                        </label>
                    </Col>
                    <Col xs={24} md={19} xl={14}>
                        <Field
                            component={Input}
                            type="textarea"
                            name='description'
                            placeholder='Brief description of your product'
                            className='mb-0'
                            id="description"
                            validate={[validate.required, validate.maxLength500]}
                            size='default'
                        />
                    </Col>
                    <Col xs={0} xl={6}></Col>

                    {/* Kind, category, origin, delivery */}
                    <Col xs={24} md={5} xl={4}>
                        <label htmlFor='description' className='section-heading'>Specifics</label>
                    </Col>
                    <Col xs={24} md={19} xl={14}>
                        <Row gutter={[16, 8]}>
                            <Col xs={24} md={12} xl={8} className='d-flex flex-column'>
                                <Field
                                    component={SelectBox}
                                    name='kind'
                                    validate={[validate.required]}
                                    options={categories ? categories.map(e => ({ value: e, label: e })) : []}
                                    style={{ width: '100%' }}
                                    placeholder='Category'
                                    label='Category'
                                    className='mb-0'
                                    size='default'
                                    isRequired={true}
                                    id="Kind"
                                    tooltipText={
                                        <div className="info-popup">
                                            <span className="desc">
                                                Choose a relevant category for your food item
                                            </span>
                                        </div>
                                    }
                                />
                            </Col>
                            <Col xs={24} md={12} xl={8} className='d-flex flex-column'>
                                <Field
                                    component={SelectBox}
                                    name='category'
                                    validate={[validate.required]}
                                    disabled={!kind}
                                    options={
                                        (kind ? (subCategories[kind] || []) : []).map(e => ({ value: e, label: e }))
                                    }
                                    style={{ width: '100%' }}
                                    placeholder='Kind'
                                    label='Kind'
                                    className='mb-0'
                                    size='default'
                                    isRequired={true}
                                    id="Category"
                                    tooltipText={
                                        <div className="info-popup">
                                            <span className="desc">
                                                Choose a relevant kind for your food item
                                            </span>
                                        </div>
                                    }
                                />
                            </Col>
                            <Col xs={24} md={12} xl={8} className='d-flex flex-column'>
                                <Field
                                    component={SelectBox}
                                    name='origin'
                                    options={originType}
                                    style={{ width: '100%' }}
                                    placeholder='Origin'
                                    label='Origin'
                                    className='mb-0'
                                    size='default'
                                    id="origin"
                                    isRequired={true}
                                    tooltipText={
                                        <div className="info-popup">
                                            <span className="desc">
                                                Select the relevant origin for your food item
                                            </span>
                                        </div>
                                    }
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={0} xl={6}></Col>

                    {/* quantity, calorie count, servings, price */}
                    <Col xs={24} md={5} xl={4}></Col>
                    <Col xs={24} md={19} xl={14}>
                        <Row gutter={[16, 8]} align='bottom'>
                            <Col xs={24} md={12} xl={6} className='d-flex flex-column'>
                                <Field
                                    component={Input}
                                    name="price.unit_price"
                                    validate={[validate.required, validate.number]}
                                    normalize={acceptNumbers(true, 0)}
                                    size='default'
                                    className='mb-0'
                                    label='Price($)'
                                    isRequired={true}
                                    id='total_beds'
                                    tooltipText={
                                        <div className="info-popup">
                                            <span className="desc">
                                                Mention the total price for your food item
                                            </span>
                                        </div>
                                    }
                                />
                            </Col>
                            <Col xs={24} md={12} xl={6} className='d-flex flex-column'>
                                <Field
                                    component={Input}
                                    type="text"
                                    name='quantity'
                                    label='Quantity'
                                    placeholder='Ex. 1 lb, 16 oz, 12 ct'
                                    className='mb-0'
                                    size='default'
                                    id="quantity"
                                    tooltipText={
                                        <div className="info-popup">
                                            <span className="desc">
                                                Mention the standard quantity of your food item. Example, 1 lb, 16 oz, etc.
                                            </span>
                                        </div>
                                    }
                                />
                            </Col>
                            <Col xs={24} md={12} xl={6} className='d-flex flex-column'>
                                <Field
                                    component={Input}
                                    name="serves.max"
                                    normalize={acceptNumbers(true, 1)}
                                    size='default'
                                    className='mb-0'
                                    label='Serves' Unit
                                    id='serves'
                                    tooltipText={
                                        <div className="info-popup">
                                            <span className="desc">
                                                Mention how many people will the standard quantity serve.
                                            </span>
                                        </div>
                                    }
                                />
                            </Col>
                            <Col xs={24} md={12} xl={6} className='d-flex flex-column'>
                                <Field
                                    component={Input}
                                    name="total_calories"
                                    normalize={acceptNumbers(false, 0)}
                                    size='default'
                                    className='mb-0'
                                    label='Total Calories'
                                    id='total_calories'
                                    tooltipText={
                                        <div className="info-popup">
                                            <span className="desc">
                                                Mention the total calories in standard quantity.
                                            </span>
                                        </div>
                                    }
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={0} xl={6}></Col>

                    {/* dietary guidelines */}
                    <Col xs={24} md={5} xl={4}>
                        <label htmlFor='description' className='section-heading'>Dietary Guidelines</label>
                    </Col>
                    <Col xs={24} md={19} xl={14}>
                        <Row gutter={[16, 8]} align='bottom'>
                            <Col xs={24} md={12} xl={6} className='d-flex flex-column'>
                                <Field
                                    component={SelectBox}
                                    name='dietary_guidelines.diet_type'
                                    options={dietType}
                                    style={{ width: '100%' }}
                                    placeholder='Diet Type *'
                                    className='mb-0'
                                    size='default'
                                    id="diet_type"
                                    isRequired={true}
                                    tooltipText={
                                        <div className="info-popup">
                                            <span className="desc">
                                                Select the appropriate dietary category for your food item
                                            </span>
                                        </div>
                                    }
                                />
                            </Col>
                            <Col xs={24} md={12} xl={6} className='d-flex flex-column'>
                                <Field
                                    component={Checkbox}
                                    name='dietary_guidelines.gluten_free'
                                    label='Gluten Free'
                                    className='mb-0'
                                    tooltipText={
                                        <div className="info-popup">
                                            <span className="desc">
                                                Check this box if the food item is gluten free
                                            </span>
                                        </div>
                                    }
                                />
                            </Col>
                            <Col xs={24} md={12} xl={12} className='d-flex flex-column'>
                                <Field
                                    component={Checkbox}
                                    name='dietary_guidelines.food_allergy_disclaimer'
                                    label='Show Food Allergy Disclaimer'
                                    className='mb-0'
                                    tooltipText={
                                        <div className="info-popup">
                                            <span className="desc">
                                                Check this box if your food item contains ingredients which consumers could be allergic to
                                            </span>
                                        </div>
                                    }
                                    onChange={(x) => setDisclaimer(x.target.checked)}
                                />
                            </Col>
                            {disclaimer &&
                                <Col xs={24} md={20} xl={14} className="offset-lg-6">
                                    <Field
                                        component={Input}
                                        type="textarea"
                                        name='dietary_guidelines.disclaimer_description'
                                        placeholder='Brief description of your Disclaimer(200)'
                                        className='mb-0'
                                        validate={[validate.required, validate.maxLength200]}
                                        size='default'
                                    />
                                </Col>
                            }
                        </Row>
                    </Col>
                    <Col xs={0} xl={6}></Col>

                    <Col xs={24} md={5} xl={4}>
                        <label htmlFor='moq' className='section-heading'>
                            Minimum Order Quantity <Tooltip2 text='Enter minimum quantity per order.' />
                        </label>
                    </Col>
                    <Col xs={24} md={19} xl={14}>
                        <Field
                            component={Input}
                            name="moq"
                            validate={[validate.required, validate.number]}
                            normalize={acceptNumbers(true, 0)}
                            size='default'
                            className='mb-0'
                            id='moq'
                        />
                    </Col>
                    <Col xs={0} xl={6}></Col>

                    <Col xs={24} md={5} xl={4}>
                        <label htmlFor='ingredients' className='section-heading'>
                            Photos
                            <span className='text-danger'><sup>{" * "}</sup></span>
                            <Tooltip2 text='Upload picture of your food item. You can maximum upload 3 pictures ' />
                        </label>
                    </Col>
                    <Col xs={24} md={19} xl={14}>
                        <FieldArray
                            component={FileUpload}
                            name="photo_urls"
                            validate={Mandatory_picture ? [validate.required] : []}
                            resize={true}
                            width={800}
                            height={600}
                            context='products'
                            maxLimit={3}
                            fileTypes={["image/jpg", "image/jpeg", "image/png"]}
                        />
                    </Col>
                    <Col xs={0} xl={6}></Col>

                    <Col xs={24} md={5} xl={4}>
                        <label htmlFor='ingredients' className='section-heading'>
                            Tags <Tooltip2 text='Enter relevant tags for the food item. Tags help you appear in consumers searches.' />
                        </label>
                    </Col>
                    <Col xs={24} md={19} xl={14}>
                        <Field
                            component={TagInput}
                            name="tags"
                            options={kind && tag_suggestions[kind]}
                        />
                    </Col>
                    <Col xs={0} xl={6}></Col>

                    <Col xs={24} md={5} xl={4}>
                        <label htmlFor='ingredients' className='section-heading'>
                            Ingredients
                            <Tooltip2 text='Mention the ingredients used to prepare your food item. Write NA  in case your food item is dairy/farm produce.' />
                        </label>
                    </Col>
                    <Col xs={24} md={19} xl={14}>
                        <FieldArray
                            component={IngredientInput}
                            name="ingredients"
                        />
                    </Col>
                    <Col xs={0} xl={6}></Col>

                    <Col xs={24} md={5} xl={4}>
                        <label htmlFor='rent.amount' className='section-heading'>
                            Preparation Catering<span className='text-danger'><sup>{" * "}</sup></span>
                            <Tooltip2 text={window._env.REACT_APP_PREP_CATEGORY_TOOLTIP_MSG} />
                        </label>
                    </Col>
                    <Col xs={24} md={19} xl={14}>
                        <Row gutter={[16, 16]} className=''>
                            <Field
                                component={({ input: { onChange, value } }) => {
                                    return (
                                        <React.Fragment >
                                            <Col onClick={() => onChange('ALWAYS_READY')} span={8}>
                                                <Popover
                                                    content={
                                                        <div className="info-popup">
                                                            <span className="desc">
                                                                Select this option if your food item is prepared and can be picked up/delivered asap.
                                                            </span>
                                                        </div>
                                                    }
                                                    trigger="hover" placement='bottom'
                                                >
                                                    <div className={`w-100 text-center py-3 ${value === 'ALWAYS_READY' ? 'shadow selected-type' : 'border rounded'}`}>
                                                        Ready to Serve
                                                    </div>
                                                </Popover>
                                            </Col>
                                            <Col onClick={() => onChange('PREPARED_ASAP')} span={8}>
                                                <Popover
                                                    content={
                                                        <div className="info-popup">
                                                            <span className="desc">
                                                                Select this option if the preparation will take a while to complete.
                                                            </span>
                                                        </div>
                                                    }
                                                    trigger="hover" placement='bottom'
                                                >
                                                    <div className={`w-100 text-center py-3 ${value === 'PREPARED_ASAP' ? 'shadow selected-type' : 'border rounded'}`}>
                                                        Make to Order
                                                    </div>
                                                </Popover>
                                            </Col>
                                            <Col onClick={() => onChange('COLLECTING_ORDERS_TO_COOK')} span={8} >
                                                <Popover
                                                    content={
                                                        <div className="info-popup">
                                                            <span className="desc">
                                                                Select this option if you want to collect orders to make the food item on a later date.
                                                            </span>
                                                        </div>
                                                    }
                                                    trigger="hover" placement='bottom'
                                                >
                                                    <div className={`w-100 text-center py-3 ${value === 'COLLECTING_ORDERS_TO_COOK' ? 'shadow selected-type' : 'border rounded'}`}>
                                                        Collecting Orders
                                                    </div>
                                                </Popover>
                                            </Col>
                                            <Col onClick={() => onChange('ON_DEMAND_COOKING')} span={8} >
                                                <Popover
                                                    content={
                                                        <div className="info-popup">
                                                            <span className="desc">
                                                                Select this option if you can travel on-site to cook food for foodies’ gig and gathering
                                                            </span>
                                                        </div>
                                                    }
                                                    trigger="hover" placement='bottom'
                                                >
                                                    <div className={`w-100 text-center py-3 ${value === 'ON_DEMAND_COOKING' ? 'shadow selected-type' : 'border rounded'}`}>
                                                        On-demand Cooking
                                                    </div>
                                                </Popover>
                                            </Col>
                                            <Col onClick={() => onChange('EVENT_CATERING')} span={8} >
                                                <Popover
                                                    content={
                                                        <div className="info-popup">
                                                            <span className="desc">
                                                                Select this option if you are a full-service catering company for small and large events.
                                                            </span>
                                                        </div>
                                                    }
                                                    trigger="hover" placement='bottom'
                                                >
                                                    <div className={`w-100 text-center py-3 ${value === 'EVENT_CATERING' ? 'shadow selected-type' : 'border rounded'}`}>
                                                        Event Catering
                                                    </div>
                                                </Popover>
                                            </Col>
                                        </React.Fragment>
                                    );
                                }}
                                name="ordering_restriction.type"
                                defaultValue='PREPARED_ASAP'
                                validate={[validate.required]}
                                size='default'
                                className='mb-0'
                                isRequired={true}
                                placeholder='Name of your food product'
                                id='title'
                            />
                            {
                                ordering_restriction?.type === 'PREPARED_ASAP' &&
                                <React.Fragment >
                                    <Col xs={24} className='d-flex flex-column'>
                                        <Field
                                            component={Input}
                                            name="ordering_restriction.prep_time_hrs"
                                            normalize={acceptNumbers(false, 0)}
                                            size='default'
                                            className='mb-0'
                                            label='Required Preparation Time (in hours)'
                                            id='total_calories'
                                            isRequired={true}
                                            validate={[validate.required]}
                                        />
                                    </Col>
                                </React.Fragment>
                            }
                            {
                                ordering_restriction?.type === 'COLLECTING_ORDERS_TO_COOK' &&
                                <React.Fragment>
                                    <Col xs={24} md={12} className='d-flex flex-column'>
                                        <Field
                                            component={DatePicker}
                                            disabledDate={(date) => moment(date).isBefore(ordering_restriction?.accepting_orders_till || moment())}
                                            dateformat="MM/DD/YYYY h:mm a"
                                            name="ordering_restriction.accepting_orders_for"
                                            size='default'
                                            className='mb-0'
                                            datePickerClass='w-100'
                                            label='Taking Orders for Date and Time'
                                            placeholder='MM/DD/YYYY'
                                            id='accepting_orders_for'
                                            isRequired={true}
                                            showTime={true}
                                            validate={[validate.required]}
                                        />
                                    </Col>
                                    {console.log("s", ordering_restriction?.accepting_orders_for)}
                                    <Col xs={24} md={12} className='d-flex flex-column'>
                                        <Field
                                            component={DatePicker}
                                            disabledDate={(date) => moment(date).isBetween(ordering_restriction?.accepting_orders_for, moment())}
                                            dateformat="MM/DD/YYYY h:mm a"
                                            name="ordering_restriction.accepting_orders_till"
                                            size='default'
                                            className='mb-0'
                                            datePickerClass='w-100'
                                            label='Order Before (Cutoff Date and Time)'
                                            placeholder='MM/DD/YYYY'
                                            id='accepting_orders_till'
                                            isRequired={true}
                                            showTime={true}
                                            validate={[validate.required]}
                                        />
                                    </Col>
                                </React.Fragment>
                            }
                            {
                                (ordering_restriction?.type === 'ON_DEMAND_COOKING' || ordering_restriction?.type === 'EVENT_CATERING') &&
                                <React.Fragment >
                                    <Col xs={24} className='d-flex flex-column'>
                                        <Field
                                            component={Input}
                                            type='textarea'
                                            autoSize={{ minRows: 2, maxRows: 2 }}
                                            name="ordering_restriction.prerequisite"
                                            size='default'
                                            className='mb-0'
                                            label='Prerequisite for on premise cooking/catering'
                                            id='prerequisite'
                                            isRequired={true}
                                            validate={[validate.required, validate.maxLength250]}
                                        />
                                    </Col>
                                </React.Fragment>
                            }
                        </Row>
                    </Col>
                    <Col xs={0} xl={6}></Col>

                    {

                        !(ordering_restriction?.type === 'ON_DEMAND_COOKING' || ordering_restriction?.type === 'EVENT_CATERING') ?
                            <React.Fragment>
                                <Col xs={0} md={5} xl={4}>
                                </Col>
                                <Col xs={24} md={19} xl={14}>
                                    <Field
                                        component={Checkbox}
                                        name='stock_mgnt_required'
                                        className='mb-0 mt-3'
                                        label='Stock management required?'
                                    />
                                </Col>
                                <Col xs={0} xl={6}></Col>
                            </React.Fragment>
                            :
                            null
                    }

                    <Col xs={0} md={5} xl={4}>
                    </Col>
                    <Col xs={24} md={19} xl={14}>
                        {error && <p className='text-danger'>{error}</p>}

                        <Popover
                            content={
                                <div className="info-popup">
                                    <span className="desc">
                                        Click to add a new food item
                                    </span>
                                </div>
                            }
                            trigger="hover" placement='bottom'
                        >
                            <Button shape='round' type='primary' htmlType='submit' className='mt-2' loading={_loading}>
                                {params.id ? 'Save' : 'Submit'}
                            </Button>
                        </Popover>

                        {
                            params.id ?
                                <Button shape='round' type='primary' htmlType='button' className='mt-2 ml-3' loading={_loading} onClick={() => setIsVisible(true)}>
                                    View
                                </Button>
                                :
                                ''
                        }
                    </Col>
                    <Col xs={0} xl={6}></Col>

                    <ProductPopup isVisible={isVisible} handleClose={() => setIsVisible(false)} store_hours={user.store_hours} />

                </Row>

            </form >
        </Spin>
    );
}

export default reduxForm({
    form: 'product_form',
    onSubmitFail: () => window.scrollTo(0, 0)
})(Product);