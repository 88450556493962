import { Fetch } from "./common";

export const getProProfile = (username) => {
    const url = `/profile?username=${username}`;
    return Fetch(url, "GET")
        .then(({ status, data = {} }) => {
            if (status === 200) {
                return data;
            }
            throw new Error(data.message);
        })
        .catch((error) => {
            console.log(error);
            throw error;
        });
};

export const getProReviews = (username, skip, limit) => {
    const url = `/pro/reviews?username=${username}&skip=${skip}&limit=${limit}`;
    return Fetch(url, "GET")
        .then(({ status, data = {} }) => {
            if (status === 200) {
                return data;
            }
            throw new Error(data.message);
        })
        .catch((error) => {
            console.log(error);
        });
};

export const getProServices = (username, skip, limit) => {
    const url = `/pro/services?username=${username}&skip=${skip}&limit=${limit}`;
    return Fetch(url, "GET")
        .then(({ status, data = {} }) => {
            if (status === 200) {
                return data;
            }
            throw new Error(data.message);
        })
        .catch((error) => {
            console.log(error);
        });
};

export const createLead = (body) => {
    const options = {
        data: JSON.stringify(body),
    };
    return Fetch(`/lead`, "POST", options)
        .then(({ status, data = {} }) => {
            if (status === 200) {
                return data;
            }
            if (data.recaptchaIssue) {
                return data;
            }
            throw new Error(data.message);
        })
        .catch((e) => {
            throw new Error(e.message);
        });
};
