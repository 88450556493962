import React, { useState } from 'react';
import { Col, Row, Spin } from 'antd';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { reduxForm, SubmissionError } from 'redux-form';
import { AiOutlineCheck, AiOutlineClose, AiOutlineEdit } from 'react-icons/ai';
import moment from 'moment';

import EditServiceDetail from './editServiceDetail';
import { primaryColor } from '../../../common/theme';
import EditMyPledges from './editMyPledge';
import EditStoreHours from './editStoreHours';
import EditFaq from './editFaq';

function ServiceInfo({
    updateUserDetail,
    onSubCategorySearch,
    subCategories,
    onServiceSearch,
    categories,
    serviceArea,
    initialize,
    reset,
    initialValues,
    handleSubmit,
    error,
    form
}) {

    const [isUpdateMode, setIsUpdateMode] = useState(false);
    const [isUpdateMode2, setIsUpdateMode2] = useState(false);
    const [isUpdateMode3, setIsUpdateMode3] = useState(false);
    const [isUpdateMode4, setIsUpdateMode4] = useState(false);
    const user = useSelector(st => st.user);

    const providerAttributes = user.provider_attributes ?? {};

    const _handleSubmit = async (values) => {

        let pa = values.provider_attributes;

        if (pa?.serving_options && !pa?.serving_options?.delivery && !pa?.serving_options?.pickup) {
            return toast.error('Please enable delivery or pickup serving options');
        }

        if (values.store_hours) {
            values.store_hours.timezone = moment().utcOffset();
        }

        try {
            await updateUserDetail(values);
            reset();
            initialize({ ...initialValues, ...values });
            setIsUpdateMode(false);
            setIsUpdateMode2(false);
            setIsUpdateMode3(false);
        } catch (e) {
            throw new SubmissionError({ _error: e.message });
        }
    };

    const handleUpdate = () => {
        setIsUpdateMode(true);
    };

    const handleCancel = () => {
        setIsUpdateMode(false);
    };

    const handleUpdate2 = () => {
        setIsUpdateMode2(true);
    };

    const handleCancel2 = () => {
        setIsUpdateMode2(false);
    };

    return (
        <Spin spinning={user?.isLoading} >
            <div className="personal-detail service-detail">

                <Row className="tab-header">
                    <Col span={24}>
                        <h3 className="title">
                            <span>Service Detail</span>
                            <AiOutlineEdit onClick={handleUpdate} size={20} />
                        </h3>
                    </Col>
                </Row>

                <form onSubmit={handleSubmit(_handleSubmit)} >

                    <Row type='flex' justify='center' className="tab-body">
                        <Col span={15}>
                            <div className="profile-field">
                                <div className="profile-item my-1">
                                    <label>Specialties:</label>
                                    <span className="text">{
                                        Array.isArray(providerAttributes.expertise) &&
                                        providerAttributes.expertise.join(", ")
                                    }</span>
                                </div>
                                <div className="profile-item my-1">
                                    <label>Preferred Events:</label>
                                    <span className="text">{
                                        Array.isArray(providerAttributes.preferred_events) &&
                                        providerAttributes.preferred_events.join(", ")
                                    }</span>
                                </div>
                                <div className="profile-item my-1">
                                    <label>What defines you better?:</label>
                                    <span className="text">{providerAttributes.type}</span>
                                </div>
                                <div className="profile-item my-1">
                                    <label>Service Area:</label>
                                    <span className="text">{
                                        Array.isArray(providerAttributes.service_area) &&
                                        providerAttributes.service_area.join(", ")
                                    }</span>
                                </div>
                                <div className="profile-item my-1">
                                    <label>Professional License:</label>
                                    <span className="text">{providerAttributes.licensed ? 'Yes' : 'No'}</span>
                                </div>
                                <div className="profile-item my-1">
                                    <label>Serving Options:</label>
                                    <span className="text">
                                        <ul>
                                            {
                                                [
                                                    providerAttributes.serving_options?.pickup ? 'Off-site catering and delivery' : false,
                                                    providerAttributes.serving_options?.delivery ? 'Off-site catering and curbside pickup' : false,
                                                    providerAttributes.serving_options?.on_site_catering ? 'On-site event catering' : false,
                                                    providerAttributes.serving_options?.on_demand_cooking ? 'On-Demand Cooking/private chef' : false,
                                                    providerAttributes.serving_options?.food_truck ? 'Food truck service' : false
                                                ].filter(e => e).map(e => (
                                                    <li>
                                                        {e}
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </span>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className="tab-header">
                        <Col span={24}>
                            <h3 className="title">
                                <span>Store Hours</span>
                                <AiOutlineEdit onClick={() => setIsUpdateMode4(true)} size={20} />
                            </h3>
                        </Col>
                    </Row>

                    <Row type='flex' justify='center' className="tab-body mb-4">
                        <Col span={15}>
                            {
                                user?.store_hours?.open ?
                                    ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
                                        .map(e => (
                                            <Row key={e}>
                                                <Col xs={10} md={5}><strong>{e}</strong></Col>
                                                <Col xs={14} md={19}>
                                                    {
                                                        user?.store_hours && user.store_hours[e.toLowerCase()]?.open ?
                                                            `${user.store_hours[e.toLowerCase()].opens_at} - ${user.store_hours[e.toLowerCase()].closes_at}`
                                                            :
                                                            'Closed'
                                                    }
                                                </Col>
                                            </Row>
                                        ))
                                    :
                                    'Store is closed temporarily'
                            }
                        </Col>
                    </Row>

                    <Row className="tab-header">
                        <Col span={24}>
                            <h3 className="title">
                                <span>My Pledges</span>
                                <AiOutlineEdit onClick={handleUpdate2} size={20} />
                            </h3>
                        </Col>
                    </Row>

                    <Row type='flex' justify='center' className="tab-body">
                        <Col span={15}>
                            <div className="profile-field">
                                <Row className="profile-item my-1">
                                    <Col span={20} style={{ fontWeight: 600 }}>I pledge that I have a valid professional license</Col>
                                    <Col className="text">
                                        {
                                            providerAttributes.licensed ?
                                                <AiOutlineCheck color={primaryColor} />
                                                :
                                                <AiOutlineClose color={primaryColor} />
                                        }
                                    </Col>
                                </Row>
                                <Row className="profile-item my-1">
                                    <Col span={20} style={{ fontWeight: 600 }}>I pledge that I have valid professional liability insurance</Col>
                                    <Col className="text">
                                        {
                                            providerAttributes.insured ?
                                                <AiOutlineCheck color={primaryColor} />
                                                :
                                                <AiOutlineClose color={primaryColor} />
                                        }
                                    </Col>
                                </Row>
                                <Row className="profile-item my-1">
                                    <Col span={20}>
                                        <p style={{ fontWeight: 600 }}>I pledge that I reviewed CDC safety guidelines for COVID-19 and promised to:</p>
                                        <ul>
                                            <li>Maintain 6 feet of distance from customers</li>
                                            <li>Wear masks during the job</li>
                                            <li>Wear gloves during the job</li>
                                            <li>Disinfect surfaces touched during the job</li>
                                        </ul>
                                    </Col>
                                    <Col className="text">
                                        {
                                            providerAttributes.safety_mantra ?
                                                <AiOutlineCheck color={primaryColor} />
                                                :
                                                <AiOutlineClose color={primaryColor} />
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>

                    <Row className="tab-header">
                        <Col span={24}>
                            <h3 className="title">
                                <span>Store FAQs</span>
                                <AiOutlineEdit onClick={() => setIsUpdateMode3(true)} size={20} />
                            </h3>
                        </Col>
                    </Row>

                    <Row type='flex' justify='center' className="tab-body">
                        <Col span={15}>
                            {
                                Array.isArray(providerAttributes.faq) ?
                                    providerAttributes.faq.map(e => (

                                        <div className="profile-item my-2">
                                            <Row gutter={16}>
                                                <Col xs={5} lg={4} style={{ fontWeight: 600 }} className='text-right'>Question:</Col>
                                                <Col span={20}>{e.question}</Col>
                                            </Row>
                                            <Row gutter={16}>
                                                <Col xs={5} lg={4} style={{ fontWeight: 600 }} className='text-right'>Answer: </Col>
                                                <Col span={20}>{e.answer}</Col>
                                            </Row>
                                        </div>
                                    ))
                                    :
                                    null
                            }
                        </Col>
                    </Row>

                    <EditMyPledges
                        onSubmit={handleSubmit(_handleSubmit)}
                        onClose={handleCancel2}
                        isVisible={isUpdateMode2}
                        error={error}
                        isLoading={user.isLoading}
                        licensed={providerAttributes.licensed}
                        insured={providerAttributes.insured}
                        safety_mantra={providerAttributes.safety_mantra}
                    />

                    <EditServiceDetail
                        onSubmit={handleSubmit(_handleSubmit)}
                        onClose={handleCancel}
                        isVisible={isUpdateMode}
                        error={error}
                        isLoading={user.isLoading}
                        categories={categories}
                        onSubCategorySearch={onSubCategorySearch}
                        subCategories={subCategories}
                        onServiceSearch={onServiceSearch}
                        serviceArea={serviceArea}
                        licensed={providerAttributes.licensed}
                        serving_options={providerAttributes.serving_options}
                        initialValues={initialValues}
                    />

                    <EditFaq
                        onSubmit={handleSubmit(_handleSubmit)}
                        onClose={() => setIsUpdateMode3(false)}
                        isVisible={isUpdateMode3}
                        error={error}
                        isLoading={user.isLoading}
                        faq={providerAttributes.faq}
                        initialValues={initialValues}
                    />

                    <EditStoreHours
                        onSubmit={handleSubmit(_handleSubmit)}
                        onClose={() => setIsUpdateMode4(false)}
                        isVisible={isUpdateMode4}
                        error={error}
                        isLoading={user.isLoading}
                        initialValues={initialValues}
                        form={form}
                    />

                </form>

            </div>
        </Spin>
    );
}

export default reduxForm({
    form: "service-detail",
    enableReinitialize: true
})(ServiceInfo);