import React from 'react';
import { Row, Col } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

import './style.scss';
import welcome from '../../images/aboutus/about-03.svg';

export default function Section2() {

    const breakpoint = useBreakpoint();

    return (
        <React.Fragment>
            <Row type="flex" justify="center" align="middle" style={{ position: 'relative' }} className='mt-5'>

                <Col xs={{ span: 24, order: 1 }} lg={{ span: 10, order: 1 }} xxl={9} className="p-3">
                    <h2 className="section-heading">
                        Our Vision
                    </h2>
                    <div className={breakpoint.lg ? "section-content mt-4" : "section-content-mobile mt-3"}>
                    Our vision is to empower people passionate about selling food to start their journey without hassle. Foodelys wants to enable employment opportunities for cooks-on-demand, cloud kitchens, chefs, caterers, bakers, and organic farmers without having them invest money in the infrastructure, ordering system, and marketplace to reach consumers.
                    </div>

                </Col>
                <Col className='text-center'
                    xs={{ span: 20, order: 0 }} lg={{ span: 10, offset: 1, order: 2 }} xl={{ span: 9, offset: 1 }} xxl={{ span: 7, offset: 1 }}
                >
                    <img className="img img-fluid"
                        src={welcome} alt="Welcome To Foodelys" />
                </Col>
            </Row>

        </React.Fragment>
    );
}