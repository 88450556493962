import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Addons from '../../components/provider/addons';
import { fetchAddonsByProviderId, createAddons, updateAddons, deleteAddonsById } from '../../actions/addons';

const mapStateToProps = ({
    addons,
    user
}) => ({
    ...addons,
    userId: user._id
});


const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchAddonsByProviderId,
    createAddons,
    updateAddons,
    deleteAddonsById
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Addons);
