import React, { Component } from 'react';
import { DatePicker as AntDatePicker, Popover } from 'antd';
import { AiFillQuestionCircle } from "react-icons/ai";
import moment from 'moment';

const {
    MonthPicker,
} = AntDatePicker;

class DatePicker extends Component {
    static defaultProps = {
        dateformat: "MM/DD/YYYY",
        input: {},
        meta: {},
        placeholder: "",
        className: "",
        errorClass: "",
        label: "",
        labelClass: "",
        isRequired: false,
        tooltipPlacement: "rightTop",
        pickerType: "Date"
    };

    onChange = (time, timeString) => {
        const { input: { onChange } } = this.props;
        onChange(timeString);
    };

    render() {

        const {
            input,
            meta: { touched, error, warning },
            className,
            errorClass,
            label,
            labelClass,
            isRequired,
            tooltipText,
            tooltipPlacement,
            dateformat,
            pickerType,
            placeholder,
            disabledDate,
            datePickerClass,
            size,
            showTime
        } = this.props;


        const isError = touched && (error || warning);
        const errorText = error || warning;
        const fieldError = isError ? "field-error" : "";
        const noError = !isError ? "no-error" : "";

        const Picker = pickerType === 'Month' ? MonthPicker : AntDatePicker;
        
        let extraProps = {
            format: dateformat,
            placeholder,
            className: datePickerClass,
            size
        };
        
        if(pickerType !== 'Month'){
            extraProps.disabledDate = disabledDate;
        }
        
        if (input.value) {
            extraProps.defaultValue = moment(input.value);
        }

        if(showTime) extraProps.showTime = true;

        return (
            <>
                <div id="redux-datepicker" className={`form-field ${className} ${fieldError}`}>
                    {label &&
                        <label className={labelClass}>
                            {label}
                            {isRequired ? <span className="required-label"><sup>{" * "}</sup></span> : ""}
                            {' '}
                            {
                                tooltipText &&
                                <Popover content={tooltipText} trigger="hover" placement={tooltipPlacement}>
                                    <span><AiFillQuestionCircle  size={20}/></span>
                                </Popover>
                            }
                        </label>
                    }
                    <Picker
                        {...extraProps}
                        onChange={this.onChange}
                    />
                    {
                        <span className={`error ${errorClass} ${noError}`}>
                            {isError && errorText}
                        </span>
                    }
                </div>

            </>
        );
    }
}

export default DatePicker;
