import React from 'react';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { Col, Row, Avatar } from 'antd';

import welcome from '../../images/how-it-works3.svg';
import './style.scss';

export default function Section2() {

    const breakpoint = useBreakpoint();

    return (
        <React.Fragment>
            <Row type="flex" justify="center" align="middle" id='buy_services_online'>

                <Col xs={0} lg={1} xl={3} xxl={4}>
                </Col>

                <Col xs={24} md={8} lg={10} xl={9} xxl={8}  >
                    <img className="img img-fluid"
                        src={welcome} alt="Welcome To Foodelys" />
                </Col>

                <Col xs={24} md={16} lg={12} xl={9} xxl={8}>

                    <div className="my-5 p-3 d-flex">
                        <div>
                            <Avatar
                                size={breakpoint.lg ? 50 : 30}
                                style={{ backgroundColor: '#c1395f', color: 'white', fontSize: breakpoint.lg ? '1.6rem' : '0.9rem' }}
                                className='mr-3'
                            >
                                3
                            </Avatar>
                        </div>
                        <div>
                            <h4 className='mb-3 d-flex align-items-center section-heading ' >
                                Add Food Products
                            </h4>
                            <div className={`${breakpoint.lg ? 'section-content' : 'section-content-mobile'}`}>
                                Provide name, description of your food items along with price per unit, product category, ingredient, preparation time. Do not forget to add pictures!
                            </div>
                        </div>
                    </div>

                </Col>

                <Col xs={0} lg={1} xl={3} xxl={4}>
                </Col>
            </Row>
        </React.Fragment >
    );
}