import React, { useEffect, useState } from "react";
import { Row, Col, Button, Tooltip, InputNumber, Divider, Popover } from 'antd';

import { originType, dietType } from "../../constants/commonContext";

import { useSelector } from "react-redux";
import Modal from "antd/lib/modal/Modal";
import { AiOutlineCloseCircle, AiOutlineInfoCircle, AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";
import { BiCheck, BiDish, BiFoodTag } from 'react-icons/bi';
import TwrCarousel from "../../common/TwrCarousel";
import './styles.scss';
import moment from 'moment';
import { Scrollbars } from 'react-custom-scrollbars';
import { useCookies } from "react-cookie";
import sha1 from 'sha-1';
import { toast } from 'react-toastify';
import { decode, encode } from "../../common/order_utilities";
import { Link, useHistory } from "react-router-dom";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

function Ingredients({ ingredients }) {
    return (
        <div>
            <div><strong>Ingredients</strong> </div>
            <div>{ingredients.join(', ')}</div>
        </div>
    );
}

function getColor(type) {
    let obj = {
        VEGAN: '#33ff30',
        VEGETARIAN: '#dec628',
        PESCATARIAN: '#3dffdb',
        FLEXITARIAN: '#ff3c2e',
        MACROBIOTIC: '#4fff4d',
    };
    return obj[type];
}

export default function ProductPopup({ handleClose, isVisible, allowOrder = true, store_hours }) {


    const { productDetail } = useSelector(st => st.product);

    const {
        title, origin, description, ingredients, serves, price, quantity, total_calories, provider_id,
        dietary_guidelines, photo_urls, ordering_restriction, accepting_orders, uid, moq
    } = productDetail;

    const { isAuthenticated } = useSelector(st => st.user);
    const [order_quantity, setOrderQuantity] = useState(moq || 1);
    const { scope } = useSelector(st => st.user);
    const [cookies, setCookies] = useCookies(['order', 'ch']);
    const [loading, setLoading] = useState(false);
    const [overwritePopUp, setOverwritePopUp] = useState(false);
    const [storeOpen, setStoreOpen] = useState(false);
    let breakpoint = useBreakpoint();

    let history = useHistory();

    useEffect(() => {
        if (!productDetail) handleClose();
    }, [productDetail, handleClose]);

    useEffect(() => {
        console.log(store_hours);
        if (store_hours) {
            let days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
            let today = days[moment().day()];
            let current = moment(moment().format('h:mm a'), 'h:mm a');
            let open = moment(store_hours[today].opens_at, 'h:mm a').utcOffset(store_hours.timezone);
            let close = moment(store_hours[today].closes_at, 'h:mm a').utcOffset(store_hours.timezone);
            let isStoreOpen = store_hours[today].open ? current.isBetween(open, close) : false;
            setStoreOpen(isStoreOpen);
        }
    }, [store_hours]);

    function order(ev, overwrite) {

        if (!isAuthenticated) return history.push('/login');

        if (order_quantity >= (moq || 1)) {
            setLoading(true);

            let order = null;
            if (cookies.order && sha1(cookies.order) === cookies.ch) {
                order = decode(cookies.order);
            }

            if (order && order.seller === provider_id) {
                if (ordering_restriction?.type !== order.category) {
                    setLoading(false);
                    return toast.error(<span>We are not supporting complex orders for now. Please refer <Link to='/faq'>FAQs</Link> to learn more.</span>);
                }
                let i = order.lineItems ? order.lineItems.findIndex(e => e.uid === uid) : -1;
                if (i !== -1) {
                    order.lineItems[i].quantity = order_quantity;
                } else {
                    order.lineItems.push({
                        category: ordering_restriction?.type,
                        uid: uid,
                        quantity: order_quantity
                    });
                }
            } else {
                if (order && !overwrite) {
                    setLoading(false);
                    return setOverwritePopUp(true);
                }
                order = {
                    category: ordering_restriction?.type,
                    seller: provider_id,
                    lineItems: [{
                        uid: uid,
                        quantity: order_quantity
                    }]
                };
            }

            let cart = encode(order);

            setCookies('order', cart, { maxAge: 1000 * 24 * 3600 * 1000 });
            setCookies('ch', sha1(cart));

            setLoading(false);
            toast.success('Cart updated successfully');
            handleClose();
        }

        return false;

    }

    useEffect(() => {
        setOrderQuantity(1);
    }, [isVisible]);

    useEffect(() => {
        let _moq = moq || 1;
        if (order_quantity < _moq) {
            setOrderQuantity(_moq);
        }
    }, [moq, order_quantity]);

    return (
        <Modal
            visible={isVisible}
            className='p-0 product-popup'
            closable
            centered
            onCancel={handleClose}
            closeIcon={<AiOutlineCloseCircle size={20} color='#999' />}
            bodyStyle={{
                padding: 0,
                borderTopLeftRadius: 15,
                borderTopRightRadius: 15,
                background: 'rgba(0, 0, 0, 0.45)',
                overflow: 'hidden'
            }}
            destroyOnClose={true}
            width={breakpoint.xs ? '90%' : 450}
            footer={
                <div className='d-flex'>
                    {storeOpen ? '' : <span className='mr-auto my-auto text-danger' style={{fontWeight: 600}} >Sorry we are closed</span>}
                    {
                        allowOrder ?
                            isAuthenticated
                                ?
                                <div className='d-flex justify-content-end align-items-center mt-1 flex-fill'>
                                    {
                                        accepting_orders &&
                                        <React.Fragment>
                                            <Button shape='round' type='link' className='d-none d-md-inline'
                                                icon={<AiOutlineMinusCircle size={20} color='#15c39a' />}
                                                onClick={() => setOrderQuantity(order_quantity - 1 >= (moq || 1) ? order_quantity - 1 : order_quantity)}
                                                disabled={order_quantity <= (moq || 1) || loading}
                                            />
                                            <h6 className='d-inline d-md-none'>Qty:</h6>
                                            <InputNumber disabled={loading}
                                                min={moq || 1} size='middle' value={order_quantity}
                                                style={{ width: 60 }} className='mx-2'
                                                onChange={(val) => setOrderQuantity(val)} precision={0}
                                            />
                                            <Button shape='round' type='link' className='d-none d-md-inline'
                                                icon={<AiOutlinePlusCircle size={20} color='#15c39a' />}
                                                onClick={() => setOrderQuantity(order_quantity + 1)}
                                                disabled={loading}
                                            />
                                        </React.Fragment>

                                    }
                                    <Popover
                                        content={
                                            <div>
                                                <Button shape='round'
                                                    type='default'
                                                    onClick={() => {
                                                        setOverwritePopUp(false);
                                                        setLoading(false);
                                                    }}
                                                >
                                                    No
                                                </Button>
                                                <Button shape='round'
                                                    type='default' className='ml-2'
                                                    onClick={() => {
                                                        setOverwritePopUp(false);
                                                        order(null, true);
                                                    }}
                                                >
                                                    Yes
                                                </Button>
                                            </div>
                                        }
                                        title="You already have an order in your cart from another seller. Do you want to delete that order ?"
                                        visible={overwritePopUp}
                                        trigger='click'
                                        onVisibleChange={(visible) => visible ? '' : setOverwritePopUp(false)}
                                        overlayClassName='product-popup-max-width' placement='topRight'
                                    >
                                        <Button shape='round'
                                            className='ml-4' type='primary' loading={loading}
                                            disabled={scope === 'PROVIDER' || !accepting_orders || loading}
                                            onClick={order}
                                            htmlType='button'
                                        >
                                            {
                                                accepting_orders || ordering_restriction?.type === '' ? `Order ($${order_quantity * (price?.unit_price || 0)})` : 'Not Accepting Orders'
                                            }
                                        </Button>
                                    </Popover>
                                </div>
                                :
                                <div className='w-100 d-flex'>
                                    <Button
                                        shape='round' className="ml-auto"
                                        onClick={() => history.push('/signup')} type='primary'
                                    >
                                        <strong>Sign Up</strong>
                                    </Button>
                                    <Button
                                        shape='round' className=""
                                        onClick={() => history.push('/login')}
                                    >
                                        <strong>Login</strong>
                                    </Button>
                                </div>
                            :
                            <h6 className='text-right'>Price: ${price?.unit_price}</h6>
                    }
                </div>
            }
        >
            <div className='card border-bottom-0 bootstrap-card-round'>
                <Scrollbars
                    autoHide
                    autoHeight
                    autoHeightMin={600}
                    autoHeightMax='calc(100vh - 100px)'
                >
                    <div className='card-body'>
                        <h5 className="card-title mb-1">{title} </h5>
                        {(!total_calories && !quantity) ? undefined :
                            <div className='text-muted mb-3' style={{ fontSize: 14 }}>({quantity ? quantity : ''}{quantity && total_calories && ", "}{total_calories ? `${total_calories} cal.` : ''})</div>
                        }
                        <p className="card-text">{description}</p>
                        <div className='d-flex justify-content-between'>
                            {
                                dietary_guidelines?.food_allergy_disclaimer &&

                                <Tooltip
                                    title={dietary_guidelines?.disclaimer_description}
                                    className='pointer'
                                    placement="right"
                                >
                                    <strong>Allergy Disclaimer <AiOutlineInfoCircle size={18} color='#15c39a' /></strong>
                                </Tooltip>
                            }
                        </div>
                    </div>

                    <Row className='mb-2'>
                        {
                            serves?.max &&
                            <Col span={8} className='text-center'>
                                <BiDish size={20} color='#15c39a' />&nbsp;
                                <strong className='text-muted'>
                                    Serves {serves.max}
                                </strong>
                            </Col>
                        }
                        {
                            dietary_guidelines?.diet_type &&
                            <Col span={8} className='text-center'>
                                <BiFoodTag size={20} color={getColor(dietary_guidelines.diet_type)} />&nbsp;
                                <strong className='text-muted'>
                                    {dietType.find(e => e.value === dietary_guidelines.diet_type)?.label}
                                </strong>
                            </Col>
                        }
                        {
                            dietary_guidelines?.gluten_free &&
                            <Col span={8} className='text-center'>
                                <BiCheck size={20} color='#15c39a' />
                                <strong className='text-muted'>
                                    Gluten Free
                                </strong>
                            </Col>
                        }
                    </Row>

                    <TwrCarousel showThumbnails={false} images={photo_urls} imageClass='card-img-top rounded-0' />

                    <div className='card-body rounded-0 py-2'>
                        {
                            ingredients && Array.isArray(ingredients) && ingredients.length > 0
                                ?
                                <Row gutter={[8, 8]}>
                                    <Col span={24}>
                                        <Ingredients ingredients={ingredients} total_calories={total_calories} />
                                    </Col>
                                </Row>
                                :
                                total_calories && <strong className='text-muted'>Total Cal. {total_calories}</strong>
                        }
                    </div>

                    {
                        (ordering_restriction?.type === 'ON_DEMAND' || ordering_restriction?.type === 'EVENT_CATERING') && ordering_restriction?.prerequisite
                            ?
                            <React.Fragment>
                                <Divider style={{ borderTopColor: '#f0f0f0', borderTopWidth: 1, marginBottom: 0, marginTop: 0 }} />
                                <div className='card-body rounded-0 py-2'>
                                    <Row gutter={[8, 8]}>
                                        <Col span={24}>
                                            <div>
                                                <div><strong>Prerequisite</strong> </div>
                                                <div>{ordering_restriction.prerequisite}</div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </React.Fragment>
                            :
                            null
                    }

                    {
                        origin || ordering_restriction?.type !== 'ALWAYS_READY'
                            ?
                            <Divider style={{ borderTopColor: '#f0f0f0', borderTopWidth: 1, marginBottom: 0, marginTop: 0 }} />
                            :
                            null
                    }

                    <div className='card-body rounded-0 pt-2 pb-3'>
                        <Row gutter={[8, 8]}>

                            {
                                origin &&
                                <React.Fragment>
                                    <Col span={12}>
                                        <div><strong>Origin</strong></div>
                                        <div>{originType.find(e => e.value === origin)?.label}</div>
                                    </Col>
                                </React.Fragment>
                            }

                            {
                                ordering_restriction?.type === 'COLLECTING_ORDERS_TO_COOK' &&
                                <React.Fragment>
                                    <Col span={12}>
                                        <div><strong>Order Before</strong></div>
                                        <div>{moment(ordering_restriction.accepting_orders_till).format('ddd, MMM D YYYY LT')}</div>
                                    </Col>
                                    <Col span={12}>
                                        <div><strong>Accepting Orders For</strong></div>
                                        <div>{moment(ordering_restriction.accepting_orders_for).format('ddd, MMM D YYYY LT')}</div>
                                    </Col>
                                </React.Fragment>
                            }

                            {
                                ordering_restriction?.type === 'PREPARED_ASAP' &&
                                <React.Fragment>
                                    <Col span={12}>
                                        <div><strong>Order Preparation Time</strong></div>
                                        <div>{ordering_restriction.prep_time_hrs} Hours</div>
                                    </Col>
                                </React.Fragment>
                            }


                            {
                                moq && moq > 1 &&
                                <React.Fragment>
                                    <Col span={12}>
                                        <div><strong>Min Order Qty</strong></div>
                                        <div>{moq}</div>
                                    </Col>
                                </React.Fragment>
                            }

                        </Row>
                    </div>
                </Scrollbars>
            </div>
        </Modal >
    );
}
