import React from 'react';
import { Row, Col } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { FaLinkedinIn } from 'react-icons/fa';

import './style.scss';
import welcome from '../../images/aboutus/about-03.svg';

export default function Section4() {

    const breakpoint = useBreakpoint();

    return (
        <React.Fragment>
            <Row type="flex" justify="center" align="middle" style={{ position: 'relative' }} className='mt-5'>

                <Col xs={{ span: 24, order: 1 }} lg={{ span: 21, order: 1 }} xl={20} xxl={17} className="p-3">
                    <h2 className="section-heading">
                        Our Team
                    </h2>

                    <Row gutter={32} className="mt-4 gap-2" style={{rowGap: "2rem"}}>
                        <Col xs={{ span: 24, order: 1 }} md={{ span: 8, order: 1 }}>
                            <div className='team-card'>
                                <div className='d-flex mb-3'>
                                    <div className='rounded-circle overflow-hidden' style={{ width: 100, height: 100 }}>
                                        <img src='aarav.jpeg' className='img-fluid' style={{ objectFit: 'cover' }} />
                                    </div>
                                    <div className='ml-3'>
                                        <h4 className='name'>Aarav Tiwari</h4>
                                        <p className='designation'>Co-founder, Developer, Tester and Business Development</p>
                                        <a
                                            className='linked-in'
                                            href='https://www.linkedin.com/in/aarav-tiwari-pchs/'
                                            target='_blank' rel='noreferrer noopener' >
                                            <FaLinkedinIn />
                                        </a>
                                    </div>
                                </div>
                                <p style={{textOverflow: 'ellipsis', height: 140, overflowY: "scroll"}} className='mb-0'>
                                Aarav, a junior attending high school in Cary, North Carolina, carries an enthusiastic commitment to crafting innovative solutions for the challenges encountered in our daily lives. He was thrilled when the concept of foodelys took shape. He assumed various responsibilities during the product development process, serving as a thought leader, coder, tester, and contributing to business development by engaging with users and facilitating their onboarding.
                                </p>
                            </div>
                        </Col>
                        <Col xs={{ span: 24, order: 1 }} md={{ span: 8, order: 1 }}>
                            <div className='team-card d-flex flex-column justify-content-between'>
                                <div className='d-flex mb-3'>
                                    <div className='rounded-circle overflow-hidden' style={{ width: 100, height: 100 }}>
                                        <img src='arvind.jpeg' className='img-fluid' style={{ objectFit: 'cover' }} />
                                    </div>
                                    <div className='ml-3'>
                                        <h4 className='name'>Arvind Tiwari</h4>
                                        <p className='designation' >CTO and Advisor</p>
                                        <a
                                            className='linked-in'
                                            href='https://www.linkedin.com/in/arvtiwar/#'
                                            target='_blank' rel='noreferrer noopener'
                                        >
                                            <FaLinkedinIn />
                                        </a>
                                    </div>
                                </div>
                                <p style={{textOverflow: 'ellipsis', height: 140, overflowY: "scroll"}} className='mb-0'>
                                Arvind took a multifaceted responsibilities within the foodelys project, acting as the Chief Technology Officer (CTO), an advisor offering valuable insights, and a prominent contributor driving the guidance and direction of the product. In these roles, Arvind played a pivotal part in shaping the strategic and technical aspects of foodelys's development, ensuring alignment with the project's goals and objectives. His expertise and leadership spanned various dimensions, encompassing technology decisions, strategic planning, advisory input, and active participation in steering the product's evolution.
                                </p>
                            </div>
                        </Col>
                        <Col xs={{ span: 24, order: 1 }} md={{ span: 8, order: 1 }}>
                            <div className='team-card  d-flex flex-column justify-content-between'>
                                <div className='d-flex mb-3'>
                                    <div className='rounded-circle overflow-hidden' style={{ width: 100, height: 100 }}>
                                        <img src='nitin.jpeg' className='img-fluid' style={{ objectFit: 'cover' }} />
                                    </div>
                                    <div className='ml-3'>
                                        <h4 className='name'>Nitin Garg</h4>
                                        <p className='designation'>Co-founder, Lead Developer and Tester</p>
                                        <a
                                            className='linked-in' href='https://www.linkedin.com/in/nitin992503/'
                                            target='_blank' rel='noreferrer noopener'
                                        >
                                            <FaLinkedinIn />
                                        </a>
                                    </div>
                                </div>
                                <p style={{textOverflow: 'ellipsis', height: 140, overflowY: "scroll"}} className='mb-0'>
                                Nitin spearheads the development efforts and has been instrumental in the critical advancement of the foodelys project. His extensive proficiency extends across the domains of the modern MERN (MongoDB, Express.js, React, Node.js) stack, containerization, and the dynamic realm of cloud application development. As the lead developer and head of development, he stands as the driving force across every stage of the project's evolution.
                                </p>
                            </div>
                        </Col>
                    </Row>

                </Col>

            </Row>

        </React.Fragment>
    );
}