import React from "react";
import { Alert } from "antd";

export default function PIConcernMsg({containerClassName, alerClassName, containerStyle = {}}){
    return (
        <div className={containerClassName}>
            <Alert
                className={alerClassName}
                style={containerStyle}
                type="warning"
                message={window._env.REACT_APP_PII_GDPR_ALERT_MESSAGE}
                // description={}
                showIcon
                closable
            />
        </div>
    );
}