const initialState = {
    isManualLoading: false,
    manualData: [],
    subManualData: [],
    formValue: {},
};

const manual = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case "MANUAL_DATA":
            return { ...state, ...payload };
        case "SET_Manual_FORM":
            const formValue = { ...state.formValue, ...payload };
            return { ...state, formValue };
        default:
            return state;
    }
};

export default manual;
