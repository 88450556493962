const initialState = {
    formValue: {},
    isLoading: false,
    projectDetail: {},
    projects: [],
    totalProjects: 0
};

const project = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case "SET_PROJECTS":
            return { ...state, projects: payload.data, totalProjects: payload.totalProjects };
        case "SET_PROJECT_DETAILS":
            return { ...state, projectDetail: payload };
        case "SET_PROJECT_LOADING":
            return { ...state, isLoading: payload };
        default:
            return state;
    }
};

export default project;
