import React, { Component } from 'react';
import { reduxForm, Field, SubmissionError } from "redux-form";
import { Row, Col, Button } from "antd";
import { AiOutlineMail } from "react-icons/ai";
import { Link } from "react-router-dom";

import {
    Input,
    validate
} from "../../../common/redux-form";
import "../styles.scss";

class ForgotPassword extends Component {

    componentDidUpdate() {
        const { isAuthenticated, history } = this.props;
        if(isAuthenticated) {
            history.push('/');
        }
    }

    handleSubmit = async ({ username }) => {
        const { forgotPasswordRequest } = this.props;

        try {
            await forgotPasswordRequest(username);
        } catch(error) {
            throw new SubmissionError({ _error: error.message });
        }
    };

    render() {
        const {
            submitting,
            handleSubmit,
            error,
            isLoading
        } = this.props;

        return(
            <Row className="auth-wrapper" type="flex" justify="center">
                <Col span={6} xs={22} sm={17} md={15} lg={10} xl={9} className="form-wrapper">
                    <h4 className="title">
                        <AiOutlineMail size={20}/>
                        Forgot Password Request
                    </h4>
                    <div className="form-content">
                        <form onSubmit={handleSubmit(this.handleSubmit)}>
                            <Field
                                name="username"
                                placeholder="mail@example.com"
                                component={Input}
                                label="Email"
                                validate={[validate.required, validate.email]}
                                normalize={(value) => value.trim().toLowerCase()}
                                isRequired={true}
                            />
                            <div className="form-error">{error}</div>
                            <Button shape='round'
                                className="btn-primary btn-submit"
                                type="primary"
                                size={"large"}
                                htmlType="submit"
                                icon={<AiOutlineMail size={20} />}
                                loading={submitting || isLoading}
                            >
                                Send
                            </Button>
                        </form>
                        <Row className="other-options">
                            <Col span={24}>
                                <p>Go to login? <Link to='/login'>Login </Link></p>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        );
    }
}

export default reduxForm({
    form: "forgot-password",
    enableReinitialize: true
})(ForgotPassword);
