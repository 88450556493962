import React, { Component } from "react";
import {
    Upload,
    message,
    Spin
} from "antd";
import { AiOutlineClose } from "react-icons/ai";
import { HiPlus } from "react-icons/hi";
import imageCompression from 'browser-image-compression';

import { fileUpload as onFileUpload } from "../../actions/common";
import ResizeModal from "../../common/redux-form/ResizeModal";

class FileUpload extends Component {

    state = {
        isLoading: false,
        file: null,
        showResizeModal: false,
    };

    handleBeforeUpload = (file) => {
        const {
            fileSizeType,
            maxFileSize,
            fileTypes
        } = this.props;
        const isValidFileType = fileTypes.includes(file.type);
        let size = file.size / 1024;
        if (fileSizeType === "mb") {
            size = size / 1024;
        }
        const isValidSize = (size <= maxFileSize) || /image\/*/.test(file.type);

        if (!isValidFileType) {
            message.error("Invalid file type");
        }

        if (!isValidSize) {
            message.error(`File must smaller than ${maxFileSize}${fileSizeType}!`);
        }

        const isValidFile = isValidSize && isValidFileType;
        this.setState({ isValidFile });

        return isValidSize && isValidFileType;
    };

    handleAction = (file) => {

        const { resize } = this.props;
        const isValidFile = this.handleBeforeUpload(file);

        if (!isValidFile) {
            return false;
        }

        this.setState({ file: file }, () => {
            if (!resize) {
                this.upload();
            }
            this.setState({ showResizeModal: true });
        });
        return false;
    };

    upload = async () => {

        const { context, fields } = this.props;
        let file = this.state.file;

        try {
            // compress file if its a image
            if (/image\/*/.test(file.type)) {
                const options = {
                    maxSizeMB: 1
                };
                file = await imageCompression(file, options);
            }

            // upload files
            const formData = new FormData();
            let name = (new Date().valueOf()).toString(16) + parseInt(Math.random() * 1000).toString(16) + '.png';
            formData.append("file", file, name);
            this.setState({ isLoading: true });
            const { Location } = await onFileUpload(formData, context);
            this.setState({ isLoading: false });
            fields.push({ url: Location, tags: [] });

        } catch (e) {
            message.error("File not uploaded, please try again");
            this.setState({ isLoading: false });
            return false;
        }
    };

    handleCancel = () => {
        this.setState({ showResizeModal: false, file: "" });
    };

    handleSubmit = (blob) => {
        this.setState({ file: blob, showResizeModal: false }, () => {
            this.upload();
        });
    };

    render() {

        const {
            value,
            width,
            height,
            alt,
            maxLimit,
            fields, meta: { error, pristine, submitFailed }
        } = this.props;

        return (
            <React.Fragment>
                <div className='d-flex flex-wrap'>
                    {
                        fields.map((e, i) => (
                            <div
                                className='m-1 d-flex flex-column justify-content-center align-items-center position-relative'
                                style={{
                                    width: 150,
                                    height: !isNaN(width) && !isNaN(height) ? height * 150 / width : 100,
                                    border: '2px dashed #ddd'
                                }}
                                key={i}
                            >
                                <img src={window._env.REACT_APP_PUBLIC_URL_HOST + fields.get(i).url} alt={alt ? alt : value} width='140px' />
                                <AiOutlineClose
                                    className='position-absolute rounded-circle'
                                    onClick={() => fields.remove(i)} size={20}
                                    style={{
                                        right: 5,
                                        top: 5,
                                        background: '#ffffff90'
                                    }}
                                />
                            </div>
                        ))
                    }
                    {
                        (isNaN(maxLimit) || maxLimit > fields.length) &&
                        <Upload
                            showUploadList={false}
                            beforeUpload={this.handleAction}
                            disabled={this.state.isLoading}
                            accept='image/*'
                        >
                            <div
                                className='m-1 d-flex flex-column justify-content-center align-items-center'
                                style={{
                                    width: 150,
                                    height: !isNaN(width) && !isNaN(height) ? height * 150 / width : 100,
                                    border: '2px dashed #ddd'
                                }}
                            >
                                {
                                    this.state.isLoading ?
                                        <Spin />
                                        :
                                        <HiPlus size={25}/>
                                }
                                <div className="ant-upload-text">Upload</div>
                            </div>
                        </Upload>
                    }
                    <ResizeModal
                        modalVisible={this.state.showResizeModal}
                        handleCancel={this.handleCancel}
                        handleSubmit={this.handleSubmit}
                        file={this.state.file}
                        width={width}
                        height={height}
                    />

                </div>
                {error && (!pristine || submitFailed) && <p className='text-danger'>{error}</p>}
            </React.Fragment>
        );
    }
}

FileUpload.defaultProps = {
    input: {},
    meta: {},
    className: "",
    errorClass: "",
    label: "",
    labelClass: "",
    listType: "picture-card",
    isRequired: false,
    tooltipPlacement: "rightTop",
    maxFileSize: 500,
    fileSizeType: "kb",
    fileTypes: ["image/jpg", "image/jpeg", "image/png"],
    resize: false,
    context: "",
    width: 400,
    height: 300
};

export default FileUpload;
