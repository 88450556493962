import React, { useState } from "react";
import "./style.scss";
import { reduxForm, Field } from "redux-form";
import { Input, NumericInput, validate, Checkbox as ReduxCheckbox } from "../../common/redux-form";
import { Button } from "antd";
import { useHistory } from "react-router";

const ContactUs = (props) => {
    const {
        handleSubmit,
        user: { isLoading },
        reset,
        emailDisposal,
        error,
        page_visit
    } = props;

    page_visit('/contact-us');

    const [ _error, setError ] = useState("");

    const handleFinalSubmit = async (values) => {

        values.checked = signui ? values.checked:false;
        values.name = values.first_name + " " + values.second_name;
        let res = await emailDisposal(values.email_address);
        
        if(!res)
            return setError("Please provide a valid Email Address");
        res = await props.contactUsEmail(values);
        if (res) {
            props.history.push("/");
            reset();
        }
    };
    const history = useHistory();
    let search = history.location.search;
    const signui = new URLSearchParams(search).get('signui');
    
    return (
        <div className="contact-container mb-5">
            <div className="contact-section">
                <form onSubmit={handleSubmit(handleFinalSubmit)}>
                    <div className="name-sub-section">
                        <div className="first-name-section">
                            <Field
                                name="first_name"
                                placeholder="Joe"
                                component={Input}
                                label="First Name"
                                validate={validate.required}
                                isRequired={true}
                            />
                        </div>
                        <div className="second-name-section">
                            <Field
                                name="second_name"
                                placeholder="martin"
                                component={Input}
                                label="Last Name"
                                validate={validate.required}
                                isRequired={true}
                            />
                        </div>
                    </div>

                    <div className="email-sub-section">
                        <Field
                            name="email_address"
                            placeholder="name@company.com"
                            component={Input}
                            label="Email Address"
                            validate={[validate.required, validate.email]}
                            normalize={(value) => value.trim().toLowerCase()}
                            isRequired={true}
                        />
                    </div>
                    <div className="number-sub-section">
                        <Field
                            name="phone_number"
                            placeholder="Phone Number"
                            component={NumericInput}
                            label="Phone"
                            validate={[validate.required]}
                            isRequired={true}
                            maxLength={10}
                        />
                    </div>
                    { signui &&
                    <div>
                        <Field
                            name="checked"
                            component={ReduxCheckbox}
                            label="Are you a service provider, professional, or technician?"
                        />  
                    </div>
                    }
                    <div className="description-sub-section">
                        <Field
                            name="description"
                            placeholder="Please tell us About your Query"
                            label="Description"
                            component={Input}
                            type="textarea"
                            rows={5}
                            validate={validate.required}
                            isRequired={true}
                        />
                    </div>
                    <div className="form-error"> {error} </div>
                    <div className="form-error"> {_error} </div>
                    <div className="button-container">
                        <Button shape='round'
                            className="btn-primary "
                            type="primary"
                            size={"large"}
                            htmlType="submit"
                            loading={isLoading}
                        >
                            Submit
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default reduxForm({
    form: "contact-us",
})(ContactUs);
