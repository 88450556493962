import React, { PureComponent } from "react";
import ForgotPassword from "./forgotPassword";
import ResetPassword from "./resetPassword";

class ResetPasswordRequest extends PureComponent {

    render() {

        const { isResetPasswordMode } = this.props;
        const View = isResetPasswordMode ? ResetPassword : ForgotPassword;

        return (
            <View {...this.props} />
        );
    }
}

export default ResetPasswordRequest;
