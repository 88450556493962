import { createSelector } from 'reselect';

const serviceAreaSelector = state => state.serviceArea.data;
const searchTextSelector = state => state.serviceArea.searchText;

function getUnique(arr, comp) {
    const unique = arr
        .map(e => e[comp])

        // store the keys of the unique objects
        .map((e, i, final) => final.indexOf(e) === i && i)

        // eliminate the dead keys & store unique objects
        .filter(e => arr[e]).map(e => arr[e]);

    return unique;
}

function filterRow(data, text) {
    const searchString = text.toLowerCase();
    if (searchString !== '') {
        const newTableRow = data.filter(row => (
            row.city.toLowerCase().includes(searchString)
        ));
        return newTableRow;
    } else {
        return data;
    }
}

export const serviceOptionSelector = createSelector(
    [serviceAreaSelector, searchTextSelector],
    (items, text) => {
        const arr = getUnique(items, 'city');
        const arr1 = filterRow(arr,text);
        return arr1.map(item => ({ label: item.city, value: item.city }));
    }
);

