
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Messages from '../../components/shared/messages';

import {
    fetchContactRequest,
    getChatsByIdRequest,
    handleChatInputChange,
    sendMessage,
    markAsRead
}
    from '../../actions/message';

const mapStateToProps = ({
    user,
    messages
}) => ({
    user,
    messages
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchContactRequest,
    getChatsByIdRequest,
    handleChatInputChange,
    sendMessage,
    markAsRead
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Messages);