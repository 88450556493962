import React, { useCallback, useEffect, useState } from 'react';
import { Spin, Tabs, Divider, Modal, Tooltip, Row, Col, Button } from 'antd';
import { getProProfile } from '../../actions/proProfile';
import { useParams } from 'react-router';
import Avatar from 'antd/lib/avatar/avatar';
import { IoLocationOutline } from 'react-icons/io5';
import { load } from "recaptcha-v3";
import moment from 'moment';

import './styles.scss';
import { primaryColor } from '../../common/theme';
import { AiOutlineCloseCircle, AiOutlineEdit, AiOutlineFundView } from 'react-icons/ai';
import ProductTab from './ProductTab';
import AddonTab from './AddonTab';
import { FcInTransit, FcShop } from 'react-icons/fc';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DeliveryAddon from './delivery';
import StoreInfo from './storeInfo';
import { page_visit } from '../../actions/analytics';
import { useCookies } from 'react-cookie';
import { incStoreViews } from '../../actions/metrics';

const recaptchaEnabled = JSON.parse(window._env.REACT_APP_CAPTCHAV3_ENABLED);

const { TabPane } = Tabs;

function Tag({ e, className = '' }) {
    return (
        <div
            className={`m-1 px-1 rounded ${className}`}
            style={{
                backgroundColor: `#71717110`,
                border: `1px solid #71717123`,
                color: '#717171',
                fontSize: 12
            }}
        >
            {e}
        </div>
    );
}

export default function ProProfile() {

    const [loading, setLoading] = useState({
        profile: true,
        reviews: true,
    });

    const [storeOpen, setStoreOpen] = useState(false);

    const [captcha, setCaptcha] = useState(null);
    const [view_updated, setViewUpdated] = useState(false);
    const [profile, setProfile] = useState({
        user: {},
        products: [],
        totalProducts: 0,
        addons: [],
        reviews: [],
        ratings: 0,
        allow_contact: false,
        metrics: {}
    });

    const [cookie] = useCookies(['order']);

    const { scope, display_name } = useSelector(st => st.user);

    const [deliveryAddonCount, setDeliveryAddonCount] = useState(0);
    const [isVisible, setVisible] = useState(false);
    let history = useHistory();
    let dispatch = useDispatch();

    let { username } = useParams();

    useEffect(() => {
        async function getProfile() {
            try {

                dispatch(page_visit(`/store/${username}`));

                setLoading({
                    profile: true,
                    reviews: true
                });

                let res = await getProProfile(username);
                let { user, warranty, totalProducts, products, ratings, reviews, addons, projects, allow_contact, metrics } = res;
                setProfile({
                    user, warranty, totalProducts, products, ratings, reviews, addons, projects, allow_contact, metrics
                });

                let days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
                let store_hours = user.store_hours;
                let today = days[moment().day()];

                if (store_hours && store_hours.open && store_hours[today]?.open) {

                    let current = moment(moment().format('h:mm a'), 'h:mm a');
                    let open = moment(store_hours[today].opens_at, 'h:mm a').utcOffset(store_hours.timezone);
                    let close = moment(store_hours[today].closes_at, 'h:mm a').utcOffset(store_hours.timezone);
                    let isStoreOpen = current.isBetween(open, close);
                    setStoreOpen(isStoreOpen);
                }

                if (Array.isArray(addons) && addons.length) {
                    setDeliveryAddonCount(addons.reduce((init, e) => init + (e.delivery_addon ? 1 : 0), 0));
                }

            } catch (err) {
                console.log(err);

            } finally {
                setLoading({ profile: false });
            }
        }
        getProfile();

        async function loadCaptchaScript() {
            if (window._env.REACT_APP_CAPTCHAV3_SITE_KEY) {
                const _captcha = await load(window._env.REACT_APP_CAPTCHAV3_SITE_KEY);
                setCaptcha(_captcha);
            }
        }
        loadCaptchaScript();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getCaptchaToken = useCallback(async () => {
        if (captcha) {
            let token = await captcha.execute('login');
            return token;
        } else {
            throw Error('Please wait for captcha to load');
        }
    }, [captcha]);

    useEffect(() => {

        async function inc() {
            setViewUpdated(true);
            try {
                let token = '';
                if (recaptchaEnabled) {
                    token = await getCaptchaToken();
                }
                const { recaptchaIssue } = await incStoreViews({ id: profile.user.display_name, RecaptchaToken: token });
                if (recaptchaIssue) {
                    inc();
                } else {
                }
            } catch (err) {
            }
        }

        if (profile?.user?.display_name && scope !== 'PROVIDER' && !view_updated) {
            inc();
        }

    }, [profile.user, scope, view_updated, getCaptchaToken]);

    if (loading.profile)
        return (
            <div
                style={{ height: 500 }}
                className='w-100 d-flex justify-content-center align-items-center'
            >
                <Spin size='large'></Spin>
            </div>
        );

    return (

        <div className='flex-fill mb-4'>

            <div className='w-100 mb-2'>
                {
                    loading.profile ?
                        <Spin className='m-auto' size='large'></Spin>
                        :
                        profile.user.store_cover_picture ?
                            <div className='position-relative w-100'>
                                {
                                    scope === 'PROVIDER' &&
                                    profile.user.display_name === display_name &&
                                    <AiOutlineEdit
                                        className='position-absolute pointer' style={{ top: 10, right: 10 }}
                                        onClick={() => history.push('/seller/profile')} size={30}
                                    />
                                }
                                <img
                                    style={{ borderRadius: 10 }}
                                    src={window._env.REACT_APP_PUBLIC_URL_HOST + profile.user.store_cover_picture}
                                    alt={profile.user.store_name} className='img img-fluid' width='100%'
                                />
                            </div>
                            :
                            <div
                                className='d-flex align-items-center justify-content-center position-relative'
                                style={{ height: 250, width: '100%', borderRadius: 10, backgroundColor: `${primaryColor}10` }}
                            >
                                {
                                    scope === 'PROVIDER' &&
                                    profile.user.display_name === display_name &&
                                    <AiOutlineEdit
                                        className='position-absolute pointer' style={{ top: 10, right: 10 }}
                                        onClick={() => history.push('/seller/profile')} size={30}
                                    />
                                }
                                <FcShop size={200} className='mr-5' />
                                <h1>{profile.user.store_name}</h1>
                            </div>
                }
                <Avatar
                    size={120} style={{ marginLeft: 30, marginTop: -60, border: '5px solid #fff' }}
                    src={
                        Array.isArray(profile.user?.image_urls) && profile.user.image_urls.length > 0
                            ?
                            window._env.REACT_APP_PUBLIC_URL_HOST + profile.user.image_urls[0].url
                            :
                            ''
                    }
                    alt={profile.user.display_name}
                />
                <Row>
                    <Col xs={24} md={20}>
                        <h2 className='mt-2'>
                            {profile.user.store_name}
                            {
                                Array.isArray(profile.user.pickupLocation) && profile.user.pickupLocation.length
                                    ?
                                    <Tooltip title='Seller have pickup locations'>
                                        <span className='ml-2'>
                                            <IoLocationOutline color={primaryColor} size={30} className='ml-1' onClick={() => setVisible(true)} />
                                        </span>
                                    </Tooltip>
                                    :
                                    ''
                            }
                            {
                                deliveryAddonCount ?
                                    <Tooltip title='Seller can deliver orders'>
                                        <span className='ml-2'>
                                            <FcInTransit size={30} color='#1c98d6' />
                                        </span>
                                    </Tooltip>
                                    :
                                    null
                            }
                            {
                                profile.metrics?.store_views ?
                                    <Tooltip title={`${profile.metrics?.store_views} store visits this month`}>
                                        <span className='ml-2'>
                                            <AiOutlineFundView size={30} color='#15c39a' />
                                        </span>
                                    </Tooltip>
                                    :
                                    null
                            }
                            <Tag className={`ml-2 align-middle d-inline text-light ${storeOpen ? 'bg-success' : 'bg-danger'}`} e={storeOpen ? 'Store Open' : 'Store Closed'} />
                        </h2>
                        <div className='d-flex flex-wrap'>
                            {
                                Array.isArray(profile.user.provider_attributes?.expertise) && profile.user.provider_attributes.expertise.length
                                    ?
                                    profile.user.provider_attributes?.expertise.map(e => <Tag e={e} key={e} />)
                                    :
                                    null
                            }
                        </div>
                    </Col>
                    <Col xs={24} md={4} className='d-flex'>
                        {
                            scope === 'CONSUMER' && cookie.order
                                ?
                                <Button
                                    shape='round' type='primary' className='ml-auto'
                                    onClick={() => history.push('/buyer/checkout')}
                                >
                                    View Cart
                                </Button>
                                :
                                null
                        }
                    </Col>
                </Row>
            </div>

            <Divider className='mb-3' />

            {
                Array.isArray(profile.products) && profile.products.length ?
                    <Tabs destroyInactiveTabPane={true}>
                        <TabPane tab='Menus' key="1">
                            <ProductTab
                                products={profile.products} loading={loading.profile} Tag={Tag} storeOpen={storeOpen}
                                deliveryAddonCount={deliveryAddonCount} metrics={profile.metrics?.product_views}
                                store_hours={profile.user?.store_hours}
                            />
                        </TabPane>
                        <TabPane tab='Extras' key="2">
                            <AddonTab addons={profile.addons} loading={loading.profile} />
                        </TabPane>
                        <TabPane tab='Delivery' key="3">
                            <DeliveryAddon addons={profile.addons} loading={loading.profile} />
                        </TabPane>
                        <TabPane tab='Store Info' key="4">
                            <StoreInfo
                                reviews={profile.reviews} projects={profile.projects}
                                loading={loading.profile} attr={profile.user.provider_attributes}
                                _id={profile.user._id} allow_contact={profile.allow_contact}
                            />
                        </TabPane>
                    </Tabs>
                    :
                    <StoreInfo
                        reviews={profile.reviews} projects={profile.projects} allow_contact={profile.allow_contact}
                        loading={loading.profile} attr={profile.user?.provider_attributes}
                        _id={profile.user._id}
                    />
            }

            <Modal
                title='Pickup Locations'
                visible={isVisible}
                closable
                centered
                className='pickup-locations-modal'
                onCancel={() => setVisible(false)}
                closeIcon={<AiOutlineCloseCircle size={20} color='#fff' />}
                destroyOnClose={true}
                footer={null}
                width={450}
            >
                <ol>
                    {
                        Array.isArray(profile.user?.pickupLocation) && profile.user.pickupLocation.map((e, key) => (
                            <li key={key} className='mt-2'>
                                {e}
                            </li>
                        ))
                    }
                </ol>
            </Modal >

        </div>
    );
}