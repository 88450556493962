import React, { useCallback, useEffect, useState } from "react";
import { Spin, Select, Divider, Pagination } from "antd";
import { AiOutlineInbox } from "react-icons/ai";
import OrderCard from "./orderCard";
import "./style.scss";
import { OrderStatus } from "../../../constants/commonContext";
import { useDispatch, useSelector } from "react-redux";
import { getOrder } from "../../../actions/order";

export default function MyOrderComponent() {

    const [status, setStatus] = useState('');
    const [page, setPage] = useState(1);
    const limit = 10;

    const { orders, isLoading, totalOrders } = useSelector(st => st.order);

    let dispatch = useDispatch();

    const fetch = useCallback(async () => {
        await getOrder({ status, skip: page - 1, limit })(dispatch);
    }, [status, page, dispatch]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    const handleStatusFilter = async (status) => {
        setStatus(status);
    };

    const deleteOrder = async (id) => {
        const {
            deleteOrder
        } = this.props;
        await deleteOrder(id);
        fetch();
    };

    if (isLoading) return (
        <div className='w-100 h-100 '>
            <Spin className='m-auto' />
        </div>
    );

    return (
        <div className="myorder-list d-block">
            <div className="sort-order pl-2">
                <div><strong>Status</strong></div>
                <Select
                    value={status}
                    onChange={handleStatusFilter}
                    className="order-status-filter"
                >
                    {
                        (() => {
                            let arr = [
                                <Select.Option value={''} key={-1}>
                                    All
                                </Select.Option>
                            ];
                            for (let s in OrderStatus) {
                                arr.push(
                                    <Select.Option value={s} key={s}>
                                        {OrderStatus[s]}
                                    </Select.Option>
                                );
                            }
                            return arr;
                        })()
                    }
                </Select>

                <div className="clear-both" />
            </div>
            <Divider />
            {
                orders?.length === 0 &&
                <div className="no-agreement-found">
                    <AiOutlineInbox size={70} />
                    <span>No Order found</span>
                </div>
            }
            {
                Array.isArray(orders) &&
                orders.length > 0 &&
                orders.map((order, index) => (
                    <OrderCard
                        key={index}
                        order={order}
                        refresh={fetch}
                        deleteOrder={deleteOrder}
                    />
                ))
            }



            {
                totalOrders > limit
                    ?
                    <Pagination onChange={(pg) => setPage(pg)} defaultCurrent={1} pageSize={limit} total={totalOrders} />
                    :
                    null
            }
        </div>
    );
}
