import React, { Component } from "react";
import { Radio as AntRadio, Popover } from "antd";
import { AiFillQuestionCircle } from "react-icons/ai";

const RadioGroup = AntRadio.Group;
const RadioButton = AntRadio.Button;

class Radio extends Component {

    handleChange = (event) => {
        const { input: { onChange } } = this.props;
        onChange(event.target.value);
    };

    render() {

        const {
            input: { value, name },
            size,
            meta: { touched, error, warning },
            className,
            errorClass,
            label,
            labelClass,
            type,
            isRequired,
            options,
            tooltipText,
            tooltipPlacement,
            defaultValue,
            disabled,
            radioClass
        } = this.props;

        const isError = touched && (error || warning);
        const errorText = error || warning;
        const fieldError = isError ? "field-error" : "";
        const noError = !isError ? "no-error" : "";
        const radioProps = {
            value: value || defaultValue,
            name,
            onChange: this.handleChange,
            className: radioClass
        };
        const RadioOption = type === "button" ? RadioButton : AntRadio;

        return (
            <div className={`form-field ${className} ${fieldError}`}>
                {
                    label &&
                    <label className={labelClass}>
                        {label}
                        { isRequired ? <span className="required-label"><sup>{" * "}</sup></span> : "" }
                        {' '}
                        {
                            tooltipText &&
                            <Popover content={tooltipText} trigger="hover" placement={tooltipPlacement}>
                                <span><AiFillQuestionCircle  size={20}/></span>
                            </Popover>
                        }
                    </label>
                }
                <RadioGroup
                    {...radioProps}
                    size={size}
                    disabled={disabled}
                >
                    {
                        options.map(({ value, label: text, disable }, key) =>
                            <RadioOption
                                key={key}
                                value={value}
                                disabled={disable}
                            >{text}</RadioOption>
                        )
                    }
                </RadioGroup>
                {
                    <span className={`error ${errorClass} ${noError}`}>
                        {isError && errorText}
                    </span>
                }
            </div>
        );
    }
}

Radio.defaultProps = {
    input: {},
    meta: {},
    size: "default",
    className: "",
    errorClass: "",
    label: "",
    labelClass: "",
    type: "radio",
    isRequired: false,
    options: [],
    allowClear: true,
    tooltipPlacement: "rightTop",
    defaultValue: undefined,
    disabled : false
};

export default Radio;
