import React, { useState } from 'react';
import { Row, Col, Divider } from 'antd';
import { useDispatch } from 'react-redux';

import './styles.scss';
import ProjectPopup from '../projectPopup';
import { setProjectDetail } from '../../actions/project';
import { event } from '../../actions/analytics';

export default function ProjectTab({ projects, Tag }) {

    const [popupVisible, setPopupVisible] = useState(false);
    let dispatch = useDispatch();

    return (
        <React.Fragment>
            {
                Array.isArray(projects) && projects.length ?
                    <React.Fragment>

                        <h5 className='mt-5'>Featured Events</h5>
                        <Row gutter={[16, 16]} className='mb-5'>
                            {
                                projects.map((e, i) => (
                                    <Col xs={24} lg={12}
                                        onClick={() => {
                                            dispatch(event('Project', 'view', e._id));
                                            dispatch(setProjectDetail(e));
                                            setPopupVisible(true);
                                        }}
                                        className='pointer pro-profile-card' key={i}
                                    >
                                        <Row data-id='body' type="flex" align="stretch" justify="space-between" className="border rounded">
                                            <Col xs={24} md={9} className='text-center'>
                                                {
                                                    Array.isArray(e.photo_urls) && e.photo_urls.length > 0 &&
                                                    <img src={window._env.REACT_APP_PUBLIC_URL_HOST + e.photo_urls[0].url}
                                                        alt="Product main img" className='img-fluid rounded-left'
                                                    />
                                                }
                                            </Col>
                                            <Col xs={24} md={15} className='d-flex flex-column px-2 py-1 mt-3 mt-md-0'>

                                                <h6 className="card-title mb-0">
                                                    {e.title}
                                                    <span className='text-muted' style={{ fontSize: 12 }}></span>
                                                </h6>

                                                <div style={{ fontSize: 14 }} className='d-flex flex-row'>
                                                    {e.location ? e.location : ''}{e.location && e.year ? ' — ' : ''}{e.year}
                                                </div>

                                                {
                                                    e.description
                                                        ?
                                                        <p className="card-title my-2">
                                                            {e.description.substr(0, 60)}{e.description.length > 60 ? '...' : ''}
                                                        </p>
                                                        :
                                                        null
                                                }

                                                <Divider className='mb-2 mt-auto' />

                                                <div className='d-flex justify-content-between'>
                                                    <span>{e.type}</span>
                                                    <span>${e.price}</span>
                                                </div>

                                            </Col>
                                        </Row>
                                    </Col>
                                ))
                            }
                        </Row>
                    </React.Fragment>
                    :
                    null
            }

            <ProjectPopup isVisible={popupVisible} handleClose={() => setPopupVisible(false)} />
        </React.Fragment>
    );
}