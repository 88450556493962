import React, { useState } from "react";
import { Row, Col, Button, Checkbox, Divider, InputNumber, Input, DatePicker, Radio } from "antd";
import { AiOutlineDelete, AiOutlineEye, AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai';
import { IoFastFoodOutline } from 'react-icons/io5';
import { FcInTransit } from 'react-icons/fc';
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import sha1 from "sha-1";

import PaymentDetail from "./paymentDetail";
import ProductPopup from "../../productPopup";
import { setProduct } from "../../../actions/product";
import { encode } from "../../../common/order_utilities";
import moment from 'moment';
import { toast } from 'react-toastify';

function OrderDetail({ order, addons, setOrder }) {

    const [visible, setVisible] = useState(false);
    const [showAllergies, setShowAllergies] = useState(false);
    let dispatch = useDispatch();
    const [, setCookie] = useCookies(['order', 'ch']);

    function updateQuantity(ind, quantity) {

        let lineItems;

        if (quantity === 0) {
            lineItems = order.lineItems.filter((e, i) => i !== ind);
        } else {
            lineItems = order.lineItems.map((e, i) => i === ind ? ({ ...e, quantity }) : e);
        }

        setOrder({
            ...order,
            lineItems
        });

        if (lineItems.length > 0) {
            let _order = {
                category: lineItems[0].product.ordering_restriction?.type,
                seller: order.seller._id,
                lineItems: lineItems.map(e => ({ uid: e.uid, quantity: e.quantity }))
            };
            let cart = encode(_order);
            setCookie('order', cart);
            setCookie('ch', sha1(cart));
        } else {
            setCookie('order', '');
            setCookie('ch', '');
        }
    }

    function updateAddon(ind, qty, isDelivery) {

        let filtered_addons = addons.filter(e => e.delivery_addon === isDelivery);

        if (qty === 0) {
            let arr = order.addons.filter(e => e._id !== filtered_addons[ind]._id);
            return setOrder({
                ...order,
                addons: arr,
                delivery_method: arr.some(e => e.delivery_addon) ? 'DELIVERY' : 'PICKUP'
            });
        }

        let arr = order.addons.map(e => e);
        let _ind = arr.findIndex(e => e._id === filtered_addons[ind]._id);

        let bool = arr.some(e => e.delivery_addon);

        if (bool && filtered_addons[ind].delivery_addon) {
            return toast.error('You can add only 1 delivery addons');
        }

        if (_ind !== -1)
            arr.splice(_ind, 1, { _id: filtered_addons[ind]._id, quantity: qty, delivery_addon: filtered_addons[ind].delivery_addon });
        else arr.push({ _id: filtered_addons[ind]._id, quantity: qty, delivery_addon: filtered_addons[ind].delivery_addon });

        bool = bool || filtered_addons[ind].delivery_addon;

        setOrder({
            ...order,
            addons: arr,
            delivery_method: bool ? 'DELIVERY' : 'PICKUP'
        });
    }

    return (
        <div className="order-detail">

            <h5 className='mb-0'>
                Items
            </h5>
            {
                order && Array.isArray(order.lineItems) && order.lineItems.map((e, key) => (
                    <Row key={key} className='mt-2'>
                        <Col xs={0} sm={3}>
                            <img
                                src={e.product?.photo_urls ? window._env.REACT_APP_PUBLIC_URL_HOST + e.product?.photo_urls[0]?.url : ''}
                                alt={e.product?.title}
                                className='img img-fluid rounded'
                            />
                        </Col>
                        <Col xs={13} className='pl-2 pr-1'>
                            <p className='mb-0' style={{ fontWeight: 500 }}>
                                {e.product?.title}
                            </p>
                            <div>
                                <Button
                                    size='small' type='link'
                                    icon={<AiOutlineEye size={20} />}
                                    onClick={() => {
                                        dispatch(setProduct({ productDetail: e.product }));
                                        setVisible(true);
                                    }}
                                />
                                <Button
                                    size='small' type='link'
                                    icon={<AiOutlineDelete size={18} />}
                                    className='ml-3'
                                    onClick={() => updateQuantity(key, 0)}
                                />
                            </div>
                        </Col>
                        <Col xs={11} sm={8}>
                            <Button
                                type='link' className='d-md-inline'
                                icon={<AiOutlineMinusCircle size={20} />}
                                onClick={() => e.quantity > 1 ? updateQuantity(key, e.quantity - 1) : ''}
                                disabled={e.quantity <= (e.product?.moq ? e.product.moq : 1)}
                            />
                            <InputNumber
                                min={e.product?.moq ? e.product.moq : 1} size='middle' value={e.quantity}
                                style={{ width: 50 }} className='mx-2'
                                onChange={(val) => updateQuantity(key, val)}
                                precision={0}
                            />
                            <Button type='link' className='d-md-inline'
                                icon={<AiOutlinePlusCircle size={20} />}
                                onClick={() => updateQuantity(key, e.quantity + 1)}
                            />
                        </Col>
                    </Row>
                ))
            }


            {
                Array.isArray(addons) && !addons.every(e => e.delivery_addon)
                    ?
                    <React.Fragment>
                        <h5 className="mt-4 mb-0">
                            Extras
                        </h5>
                        {
                            addons.filter(e => !e.delivery_addon).map((item, key) => {

                                let qty = order.addons.find(e => e._id === item._id)?.quantity;
                                qty = qty ? qty : 0;

                                return (
                                    <Row key={key} className='mt-3'>

                                        <Col xs={0} sm={3}>
                                            {
                                                item?.addon_image
                                                    ?
                                                    <img
                                                        src={item?.addon_image ? window._env.REACT_APP_PUBLIC_URL_HOST + item.addon_image : ''}
                                                        alt={item.name}
                                                        className='img img-fluid rounded'
                                                    />
                                                    :
                                                    item.delivery_addon
                                                        ?
                                                        <div className='w-100 d-flex'>
                                                            <FcInTransit size={40} color='#1c98d6' className='m-auto' />
                                                        </div>
                                                        :
                                                        <div className='w-100 d-flex'>
                                                            <IoFastFoodOutline size={40} color='#1c98d6' className='m-auto' />
                                                        </div>
                                            }
                                        </Col>

                                        <Col xs={13} className='pl-2 pr-1'>
                                            <p className='mb-0' style={{ fontWeight: 500 }}>
                                                {item.name}<span className='text-muted'> (${item.price})</span>
                                            </p>
                                            <div>
                                                {item.description}
                                            </div>
                                        </Col>

                                        <Col xs={11} sm={8}>
                                            <Button
                                                type='link' className='d-md-inline'
                                                icon={<AiOutlineMinusCircle size={20} />}
                                                onClick={() => qty > 0 ? updateAddon(key, qty - 1, false) : ''}
                                                disabled={!qty}
                                            />
                                            <InputNumber
                                                min={0} size='middle' value={qty}
                                                style={{ width: 50 }} className='mx-2'
                                                onChange={(val) => updateAddon(key, val, false)}
                                                precision={0} max={item.delivery_addon ? 1 : 100}
                                            />
                                            <Button type='link' className='d-md-inline'
                                                icon={<AiOutlinePlusCircle size={20} />}
                                                onClick={() => updateAddon(key, qty + 1, false)}
                                                disabled={item.delivery_addon ? qty === 1 : false}
                                            />
                                        </Col>

                                    </Row>
                                );
                            })
                        }
                    </React.Fragment>
                    :
                    ''
            }


            {
                Array.isArray(addons) && addons.some(e => e.delivery_addon)
                    ?
                    <React.Fragment>
                        <h5 className="mt-4 mb-0">
                            Delivery
                        </h5>
                        {
                            addons.filter(e => e.delivery_addon).map((item, key) => {

                                let qty = order.addons.find(e => e._id === item._id)?.quantity;
                                qty = qty ? qty : 0;

                                return (
                                    <Row key={key} className='mt-3'>

                                        <Col xs={0} sm={3}>
                                            {
                                                item?.addon_image
                                                    ?
                                                    <img
                                                        src={item?.addon_image ? window._env.REACT_APP_PUBLIC_URL_HOST + item.addon_image : ''}
                                                        alt={item.name}
                                                        className='img img-fluid rounded'
                                                    />
                                                    :
                                                    item.delivery_addon
                                                        ?
                                                        <div className='w-100 d-flex'>
                                                            <FcInTransit size={40} color='#1c98d6' className='m-auto' />
                                                        </div>
                                                        :
                                                        <div className='w-100 d-flex'>
                                                            <IoFastFoodOutline size={40} color='#1c98d6' className='m-auto' />
                                                        </div>
                                            }
                                        </Col>

                                        <Col xs={13} className='px-1'>
                                            <p className='mb-0' style={{ fontWeight: 500 }}>
                                                {item.name} <span className='text-muted'> (${item.price})</span>
                                            </p>
                                            <div>
                                                {item.description}
                                            </div>
                                        </Col>

                                        <Col xs={11} sm={8}>
                                            <Button
                                                type='link' className='d-md-inline'
                                                icon={<AiOutlineMinusCircle size={20} />}
                                                onClick={() => qty > 0 ? updateAddon(key, qty - 1, true) : ''}
                                                disabled={!qty}
                                            />
                                            <InputNumber
                                                min={0} size='middle' value={qty}
                                                style={{ width: 50 }} className='mx-2'
                                                onChange={(val) => updateAddon(key, val, true)}
                                                precision={0} max={item.delivery_addon ? 1 : 100}
                                            />
                                            <Button type='link' className='d-md-inline'
                                                icon={<AiOutlinePlusCircle size={20} />}
                                                onClick={() => updateAddon(key, qty + 1, true)}
                                                disabled={item.delivery_addon ? qty === 1 : false}
                                            />
                                        </Col>

                                    </Row>
                                );
                            })
                        }
                    </React.Fragment>
                    :
                    ''
            }

            <div className='mt-4'>
                <Checkbox
                    onChange={(ev) => {
                        if (ev.target.checked)
                            setShowAllergies(true);
                        else {
                            setShowAllergies(false);
                            setOrder({ ...order, allergies: '' });
                        }
                    }}
                >
                    <span style={{ fontWeight: 500 }}>Do you have any food allergies?</span>
                </Checkbox>
                {
                    showAllergies
                        ?
                        <React.Fragment>
                            <Input
                                onChange={(ev) => setOrder({ ...order, allergies: ev.target.value })}
                                placeholder='Please enter allergens' className='mt-2'
                            />
                        </React.Fragment>
                        :
                        null
                }
            </div>

            <div className='mt-4'>
                <Checkbox
                    onChange={(ev) => setOrder({ ...order, schedule: ev.target.checked })}
                >
                    <span style={{ fontWeight: 500 }}>Schedule your plan ahead order.</span>
                </Checkbox>

                {
                    order.schedule ?
                        <div className='mt-2'>
                            <DatePicker
                                showTime={true} format="MM/DD/YYYY h:mm a"
                                onChange={(val) => setOrder({ ...order, delivery_date_time: val })}
                                disabledDate={(date) => moment(date).isBefore(moment())}
                            />
                        </div>
                        :
                        null
                }
            </div>

            {
                order?.seller?.paymentOption && Array.isArray(order.seller.paymentOption)
                    ?
                    <React.Fragment>
                        <h5 className='mt-4 mb-0'>
                            Payment Options
                        </h5>
                        <Radio.Group
                            options={
                                order.seller.paymentOption.map(e => (
                                    {
                                        label: e.detail ? `${e.tag} (${e.detail})` : `${e.tag}`,
                                        value: e._id
                                    }
                                ))
                            }
                            onChange={(ev) => setOrder({ ...order, payment_method: ev.target.value })}
                            className='mt-2'
                        />
                    </React.Fragment>
                    :
                    null
            }

            <ProductPopup allowOrder={false} isVisible={visible} handleClose={() => setVisible(false)} store_hours={order.seller.store_hours} />

        </div>
    );
}

function AddressBlock({ order, setOrder, address }) {

    function selectDeliveryAddress(id) {
        setOrder({ ...order, delivery_address: id, pickup_address: '' });
    }

    function selectPickupAddress(id) {
        setOrder({ ...order, delivery_address: '', pickup_address: id });
    }

    return (
        <React.Fragment>
            <Radio.Group
                options={[
                    { label: 'Pickup', value: 'PICKUP', disabled: order.delivery_method === 'DELIVERY' },
                    { label: 'Delivery', value: 'DELIVERY', disabled: order.delivery_method !== 'DELIVERY' }
                ]}
                value={order.delivery_method}
                className='mt-4'
            />

            {
                order.delivery_method === 'DELIVERY'
                    ?
                    Array.isArray(address)
                        ?
                        <React.Fragment>
                            <h5 className="mt-2 mb-2">
                                Select delivery address
                            </h5>

                            <div className="d-flex flex-column">
                                <Radio.Group
                                    options={
                                        address.filter(e => e.street_1).map((item) => ({
                                            label: `${item?.street_1}, ${item?.city}, ${item?.state}, ${item?.postal_code}`,
                                            value: item._id
                                        }))
                                    }
                                    onChange={(ev) => selectDeliveryAddress(ev.target.value)}
                                />
                            </div>
                        </React.Fragment>
                        :
                        <p className='mt-2'>Please visit your profile and add a delivery address.</p>
                    :
                    null
            }

            {
                order.delivery_method === 'PICKUP' ?
                    Array.isArray(order.seller?.pickupLocation)
                        ?
                        <React.Fragment>
                            <h5 className="mt-2 mb-2">
                                Select pickup location
                            </h5>
                            <div className="d-flex flex-column">
                                <Radio.Group
                                    options={
                                        order.seller.pickupLocation.map((item) => ({
                                            label: item.display_address,
                                            value: item._id
                                        }))
                                    }
                                    onChange={(ev) => selectPickupAddress(ev.target.value)}
                                />
                            </div>
                        </React.Fragment>
                        :
                        <p className='mt-2'>Seller has not added any pickup location.</p>
                    :
                    null
            }

        </React.Fragment>
    );
}

export default function OrderBlock({ setOrder, order, addons, disable, setStep }) {

    const { address } = useSelector(st => st.user);
    const [, setCookie] = useCookies(['order', 'ch']);

    return (
        <div>

            <h4 className="mb-0 mt-2 d-flex flex-row justify-content-between align-items-center">
                <span>{order?.seller?.store_name}</span>
                <Button
                    shape='round' type='primary'
                    onClick={() => { setOrder({ lineItems: [] }); setCookie('order', ''); setCookie('ch', ''); }}
                >
                    Clear Cart
                </Button>
            </h4>

            <Divider />

            <Row>
                <Col span={24} md={14} className='pb-4'>

                    <OrderDetail
                        order={order}
                        setOrder={setOrder}
                        addons={addons}
                    />

                    <div>
                        <AddressBlock address={address} setOrder={setOrder} order={order} />
                    </div>
                    
                </Col>

                <Col span={24} md={{ span: 8, offset: 2 }}>
                    <PaymentDetail
                        setStep={setStep}
                        order={order}
                        disableCheckout={disable}
                        addons={addons}
                    />
                </Col>

            </Row>
        </div>
    );
}
