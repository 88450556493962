import React, { useState } from 'react';
import { Modal, Input } from 'antd';
import { useDispatch } from 'react-redux';
import { cancelOrder, updateOrder } from '../../../actions/order';

export default function CancelOrderForm({ id, status, setVisible, refresh }) {

    const [reason, setReason] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState("");

    let dispatch = useDispatch();

    function onReasonChange(ev) {
        setReason(ev.target.value);
    }

    async function updateOrderStatus() {
        try {
            setLoading(true);

            if (status === 'CANCEL')
                await cancelOrder({ id, reason: reason.trim() })(dispatch);
            else
                await updateOrder({ id, status, reason: reason.trim() })(dispatch);

            refresh();
        } catch (err) {
            console.log(err);
            setError(err.message);
        }
        setLoading(false);
    }

    return (
        <div>

            <Modal
                title={status === "CANCEL" ? "Cancel Order" : status === 'REJECTED' ? "Decline Order" : "Accept Order"}
                visible={!!status}
                okText={status === 'CANCEL' ? "Cancel Order" : status === 'REJECTED' ? "Decline" : "Accept"}
                okButtonProps={{ loading: loading, shape: 'round' }}
                cancelButtonProps={{ shape: 'round' }}
                onOk={updateOrderStatus}
                onCancel={() => loading ? '' : setVisible()}
                wrapClassName="modal-content-wrapper"
            >

                <div className={`form-field`}>

                    <label>
                        {
                            status === 'CANCEL' ?
                                'Please provide a reason for canceling order.'
                                :
                                status === 'REJECTED'
                                    ?
                                    'Are you sure you want to DECLINE the order? Would you like to say something to the buyer?'
                                    :
                                    'Are you sure you want to ACCEPT the order? Would you like to say something to the buyer?'
                        }
                    </label>

                    <Input.TextArea rows={4} onChange={onReasonChange} />

                    {
                        error.length > 0 &&
                        <span className="error">
                            {error}
                        </span>
                    }

                </div>

            </Modal>

        </div>
    );

}