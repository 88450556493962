import { Modal } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Field, reduxForm, reset, initialize } from 'redux-form';
import { Input, InputNumber, validate, FileUpload, Checkbox } from '../../../common/redux-form';

function CreateAddon({
    visible,
    createAddons,
    updateAddons,
    setVisible,
    handleSubmit,
    isLoading,
    refresh,
    item
}) {

    let dispatch = useDispatch();

    useEffect(() => {
        dispatch(initialize('addon', item));
    }, [item, dispatch]);

    async function submit(values) {
        try {
            if (item._id)
                await updateAddons({ ...values });
            else await createAddons(values);
            refresh();
            dispatch(reset('addon'));
            setVisible(false);
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <form onSubmit={handleSubmit(submit)}>
            <Modal
                onCancel={() => {
                    dispatch(reset('addon'));
                    setVisible(false);
                }}
                onOk={handleSubmit(submit)}
                visible={visible}
                title="Create New Addon"
                confirmLoading={isLoading}
            >
                <Field
                    name="name"
                    type="input"
                    component={Input}
                    label="Title"
                    validate={[validate.required]}
                    isRequired={true}
                    tooltipText={
                        <div className="info-popup">
                            <span className="desc">
                                Mention the name of your add on
                            </span>
                        </div>
                    }
                />
                {
                    item._id
                        ?
                        null
                        :
                        <Field
                            name="delivery_addon"
                            component={Checkbox}
                            label="Is this delivery addon ?"
                            isRequired={true}
                            tooltipText={
                                <div className="info-popup">
                                    <span className="desc">
                                        Check this box only if this is a delivery add-on
                                    </span>
                                </div>
                            }
                        />
                }
                <Field
                    name="description"
                    type="textarea"
                    component={Input}
                    label="Description"
                    validate={[validate.required]}
                    isRequired={true}
                    tooltipText={
                        <div className="info-popup">
                            <span className="desc">
                                Describe the add on for better understanding of the consumer.
                            </span>
                        </div>
                    }
                />
                <Field
                    name="price"
                    component={InputNumber}
                    precision={2}
                    min={0}
                    step={1.00}
                    label="Price($)"
                    isRequired={true}
                    tooltipText={
                        <div className="info-popup">
                            <span className="desc">
                                Mention the price for the specific add-on
                            </span>
                        </div>
                    }
                />
                {
                    item?.deliver_addon ?
                        null
                        :
                        <Field
                            name="addon_image"
                            component={FileUpload}
                            label="Picture"
                            resize={true}
                            width={800}
                            height={600}
                            context='addons'
                            accept='image/*'
                            fileTypes={["image/jpg", "image/jpeg", "image/png"]}
                            tooltipText={
                                <div className="info-popup">
                                    <span className="desc">
                                        Upload picture of your add-on.
                                    </span>
                                </div>
                            }
                        />
                }
            </Modal>
        </form>
    );
}

export default reduxForm({
    form: 'addon', // a unique identifier for this form
})(CreateAddon);
