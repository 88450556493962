export const durationInOptions = [
    { label: "Days", value: "DAYS" },
    /**
     * { label: "Weeks", value: "WEEKS" },
     * { label: "Months", value: "MONTHS" },
     * { label: "Years", value: "YEARS" },
     */
];

export const gender = [
    { text: "male", value: "MALE" },
    { text: "female", value: "FEMALE" },
];

export const monthOptions = [
    { label: "January", value: 1 },
    { label: "February", value: 2 },
    { label: "March", value: 3 },
    { label: "April", value: 4 },
    { label: "May", value: 5 },
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
    { label: "September", value: 9 },
    { label: "October", value: 10 },
    { label: "November", value: 11 },
    { label: "December", value: 12 },
];

export const userStatus = [
    { text: "Draft", value: "DRAFT" },
    { text: "Email verification pending", value: "EMAIL_VALIDATION_PENDING" },
    { text: "Registration pending", value: "REGISTRATION_PENDING" },
    { text: "Registration done", value: "REGISTRATION_DONE" },
    { text: "Active", value: "ACTIVE" },
    { text: "Under review", value: "ADMIN_REVIEW" },
    { text: "Deleted", value: "DELETED" },
];

export const userStatusValue = {
    DRAFT: "Draft",
    EMAIL_VALIDATION_PENDING: "Email verification pending",
    REGISTRATION_PENDING: "Registration pending",
    REGISTRATION_DONE: "Registration done",
    ACTIVE: "Active",
    ADMIN_REVIEW: "Under review",
    DELETED: "Deleted",
    ADMIN_DEACTIVATE: "Admin Deactivated",
};
// "DRAFT", "INVITE_SENT", "JOINED", "CLOSED", "DELETED"
export const leadStatusFilters = [
    { text: "draft", value: "DRAFT" },
    { text: "invite sent", value: "INVITE_SENT" },
    { text: "joined", value: "JOINED" },
    { text: "closed", value: "CLOSED" },
    { text: "deleted", value: "DELETED" },
];

export const STATE = [
    { label: "Alabama", value: "AL" },
    { label: "Alaska", value: "AK" },
    { label: "American Samoa", value: "AS" },
    { label: "Arizona", value: "AZ" },
    { label: "Arkansas", value: "AR" },
    { label: "California", value: "CA" },
    { label: "Colorado", value: "CO" },
    { label: "Connecticut", value: "CT" },
    { label: "Delaware", value: "DE" },
    { label: "District Of Columbia", value: "DC" },
    { label: "Federated States Of Micronesia", value: "FM" },
    { label: "Florida", value: "FL" },
    { label: "Georgia", value: "GA" },
    { label: "Guam", value: "GU" },
    { label: "Hawaii", value: "HI" },
    { label: "Idaho", value: "ID" },
    { label: "Illinois", value: "IL" },
    { label: "Indiana", value: "IN" },
    { label: "Iowa", value: "IA" },
    { label: "Kansas", value: "KS" },
    { label: "Kentucky", value: "KY" },
    { label: "Louisiana", value: "LA" },
    { label: "Maine", value: "ME" },
    { label: "Marshall Islands", value: "MH" },
    { label: "Maryland", value: "MD" },
    { label: "Massachusetts", value: "MA" },
    { label: "Michigan", value: "MI" },
    { label: "Minnesota", value: "MN" },
    { label: "Mississippi", value: "MS" },
    { label: "Missouri", value: "MO" },
    { label: "Montana", value: "MT" },
    { label: "Nebraska", value: "NE" },
    { label: "Nevada", value: "NV" },
    { label: "New Hampshire", value: "NH" },
    { label: "New Jersey", value: "NJ" },
    { label: "New Mexico", value: "NM" },
    { label: "New York", value: "NY" },
    { label: "North Carolina", value: "NC" },
    { label: "North Dakota", value: "ND" },
    { label: "Northern Mariana Islands", value: "MP" },
    { label: "Ohio", value: "OH" },
    { label: "Oklahoma", value: "OK" },
    { label: "Oregon", value: "OR" },
    { label: "Palau", value: "PW" },
    { label: "Pennsylvania", value: "PA" },
    { label: "Puerto Rico", value: "PR" },
    { label: "Rhode Island", value: "RI" },
    { label: "South Carolina", value: "SC" },
    { label: "South Dakota", value: "SD" },
    { label: "Tennessee", value: "TN" },
    { label: "Texas", value: "TX" },
    { label: "Utah", value: "UT" },
    { label: "Vermont", value: "VT" },
    { label: "Virgin Islands", value: "VI" },
    { label: "Virginia", value: "VA" },
    { label: "Washington", value: "WA" },
    { label: "West Virginia", value: "WV" },
    { label: "Wisconsin", value: "WI" },
    { label: "Wyoming", value: "WY" },
];

export const SearchTypeOptions = [
    { key: "all", text: "All", value: "" },
    { key: "gig", text: "Service Gig", value: "gig" },
    { key: "estimate", text: "Estimate", value: "estimate" },
    { key: "warranty", text: "Service Warranty", value: "warranty" },
    { key: "maintenance", text: "Maintenance Plan", value: "maintenance" },
    // { key: "supply", text: "Supply", value: "supply" }
];

export const USER_SCOPE = {
    PROVIDER: "PROVIDER",
    CONSUMER: "CONSUMER",
};

export const TargetConsumerOptions = [
    { label: "Residential", value: "RESIDENTIAL" },
    { label: "Commercial", value: "COMMERCIAL" },
];

export const ContractActionsRule = {
    DRAFT: {
        View: false,
        Edit: false,
        Publish: true,
        Delete: false,
        Unpublish: true,
    },
    ACTIVE: {
        View: false,
        Edit: false,
        Publish: false,
        Delete: false,
        Unpublish: true,
    },
    PUBLISHED: {
        View: false,
        Edit: true,
        Publish: true,
        Delete: true,
        Unpublish: false,
    },
    DELETED: {
        View: false,
        Edit: true,
        Publish: true,
        Delete: true,
        Unpublish: true,
    },
    ADMIN_REVIEW: {
        View: true,
        Edit: true,
        Publish: true,
        Delete: true,
        Unpublish: true,
    },
    PREPUBLISH_CHECK: {
        View: false,
        Edit: true,
        Publish: true,
        Delete: true,
        Unpublish: false,
    },
};

export const CategoryStatus = [
    { text: "Active", value: "ACTIVE" },
    { text: "Draft", value: "DRAFT" },
    { text: "Deleted", value: "DELETED" },
];

export const BOOLEAN_OPTIONS = [
    { text: "true", value: true },
    { text: "false", value: false },
];

export const OrderStatus = {
    ORDER_PLACED: "Placed",
    ACCEPTED: "Accepted",
    READY: "Ready",
    FULFILLED: "Fulfilled",
    REJECTED: "Rejected",
    BUYER_CANCELED: "Canceled by Buyer",
    SELLER_CANCELED: "Canceled by Seller",
    EXPIRED: "Expired",

};

export const OrderStatusOptions = [
    { text: "All", value: "" },
    { text: "Placed", value: "ORDER_PLACED" },
    { text: "Accepted", value: "ACCEPTED" },
    { text: "Rejected", value: "REJECTED" },
    { text: "Cancelled", value: "CANCELED" },
    { text: "Expired", value: "EXPIRED" },
];

export const InvoiceOrderedStatus = {
    DRAFT: "Draft",
    PAYMENT_INTENT_SENT: "Pending",
    PAYMENT_INTENT_ACCEPTED: "Paid",
    PAYMENT_INTENT_REJECTED: "Rejected",
    REFUND_REQUESTED: "Refund Requested",
    REFUND_SENT: "Refund Sent",
    SUSPENDED_FRAUD: "Suspended",
    PROCESSING_ISSUE: "Processing Issue",
    ADMIN_REVIEW: "Admin Review",
    CANCELED: "Cancelled",
    EXPIRED: "Expired"
};

export const UserStatus = {
    DRAFT: "Draft",
    EMAIL_VALIDATION_PENDING: "Email Validation Pending",
    REGISTRATION_PENDING: "Registration Pending",
    REGISTRATION_PENDING_INVITE_PROVIDER:
        "Registration Pending Invite Provider",
    REGISTRATION_DONE: "Registration Done",
    ACTIVE: "Active",
    ADMIN_REVIEW: "Admin Review",
    DELETED: "Deleted",
    SELF_DEACTIVATE: "Self Deactivate",
    ADMIN_DEACTIVATE: "Admin Deactivate",
};

export const UserType = {
    PROVIDER: "Provider",
    CONSUMER: "Consumer",
};

export const UserTypeOptions = [
    { label: "Provider", value: "PROVIDER" },
    { label: "Consumer", value: "CONSUMER" },
];

export const SystemEventActions = [
    { label: "Admin Review", value: "ADMIN_REVIEW" },
    { label: "Admin Error Review", value: "ADMIN_ERROR_REVIEW" },
    { label: "Deactivation Request", value: "DEACTIVATION_REQUEST" },
    { label: "Contact Seeker", value: "CONTACT_SEEKER" },
    { label: "Contact Provider", value: "CONTACT_PROVIDER" },
];

export const SystemEventActionFilterSet = [
    { text: "Admin Review", value: "ADMIN_REVIEW" },
    { text: "Admin Error Review", value: "ADMIN_ERROR_REVIEW" },
    { text: "Deactivation Request", value: "DEACTIVATION_REQUEST" },
    { text: "Contact Seeker", value: "CONTACT_SEEKER" },
    { text: "Contact Provider", value: "CONTACT_PROVIDER" },
];

export const SystemEventActionValue = {
    ADMIN_REVIEW: "Admin Review",
    ADMIN_ERROR_REVIEW: "Admin Error Review",
    DEACTIVATION_REQUEST: "Deactivation Request",
    CONTACT_SEEKER: "Contact Seeker",
    CONTACT_PROVIDER: "Contact Provider",
    "Admin Review": "ADMIN_REVIEW",
    "Admin Error Review": "ADMIN_ERROR_REVIEW",
    "Deactivation Request": "DEACTIVATION_REQUEST",
    "Contact Seeker": "CONTACT_SEEKER",
    "Contact Provider": "CONTACT_PROVIDER",
};

export const SystemEventPriority = [
    { label: "Low", value: "P3" },
    { label: "Mid", value: "P2" },
    { label: "High", value: "P1" },
];

export const SystemEventPriorityFilterSet = [
    { text: "Low", value: "P3" },
    { text: "Mid", value: "P2" },
    { text: "High", value: "P1" },
];

export const SystemEventPriorityValue = {
    P3: "Low",
    P2: "Mid",
    P1: "High",
};

export const SystemEventStatus = [
    { label: "New", value: "NEW" },
    { label: "Resolved", value: "RESOLVED" },
    { label: "WIP", value: "WIP" }, // Work in progress
];

export const SystemEventStatusValue = {
    NEW: "New",
    RESOLVED: "Resolved",
    WIP: "WIP",
    New: "NEW",
    Resolved: "RESOLVED",
};

export const systemEventStatusFilterSet = [
    { text: "New", value: "NEW" },
    { text: "Resolved", value: "RESOLVED" },
    { text: "WIP", value: "WIP" }
];

export const ServiceActionsRule = {
    UNPUBLISHED: {
        View: false,
        Edit: false,
        Publish: false,
        Delete: false,
        Unpublish: true,
    },
    DRAFT: {
        View: false,
        Edit: false,
        Publish: false,
        Delete: false,
        Unpublish: true,
    },
    PUBLISHED: {
        View: false,
        Edit: true,
        Publish: true,
        Delete: true,
        Unpublish: false,
    },
    DELETED: {
        View: false,
        Edit: true,
        Publish: true,
        Delete: true,
        Unpublish: true,
    },
    ADMIN_REVIEW: {
        View: true,
        Edit: true,
        Publish: true,
        Delete: true,
        Unpublish: true,
    },
    REDACTED: {
        View: true,
        Edit: true,
        Publish: true,
        Delete: true,
        Unpublish: true,
    },
};

export const originType = [
    { label: "Homemade", value: "HOMEMADE" },
    { label: "Farm Fresh", value: "FARM_FRESH" },
    { label: "Wholesale Market", value: "WHOLESALE_MARKET" },
    { label: "Fresh Organic Produce", value: "FRESH_ORGANIC_PRODUCE" },
    { label: "Artisan Farm Products", value: "ARTISAN_FARM_PRODUCTS" },
    { label: "Made On Premise", value: "ON_PREMISE" }
];

export const dietType = [
    { label: "Vegan", value: "VEGAN" },
    { label: "Vegetarian", value: "VEGETARIAN" },
    { label: "Pescatarian", value: "PESCATARIAN" },
    { label: "Flexitarian", value: "FLEXITARIAN" },
    { label: "Macrobiotic", value: "MACROBIOTIC" },
];

export const projectType = window._env.REACT_APP_EVENTS_OPTIONS.split(';');
