import { toast } from 'react-toastify';
import { Fetch, getErrorMessage } from "./common";
import { setApp as setAppReducer } from "./app";
import { setRegistration } from "./registration";
import Axios from "axios";
import { initialize } from "redux-form";
import { setApp } from "./app";
import { message } from 'antd';
/**
 * Set user data
 * @param {object} payload user data
 * @returns {object} updated payload
 */

export const setUser = (payload = {}) => ({
    type: "SET_USER",
    payload,
});

export const userLogout = () => ({
    type: "LOGOUT"
});

/**
 * Get user detail
 * @returns {object} updated payload
 */
export const getUserDetail = () => (dispatch, getState) => {

    dispatch(setUser({ isLoading: true }));
    return Fetch('/user/basicInfo')
        .then((res) => {
            if (res.status === 200) {
                let phone_number = res.data.phone_number;
                if (phone_number && phone_number.length > 0)
                    phone_number[0].number = phone_number[0].number.substring(2);
                dispatch(
                    setUser({
                        ...res.data,
                        isAuthenticated: true,
                        isLoading: false,
                        phone_number
                    })
                );
                dispatch(
                    setRegistration({
                        formValue: res.data,
                    })
                );
                return res.data;
            }
            dispatch(setUser({ isAuthenticated: false }));
            toast.error('Session expired please login again');
            throw (res.data);
        })
        .catch((e) => {
            dispatch(setUser({ isAuthenticated: false }));
            toast.error(e.message);
            throw (e);
        });
};

export const getUserDetailIfNeeded = () => (dispatch, getState) => {
    dispatch(getUserDetail());
};

/**
 * Change password
 *
 * @param {object} params {old_password, new_password}
 * @returns {{<Promise>}} promise
 */
export const changePassword = (params = {}) => (dispatch) => {
    const access_token = localStorage.getItem("access_token");
    const options = {
        data: JSON.stringify({ ...params, access_token }),
    };

    return Fetch(`/user/change-password`, "PATCH", options)
        .then((res) => {
            if (res.status !== 200) {
                throw new Error(
                    getErrorMessage(res, "Something went wrong, please try again.")
                );
            }
            toast.success("Password changed successfully");
        })
        .catch((e) => {
            throw new Error(getErrorMessage(e, "Something went wrong, please try again."));
        });
};

/**
 * Login
 *
 * @param {object} credential {username, password}
 * @returns {{<Promise>}} promise
 */
export const login = (credential = {}) => (dispatch) => {
    const options = {
        data: JSON.stringify(credential),
    };

    dispatch(setUser({ isLoading: true }));
    return Fetch(`/user/login`, "POST", options)
        .then(({ status, data = {} }) => {
            if (status === 200) {
                dispatch(setUser({ ...data }));
                dispatch(getUserDetail());
                if (data.warning) {
                    message.config({ top: 200 });
                    message.warning(data.warning, 4);
                }
                return data;
            }
            if (data.recaptchaIssue) {
                dispatch(setUser({ isLoading: false }));
                return data;
            }
            dispatch(setUser({ isLoading: false }));
            throw new Error(data.message);
        })
        .catch((e) => {
            dispatch(setUser({ isLoading: false }));
            throw new Error(getErrorMessage(e, "Something went wrong, please try again."));
        });
};

/**
 * Check postal code
 *
 * @param {string|number} postalCode postal_code
 * @returns {{<Promise>}} promise
 */
const checkPostalCode = (postalCode) => async (dispatch) => {
    try {
        const url = `/servicearea/getByZip?code=${postalCode}`;
        let errorMessage =
            "Thanks for your interest in sign-up on Foodelys, currently we are not in your area.";
        dispatch(setUser({ isLoading: true }));
        const response = await Fetch(url);
        const { status, data } = response;
        if (data.message) {
            throw new Error(data.message);
        }

        if (status === 200 && data.postal_code && data.IsEnabled === "TRUE") {
            return { status: true, data };
        }

        if (data.message) {
            errorMessage = data.message;
        }
        dispatch(setUser({ isLoading: false }));
        throw new Error(errorMessage);
    } catch (error) {
        dispatch(setUser({ isLoading: false }));
        throw new Error(getErrorMessage(error, "Something went wrong, please try again."));
    }
};

/**
 * Signup
 *
 * @param {object} userDetail {username, password}
 * @returns {{<Promise>}} promise
 */
export const signup = (userDetail = {}) => async (dispatch) => {
    const options = {
        data: JSON.stringify(userDetail),
    };

    dispatch(setUser({ isLoading: true }));

    const { status: zipApiRes } = await dispatch(
        checkPostalCode(userDetail.postal_code)
    );

    if (!zipApiRes) {
        throw new Error(
            "Thanks for your interest in sign-up on Foodelys, currently we are not in your area."
        );
    }

    return Fetch(`/user`, "POST", options)
        .then((response) => {
            const { status, data = {} } = response;
            if (status === 200 || status === 201) {
                const signupMail =
                    userDetail.scope === "PROVIDER"
                        ? userDetail.email
                        : userDetail.username;
                localStorage.setItem("signup-mail", signupMail);
                dispatch(setUser({ isLoading: false }));
                return true;
            }

            if (data.recaptchaIssue) {
                dispatch(setUser({ isLoading: false }));
                return data;
            }

            dispatch(setUser({ isLoading: false }));
            throw new Error("Invalid credentials");
        })
        .catch((e) => {
            dispatch(setUser({ isLoading: false }));
            throw new Error(getErrorMessage(e, "Something went wrong, please try again."));
        });
};

/**
 * Signup
 *
 * @param {object} userDetail {username, password}
 * @returns {{<Promise>}} promise
 */
export const resend_confirmation_email = (userDetail = {}) => async (dispatch) => {
    const options = {
        data: JSON.stringify(userDetail),
    };

    dispatch(setUser({ isLoading: true }));
    return Fetch(`/user/resend_confirmation_email`, "POST", options)
        .then((response) => {
            const { status, data = {} } = response;
            if (status === 200) {
                dispatch(setUser({ isLoading: false }));
                toast.success('Confirmation email sent successfully');
                return true;
            }

            if (data.recaptchaIssue) {
                dispatch(setUser({ isLoading: false }));
                return data;
            }

            dispatch(setUser({ isLoading: false }));
            throw new Error("Invalid credentials");
        })
        .catch((e) => {
            dispatch(setUser({ isLoading: false }));
            toast.error(getErrorMessage(e, "Something went wrong, please try again."));
            throw new Error(getErrorMessage(e, "Something went wrong, please try again."));
        });
};

/**
 * Logout
 *
 * @returns {object} payload
 */
export const logout = () => (dispatch) => {
    dispatch(setApp({ isAppLoader: true }));
    return Fetch(`/user/logout`, "GET")
        .then((res) => {
            dispatch(userLogout({}));
            return res;
        })
        .catch((e) => {
            console.log(e);
        })
        .finally(() => dispatch(setApp({ isAppLoader: false })));
};

/**
 * Forgot password request
 *
 * @param {string} username username
 * @returns {{<Promise>}} promise
 */
export const forgotPasswordRequest = (username) => async (dispatch) => {
    const url = `/user/${username}/init-forgot-pwd`;

    dispatch(setUser({ isLoading: true }));

    return Fetch(url, "POST")
        .then(({ status, data = {} }) => {
            if (status === 200) {
                toast.success("Sent reset password request");
                localStorage.setItem("reset-username", username);
                dispatch(setUser({ isLoading: false }));
                dispatch(setAppReducer({ isResetPasswordMode: true }));
                return { status: true };
            } else {
                dispatch(setUser({ isLoading: false }));
                dispatch(setAppReducer({ isResetPasswordMode: true }));
                toast.success(data.msg);
            }
        })
        .catch((e) => {
            dispatch(setUser({ isLoading: false }));
            throw new Error(getErrorMessage(e, "Something went wrong, please try again."));
        });
};

/**
 * Password peset request
 *
 * @param {object} params {password, code}
 * @returns {{<Promise>}} promise
 */
export const resetPassword = ({ password, code }) => async (dispatch) => {
    const username = localStorage.getItem("reset-username");
    const url = `/user/confirm-forgot-pw`;
    const options = {
        data: JSON.stringify({ username, password, code }),
    };

    dispatch(setUser({ isLoading: true }));
    return Fetch(url, "POST", options)
        .then(({ status }) => {
            if (status === 200) {
                toast.success("Password reset successfully");
                localStorage.setItem("signup-mail", username);
                dispatch(cancelResetPassword());
                dispatch(setUser({ isLoading: false }));
                return { status: true };
            }

            dispatch(setUser({ isLoading: false }));
            throw new Error("Something went wrong, please try again.");
        })
        .catch((e) => {
            dispatch(setUser({ isLoading: false }));
            throw new Error(getErrorMessage(e, "Something went wrong, please try again."));
        });
};

/**
 * Cancel reset password request
 */
export const cancelResetPassword = () => (dispatch) => {
    localStorage.removeItem("reset-username");
    dispatch(setAppReducer({ isResetPasswordMode: false }));
};

/**
 * getIpaddress commented for future uses don't remove its important
 */
export const getIpaddress = () => async dispatch => {
    try {
        const url = window._env.REACT_APP_GEO_IP_ENDPOINT;
        const headers = {
            'Accept': "application/json",
            'Authorization': window._env.REACT_APP_GEO_IP_KEY
        };
        const address = await Axios.get(url, { headers }).then(res => res.data);
        dispatch(setAppReducer({ address_from_ip: address }));
        localStorage.setItem("address", JSON.stringify(address));

        return address;
    } catch (error) {
        return error.message;
    }
};

/**
 * update user detail
 * @param {object} userBody user new detail
 * @returns {object} updated payload
 */
export const updateUserDetail = (userBody = {}) => (dispatch) => {
    const options = {
        data: JSON.stringify(userBody),
    };

    dispatch(setUser({ isLoading: true }));
    return Fetch(`/user`, "PATCH", options)
        .then(({ status, data = {} }) => {
            if (status === 200) {
                dispatch(getUserDetail());
                toast.success("User details updated successfully");
                return data;
            }

            throw new Error(data.message || "User detail not update try again");
        })
        .catch((e) => {
            const message = getErrorMessage(e, "Something went wrong, please try again.");
            dispatch(setUser({ isLoading: false }));
            throw new Error(message);
        });
};

export const updatePersonalDetail = (userBody = {}) => (dispatch) => {
    const options = {
        data: JSON.stringify(userBody),
    };

    dispatch(setUser({ isLoadingProfile: true }));
    return Fetch(`/user`, "PATCH", options)
        .then(({ status, data = {} }) => {
            if (status === 200) {
                dispatch(getUserDetail());
                dispatch(setUser({ isLoadingProfile: false }));
                toast.success("User details updated successfully");
                return data;
            }

            throw new Error(data.message || "User detail not update try again");
        })
        .catch((e) => {
            const message = getErrorMessage(e, "Something went wrong, please try again.");
            dispatch(setUser({ isLoadingProfile: false }));
            throw new Error(message);
        });
};

/**
 * Add update user address
 *
 * @param {object} params address
 * @returns {{<Promise>}} promise
 */
export const addUpdateAddress = (params = {}) => (dispatch) => {
    const options = {
        data: JSON.stringify(params),
    };

    dispatch(setUser({ isLoadingAddress: true }));
    return Fetch(`/user/address`, params._id ? 'PATCH' : "POST", options)
        .then((res) => {
            if (res.status !== 200) {
                throw new Error(
                    getErrorMessage(res, "Something went wrong, please try again.")
                );
            }
            // toast.success("Update successfully");
            dispatch(setUser({ isLoadingAddress: false }));
        })
        .catch((e) => {
            dispatch(setUser({ isLoadingAddress: false }));
            throw new Error(getErrorMessage(e, "Something went wrong, please try again."));
        });
};

/**
 * Remove address from user
 *
 * @param {string} id address id
 * @returns {{<Promise>}} promise
 */
export const removeAddress = (addressId) => (dispatch) => {
    if (!addressId) {
        toast.error("Select address to remove");
        return false;
    }

    dispatch(setUser({ isLoadingAddress: true }));
    return Fetch(`/user/address/${addressId}`, "DELETE")
        .then((res) => {
            if (res.status !== 200) {
                throw new Error(
                    getErrorMessage(res, "Something went wrong, please try again.")
                );
            }

            dispatch(getUserDetail());
            toast.success("Address deleted successfully");
            dispatch(setUser({ isLoadingAddress: false }));
        })
        .catch((e) => {
            dispatch(setUser({ isLoadingAddress: false }));
            const message = getErrorMessage(e, "Something went wrong, please try again.");
            toast.error(message);
        });
};

/**
 * set notification status
 *
 * @param {object} note { name, type, is_preferred }
 * @returns {{<dispatch>}} dispatch
 */
export const setNotification = ({ name, type, is_preferred }) => (
    dispatch,
    getState
) => {
    let { preferences: { notification = [] } = {} } = getState().user;

    notification = notification.map((item) => {
        if (item.name === name) {
            const method = item.method.map((i) => {
                if (i.type === type) {
                    return { ...i, is_preferred };
                }

                return i;
            });

            return { ...item, method };
        }

        return item;
    });

    dispatch({
        type: "SET_USER_NOTIFICATION",
        payload: { notification },
    });
};

/**
 * Update notification
 *
 * @returns {{<Promise>}} promise
 */
export const updateNotification = () => (dispatch, getState) => {
    let { preferences: { notification = [] } = {} } = getState().user;

    const options = {
        data: JSON.stringify({ notification }),
    };

    dispatch(setUser({ isLoadingProfile: true }));
    return Fetch(`/user/notification`, "POST", options)
        .then((res) => {
            if (res.status !== 200) {
                throw new Error(
                    getErrorMessage(res, "Something went wrong, please try again.")
                );
            }

            toast.success(res.data.message);
            dispatch(setUser({ isLoadingProfile: false }));
        })
        .catch((e) => {
            dispatch(setUser({ isLoadingProfile: false }));
            const message = getErrorMessage(e, "Something went wrong, please try again.");
            toast.error(message);
        });
};

export const resetSignUpProForm = () => (dispatch, getState) => {
    dispatch(initialize("signup-pro", {}));
    dispatch({
        type: "RESET_SIGN_UP_PRO_FORM",
    });
};

export const resetSignUpSeekerForm = () => (dispatch, getState) => {
    dispatch(initialize("signup", {}));
    dispatch({
        type: "RESET_SIGN_UP_SEEKER_FORM",
    });
};

export const deactivate = (message) => (dispatch) => {
    dispatch(setUser({ isLoading: true }));
    return Fetch(`/user/deactivate/${message}`, "DELETE")
        .then(({ status, data = {} }) => {
            if (status === 202) {
                dispatch(logout());
                toast.success("Your account is deactivated successfully");
            }
        })
        .catch((error) => {
            toast.error("Some error occurred while deactivating your account, if the issue persists please contact Foodelys support");
            dispatch(setUser({ isLoading: false }));
        });
};

export const deactivateByAdmin = (id) => (dispatch) => {
    dispatch(setUser({ isLoading: true }));
    return Fetch(`/user/admin_deactivate_user/${id}`, "DELETE")
        .then(({ status, data = {} }) => {
            if (status === 202) {
                toast.success(data);

            }
        })
        .catch((error) => {
            toast.error("Some error occurred while deactivating your account, if the issue persists please contact Foodelys support");
            dispatch(setUser({ isLoading: false }));
        });
};

export const emailDisposal = (email) => (dispatch) => {
    const url = `/email/validateEmail/${email}`;
    return Fetch(url, "GET")
        .then((result) => {
            const { data } = result;

            return data;
        })
        .catch((error) => {
            toast.error("This emails address is not valid, please use a valid email address");
            return false;
        });
};

export const supportEmail = (params = {}) => (dispatch) => {
    const options = {
        data: JSON.stringify(params),
    };

    dispatch(setUser({ isLoading: true }));
    return Fetch(`/user/support/email`, "POST", options)
        .then((res) => {
            if (res.status === 200) {
                dispatch(setUser({ isLoading: false }));
                toast.success("Thanks, your emails message is sent");
                return true;
            }
        })
        .catch((e) => {
            dispatch(setUser({ isLoading: false }));
            throw new Error(getErrorMessage(e, "Something went wrong, please try again."));
        });
};

export const contactUsEmail = (params = {}) => (dispatch) => {
    const options = {
        data: JSON.stringify(params),
    };
    dispatch(setUser({ isLoading: true }));
    return Fetch(`/user/contact-us`, "POST", options)
        .then((res) => {
            if (res.status === 200) {
                dispatch(setUser({ isLoading: false }));
                toast.success("Thanks, we have received your query");
                return true;
            }
        })
        .catch((e) => {
            dispatch(setUser({ isLoading: false }));
            const message = getErrorMessage(e, "Something went wrong, please try again.");
            toast.error(message);
        });
};

export const addPickupLocation = (params = {}) => (dispatch) => {
    const options = {
        data: JSON.stringify(params),
    };

    dispatch(setUser({ isLoadingPickupLocation: true }));
    return Fetch(`/user/pickup-location`, "POST", options)
        .then((res) => {
            if (res.status !== 201) {
                throw new Error(
                    getErrorMessage(res, "Something went wrong, please try again.")
                );
            }
            dispatch(getUserDetail());
            toast.success("Pickup Added successfully");
            dispatch(setUser({ isLoadingPickupLocation: false }));
        })
        .catch((e) => {
            dispatch(setUser({ isLoadingPickupLocation: false }));
            const message = getErrorMessage(e, "Something went wrong, please try again.");
            toast.error(message);
        });
};

export const updatePickupLocation = (params = {}) => (dispatch) => {
    const options = {
        data: JSON.stringify(params),
    };

    dispatch(setUser({ isLoadingPickupLocation: true }));
    return Fetch(`/user/pickup-location`, "PATCH", options)
        .then((res) => {
            if (res.status !== 200) {
                throw new Error(
                    getErrorMessage(res, "Something went wrong, please try again.")
                );
            }
            dispatch(getUserDetail());
            toast.success("Pickup Updated successfully");
            dispatch(setUser({ isLoadingPickupLocation: false }));
        })
        .catch((e) => {
            dispatch(setUser({ isLoadingPickupLocation: false }));
            const message = getErrorMessage(e, "Something went wrong, please try again.");
            toast.error(message);
        });
};

export const removePickupLocation = (pickupLocationId) => (dispatch) => {
    if (!pickupLocationId) {
        toast.error("Select location to remove");
        return false;
    }
    const url = `/user/pickup-location?id=${pickupLocationId}`;
    dispatch(setUser({ isLoadingPickupLocation: true }));
    return Fetch(url, "DELETE")
        .then((res) => {
            if (res.status !== 200) {
                throw new Error(
                    getErrorMessage(res, "Something went wrong, please try again.")
                );
            }

            dispatch(getUserDetail());
            toast.success("Pickup deleted successfully");
            dispatch(setUser({ isLoadingPickupLocation: false }));
        })
        .catch((e) => {
            dispatch(setUser({ isLoadingPickupLocation: false }));
            const message = getErrorMessage(e, "Something went wrong, please try again.");
            toast.error(message);
        });
};


export const addPaymentOption = (params = {}) => (dispatch) => {
    const options = {
        data: JSON.stringify(params),
    };
    dispatch(setUser({ isLoadingPaymentOption: true }));
    return Fetch(`/user/payment-option`, "POST", options)
        .then((res) => {
            if (res.status !== 201) {
                throw new Error(
                    getErrorMessage(res, "Something went wrong, please try again.")
                );
            }
            dispatch(getUserDetail());
            toast.success("Payment Option Added successfully");
            dispatch(setUser({ isLoadingPaymentOption: false }));
        })
        .catch((e) => {
            dispatch(setUser({ isLoadingPaymentOption: false }));
            const message = getErrorMessage(e, "Something went wrong, please try again.");
            toast.error(message);
        });

};

export const updatePaymentOption = (params = {}) => (dispatch) => {
    const options = {
        data: JSON.stringify(params),
    };
    dispatch(setUser({ isLoadingPaymentOption: true }));
    return Fetch(`/user/payment-option`, "PATCH", options)
        .then((res) => {
            if (res.status !== 200) {
                throw new Error(
                    getErrorMessage(res, "Something went wrong, please try again.")
                );
            }
            dispatch(getUserDetail());
            toast.success("Payment Option Updated successfully");
            dispatch(setUser({ isLoadingPaymentOption: false }));
        })
        .catch((e) => {
            dispatch(setUser({ isLoadingPaymentOption: false }));
            const message = getErrorMessage(e, "Something went wrong, please try again.");
            toast.error(message);
        });

};

export const removePaymentOption = (paymentOptionId) => (dispatch) => {
    if (!paymentOptionId) {
        toast.error("Select location to remove");
        return false;
    }
    const url = `/user/payment-option?id=${paymentOptionId}`;
    dispatch(setUser({ isLoadingPaymentOption: true }));
    return Fetch(url, "DELETE")
        .then((res) => {
            if (res.status !== 200) {
                throw new Error(
                    getErrorMessage(res, "Something went wrong, please try again.")
                );
            }
            dispatch(getUserDetail());
            toast.success("Payment Option deleted successfully");
            dispatch(setUser({ isLoadingPaymentOption: false }));
        })
        .catch((e) => {
            dispatch(setUser({ isLoadingPaymentOption: false }));
            const message = getErrorMessage(e, "Something went wrong, please try again.");
            toast.error(message);

        });

};

/**
 * Update notification
 *
 * @returns {{<Promise>}} promise
 */
export const updatePrivacySettings = (data) => {

    const options = {
        data: JSON.stringify(data),
    };

    return Fetch(`/user/privacy-settings`, "PATCH", options)
        .then((res) => {
            if (res.status === 200) {
                return toast.success(res.data.message);
            }
            toast.error(res.data.message);
        })
        .catch((e) => {
            const message = getErrorMessage(e, "Something went wrong, please try again.");
            toast.error(message);
        });
};