import React, { useEffect } from "react";
import { Row, Col, Button } from 'antd';

import { useSelector } from "react-redux";
import Modal from "antd/lib/modal/Modal";
import { AiOutlineCloseCircle } from "react-icons/ai";
import TwrCarousel from "../../common/TwrCarousel";
import './styles.scss';
import { Scrollbars } from 'react-custom-scrollbars';
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

export default function ProjectPopup({ handleClose, isVisible }) {


    const { projectDetail } = useSelector(st => st.project);

    const {
        title, description, price, audience_size, photo_urls, year, location, duration, type
    } = projectDetail;

    let breakpoint = useBreakpoint();

    useEffect(() => {
        if (!projectDetail) handleClose();
    }, [projectDetail, handleClose]);

    return (
        <Modal
            visible={isVisible}
            className='p-0 project-popup'
            closable
            centered
            onCancel={handleClose}
            closeIcon={<AiOutlineCloseCircle size={20} color='#999' />}
            bodyStyle={{
                padding: 0,
                borderTopLeftRadius: 15,
                borderTopRightRadius: 15,
                background: 'rgba(0, 0, 0, 0.45)',
                overflow: 'hidden'
            }}
            destroyOnClose={true}
            width={breakpoint.xs ? '90%' : 450}
            footer={
                <div className='w-100 d-flex'>
                    <Button
                        shape='round' className="ml-auto"
                        onClick={handleClose} type='primary'
                    >
                        Close
                    </Button>
                </div>
            }
        >
            <div className='card border-bottom-0 bootstrap-card-round'>
                <Scrollbars
                    autoHide
                    autoHeight
                    autoHeightMin={600}
                    autoHeightMax='calc(100vh - 100px)'
                >
                    <div className='card-body'>
                        <h5 className="card-title mb-3">{title} </h5>
                        <p className="card-text">{description}</p>
                    </div>

                    <TwrCarousel showThumbnails={false} images={photo_urls} imageClass='card-img-top rounded-0' />

                    <div className='card-body rounded-0 pt-2 pb-3'>
                        <Row gutter={[8, 8]}>

                            {
                                type &&
                                <React.Fragment>
                                    <Col span={12}>
                                        <div className='project-meta-heading'>Type</div>
                                        <div>{type}</div>
                                    </Col>
                                </React.Fragment>
                            }

                            {
                                price &&
                                <React.Fragment>
                                    <Col span={12}>
                                        <div className='project-meta-heading'>Event Cost</div>
                                        <div>${price}</div>
                                    </Col>
                                </React.Fragment>
                            }

                            {
                                audience_size &&
                                <Col span={12}>
                                    <div className='project-meta-heading'>Audience Size</div>
                                    <div>{audience_size}</div>
                                </Col>
                            }
                            {
                                duration?.value ?
                                    <Col span={12}>
                                        <div className='project-meta-heading'>Duration</div>
                                        <div>{duration?.value} {duration?.unit}</div>
                                    </Col>
                                    :
                                    null
                            }
                            {
                                location ?
                                    <Col span={12}>
                                        <div className='project-meta-heading'>Location</div>
                                        <div>{location}</div>
                                    </Col>
                                    :
                                    null
                            }
                            {
                                year ?
                                    <Col span={12}>
                                        <div className='project-meta-heading'>Year</div>
                                        <div>{year}</div>
                                    </Col>
                                    :
                                    null
                            }

                        </Row>
                    </div>
                </Scrollbars>
            </div>
        </Modal >
    );
}
