export const getProductType = (type) => {
    switch (type) {
        case 'COLLECTING_ORDERS_TO_COOK':
            return 'Collecting orders';
        case 'PREPARED_ASAP':
            return 'Make To Order';
        case 'ALWAYS_READY':
            return 'Always Ready';
        case 'ON_DEMAND_COOKING':
            return 'On Demand';
        case 'EVENT_CATERING':
            return 'Event Catering';
        default:
            return '';
    }
};