import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { BrowserRouter } from "react-router-dom";

import {
    getUserDetailIfNeeded,
    getIpaddress,
    setUser
} from "../src/actions/user";
import { setApp as onSetAppState } from "../src/actions/app";
import { getCsrfToken } from './services/resource_services';
import { cancelRequest } from "./actions/common";
import ErrorComponent from './ErrorComponent';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Router from './routes';
import ScrollToTop from "./scroll";

class App extends Component {

    state = { hasError: false, error: "", stack_trace: "" };

    async componentDidMount() {

        const { onSetAppState, setUser } = this.props;
        onSetAppState({ isAppLoader: true });

        await getCsrfToken();

        try {
            let res = await fetch(`${window._env.REACT_APP_API_BASE_URL}/v1/user/basicInfo`, { credentials: 'include' });
            if (res.status === 200) {
                res = await res.json();
                let phone_number = res.phone_number;
                if (phone_number && phone_number.length > 0)
                    phone_number[0].number = phone_number[0].number.substring(2);
                setUser({
                    ...res,
                    isAuthenticated: true,
                    isLoading: false,
                    phone_number
                });
            } else {
                setUser({
                    isAuthenticated: false,
                });
            }
        } catch (err) {
            console.log(err);
        }

        onSetAppState({ isAppLoader: false });
        this.getInitDetail();
    }

    getInitDetail = async () => {

        const { user, getIpaddress } = this.props;

        await getIpaddress();

        if (user.scope === 'ADMIN')
            window.location.replace('/not_found');

    };

    componentDidCatch(err, info) {
        console.log(err, info);
        cancelRequest("due to error in component");
        this.setState({
            hasError: true,
            error: JSON.stringify({
                message: err.message ? err.message : "",
                stack: err.stack ? err.stack : ""
            }),
            stack_trace: JSON.stringify(info)
        });
    }

    render() {

        if (this.state.hasError) return <ErrorComponent err={this.state.error} info={this.state.stack_trace} />;

        return (
            <React.Fragment>
                <BrowserRouter>
                    <ScrollToTop />
                    <Router />
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </BrowserRouter>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ app, user, messages }) => ({
    app,
    user,
    messages
});

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators(
        {
            getUserDetailIfNeeded,
            onSetAppState,
            getIpaddress
        },
        dispatch
    ),
    setUser: (payload) => dispatch(setUser(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
