module.exports = {
    ZipCodeMessage: 'Please enter a valid 5 digit zip code.',
    SignupSuccess: "Congratulations, your account has been created. Please check the mail we sent you and verify your account ",
    LoginFail: 'Unable to login to your account. Invalid Login credentials',
    RegistrationSuccess: "Congratulations on completing your registration process. Let's explore Foodelys",
    RegistrationFail: "Unable to complete the registration process. Please verify that you have filled all the details correctly.",
    GenericError: 'Oops! Something went wrong. Please try again later',
    UserExistMessage: "An account alreadt exists with this mail address. Please use a different mail address to create an account",
    AgreementMessage: 'Please read and agree to all the terms and conditions before you continue',
    InvalidAddressMessage: 'We were unable to verify your address. Please make sure that your entered address is correct.',
    reCaptchaMessage: 'Please refresh your browser to continue',
    resrtictContractSkus: 'You have reached max no. of contracts. Please upgrade your plan to add more contracts.',
    invalidSubscriptionPlan: 'Invalid subscription plan. Please update with new subscription plan.',
    InvalidState: 'We are currently not serving in this zip-code area.'
};