import { Fetch } from "./common";

/**
 * Set contract data
 * @param {object} payload contract data
 * @returns {object} updated payload
 */
export const setCategory = (payload = {}) => ({
    type: "SET_CATEGORY",
    payload
});

export const getAllCategories = () => (dispatch, getState) => {

    const url = "/category/active";

    dispatch(setCategory({ isLoading: true }));
    return Fetch(url, "GET")
        .then(({ status, data = {} }) => {
            if (status === 200 || status === 201) {
                let { categories = [] } = data;
                dispatch(
                    setCategory({
                        isLoading: false,
                        categories: categories.map(e => e.name),
                        subCategories: categories.reduce((obj, e) => {
                            obj[e.name] = e.sub_categories.map(e => e.name);
                            return obj;
                        }, {}),
                        tag_suggestions: categories.reduce((obj, e) => {
                            obj[e.name] = e.tag_suggestions.map((e, i) => { return { value: e, key: i }; });
                            return obj;
                        }, {})
                    })
                );
                return data;
            }

            throw new Error(data.message);
        })
        .catch((error) => {
            console.log(error);
            dispatch(setCategory({ isLoading: false, categories: [], subCategories: [] }));
        });
};