import React, { Component } from "react";
import { Col, Tooltip, Switch } from 'antd';
import { AiOutlineEdit, AiOutlineCheckCircle, AiOutlineDelete } from "react-icons/ai";

class PickupLocationBlock extends Component {

    state = {
        isUpdateMode: false,
        updateType: "edit"
    };

    handleUpdate = () => {
        const { item, onUpdate } = this.props;
        onUpdate(item);
    };

    handleRemove = async () => {
        const { item, onRemove } = this.props;
        if (item._id) {
            const isRemove = window.confirm("Are you sure to remove this address ?");
            if (isRemove)
                await onRemove(item._id);
        }
    };

    enablePickupLocation = async (x) => {
        const { item, updatePickupLocation } = this.props;
        const {
            _id,
            street_1,
            street_2,
            city,
            state,
            postal_code,
            is_primary
        } = item;
        const pickupLocationPayload = {
            _id,
            street_1,
            street_2,
            city,
            state,
            postal_code,
            is_primary,
            is_verified: true,
            enable: x
        };
        await updatePickupLocation(pickupLocationPayload);
    };

    render() {
        const { item } = this.props;

        return (
            <Col className="address-item" md={7} sm={9} lg={7} xs={22}>
                {
                    item.is_primary &&
                    <AiOutlineCheckCircle size={20} />
                }
                <address>
                    <div>{item.street_1}</div>
                    <div>{item.street_2}</div>
                    <div>{item.city} {item.state} {item.postal_code}</div>
                </address>
                {
                    item._id &&
                    <div className="action">
                        <AiOutlineEdit onClick={this.handleUpdate} size={25} />
                        <AiOutlineDelete onClick={this.handleRemove} size={25} />
                        <Tooltip title="enable" onClick={(x) => this.enablePickupLocation(x)}>
                            <Switch size="small" checked={item.enable} />
                        </Tooltip>
                    </div>
                }
            </Col>
        );
    }
}

export default PickupLocationBlock;
