import React, { Component } from "react";
import { Row, Spin, Tooltip } from 'antd';
import EditPickupLocation from "./editPickupLocation";
import PickupLocationBlock from "./pickupLocationBlock";
import { HiOutlinePlus } from 'react-icons/hi';

const initialPickupLocationVal = {
    street_1: "",
    street_2: "",
    postal_code: "",
    city: "",
    state: "",
    country: "",
    is_primary: false
};



const MAX_PICKUP_LOCATIONS_ALLOWED = window._env.REACT_APP_MAX_PICKUP_LOCATIONS_ALLOWED;

class PickupLocation extends Component {

    state = {
        isUpdateMode: false,
        updateType: "edit",
        pickupLocationDetail: undefined
    };

    handleToggleMode = (isUpdateMode) => {
        this.setState({ isUpdateMode });
    };

    handleAdd = () => {
        this.handleAddUpdate(initialPickupLocationVal);
    };

    handleAddUpdate = (pickupLocationDetail) => {
        this.setState({
            isUpdateMode: true,
            pickupLocationDetail: { ...pickupLocationDetail }
        });
    };

    handleCancel = () => {
        this.setState({
            pickupLocationDetail: undefined
        });
        this.handleToggleMode(false);
    };

    render() {
        const {
            user = {},
            addPickupLocation,
            updatePickupLocation,
            removePickupLocation,
            updateUserDetail
        } = this.props;


        const { pickupLocation = [], isLoadingPickupLocation, scope } = user;
        const { isUpdateMode, pickupLocationDetail } = this.state;
        console.log("ss", MAX_PICKUP_LOCATIONS_ALLOWED);
        return (
            <div className="address">
                <div>
                    <div className="tab-header">
                        <h3 className="title">

                            <span>Pickup Locations</span>
                            <Tooltip title={pickupLocation.length >= MAX_PICKUP_LOCATIONS_ALLOWED && 'Limit Reached'}>
                                <HiOutlinePlus
                                    style={pickupLocation.length >= MAX_PICKUP_LOCATIONS_ALLOWED && { opacity: 0.5 }}
                                    onClick={pickupLocation.length < MAX_PICKUP_LOCATIONS_ALLOWED ? this.handleAdd : undefined}
                                    size={20} />
                            </Tooltip>
                        </h3>
                    </div>

                    <div className="tab-body">
                        <Spin spinning={isLoadingPickupLocation || user.isLoading}>
                            <Row className="address-list" type='flex' gutter={16}>
                                {
                                    Array.isArray(pickupLocation) &&
                                    pickupLocation.map((add, key) =>
                                        <PickupLocationBlock
                                            index={key}
                                            key={key}
                                            item={add}
                                            onUpdate={this.handleAddUpdate}
                                            onRemove={removePickupLocation}
                                            pickupLocationData={pickupLocation}
                                            updatePickupLocation={updatePickupLocation}
                                        />
                                    )
                                }
                            </Row>
                        </Spin>
                    </div>
                </div>
                <EditPickupLocation
                    updateUserDetail={updateUserDetail}
                    addPickupLocation={addPickupLocation}
                    updatePickupLocation={updatePickupLocation}
                    onClose={this.handleCancel}
                    isVisible={isUpdateMode}
                    isLoading={isLoadingPickupLocation || user.isLoading}
                    initialValues={pickupLocationDetail}
                    userscope={scope}

                />
            </div>
        );
    }
}

export default PickupLocation;
