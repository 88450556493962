import { Fetch } from "./common";
import { initialize } from "redux-form";
import { toast } from 'react-toastify';

export const SetManualState = (payload = {}) => ({
    type: "MANUAL_DATA",
    payload,
});

export const setManualForm = (payload = {}) => ({
    type: "SET_Manual_FORM",
    payload,
});

// get Manual Data
export const getManualData = () => (dispatch) => {
    const url = `/manual/manualdata`;
    dispatch(SetManualState({ isManualLoading: true }));
    return Fetch(url, "GET")
        .then(({ status, data = {} }) => {
            if (status === 200) {
                dispatch(SetManualState({ isManualLoading: false, manualData: data }));
                return data;
            }

            throw new Error(data.message);
        })
        .catch((error) => {
            dispatch(SetManualState({ isManualLoading: false, manualData: [] }));
        });
};

// add manual by categories
export const addManualByCategories = (params = {}) => (dispatch) => {
    const options = {
        data: JSON.stringify(params),
    };
    const url = `/manual/addmanual`;
    dispatch(SetManualState({ isManualLoading: true }));
    return Fetch(url, "POST", options)
        .then(({ status, data = {} }) => {
            if (status === 201) {
                dispatch(SetManualState({ isManualLoading: false, formValue: {} }));
                toast.success("Manual Successfully Added");
                return data;
            }
            dispatch(initialize("manual", {}));
            throw new Error(data.message);
        })
        .catch((error) => {
            dispatch(SetManualState({ isManualLoading: false, manualData: [] }));
        });
};

// get manual by Id
export const getManualById = (params) => (dispatch) => {
    const options = {
        data: JSON.stringify(params),
    };
    const url = `/manual/getmanualbyid/`;
    dispatch(SetManualState({ isManualLoading: true }));
    return Fetch(url, "POST", options)
        .then(({ status, data = {} }) => {
            if (status === 200) {
                dispatch(
                    SetManualState({
                        isManualLoading: false,
                        formValue: {},
                        subManualData: data,
                    })
                );
                return data;
            }
            dispatch(initialize("manual", {}));
            throw new Error(data.message);
        })
        .catch((error) => {
            dispatch(SetManualState({ isManualLoading: false, manualData: [] }));
        });
};

// get Edit Manual
export const getEditManual = (params) => (dispatch) => {
    dispatch(initialize("manual", params));
};

// update Module
export const updateManualData = (params = {}, id) => (dispatch) => {
    const options = {
        data: JSON.stringify(params),
    };
    const url = `/manual/updatemanual/${id}/`;
    dispatch(SetManualState({ isManualLoading: true }));
    return Fetch(url, "PATCH", options)
        .then(({ status, data = {} }) => {
            if (status === 201) {
                toast.success("Manual successfully Updated");
                dispatch(SetManualState({ isManualLoading: false }));
                return data;
            }
            dispatch(initialize("manual", {}));

            throw new Error(data.message);
        })
        .catch((error) => {
            dispatch(SetManualState({ isManualLoading: false, manualData: [] }));
            throw Error("Something went wrong, please try again.");
        });
};

// get manual data by name
export const getManualDataByName = (manualName) => (dispatch) => {
    const url = `/manual/manualbyname/${manualName}`;
    dispatch(SetManualState({ isManualLoading: true }));
    return Fetch(url, "GET")
        .then(({ status, data = {} }) => {
            if (status === 200) {
                dispatch(SetManualState({ isManualLoading: false, manualData: data }));
                return data;
            }

            throw new Error(data.message);
        })
        .catch((error) => {
            dispatch(SetManualState({ isManualLoading: false, manualData: [] }));
        });
};
