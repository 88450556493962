import React from 'react';
import { Row, Col } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

import './style.scss';
import welcome from '../../images/aboutus/about-02.svg';

export default function Section2() {

    const breakpoint = useBreakpoint();

    return (
        <React.Fragment>
            <Row type="flex" justify="center" align="middle" className="mt-5"
                id='buy_services_online'>

                <Col xs={20} lg={11} xl={{ span: 8, offset: 1 }} xxl={{ span: 7, offset: 3 }} className='text-center'>
                    <img className="img img-fluid"
                        src={welcome} alt="Welcome To Foodelys" />
                </Col>

                <Col xs={24} lg={{ span: 12, offset: 1 }} xl={{ span: 13, offset: 1 }} xxl={{ span: 9, offset: 2 }} className="p-3">
                    <h2 className="section-heading">
                        Our mission
                    </h2>
                    <div className={breakpoint.lg ? "section-content mt-4" : "section-content-mobile mt-3"}>
                    Foodelys' mission is to make homemade, custom-cooked food accessible to everyone through its tech-based platform. Furthermore, we intend to bridge the gap between Cooks-on-Demand, cloud kitchens, chefs/caterers/bakers, and people craving genuine, ethnic food.
                    </div>

                </Col>

                <Col xs={0} xl={1} xxl={3} />
            </Row>
        </React.Fragment>

    );
}