import { toast } from 'react-toastify';
import { Fetch, getErrorMessage } from "./common";

/**
 * Set service data
 * @param {object} payload service data
 * @returns {object} updated payload
 */
export const setProjects = (payload = {}) => ({
    type: "SET_PROJECTS",
    payload
});

export const setProjectLoading = (payload) => ({
    type: "SET_PROJECT_LOADING",
    payload
});

/**
 * Set service data
 * @param {object} payload service data
 * @returns {object} updated payload
 */
export const setProjectDetail = (payload = {}) => ({
    type: "SET_PROJECT_DETAILS",
    payload
});


/**
 * Create product
 * @param {object} serviceData service object
 * @returns {{<Promise>}} promise
 */
export const createProject = (productData = {}) => (dispatch) => {

    const url = "/project";
    const options = {
        data: JSON.stringify({
            ...productData
        })
    };

    dispatch(setProjectLoading(true));
    return Fetch(url, "POST", options)
        .then(({ status, data = {} }) => {
            if (status === 201) {
                toast.success("Event Created Successfully");
                return data;
            }
            throw new Error(data.message);
        })
        .catch((error) => {
            console.log(error);
            toast.error('Some error occurred while creating the event');
        })
        .finally(() => {
            dispatch(setProjectLoading(false));
        });

};

/**
 * Get product
 * @returns {{<Promise>}} promise
 */
export const getProject = (projectId) => (dispatch, getState) => {
    if (!projectId) {
        return true;
    }

    const url = `/project?id=${projectId}`;

    dispatch(setProjectLoading(true));
    return Fetch(url, "GET").then(({ status, data = {} }) => {
        if (status === 200 || status === 201) {
            dispatch(setProjectLoading(false));
            if (Array.isArray(data?.data) && data.data.length){
                dispatch(setProjectDetail(data.data));
                return data.data[0];
            }
            return data;
        }

        dispatch(setProjectLoading(false));
        throw new Error(data.message);
    }).catch((error) => {
        dispatch(setProjectLoading(false));
        dispatch(setProjectDetail(null));
    });
};

export const getAllProjects = (status = '', skip = 0, limit = 5) => (dispatch, getState) => {

    let params = {
        status, skip, limit
    };

    const url = '/project';
    dispatch(setProjectLoading(true));
    return Fetch(url, "GET", { params })
        .then(({ status, data = {} }) => {
            if (status === 200 || status === 201) {
                dispatch(setProjectLoading(false));
                dispatch(setProjects(data));
                return data;
            }

            dispatch(setProjectLoading(false));
            throw new Error(data.message);
        }).catch((error) => {
            dispatch(setProjectLoading(false));
            dispatch(setProjects([]));
        });
};

export const removeProject = (projectId) => (dispatch) => {

    if (!projectId) {
        toast.error("Invalid project id");
        return false;
    }

    const url = `/project/${projectId}`;
    dispatch(setProjectLoading(true));
    return Fetch(url, "DELETE").then(({ status, data = {} }) => {
        if (status === 200 || status === 201) {
            dispatch(getAllProjects());
            if (data.message) toast.success(data.message);
            return data;
        }

        throw new Error(data.message);
    }).catch((error) => {
        const message = getErrorMessage(error);
        if (message) {
            toast.error(message);
        }
        dispatch(setProjectLoading(false));
    });
};

/**
 * Update product
 * @param {object} product updated product
 * @returns {{<Promise>}} promise
 */
export const updateProject = (project) => (dispatch, getState) => {
    if (!project || !project._id) {
        return true;
    }

    const url = `/project`;
    const options = {
        data: JSON.stringify(project)
    };

    dispatch(setProjectLoading(true));
    return Fetch(url, "PATCH", options)
        .then(({ status, data = {} }) => {
            if (status === 200) {
                toast.success(data.message);
                return data.service;
            }
            throw new Error(data.message);

        })
        .catch((error) => {
            console.log(error);
            toast.error('Some error occurred while creating the product');
        })
        .finally(() => {
            dispatch(setProjectLoading(false));
        });
};

export const checkUserProjectLimit = () => (dispatch, getState) => {

    const url = `/project/limit_check`;
    dispatch(setProjectLoading(true));
    return Fetch(url, "GET").then(({ status, data = {} }) => {
        if (!data.isUserAllow) {
            toast.error(data.message);
        } else {
            toast.success(data.message);
        }
        dispatch(setProjectLoading(true));
        return data;

    }).catch((error) => {
        toast.error(error.message);
        dispatch(setProjectLoading(true));
    });
};

