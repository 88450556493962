const initialState = {
    isLoading: false,
    isLoadingAddress: false,
    isLoadingProfile: false,
    formValue: {},
    preferences: {},
    isAuthenticated: false,
    isLoadingPaymentOption: false,
    isLoadingPickupLocation: false
};

const user = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case "SET_USER":
            return { ...state, ...payload };

        case "LOGOUT":
            return initialState;

        case "SET_USER_NOTIFICATION":
            const preferences = { ...state.preferences, ...payload };
            return { ...state, preferences };

        case "RESET_SIGN_UP_PRO_FORM":
            return { ...state, formValue: {} };

        case "RESET_SIGN_UP_SEEKER_FORM":
            return { ...state, formValue: {} };

        case "INCREMENT_JOB_TICKET_COUNTER":
            return { ...state, job_ticket_counter: { ...state.job_ticket_counter, count: state.job_ticket_counter.count + 1 } };

        default:
            return state;
    }
};

export default user;
