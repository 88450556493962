import React from "react";
import Section1 from "./section1";
import Section2 from "./section2";
import Section3 from "./section3";
import Section4 from "./section4";
import Section5 from "./section5";
import "./style.scss";

const AboutUs = (props) => {
    
    const { page_visit } = props;

    page_visit('/ourVision');

    return (
        <React.Fragment>
            <Section1 />
            <Section2 />
            <Section3 />
            <Section4 />
            <Section5 />
        </React.Fragment>
    );
};

export default AboutUs;
