const initialState = {
    isLoading: false,
    currentTask: null,
    reviewAndRating: null
};

const reviewAndRating = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case "SET_REVIEW_AND_RATING":
            return {
                ...state,
                ...payload
            };
        default:
            return state;
    }
};

export default reviewAndRating;