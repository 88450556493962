import React from 'react';
import { Row, Col } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

import './style.scss';

import welcome from '../images/landingPage/desktop/Source-04.svg';
import CloudImg from '../images/landingPage/desktop/Source-15.svg';
import welcome_mobile from '../images/landingPage/mobile/Source-07.svg';
import CloudImg_mobile from '../images/landingPage/mobile/Source-08.svg';
export default function Section2() {

    const breakpoint = useBreakpoint();

    return (
        <Row type="flex" justify="center" align="middle" className='position-relative section-top-margin'>

            <img className="d-lg-none position-absolute" style={{width: '18%', top: 0, right: 0}}
                src={CloudImg_mobile} alt="Welcome To Foodelys" />

            <Col xs={0} lg={{span: 3, order: 0}} xl={{span: 3, order: 0}} xxl={{span: 2, order: 0}} style={{ alignSelf: 'flex-start' }}>
                <img className="img img-fluid d-none d-lg-inline"
                    src={CloudImg} alt="Welcome To Foodelys" />
            </Col>

            <Col xs={{ span: 24, order: 1 }} lg={{ span: 10, order: 1 }} xl={10} xxl={{span: 8, offset: 2}} className="p-3">
                <h2 className="section-heading">
                    Cater-ing to all Your needs!
                </h2>
                <div className={breakpoint.md ? "section-content mt-4" : "section-content-mobile mt-3"}>
                   Good food is the life of any gathering. Big parties, events, or family dinner, 
                   trust us with your catering needs and spend time with your loved ones hassle free!
                </div>

            </Col>
            <Col className='text-center'
                xs={{ span: 16, order: 0 }} lg={{ span: 10, offset: 1, order: 2 }} xl={{ span: 9, offset: 1 }} xxl={{ span: 6, offset: 2 }}
            >
                <img className="img img-fluid"
                    src={breakpoint.lg ? welcome : welcome_mobile} alt="Welcome To Foodelys" />
            </Col>
            <Col xs={0} xl={{span: 1, order: 3}} xxl={4}></Col>
        </Row>
    );
}