import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { page_visit } from "../../actions/analytics";
import Section1 from "./section1";
import Section2 from "./section2";
import Section3 from './section3';
import Section4 from './section4';
import Section5 from './section5';
import "./style.scss";

const HowItWorksForSeeker = (props) => {
    
    let dispatch = useDispatch();

    useEffect(()=>{
        dispatch(page_visit('/how-it-works-for-seeker'));
    }, [dispatch]);

    return (
        <div style={{ overflowX: 'hidden' }}>
            <Section1 />
            <Section2 />
            <Section3 />
            <Section4 />
            <Section5 />
        </div>
    );
};

export default HowItWorksForSeeker;
