import React, { useState, useEffect, useCallback } from "react";
import { Button, Popconfirm, Tooltip, Switch, Col, Row } from "antd";
import { useHistory } from "react-router-dom";
import { ServiceActionsRule } from "../../../constants/commonContext";
import { AiOutlineEye, AiOutlineEdit, AiOutlineDelete, AiOutlineCloudUpload, AiOutlineCloudDownload } from 'react-icons/ai';
import ProductPopup from "../../productPopup";
import { setProduct, updateProduct } from "../../../actions/product";
import { useDispatch, useSelector } from "react-redux";
import ProductCard from "../../../common/productCard";
import { TelegramIcon, TelegramShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
import InventoryModal from './inventoryModal';
import { createInventory, updateInventory, getInventoryByProductId } from "../../../actions/inventory";
import { toast } from 'react-toastify';

function ProductItem({
    refresh,
    productDetail
}) {

    async function updateStatus(status) {
        if (status === "UNPUBLISHED" && productDetail.accepting_orders)
            acceptingOrder(false);
        let product = { _id: productDetail._id, status, isStatusUpdate: true };
        await updateProduct(product)(dispatch);
        await refresh();
    }

    async function updateStockProduct() {
        let product = { _id: productDetail._id, accepting_orders: true };
        await updateProduct(product)(dispatch);
        await refresh();
    }

    async function acceptingOrder(status) {
        if(status && productDetail.status !== 'PUBLISHED') return toast.error('Please publish your product to accept orders');
        if (status && productDetail?.stock_mgnt_required) setVisible(true);
        let product = { _id: productDetail._id, accepting_orders: status };
        if (productDetail?.stock_mgnt_required && status) return;
        await updateProduct(product)(dispatch);
        await refresh();
    }

    const text = 'Are you sure you want to delete this product?';
    const dispatch = useDispatch();
    const [showProduct, setShowProduct] = useState(false);
    const [visible, setVisible] = useState(false);
    const [inventoryItem, setInventoryItem] = useState({
        _id: null,
        inStock: 0,
        productId: null
    });
    const history = useHistory();
    const { display_name, store_name, store_hours } = useSelector(st => st.user);
    const { isLoading } = useSelector(st => st.inventory);

    const fetchInventory = useCallback(async () => {
        const res = await getInventoryByProductId(productDetail._id)(dispatch);
        if(res.inventory)
            if (res.inventory) setInventoryItem(res.inventory);

    }, [productDetail, dispatch]);


    useEffect(() => {
        if (!visible) fetchInventory();
    }, [fetchInventory, visible]);

    const Action = () => (
        <Row gutter={[8, 12]} className='mt-auto'>
            <Col span={6} className='text-center'>
                <Tooltip title='View'>
                    <Button
                        icon={<AiOutlineEye size={20} />}
                        disabled={ServiceActionsRule[productDetail.status]["View"]}
                        shape='circle'
                        onClick={() => {
                            dispatch(setProduct({ productDetail: productDetail }));
                            setShowProduct(true);
                        }}
                    />
                </Tooltip>
            </Col>
            <Col span={6} className='text-center'>
                <Tooltip title='Edit this food-item'>
                    <Button
                        onClick={() => history.push(`/seller/product/${productDetail._id}`)}
                        icon={<AiOutlineEdit size={20} />}
                        disabled={ServiceActionsRule[productDetail.status]["Edit"]}
                        shape='circle'
                    />
                </Tooltip>
            </Col>
            <Col span={6} className='text-center'>
                <Tooltip title='Click this to make the food item visible to the consumer on Foodelys platform'>
                    <Button
                        icon={<AiOutlineCloudUpload size={20} />}
                        onClick={() => updateStatus("PUBLISHED")}
                        disabled={ServiceActionsRule[productDetail.status]["Publish"]}
                        shape='circle'
                    />
                </Tooltip>
            </Col>
            <Col span={6} className='text-center'>
                <Tooltip title='Click this to hide the food item from the consumer on Foodelys platform'>
                    <Button
                        icon={<AiOutlineCloudDownload size={20} />}
                        onClick={() => updateStatus("UNPUBLISHED")}
                        disabled={ServiceActionsRule[productDetail.status]["Unpublish"]}
                        shape='circle'
                    />
                </Tooltip>
            </Col>
            <Col span={6} className='text-center'>
                <Tooltip title='Delete'>
                    <Popconfirm
                        placement="top"
                        title={text}
                        onConfirm={() => updateStatus("DELETED")}
                        okText="Yes"
                        cancelText="No"
                        disabled={ServiceActionsRule[productDetail.status]["Delete"]}
                        className="delete"
                    >
                        <Button
                            icon={<AiOutlineDelete size={20} />}
                            disabled={ServiceActionsRule[productDetail.status]["Delete"]}
                            shape='circle'
                            style={ServiceActionsRule[productDetail.status]["Delete"] ? { width: 'inherit' } : {}}
                        />
                    </Popconfirm>
                </Tooltip>
            </Col>
            <Col span={6} className='text-center'>
                <Tooltip title={'Select this when you are accepting Orders for this particular food item'}>
                    <Switch
                        defaultChecked={productDetail.accepting_orders}
                        size='small'
                        onChange={(checked) => acceptingOrder(checked)}
                        disabled={productDetail.status !== 'PUBLISHED'}
                    />
                </Tooltip>
            </Col>
            <Col span={6} className='text-center'>
                <Tooltip title='Share your menu with your friends on WhatsApp'>
                    <WhatsappShareButton
                        title={`Howdy Friends,\n\nCheck out my menus at my Foodelys store.\n\nWarmly,\n${store_name}\n`} url={`${window._env.REACT_APP_HOMEPAGE_URL}/store/${display_name}`}
                    >
                        <WhatsappIcon size={28} round={true} />
                    </WhatsappShareButton>
                </Tooltip>
            </Col>
            <Col span={6} className='text-center'>
                <Tooltip title='Share your menu with your friends on Telegram app'>
                    <TelegramShareButton
                        title={`Howdy Friends,\n\nCheck out my menus at my Foodelys store.\n\nWarmly,\n${store_name}`} url={`${window._env.REACT_APP_HOMEPAGE_URL}/store/${display_name}`}
                    >
                        <TelegramIcon size={28} round={true} />
                    </TelegramShareButton>
                </Tooltip>
            </Col>
        </Row>
    );

    return (
        <React.Fragment>
            <ProductCard productDetail={productDetail} Action={Action} />
            <ProductPopup handleClose={() => setShowProduct(false)} isVisible={showProduct} store_hours={store_hours} />
            <InventoryModal
                visible={visible}
                createInventory={createInventory}
                updateInventory={updateInventory}
                getInventory={getInventoryByProductId}
                setVisible={setVisible}
                inventoryItem={inventoryItem}
                refresh={refresh}
                item={productDetail}
                isLoading={isLoading}
                updateStockProduct={updateStockProduct}
                moq = {productDetail.moq || 1}
            />
        </React.Fragment>
    );

}

export default ProductItem;
