import React from 'react';
import './style.scss';
import { Row, Col } from 'antd';
import image1 from '../../images/aboutus/card-01.svg';
import image2 from '../../images/aboutus/card-02.svg';
import image3 from '../../images/aboutus/card-03.svg';
import image4 from '../../images/aboutus/card-04.svg';

export default function Section5() {

    const data = [
        {
            img: image1,
            title: 'No more yearning for homemade style meals',
            description: 'Choose from a variety of chefs, caterers and bakers serving authentic flavors of your homeland'
        },
        {
            img: image2,
            title: 'Catering needs, fulﬁlled!',
            description: 'Catering for small or large gatherings done at the convenience of a click'
        },
        {
            img: image3,
            title: 'Get customers for your grocery/dairy farm business',
            description: 'Get amazing reach with Foodelys and sell your grocery, dairy farm items at your comfort and schedule.'
        },
        {
            img: image4,
            title: 'Start your food business',
            description: 'Become an entrepreneur with zero investment for the marketplace to list items, find consumers, and ordering systems to start your business.'
        }
    ];

    return (
        <>
            <div className="top-wave mt-5">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 100" preserveAspectRatio="none">
                    <path className="wavePath-haxJK1"
                        d="M826.337463,25.5396311 C670.970254,58.655965 603.696181,68.7870267 447.802481,35.1443383 C293.342778,1.81111414 137.33377,1.81111414 0,1.81111414 L0,150 L1920,150 L1920,1.81111414 C1739.53523,-16.6853983 1679.86404,73.1607868 1389.7826,37.4859505 C1099.70117,1.81111414 981.704672,-7.57670281 826.337463,25.5396311 Z"
                        fill="currentColor">
                    </path>
                </svg>
            </div>
            <Row className="d-none d-lg-flex"
                id='buy_services_online' style={{ backgroundColor: 'rgb(249 216 222)' }}>

                <Col xs={24} md={{ span: 20, offset: 2 }} xl={{ span: 16, offset: 4 }} xxl={{ span: 12, offset: 6 }}>
                    <h1 className="text-center my-5 section-heading">Why  Choose Foodelys?</h1>
                    {
                        data.map((x, i) => {
                            return (
                                <Row align='middle' key={i} style={{ backgroundColor: "#fff", borderRadius: 10 }} className='my-4'>
                                    <Col lg={8}>
                                        <img src={x.img} alt="icon" />
                                    </Col>
                                    <Col lg={16}>
                                        <h4 className="section-heading-2" style={{ color: '#c1395f' }}>{x.title}</h4>
                                        <div className='section-content-2'>{x.description}</div>
                                    </Col>
                                </Row>
                            );
                        })
                    }
                </Col>
            </Row>
            <Row id='buy_services_online' className="d-lg-none d-md-flex" style={{ backgroundColor: 'rgb(249 216 222)' }}>

                <Col xs={24} className="p-3 d-flex flex-column" >
                    <h4 className="section-heading my-5 text-center">
                        Why  Choose Foodelys?
                    </h4>
                    {data.map((x, i) => {
                        return (
                            <Row align='middle' key={i} style={{ backgroundColor: "#fff", padding: 10, margin: '20px 5px', borderRadius: 10 }}>
                                <Col xs={8}>
                                    <img src={x.img} alt="icon" />
                                </Col>
                                <Col xs={16}>
                                    <h6 className="mb-2" style={{ color: '#c1395f' }}><strong>{x.title}</strong></h6>
                                    <span style={{ fontSize: 14, fontWeight: 500 }}>{x.description}</span>
                                </Col>
                            </Row>
                        );
                    })
                    }
                </Col>
            </Row>
        </>
    );

}
