import React from 'react';
import './style.scss';
import { Row, Col } from 'antd';
import welcome from '../images/landingPage/desktop/Source-03.svg';
import welcome_mobile from '../images/landingPage/mobile/Source-06.svg';
import cloud from '../images/landingPage/mobile/Source-10.svg';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

export default function Section1() {

    const breakpoint = useBreakpoint();

    return (
        <Row type="flex" justify="center" align="middle"
            id='buy_services_online' className='position-relative'>

            <img className="d-lg-none position-absolute" style={{ width: '8.33333%', top: 0, left: 0 }} src={cloud} alt="" />

            <Col xs={20} lg={11} xl={{ span: 8, offset: 1 }} xxl={{ span: 6, offset: 4 }} className='text-center'>
                <img className="img img-fluid"
                    src={breakpoint.lg ? welcome : welcome_mobile} alt="Welcome To Foodelys" />
            </Col>

            <Col xs={24} lg={{ span: 12, offset: 1 }} xl={{ span: 13, offset: 1 }} xxl={{ span: 8, offset: 2 }} className="p-3">
                <h2 className="section-heading">
                    Deliciously made with love!
                </h2>
                <div className={breakpoint.md ? "section-content mt-4" : "section-content-mobile mt-3"}>
                    When nostalgia overwhelms us with memories, we all crave the authentic flavors 
                    of the food we grew up with. Whatever your ethnicity is, find homemade style 
                    food for every mood curated just for you by our food-partners!
                </div>

            </Col>

            <Col xs={0} xl={1} xxl={4}></Col>

        </Row>
    );
}