import React, { useCallback, useEffect, useState } from 'react';
import { Spin, Row, Col, Divider, Tooltip } from 'antd';
import { load } from "recaptcha-v3";

import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setProduct } from '../../actions/product';
import ProductPopup from '../productPopup';
import { event } from '../../actions/analytics';
import { getProductType } from '../../common/productCard/productType';
import { incProductViews } from '../../actions/metrics';
import { AiOutlineFundView } from 'react-icons/ai';

const recaptchaEnabled = JSON.parse(window._env.REACT_APP_CAPTCHAV3_ENABLED);

export default function ProductTab({ loading, products, Tag, metrics, storeOpen, store_hours }) {

    const [captcha, setCaptcha] = useState(null);
    const [popupVisible, setPopupVisible] = useState(false);
    const { scope } = useSelector(st => st.user);
    let dispatch = useDispatch();

    const getCaptchaToken = useCallback(async () => {
        if (captcha) {
            let token = await captcha.execute('login');
            return token;
        } else {
            throw Error('Please wait for captcha to load');
        }
    }, [captcha]);

    useEffect(() => {
        async function loadCaptchaScript() {
            if (window._env.REACT_APP_CAPTCHAV3_SITE_KEY) {
                const _captcha = await load(window._env.REACT_APP_CAPTCHAV3_SITE_KEY);
                setCaptcha(_captcha);
            }
        }
        loadCaptchaScript();
    }, []);

    async function inc(id) {
        try {
            let token = '';
            if (recaptchaEnabled) {
                token = await getCaptchaToken();
            }
            const { recaptchaIssue } = await incProductViews({ id, RecaptchaToken: token });
            if (recaptchaIssue) {
                inc();
            } else {
            }
        } catch (err) {
        }
    }

    if (loading)
        return (
            <div
                style={{ height: 500 }}
                className='w-100 d-flex justify-content-center align-items-center'
            >
                <Spin size='large'></Spin>
            </div>
        );

    return (

        <div>
            {
                Array.isArray(products) && products.length ?
                    <div className='d-flex flex-column'>
                        {
                            loading ?
                                <Spin className='m-auto' size='large'></Spin>
                                :
                                <div>
                                    <Row gutter={[16, 16]}>
                                        {
                                            products.map((e, i) => (
                                                <Col xs={24} lg={12}
                                                    onClick={() => {
                                                        dispatch(event('Product', 'view', e._id));
                                                        dispatch(setProduct({ productDetail: e }));
                                                        setPopupVisible(true);
                                                        if (scope !== 'PROVIDER')
                                                            inc(e._id);
                                                    }}
                                                    className='pointer pro-profile-card' key={i}
                                                >
                                                    <Row data-id='body' type="flex" align="stretch" justify="space-between" className="border rounded">
                                                        <Col xs={24} md={9} className='text-center'>
                                                            {
                                                                Array.isArray(e.photo_urls) && e.photo_urls.length > 0 &&
                                                                <img src={window._env.REACT_APP_PUBLIC_URL_HOST + e.photo_urls[0].url}
                                                                    alt="Product main img" className='img-fluid rounded-left'
                                                                />
                                                            }
                                                        </Col>
                                                        <Col xs={24} md={15} className='d-flex flex-column px-2 py-1 mt-3 mt-md-0'>

                                                            <h6 className="card-title mb-0">
                                                                {e.title}
                                                                <span className='text-muted' style={{ fontSize: 12 }}>&nbsp;(${e.price.unit_price})</span>
                                                            </h6>

                                                            {
                                                                e.description
                                                                    ?
                                                                    <p className="card-title my-2">
                                                                        {e.description.substr(0, 80)}{e.description.length > 80 ? '...' : ''}
                                                                    </p>
                                                                    :
                                                                    null
                                                            }

                                                            <div className='d-flex mt-auto'>
                                                                {
                                                                    !e.accepting_orders
                                                                        ?
                                                                        <div className='text-warning mb-2'>Currently not accepting order</div>
                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    metrics?.find(m => m.product_id === e._id)?.views
                                                                        ?
                                                                        <Tooltip title={`${metrics?.find(m => m.product_id === e._id)?.views} product views this month`}>
                                                                            <span className='ml-auto'>
                                                                                <AiOutlineFundView size={20} color='#15c39a' />
                                                                            </span>
                                                                        </Tooltip>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                            <Divider className='mb-2 mt-auto' />


                                                            <div style={{ fontSize: 14 }} className='d-flex flex-row'>
                                                                {
                                                                    e.serves?.max ?
                                                                        <React.Fragment>
                                                                            Serves {e.serves.max}
                                                                            <br />
                                                                        </React.Fragment>
                                                                        :
                                                                        null
                                                                }
                                                                <Tag e={getProductType(e.ordering_restriction?.type)} className='ml-auto' />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                </div>
                        }
                    </div>
                    :
                    null
            }

            <ProductPopup isVisible={popupVisible} handleClose={() => setPopupVisible(false)} allowOrder={storeOpen} store_hours={store_hours} />
        </div>
    );
}