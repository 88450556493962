import React, { Component } from "react";
import { Form, Row, Col, Button, Checkbox, Spin, Table } from 'antd';
import { AiOutlineEdit } from 'react-icons/ai';
import { MdSms, MdCheck, MdClose } from 'react-icons/md';
import { FiMail } from 'react-icons/fi';

const NotificationStatus = ({
    isUpdateMode,
    onSelect,
    name,
    isPreferred,
    type
}) => {

    const handleClick = (e) => {
        const isCheck = e.target.checked;
        onSelect({ name, type, is_preferred: isCheck });
    };

    const iconName = isPreferred ? "check" : "close";

    return isUpdateMode ?
        <Checkbox onChange={handleClick} checked={isPreferred} />
        :
        iconName === "check" ? <MdCheck /> : <MdClose />;
};

class Notifications extends Component {

    state = {
        isUpdateMode: false,
        error: null
    };

    handleSubmit = async () => {
        const { updateNotification } = this.props;
        try {
            await updateNotification();
            this.handleCancel();
        } catch (error) {
            this.setState({ error: error.message });
        }
    };

    handleSelect = (data) => {
        const { setNotification, user: {phone_number} } = this.props;
        if(phone_number && !phone_number[0].number.trim()){
            this.setState({error: "Please add contact number before enable the text notification."});
            return false;
        }
        if (!this.state.error) {
            this.setState({ error: null });
        }
        setNotification(data);
    };

    handleToggleMode = (isUpdateMode) => {
        this.setState({ isUpdateMode });
    };

    handleUpdate = () => {
        this.handleToggleMode(true);
        this.setState({ error: null });
    };

    handleCancel = () => {
        this.handleToggleMode(false);
    };

    render() {
        const { user = {} } = this.props;
        const {
            preferences: {
                notification = []
            } = {},
            isLoading,
            isLoadingProfile
        } = user;
        const { isUpdateMode, error } = this.state;

        const columns = [
            {
                title : "Type",
                dataIndex: 'label',
                key: 'label'
            },
            {
                title: <><FiMail size={16} className="icon"/> Email</>,
                render : (text, record, index)=>(
                    <NotificationStatus isPreferred={record.method[0].is_preferred} 
                        type={record.method[0].type} 
                        {...{isUpdateMode}} onSelect={this.handleSelect} name={record.name}/>
                ),
                key: 'email'
            },
            {
                title: <><MdSms size={16} className="icon"/> Text</>,
                render : (text, record, index)=>(
                    <NotificationStatus isPreferred={record.method[1].is_preferred} 
                        type={record.method[1].type} 
                        {...{isUpdateMode}} onSelect={this.handleSelect} name={record.name}/>
                ),
                key: 'text'
            },
        ];

        return (
            <div className="notifications">
                <Form onSubmit={this.handleSubmit} >
                    <Row type="flex" className="tab-header" justify="space-between">
                        <Col span={20}>
                            <h3 className="title">
                                Notifications
                            </h3>
                        </Col>
                        <Col span={2}>
                            <AiOutlineEdit onClick={this.handleUpdate} size={20}/>
                        </Col>
                    </Row>

                    <Row type='flex' justify='center' className='tab-body'>
                        <Col span={24} lg={22}>
                            <Spin spinning={isLoading || isLoadingProfile}>

                                <Table columns={columns} dataSource={notification} size="middle" pagination={false} />
                                {/* <Table striped bordered hover>

                                    <thead>
                                        <tr>
                                            <th>Type</th>
                                            <th><FiMail /> Email</th>
                                            <th><MdSms /> Text</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {
                                            Array.isArray(notification) &&
                                            notification.map((note, key) =>
                                                <tr key={key}>
                                                    <td className="name">
                                                        <span>{note.label}</span>
                                                    </td>
                                                    <NotificationMethod
                                                        method={note.method}
                                                        props={{
                                                            name: note.name,
                                                            isUpdateMode: isUpdateMode,
                                                            onSelect: this.handleSelect
                                                        }}
                                                    />
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table> */}
                            </Spin>
                            {
                                (error && isUpdateMode) &&
                                <div className="form-error">{error}</div>
                            }
                            {
                                isUpdateMode &&
                                <div className="form-action">
                                    <Button shape='round'
                                        onClick={this.handleCancel}
                                        className="btn-default"
                                        size={'large'}
                                        disabled={isLoadingProfile}
                                    >Cancel</Button>
                                    <Button shape='round'
                                        onClick={this.handleSubmit}
                                        className="btn-primary btn-submit"
                                        type="primary"
                                        size={'large'}
                                        loading={isLoadingProfile}
                                    >Update</Button>
                                </div>
                            }
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}

Notifications.defaultProps = {
    form: {},
    onSubmit: (values) => values
};
export default Notifications;
