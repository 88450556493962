import React, { useEffect, useState } from "react";
import { Spin } from "antd";

import OrderDetail from "./orderDetail";
import PaymentSuccess from "./paymentSuccess";
import "./styles.scss";
import { toast } from 'react-toastify';
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import { getProductV2 } from "../../../actions/product";
import { decode } from "../../../common/order_utilities";
import { useHistory } from "react-router";
import { getAddons } from "../../../actions/addons";

export default function Checkout() {

    const [currentStep, setCurrentStep] = useState(0);
    const [addons, setAddons] = useState([]);
    const [order, setOrder] = useState({});
    const [loading, setLoading] = useState(false);
    const [disable, setDisable] = useState(true);

    const [cookie] = useCookies(['order']);
    let dispatch = useDispatch();
    let history = useHistory();

    const fetchData = async () => {
        try {

            if (!cookie.order) return;

            setLoading(true);
            let order = decode(cookie.order);
            setOrder(order);

            let products = order.lineItems.map(e => e.uid);

            products = await getProductV2({ UIDs: JSON.stringify(products), includeSellerInfo: true })(dispatch);

            if (!products) return;

            let addons = await getAddons(order.seller)(dispatch);

            setAddons(addons);

            let productMap = new Map();

            products.forEach((e, i) => productMap.set(e.uid, i));

            setOrder({
                seller: { ...products[0].provider_id, _id: order.seller },
                lineItems: order.lineItems.map(e => ({
                    uid: e.uid,
                    quantity: e.quantity,
                    product: products[productMap.get(e.uid)]
                })),
                addons: [],
                allergies: '',
                delivery_method: 'PICKUP',
                delivery_address: '',
                pickup_address: '',
                delivery_date_time: '',
                schedule: false,
                payment_method: ''
            });
        }
        catch (e) {
            toast.error('Some error occurred please try again');
            console.log(e);
            history.push('/search');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (
            order?.lineItems?.length === 0 ||
            !order?.delivery_method ||
            (order?.delivery_method === 'DELIVERY' && !order.delivery_address) ||
            (order?.delivery_method === 'PICKUP' && !order.pickup_address) ||
            (order?.schedule && !order.delivery_date_time) ||
            !order.payment_method
        ) return setDisable(true);

        return setDisable(false);

    }, [order]);

    if (loading)
        return (
            <div className='w-100 h-100 d-flex'>
                <Spin className='m-auto' />
            </div>
        );

    return (
        <div className="checkout row">
            <div className='col-12 col-lg-9 d-flex flex-column mx-auto'>
                {
                    order?.lineItems && Array.isArray(order.lineItems) && order.lineItems.length > 0 && currentStep === 0
                        ?
                        <Spin spinning={loading}>
                            <OrderDetail
                                nextStep={currentStep + 1}
                                addons={addons}
                                setOrder={setOrder}
                                order={order}
                                disable={disable}
                                setDisable={setDisable}
                                setStep={setCurrentStep}
                            />
                        </Spin>
                        :
                        currentStep === 0 ?
                            <h5 className='m-auto'>You cart is empty</h5>
                            :
                            null
                }

                {currentStep === 1 && <PaymentSuccess />}
            </div>
        </div>
    );
}
