import user from "./user";
import app from "./app";
import searchDetail from "./searchDetail";
import serviceArea from './serviceArea';
import subCategory from './subCategory';
import registration from './registration';
import order from "./order";
import product from "./product";
import messages from "./message";
import reviewAndRating from "./reviewAndRating";
import users from "./users";
import category from "./category";
import systemEventLogs from "./systemEvents";
import manual from "./manual";
import analytics from './google-analytics';
import addons from './addons';
import productTabs from './myProduct';
import inventory from './inventory';
import project from './project';

export const reducers = {
    user,
    app,
    searchDetail,
    serviceArea,
    subCategory,
    registration,
    order,
    product,
    messages,
    reviewAndRating,
    users,
    category,
    manual,
    systemEventLogs,
    analytics,
    addons,
    productTabs,
    inventory,
    project
};
