import React, { Component } from 'react';
import { Row, Col, Button} from 'antd';
import './style.scss';
import { withRouter } from 'react-router-dom';

class PageNotFound extends Component {

    backToHome = () => {
        const { history } = this.props;
        history.push("/");
    };

    render() {
        return (
            <Row>
                <Col span={24} className="page_not_found">
                    <h1>{window._env.REACT_APP_PAGE_NOT_FOUND_RESPONSE_MSG}</h1>
                    <Button shape='round' onClick={()=>this.backToHome()} type="primary" size="large">Back To Home</Button>
                </Col>
            </Row>
        );
    }
}
export default withRouter(PageNotFound);