const initialState = {
    loading: false,
    loadingChats: false,
    contactUsModalVisible: false,
    contactList: [],
    selectedUsers: null,
    chatInput: '',
    unreadCount: -1
};

const message = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case "MESSAGE_GENERIC_ACTION":
            return {
                ...state,
                ...payload
            };

        case "HANDLE_CONTACT_US_MODAL":
            return {
                ...state,
                contactUsModalVisible: payload
            };

        case "HANDLE_CHAT_INPUT_CHANGE":
            return {
                ...state,
                chatInput: payload
            };

        default:
            return state;
    }
};

export default message;