import ReactGA from 'react-ga';
const enabled = JSON.parse(
    window._env.REACT_APP_GOOGLE_ANALYTICS_ENABLED
);
const id = window._env.REACT_APP_GOOGLE_ANALYTICS_SITEID;

ReactGA.initialize(id ? id : null);

const analytics = (state = {}, action) => {
    if (!enabled) return state;
    switch (action.type) {
        case 'PAGE_VISIT': {
            ReactGA.pageview(action.path);
            return state;
        }
        case 'EVENT': {
            ReactGA.event({
                category: action.category,
                action: action.action,
                product_id: action.id
            });
            return state;
        }
        case 'EXCEPTION': {
            ReactGA.exception(action.err);
            return state;
        }
        default:
            return state;
    }
};

export default analytics;