import React from "react";
import { Col, Tooltip, Switch } from 'antd';
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { useDispatch } from 'react-redux';

const PaymentOptionBlock = (props) => {
    const dispatch = useDispatch();
    const handleUpdate = () => {
        const { item, onUpdate } = props;
        onUpdate(item);
    };

    const handleRemove = async () => {
        const { item, onRemove } = props;
        if (item._id) {
            const isRemove = window.confirm("Are you sure to remove this option ?");
            if (isRemove)
                await dispatch(onRemove(item._id));
        }
    };

    const enablePaymentOptions = async (x) => {
        const { item, UpdatePaymentOption } = props;
        const {

            _id, tag, detail
        } = item;

        const paymentOptionsPayload = {

            _id,
            tag,
            detail,
            isEnabled: x
        };

        await dispatch(UpdatePaymentOption(paymentOptionsPayload));

    };

    const { item } = props;



    return (
        <Col className="address-item" md={7} sm={9} lg={7} xs={22}>
            <address>
                <div><strong>{item.tag.toUpperCase()}</strong></div>
                <div>{item.detail}</div>
            </address>
            {
                item._id &&
                <div className="action">
                    <AiOutlineEdit onClick={handleUpdate} size={25} />
                    <AiOutlineDelete onClick={handleRemove} size={25} />
                    <Tooltip title={item.isEnabled ? "disable" : "enable"} onClick={(x) => enablePaymentOptions(x)}>

                        <Switch size="small" checked={item.isEnabled} />

                    </Tooltip>
                </div>
            }
        </Col>
    );
};

export default PaymentOptionBlock;
