import React, { useState } from 'react';
import { Modal, Row, Col, Input } from 'antd';
import { useSelector } from 'react-redux';

export default function ContactUsModal({ visible, closeModal, onSubmit, isLoading }) {

    const [message, setMessage] = useState('');
    const { scope } = useSelector(st => st.user);

    return (
        <Modal
            title={scope === 'CONSUMER' ? "Contact Seller" : "Contact Buyer"}
            visible={visible}
            okText="Send"
            okButtonProps={{ htmlType: "button", loading: isLoading }}
            onOk={() => onSubmit(message)}
            onCancel={() => closeModal(false)}
            wrapClassName="modal-content-wrapper"
        >
            <Row>
                <Col span={24} >
                    <label>Message</label>
                    <Input
                        name="text"
                        placeholder='Enter Message'
                        onChange={(ev) => setMessage(ev.target.value)}
                    />
                </Col>
            </Row>
        </Modal>
    );
}
