import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { Col, Row, Spin, Avatar, Modal } from 'antd';

import {
    AiOutlineUser, AiOutlineHome, AiOutlineMobile,
    AiOutlineLock, AiOutlineBell, AiOutlineTool,
    AiOutlineUserDelete, AiOutlineCustomerService, AiOutlineEdit
} from 'react-icons/ai';
import { MdPayment } from 'react-icons/md';
import { GoLocation } from 'react-icons/go';
import { RiUserSettingsLine } from 'react-icons/ri';

import { Field, reduxForm, SubmissionError } from 'redux-form';

import Profile from "./profile";
import Password from "./password";
import Notifications from "./notifications";
import Deactivate from "./deactivate";
import Address from "./address";
import ServiceDetail from "./serviceDetail";
import Insurance from "./insurance";
import ContactPhone from "./contactPhone";
import Support from './support';
import Bio from "./bio";
import CoverPhotos from './coverphotos';
import PaymentOption from './paymentOptions';
import PickupLocation from './pickupLocation';
import WorkLicense from "./workLicense";

import './styles.scss';
import PIConcernMsg from '../common/PIConcernMsg';
import { FileUpload } from '../../common/redux-form';
import PrivacySettings from './privacySettings';

const defaultStoreHours = {
    open: false,
    reason: '',
    timezone: 'est',
    sunday: {
        open: false,
        opens_at: "9:00 am",
        closes_at: "6:00 pm"
    },
    monday: {
        open: true,
        opens_at: "9:00 am",
        closes_at: "6:00 pm"
    },
    tuesday: {
        open: true,
        opens_at: "9:00 am",
        closes_at: "6:00 pm"
    },
    wednesday: {
        open: true,
        opens_at: "9:00 am",
        closes_at: "6:00 pm"
    },
    thursday: {
        open: true,
        opens_at: "9:00 am",
        closes_at: "6:00 pm"
    },
    friday: {
        open: true,
        opens_at: "9:00 am",
        closes_at: "6:00 pm"
    },
    saturday: {
        open: false,
        opens_at: "9:00 am",
        closes_at: "6:00 pm"
    }
};

const UserProfile = reduxForm({
    form: 'profile_image',
    enableReinitialize: true,
})(
    ({
        handleSubmit,
        error,
        image,
        user,
        reset,
        initialize,
        updateUserDetail
    }) => {

        const [isVisible, setIsVisible] = useState(false);
        const title = (
            <div>
                <AiOutlineUser style={{ paddingRight: '10px' }} size={25} />
                Update Personal Picture
            </div>
        );

        const _handleSubmit = async (values) => {
            try {
                await updateUserDetail(values);
                reset();
                initialize(values);
                setIsVisible(false);
            } catch (e) {
                throw new SubmissionError({ _error: e.message });
            }
        };

        return (
            <div>
                <div className='d-none d-md-block p-3 text-center profile-card'>
                    <div className='w-100 d-flex' >
                        <AiOutlineEdit onClick={() => setIsVisible(true)} size={20} className='ml-auto' />
                    </div>
                    <Avatar src={image ? window._env.REACT_APP_PUBLIC_URL_HOST + image : ''} shape='circle' size={120} />
                    <h6 className='mt-2'>{user.first_name} {user.last_name}</h6>
                </div>

                <div className='d-md-none p-1 text-center profile-card'>
                    <div className='w-100 d-flex' >
                        <AiOutlineEdit onClick={() => setIsVisible(true)} size={20} className='ml-auto' />
                    </div>
                    <Avatar src={image ? window._env.REACT_APP_PUBLIC_URL_HOST + image : ''} shape='circle' size={30} />
                </div>
                <Modal
                    title={title}
                    visible={isVisible}
                    okButtonProps={{ htmlType: "submit", loading: user.isLoading }}
                    cancelButtonProps={{ disabled: user.isLoading }}
                    okText="Save"
                    onCancel={() => setIsVisible(false)}
                    onOk={handleSubmit(_handleSubmit)}
                    keyboard={false}
                >
                    <form onSubmit={handleSubmit(_handleSubmit)}>
                        <Row gutter={24} >
                            <Col span={24}>
                                <Field
                                    name="image_urls[0].url"
                                    component={FileUpload}
                                    alt="Profile Picture"
                                    label="Profile Picture"
                                    context='profile'
                                    props={{ resize: true, width: 400, height: 400 }}
                                />
                            </Col>
                        </Row>
                        <div className="form-error">{error}</div>
                    </form>
                </Modal>
            </div>
        );
    }
);

const ListItem = ({ data, selected }) => {
    let Icon = data.MenuIcon;
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    return (
        <Link to={data.link}
            className={`p-3 d-flex align-items-center text-decoration-none d-block ${selected ? 'profile-list-item-selected' : 'profile-list-item'} `}>
            <Icon size={20} className={`${selected ? 'profile-list-icon-selected' : 'profile-list-icon'}`} />
            <span className={`ml-2 d-none d-md-block ${selected ? 'profile-list-text-selected' : 'profile-text-icon'}`}>{data.label}</span>
        </Link>
    );
};

export default function AccountManagement(props) {

    let { path } = useRouteMatch();
    let location = useLocation();
    let user = props.user;
    let image = "";

    if (user.image_urls && Array.isArray(user.image_urls) && user.image_urls.length > 0) {
        const { url } = user.image_urls.filter((i) => i.type === "avatar" && i.url)[0] || {};
        image = url;
    }

    async function fetchUserDetail() {
        await props.getUserDetail();
        if (user.scope === "PROVIDER") {
            if (!props.serviceArea?.length) {
                await props.getServiceArea();
            }
            if (!props.subCategories?.length) {
                await props.getAllCategories();
            }
        }
    }

    useEffect(() => {
        if (user.scope)
            fetchUserDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // array of routes in which we should show PI warning
    const showPIWarning = [path];

    const personal_info = [{
        label: 'Profile',
        link: path,
        MenuIcon: AiOutlineUser,
        id: 'profile',
        visible: true
    }, {
        label: 'Address',
        link: `${path}/address`,
        MenuIcon: AiOutlineHome,
        id: 'address',
        visible: true
    }, {
        label: 'Mobile',
        link: `${path}/contactPhone`,
        MenuIcon: AiOutlineMobile,
        id: 'mobile',
        visible: true
    }, {
        label: 'Password',
        link: `${path}/password`,
        MenuIcon: AiOutlineLock,
        id: 'password',
        visible: true
    }, {
        label: 'Notifications',
        link: `${path}/notifications`,
        MenuIcon: AiOutlineBell,
        id: 'notification',
        visible: true
    }, {
        label: 'Payment Options',
        link: `${path}/payment-option`,
        MenuIcon: MdPayment,
        id: 'payment-option',
        visible: user.scope === "PROVIDER"
    }, {
        label: 'Pickup Locations',
        link: `${path}/pickup-location`,
        MenuIcon: GoLocation,
        id: 'pickup-location',
        visible: user.scope === "PROVIDER"
    }, {
        label: 'Privacy Settings',
        link: `${path}/privacy`,
        MenuIcon: RiUserSettingsLine,
        id: 'privacy-settings',
        visible: true
    }];

    const business_info = [/*
        {
            label: "Professional License",
            MenuIcon: AiOutlineIdcard,
            link: `${path}/workLicense`,
            id: 'work_license'
        },
        {
            label: "Liability Insurance",
            MenuIcon: AiOutlineFileText,
            link: `${path}/Insurance`,
            id: 'insurance'
        },
        {
            label: "Business Profile",
            MenuIcon: AiOutlineProfile,
            link: `${path}/businessProfile`,
            id: 'buisness_profile'
        }, */ {
            label: "Store Settings",
            MenuIcon: AiOutlineTool,
            link: `${path}/serviceDetail`,
            id: 'service_detail'
        }];

    return (
        user.isLoading ?

            <div className='w-100 h-100 d-flex'>
                <Spin spinning={true} className='m-auto'></Spin>
            </div>
            :
            <div className='my-profile h-100'>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Profile | Foodelys</title>
                </Helmet>

                <Row justify='space-between' align='stretch'>
                    <Col xs={4} md={6} className='h-100 overflow-y-auto'>

                        <UserProfile
                            image={image} user={user}
                            updateUserDetail={props.updateUserDetail}
                            initialValues={{
                                image,
                                image_urls: [{ url: image }],
                            }} />

                        {/* personal info */}

                        <div className='mt-3 profile-card'>
                            {
                                personal_info.map((e, i) => (
                                    e.visible &&
                                    <ListItem data={e} key={i} selected={location.pathname === e.link} />
                                ))
                            }

                        </div>

                        {/* business info */}

                        {
                            user.scope === 'PROVIDER' &&
                            <div className='mt-3 profile-card'>
                                {
                                    business_info.map((e, i) => (
                                        <ListItem data={e} key={i} selected={location.pathname === e.link} />
                                    ))
                                }
                            </div>
                        }

                        {/* support */}

                        <div className='mt-3 profile-card'>
                            <ListItem
                                data={{ label: 'Support', MenuIcon: AiOutlineCustomerService, link: `${path}/support`, id: 'support' }}
                                selected={location.pathname === `${path}/support`}
                            />
                        </div>

                        {/* deactivate */}

                        <div className='mt-3 profile-card'>
                            <ListItem
                                data={{ label: 'Deactivate', MenuIcon: AiOutlineUserDelete, link: `${path}/deactivate`, id: 'deactivate' }}
                                selected={location.pathname === `${path}/deactivate`}
                            />
                        </div>

                    </Col>
                    <Col xs={19} md={17} className='p-2 profile-card'>
                        <Switch>
                            <Route path={`${path}/address`} exact>
                                <Address {...props} />
                            </Route>
                            <Route path={`${path}/contactPhone`} exact>
                                <ContactPhone
                                    initialValues={{
                                        isUpdatePhoneNumber: true,
                                        phone_number: props.user.phone_number
                                    }}
                                    {...props}
                                />
                            </Route>
                            <Route path={`${path}/password`} exact>
                                <Password {...props} />
                            </Route>
                            <Route path={`${path}/notifications`} exact>
                                <Notifications {...props} />
                            </Route>
                            <Route path={`${path}/privacy`} exact>
                                <PrivacySettings {...props} />
                            </Route>
                            {
                                user.scope === "PROVIDER" &&
                                <Route path={`${path}/payment-option`} exact>
                                    <PaymentOption {...props} />
                                </Route>
                            }
                            {user.scope === "PROVIDER" &&
                                <Route path={`${path}/pickup-location`} exact>
                                    <PickupLocation {...props} />
                                </Route>
                            }
                            <Route path={`${path}/deactivate`} exact>
                                <Deactivate {...props} />
                            </Route>
                            <Route path={`${path}/support`} exact>
                                <Support {...props} />
                            </Route>
                            <Route path={path} exact>
                                <>
                                    <Profile
                                        {...props}
                                        user={user}
                                        initialValues={{
                                            first_name: user.first_name,
                                            last_name: user.last_name,
                                            languages: user.languages,
                                            store_name: user.store_name
                                        }}
                                        image={image}
                                    />
                                    {
                                        user.scope === "PROVIDER" ?
                                            <React.Fragment>
                                                <Bio
                                                    {...props}
                                                    user={user}
                                                    initialValues={{
                                                        provider_attributes: user.provider_attributes
                                                    }}
                                                />
                                                <CoverPhotos
                                                    {...props}
                                                    user={user}
                                                    initialValues={{
                                                        store_cover_picture: user.store_cover_picture
                                                    }}
                                                />
                                            </React.Fragment>
                                            :
                                            undefined
                                    }
                                </>
                            </Route>
                            {
                                user.scope === 'PROVIDER' &&
                                <Switch>
                                    <Route path={`${path}/workLicense`} exact>
                                        <WorkLicense
                                            initialValues={{
                                                isWorkLicenseUpdate: true,
                                                provider_attributes: { verify_docs: props.user.provider_attributes.verify_docs }
                                            }}
                                            {...props}
                                        />
                                    </Route>
                                    <Route path={`${path}/Insurance`} exact>
                                        <Insurance
                                            initialValues={{
                                                isInsuranceUpdate: true,
                                                provider_attributes: { verify_docs: props.user.provider_attributes.verify_docs }
                                            }}
                                            {...props}
                                        />
                                    </Route>
                                    {/*  <Route path={`${path}/businessProfile`} exact>
                                        <BusinessProfile initialValues={{ provider_attributes: props.user.provider_attributes }} {...props} />
                                    </Route> */}
                                    <Route path={`${path}/serviceDetail`} exact>
                                        <ServiceDetail
                                            initialValues={{
                                                provider_attributes: {
                                                    ...props.user.provider_attributes
                                                },
                                                timezone: props.user.timezone,
                                                store_hours: props.user.store_hours ?? defaultStoreHours
                                            }}
                                            {...props}
                                        />
                                    </Route>
                                </Switch>
                            }
                        </Switch>
                        {user.scope === 'PROVIDER' && showPIWarning.includes(location.pathname) && <PIConcernMsg containerClassName='mt-5' />}
                    </Col>
                </Row>

            </div>
    );
}
