export const getGeoLocation = () => {

    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(loc => {
                let cord = { latitude: loc.coords.latitude, longitude: loc.coords.longitude };
                resolve(cord);
            });
        } else {
            reject(false);
        }
    });

};

export const validatePostalCodeAndState = (isValidZip, state, postal) => {
    postal = postal.toString();
    postal = postal.replace(/\b0+/g, '');
    let zip = parseInt(postal);
    // console.log(state + " | " + zip);

    switch (state) {
        case "AL": isValidZip = zip >= 35004 && zip <= 36925 ? true : false;
            break;
        case "AK": isValidZip = zip >= 99501 && zip <= 99950 ? true : false;
            break;
        case "AZ": isValidZip = zip >= 85001 && zip <= 86556 ? true : false;
            break;
        case "AR": isValidZip = (zip >= 71600 && zip <= 72959) || (zip === 75500) ? true : false;
            break;
        case "AS": isValidZip = zip === 96799 ? true : false;
            break;
        case "CA": isValidZip = zip >= 90001 && zip <= 96162 ? true : false;
            break;
        case "CO": isValidZip = zip >= 80001 && zip <= 81658 ? true : false;
            break;
        case "CT": isValidZip = zip >= 6001 && zip <= 6928 ? true : false;
            break;
        case "DC": isValidZip = zip >= 20001 && zip <= 20599 ? true : false;
            break;
        case "DE": isValidZip = zip >= 19701 && zip <= 19980 ? true : false;
            break;
        case "FL": isValidZip = zip >= 32003 && zip <= 34997 ? true : false;
            break;
        case "GA": isValidZip = zip >= 30002 && zip <= 31999 ? true : false;
            break;
        case "GU": isValidZip = zip >= 96910 && zip <= 96932 ? true : false;
            break;
        case "HI": isValidZip = zip >= 96701 && zip <= 96797 ? true : false;
            break;
        case "ID": isValidZip = zip >= 83201 && zip <= 83877 ? true : false;
            break;
        case "IL": isValidZip = zip >= 60002 && zip <= 62999 ? true : false;
            break;
        case "IN": isValidZip = zip >= 46001 && zip <= 47997 ? true : false;
            break;
        case "IA": isValidZip = zip >= 50001 && zip <= 52809 ? true : false;
            break;
        case "KS": isValidZip = zip >= 66002 && zip <= 67954 ? true : false;
            break;
        case "KY": isValidZip = zip >= 40003 && zip <= 42788 ? true : false;
            break;
        case "LA": isValidZip = zip >= 70001 && zip <= 71497 ? true : false;
            break;
        case "ME": isValidZip = zip >= 3901 && zip <= 4992 ? true : false;
            break;
        case "MH": isValidZip = zip >= 96960 && zip <= 96970 ? true : false;
            break;
        case "MD": isValidZip = zip >= 20588 && zip <= 21930 ? true : false;
            break;
        case "MA": isValidZip = (zip >= 1001 && zip <= 2791) || (zip === 5501) ? true : false;
            break;
        case "MI": isValidZip = zip >= 48001 && zip <= 49971 ? true : false;
            break;
        case "MN": isValidZip = zip >= 55001 && zip <= 56763 ? true : false;
            break;
        case "MS": isValidZip = zip >= 38601 && zip <= 39776 ? true : false;
            break;
        case "MO": isValidZip = zip >= 63001 && zip <= 65899 ? true : false;
            break;
        case "MT": isValidZip = zip >= 59001 && zip <= 59937 ? true : false;
            break;
        case "NE": isValidZip = zip >= 68001 && zip <= 69367 ? true : false;
            break;
        case "NV": isValidZip = zip >= 88901 && zip <= 89883 ? true : false;
            break;
        case "NH": isValidZip = zip >= 3031 && zip <= 3897 ? true : false;
            break;
        case "NJ": isValidZip = zip >= 7001 && zip <= 8989 ? true : false;
            break;
        case "NM": isValidZip = zip >= 87001 && zip <= 88439 ? true : false;
            break;
        case "NY": isValidZip = (zip >= 10001 && zip <= 14925) || (zip === 501) || (zip === 544) || (zip === 6390) ? true : false;
            break;
        case "NC": isValidZip = zip >= 27006 && zip <= 28909 ? true : false;
            break;
        case "ND": isValidZip = zip >= 58001 && zip <= 58856 ? true : false;
            break;
        case "MP": isValidZip = zip >= 96950 && zip <= 96952 ? true : false;
            break;
        case "OH": isValidZip = zip >= 43001 && zip <= 45999 ? true : false;
            break;
        case "OK": isValidZip = zip >= 73001 && zip <= 74966 ? true : false;
            break;
        case "OR": isValidZip = zip >= 97001 && zip <= 97920 ? true : false;
            break;
        case "PA": isValidZip = zip >= 15001 && zip <= 19640 ? true : false;
            break;
        case "PR": isValidZip = zip >= 601 && zip <= 988 ? true : false;
            break;
        case "RI": isValidZip = zip >= 2801 && zip <= 2940 ? true : false;
            break;
        case "SC": isValidZip = zip >= 29001 && zip <= 29945 ? true : false;
            break;
        case "SD": isValidZip = zip >= 57001 && zip <= 57799 ? true : false;
            break;
        case "TN": isValidZip = zip >= 37010 && zip <= 38589 ? true : false;
            break;
        case "TX": isValidZip = (zip >= 75001 && zip <= 79999) || (zip >= 88510 && zip <= 88510) ? true : false;
            break;
        case "UT": isValidZip = zip >= 84006 && zip <= 84791 ? true : false;
            break;
        case "VT": isValidZip = zip >= 5001 && zip <= 5907 ? true : false;
            break;
        case "VA": isValidZip = (zip >= 22003 && zip <= 24658) || (zip >= 20101 && zip <= 20198) ? true : false;
            break;
        case "VI": isValidZip = zip >= 801 && zip <= 851 ? true : false;
            break;
        case "WA": isValidZip = zip >= 98001 && zip <= 99403 ? true : false;
            break;
        case "WI": isValidZip = zip >= 53001 && zip <= 54990 ? true : false;
            break;
        case "WV": isValidZip = zip >= 24701 && zip <= 26886 ? true : false;
            break;
        case "WY": isValidZip = zip >= 82001 && zip <= 83128 ? true : false;
            break;
        /** 
         * case "AE": ZIPrange('ZIP >= 90 && ZIP <= 98');
         * break;
         * case "AA": ZIPrange('ZIP == 340');
         * break;
         * case "AP": ZIPrange('ZIP >= 962 && ZIP <= 966');
         * break;
         */
        default:
            break;
    }
    return isValidZip;
};

export const getStateUsingZip = (postal) => {
    postal = postal.toString();
    postal = postal.replace(/\b0+/g, '');
    let zip = parseInt(postal);

    if (zip >= 35004 && zip <= 36925) {
        return "AL";
    }
    else if (zip >= 99501 && zip <= 99950) {
        return "AK";
    }
    else if (zip >= 85001 && zip <= 86556) {
        return "AZ";
    }
    else if ((zip >= 71600 && zip <= 72959) || (zip === 75500)) {
        return "AR";
    }
    else if (zip === 96799) {
        return "AS";
    }
    else if (zip >= 90001 && zip <= 96162) {
        return "CA";
    }
    else if (zip >= 80001 && zip <= 81658) {
        return "CO";
    }
    else if (zip >= 6001 && zip <= 6928) {
        return "CT";
    }
    else if (zip >= 20001 && zip <= 20599) {
        return "DC";
    }
    else if (zip >= 19701 && zip <= 19980) {
        return "DE";
    }
    else if (zip >= 32003 && zip <= 34997) {
        return "FL";
    }
    else if (zip >= 30002 && zip <= 31999) {
        return "GA";
    }
    else if (zip >= 96910 && zip <= 96932) {
        return "GU";
    }
    else if (zip >= 96701 && zip <= 96797) {
        return "HI";
    }
    else if (zip >= 83201 && zip <= 83877) {
        return "ID";
    }
    else if (zip >= 60002 && zip <= 62999) {
        return "IL";
    }
    else if (zip >= 46001 && zip <= 47997) {
        return "IN";
    }
    else if (zip >= 50001 && zip <= 52809) {
        return "IA";
    }
    else if (zip >= 66002 && zip <= 67954) {
        return "KS";
    }
    else if (zip >= 40003 && zip <= 42788) {
        return "KY";
    }
    else if (zip >= 70001 && zip <= 71497) {
        return "LA";
    }
    else if (zip >= 3901 && zip <= 4992) {
        return "ME";
    }
    else if (zip >= 96960 && zip <= 96970) {
        return "MH";
    }
    else if (zip >= 20588 && zip <= 21930) {
        return "MD";
    }
    else if ((zip >= 1001 && zip <= 2791) || (zip === 5501)) {
        return "MA";
    }
    else if (zip >= 48001 && zip <= 49971) {
        return "MI";
    }
    else if (zip >= 55001 && zip <= 56763) {
        return "MN";
    }
    else if (zip >= 38601 && zip <= 39776) {
        return "MS";
    }
    else if (zip >= 63001 && zip <= 65899) {
        return "MO";
    }
    else if (zip >= 59001 && zip <= 59937) {
        return "MT";
    }
    else if (zip >= 68001 && zip <= 69367) {
        return "NE";
    }
    else if (zip >= 88901 && zip <= 89883) {
        return "NV";
    }
    else if (zip >= 3031 && zip <= 3897) {
        return "NH";
    }
    else if (zip >= 7001 && zip <= 8989) {
        return "NJ";
    }
    else if (zip >= 87001 && zip <= 88439) {
        return "NM";
    }
    else if ((zip >= 10001 && zip <= 14925) || (zip === 501) || (zip === 544) || (zip === 6390)) {
        return "NY";
    }
    else if (zip >= 27006 && zip <= 28909) {
        return "NC";
    }
    else if (zip >= 58001 && zip <= 58856) {
        return "ND";
    }
    else if (zip >= 96950 && zip <= 96952) {
        return "MP";
    }
    else if (zip >= 43001 && zip <= 45999) {
        return "OH";
    }
    else if (zip >= 73001 && zip <= 74966) {
        return "OK";
    }
    else if (zip >= 97001 && zip <= 97920) {
        return "OR";
    }
    else if (zip >= 15001 && zip <= 19640) {
        return "PA";
    }
    else if (zip >= 601 && zip <= 988) {
        return "PR";
    }
    else if (zip >= 2801 && zip <= 2940) {
        return "RI";
    }
    else if (zip >= 29001 && zip <= 29945) {
        return "SC";
    }
    else if (zip >= 57001 && zip <= 57799) {
        return "SD";
    }
    else if (zip >= 37010 && zip <= 38589) {
        return "TN";
    }
    else if ((zip >= 75001 && zip <= 79999) || (zip >= 88510 && zip <= 88510)) {
        return "TX";
    }
    else if (zip >= 84006 && zip <= 84791) {
        return "UT";
    }
    else if (zip >= 5001 && zip <= 5907) {
        return "VT";
    }
    else if ((zip >= 22003 && zip <= 24658) || (zip >= 20101 && zip <= 20198)) {
        return "VA";
    }
    else if (zip >= 801 && zip <= 851) {
        return "VI";
    }
    else if (zip >= 98001 && zip <= 99403) {
        return "WA";
    }
    else if (zip >= 53001 && zip <= 54990) {
        return "WI";
    }
    else if (zip >= 24701 && zip <= 26886) {
        return "WV";
    }
    else if (zip >= 82001 && zip <= 83128) {
        return "WY";
    }
    else {
        return null;
    }
};