import React from 'react';
import { Row, Col } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

import './style.scss';

import welcome from '../../images/aboutus/about-01.svg';
import MobileView from '../../images/about-us-mobile.svg';
import HeaderAlt from '../header/header-alt';

export default function Section1() {

    const breakpoint = useBreakpoint();

    return (
        <div
            style={{
                position: 'relative',
                marginBottom: breakpoint.lg ? 0 : '3rem'
            }} className={breakpoint.lg ? '' : 'mobile-bg'}
        >

            <img className="position-absolute d-none d-lg-inline-block"
                style={{
                    height: breakpoint.xxl ? '100vh' : breakpoint.xl ? '85vh' : '75vh',
                    width: 'auto',
                    top: 0,
                    right: 0
                }}
                src={welcome} alt="Welcome To Foodelys"
            />

            <HeaderAlt />

            <Row type="flex" justify="center" align="middle" className="d-none d-lg-flex" style={{ position: 'relative' }}>

                <Col lg={{ span: 14 }} xl={13} xxl={{ span: 10, offset: 3 }} className="p-3" style={{ marginTop: breakpoint.xl ? '10rem' : '5rem' }}>
                    <h2 className="section-heading">
                        Our Story
                    </h2>
                    <div className={breakpoint.lg ? "section-content mt-4" : "section-content-mobile mt-3"}>
                    America is a mixture of ethnicities. With so many cultures co-existing in our melting pot of a nation, the demand for ethnic food is skyrocketing. Millions of people live away from their mother countries; America aspires to provide them with a cure for their homesickness through flavors they can recognize.
                        <br />
                        <br />
                        Our founders migrated to the US more than a decade ago. Originally hailing from India, they have loved food since childhood and often crave home-cooked, traditional Indian cuisine. However, options are often limited to restaurants and relatives, neither most convenient for them. This problem encompasses countless ethnic groups throughout our country.
                        <br />
                        <br />
                        Foodelys strives to bridge the gap between consumer and their countries by providing a platform that allows for distributing food with ease. Regional recipes are shared by real people who have a passion for cooking, all door-to-door, without having to sacrifice authenticity.
                    </div>

                </Col>
                <Col xs={24} sm={24} md={8} lg={10} xl={11} xxl={11} >
                </Col>
            </Row>
            <Row type="flex" justify="center" className="d-lg-none d-md-flex" align="middle" style={{ backgroundImage: `url(${MobileView})` }}>

                <Col className="text-center mt-5">
                    <h6 style={{ fontSize: '1.4rem' }} className="mt-2 mb-4">
                        <strong style={{ padding: '2px 15px', border: '1px solid #fff', borderRadius: 50, color: '#fff' }}>Our Story</strong>
                    </h6>
                    <h6 style={{ margin: 15, color: "#fff" }}>
                    America is a mixture of ethnicities. With so many cultures co-existing in our melting pot of a nation, the demand for ethnic food is skyrocketing. Millions of people live away from their mother countries; America aspires to provide them with a cure for their homesickness through flavors they can recognize.
                        <br />
                        <br />
                        Our founders migrated to the US more than a decade ago. Originally hailing from India, they have loved food since childhood and often crave home-cooked, traditional Indian cuisine. However, options are often limited to restaurants and relatives, neither most convenient for them. This problem encompasses countless ethnic groups throughout our country.
                        <br />
                        <br />
                        Foodelys strives to bridge the gap between consumer and their countries by providing a platform that allows for distributing food with ease. Regional recipes are shared by real people who have a passion for cooking, all door-to-door, without having to sacrifice authenticity.
                    </h6>

                </Col>

            </Row>
        </div>
    );
}