import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getManualDataByName } from "../actions/manual";
import HowItWorksForProvider from "../components/how_it_works_for_provider";
import { page_visit } from '../actions/analytics';

const mapStateToProps = ({ manual: { isManualLoading, manualData } }) => ({
    isManualLoading,
    manualData,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getManualDataByName,
            page_visit
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(HowItWorksForProvider);
