import React, { useEffect, useState } from "react";
import { Row, Col, Spin, Divider } from 'antd';

import ReviewAndRatingForm from "./form";

import "./styles.scss";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getReviewByOrderId, submitReviewRating } from "../../../actions/reviewAndRating";
import { getOrder } from "../../../actions/order";
import ReviewOrderDetail from "./form/reviewOrderDetail";

export default function ReviewAndRating() {

    const { reviewAndRating } = useSelector(st => st.reviewAndRating);
    const { orders } = useSelector(st => st.order);

    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState({});
    const { invoice_id } = useParams();
    let history = useHistory();
    let dispatch = useDispatch();

    useEffect(() => {

        async function getReviewAndRating() {
            if (!invoice_id) {
                history.push('/search');
            }
            try {
                setLoading(true);
                await getReviewByOrderId(invoice_id)(dispatch);
                await getOrder({ invoice_id })(dispatch);
            } catch (e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        }

        getReviewAndRating();

    }, [invoice_id, dispatch, history]);

    useEffect(() => {
        if (Array.isArray(orders) && orders.length && orders[0].invoice_id.toString() === invoice_id) {
            setOrder(orders[0]);
        }
    }, [orders, invoice_id]);

    if (loading) return (<div className='w-100 h-100'><Spin className='m-auto'></Spin></div>);

    return (
        <div className="review-and-rating">
            <Row type='flex' justify="center" gutter={16}>

                <Col sm={24}>
                    <h3 className='mb-0'>Review &amp; Rating</h3>
                </Col>

                <Divider />

                <Col xs={24}>

                    {
                        order._id
                            ?
                            <ReviewOrderDetail
                                order={order}
                            />
                            :
                            null
                    }
                </Col>

                <Divider />

                <Col xs={24}>
                    <ReviewAndRatingForm
                        invoice_id={invoice_id}
                        submitReviewRating={submitReviewRating}
                        dispatch={dispatch}
                        goBack={() => history.push('/search')}
                        reviewAndRating={reviewAndRating}
                        initialValues={reviewAndRating}
                    />
                </Col>
            </Row>
        </div>
    );
}