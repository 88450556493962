import React, { useState } from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { Row, Col, Modal } from 'antd';
import { AiOutlineHome } from "react-icons/ai";
import {
    Input,
    validate,
    SelectBox
} from "../../../common/redux-form";
import { STATE } from "../../../constants/commonContext";
import { validatePostalCodeAndState } from "../../../services/utility";

const matchState = (stateValue, formValues) => {
    const zip = formValues.postal_code || "";
    const isValid = validatePostalCodeAndState(true, stateValue, zip);
    return (!isValid && zip) ? "State not match with postal code." : undefined;
};

const matchZip = (zip, formValues) => {
    const stateValue = formValues.state || "";
    const isValid = validatePostalCodeAndState(true, stateValue, zip);
    return (!isValid && stateValue) ? "Zip code didn't match with state." : undefined;
};

const title = (
    <div>
        <AiOutlineHome style={{ paddingRight: '10px' }} size={20} />
        Pickup Location
    </div>
);

function EditPickupLocation({
    change,
    isVisible,
    onClose,
    error,
    isLoadingProfile,
    handleSubmit,
    initialValues = {},
    addPickupLocation,
    updatePickupLocation
}) {

    const [postalCode, setPostalCode] = useState(0);

    const _handleSubmit = async (value = {}) => {

        try {
            const {
                _id,
                street_1,
                street_2,
                city,
                state,
                postal_code,
                is_primary
            } = value;
            const pickupLocationData = {
                street_1,
                street_2,
                city,
                state,
                postal_code,
                is_primary,
                is_verified: true,
            };
            if (_id) {
                pickupLocationData._id = _id;
                await updatePickupLocation(pickupLocationData);
            }
            else {
                await addPickupLocation(pickupLocationData);
            }
            onClose();
        } catch (e) {
            throw new SubmissionError({ _error: e.message });
        }
    };

    const validatePostalWithState = state => {
        const isValid = validatePostalCodeAndState(
            true,
            state,
            postalCode
        );

        if (!isValid) {
            change('state', "null");
        } else {
            change('state', state);
        }
    };

    return (
        <Modal
            title={title}
            visible={isVisible}
            okButtonProps={{ htmlType: "submit", loading: isLoadingProfile }}
            cancelButtonProps={{ disabled: isLoadingProfile }}
            okText={initialValues._id ? "Update" : "Add"}
            onCancel={onClose}
            onOk={handleSubmit(_handleSubmit)}
            keyboard={false}
            className="billing-modal"
        >
            <form onSubmit={handleSubmit(_handleSubmit)}>
                <Field
                    name="street_1"
                    type="text"
                    component={Input}
                    label="Address"
                    validate={validate.required}
                    isRequired={true}
                />

                <Field
                    name="street_2"
                    type="text"
                    component={Input}
                    label="Address Line 2 (Unit#/Apartment#)"
                />
                <Field
                    name="city"
                    type="text"
                    component={Input}
                    label="City"
                    validate={validate.required}
                    isRequired={true}
                />
                <Row gutter={8}>
                    <Col span={12}>
                        <Field
                            name="state"
                            type="text"
                            component={SelectBox}
                            label="State"
                            validate={[validate.required, matchState]}
                            isRequired={true}
                            options={STATE}
                            showSearch={true}
                            onChange={validatePostalWithState}
                        />
                    </Col>
                    <Col span={12}>
                        <Field
                            name="postal_code"
                            type="text"
                            component={Input}
                            label="Zip Code"
                            validate={[validate.required, matchZip]}
                            isRequired={true}
                            onChange={(ev) => setPostalCode(ev.target.value)}
                        />
                    </Col>
                </Row>
                <div className="form-error">{error}</div>
            </form>
        </Modal>

    );
}

export default reduxForm({
    form: 'add-update-pickup-location',
    enableReinitialize: true,
    destroyOnUnmount: true
})(EditPickupLocation);