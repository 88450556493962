export const allowedActions = (status, action) => {
    let actions;
    switch (status) {
        case 'ORDER_PLACED':
            actions = ['CANCEL'];
            break;
        case 'ACCEPTED':
            actions = ['CONTACT'];
            break;
        case 'READY':
            actions = ['CONTACT'];
            break;
        case 'FULFILLED':
            actions = ['REVIEW'];
            break;
        default:
            actions = [];
    }

    console.log(actions);

    return actions.includes(action);
};
