import React from 'react';
import { Col } from "antd";
import './styles.scss';
import { VscTools } from 'react-icons/vsc';

export default function ProjectCard({ projectDetail, Action }) {

    const { title, photo_urls } = projectDetail;

    function getImage() {
        let image = "";
        if (photo_urls[0] && photo_urls[0].url) {
            image = photo_urls[0].url;
        }

        return image;
    }

    return (
        <React.Fragment>
            <Col xs={24} md={8} lg={6}>
                <div className="card h-100">
                    {
                        getImage() ?
                            <img src={window._env.REACT_APP_PUBLIC_URL_HOST + getImage()}
                                alt="Product main img" className='card-img-top'
                            />
                            :
                            <VscTools size={90} color='#1c98d6' className='card-img-top' />
                    }
                    <div className="card-body d-flex flex-column">
                        <div className='mb-2'>
                            <h5 className="card-title">{title}</h5>
                        </div>
                        <Action />
                    </div>
                </div>
            </Col>
        </React.Fragment>
    );
}