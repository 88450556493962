import React, { Component } from 'react';
import { reduxForm, Field, FieldArray, SubmissionError } from "redux-form";
import { Col, Row, Button, Popover } from 'antd';
import { AiFillQuestionCircle, AiOutlineUser } from "react-icons/ai";
import { toast } from 'react-toastify';

import { FileUpload, validate, SelectBox, Input, Checkbox } from "../../common/redux-form";

import MobileFields from './form/BasicInfo/MobileFields';
// import AddressFields from './AddressFields';
import { Fetch } from "../../actions/common";
import { targetConsumerOptions } from './context';
import './styles.scss';

const Mandatory_picture = JSON.parse(window._env.REACT_APP_PICTURE_MANDATORY);
const isEmpty = (arr) => (arr.length === 0 ? "Required" : false);


class BasicInfo extends Component {
    static propTypes = {

    };
    state = { isCommercial: false };

    componentDidMount() {
        this.props.getServiceArea();
        this.props.getAllCategories();
        const { registerFormValues: { consumer_attribute } } = this.props;

        if (consumer_attribute && consumer_attribute.type === targetConsumerOptions[1].value) {
            this.setState({ isCommercial: true });
        }
    }

    handleSubmit = async (value) => {
        const { updateUserDetail } = this.props;
        const {
            setRegistrationForm,
            initialize,
            updateRegistration,
            history,
        } = this.props;

        if (!(value.provider_attributes.serving_options.delivery || value.provider_attributes.serving_options.pickup)) {
            return toast.error('You must select one of delivery or pickup serving options');
        }

        initialize(value);
        setRegistrationForm(value);
        let phone_number = value.phone_number;
        phone_number[0].number = `+1${value.phone_number[0].number}`;
        try {

            await updateUserDetail({ ...value, isVerification: true, phone_number });
            updateRegistration({ ...value }, history);
        } catch (error) {
            throw new SubmissionError({ _error: error.message });
        }
    };

    verifyAddress = async (address) => {
        try {
            const { street_1, city, state } = address;
            const url = `/servicearea/googleAddress?address=${street_1}+${city}+${state}`;
            const { results } = await Fetch(url).then(res => res.data);

            if (Array.isArray(results)) {
                const addressType = 'premise';
                const { types, formatted_address, geometry: { location } } = results[0];
                if (
                    types.some(res => res === addressType) &&
                    formatted_address.toLowerCase().includes(street_1.toLowerCase())
                ) {
                    return { success: true, coordinates: { lat: location.lat, lng: location.lng } };
                } else {
                    toast.info("Look like your address in not valid");
                    return { success: false, coordinates: { lat: '', lng: '' } };
                }

            } else {
                throw Error('error');
            }
        } catch (error) {
            toast.error("Invalid mailing address");
            return { success: false, coordinates: { lat: '', lng: '' } };
        }
    };

    handelSelectChanges = (value) => {
        this.props.change('address[0].state', value);
    };

    handleConsumer = val => {
        if (val === targetConsumerOptions[1].value) {
            this.setState({ isCommercial: true });
        } else {
            this.setState({ isCommercial: false });
        }
    };

    render() {
        const {
            submitting,
            handleSubmit,
            error,
            user,
            subCategories,
            categories
        } = this.props;
        let sub_category = [];
        Array.isArray(categories) && categories.forEach(e => {
            subCategories[e].forEach(item => {
                sub_category.push(({ key: item, value: item, label: item }));
            });
        }
        );
        const data = window._env.REACT_APP_AVAILABLE_LANGUAGES;
        const temp = data.split(",");
        const languages_data = [];
        for (var i in temp) {
            languages_data.push({ "value": temp[i], "label": temp[i] });
        }

        return (
            <div>
                <div className="form-content">
                    <form onSubmit={handleSubmit(this.handleSubmit)}>

                        <Row gutter={16}>
                            <Col span={24} className="tab-header">
                                <h4 className="title">
                                    <AiOutlineUser size={25} className='mr-2' />
                                    Registration
                                </h4>
                            </Col>
                            <Col span={24}>
                                <Row gutter={[16, 16]}>
                                    <Col xs={24} sm={16} lg={10} xl={12}>
                                        <Row gutter={[0, 16]}>
                                            <Col xs={24} sm={12} md={12}>
                                                <strong>Name</strong>
                                                <p>{user.first_name} {user.last_name}</p>
                                            </Col>
                                            <Col>
                                                <strong>Email Address</strong>
                                                <p>{user.username}</p>
                                            </Col>
                                            <Col span={24}>
                                                <FieldArray
                                                    name="phone_number"
                                                    component={MobileFields}
                                                    max={1}
                                                    formChangeValue={this.props.change}
                                                    initialValues={this.props.initialValues}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={24} sm={8} lg={14} xl={12}>
                                        <Field
                                            name="image_urls[0].url"
                                            component={FileUpload}
                                            label="Profile Picture"
                                            context="profile"
                                            isRequired={true}
                                            validate={Mandatory_picture ? validate.required : () => { }}
                                            props={{ resize: true, width: 400, height: 400 }}
                                            tooltipText={
                                                <div className="info-popup">
                                                    <span className="desc">
                                                        Upload your logo through which consumers can recognize your brand. You can also upload your own picture.
                                                    </span>
                                                </div>
                                            }
                                        />
                                    </Col>
                                    <Col xs={24} sm={8} lg={14} xl={12}>
                                        <Field
                                            name="store_name"
                                            component={Input}
                                            label="Your Store Name"
                                            placeholder="Name"
                                            tooltipText={
                                                <div className="info-popup">
                                                    <span className="desc">
                                                        Enter the name of your store. Consumers will be able to search for you using this name.
                                                    </span>
                                                </div>
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Field
                                    name="languages"
                                    placeholder="Languages"
                                    component={SelectBox}
                                    label="Languages I can Speak"
                                    mode="multiple"
                                    max={5}
                                    defaultValue={"English"}
                                    options={languages_data}
                                    tooltipText={
                                        <div className="info-popup">
                                            <span className="desc">
                                                For smooth coordination between you and the consumer, enter the languages you are comfortable communicating in.
                                            </span>
                                        </div>
                                    }
                                />
                                <Field
                                    name="provider_attributes.expertise"
                                    placeholder="Skills"
                                    component={SelectBox}
                                    label="Specialties"
                                    mode="multiple"
                                    onSearch={this.props.onSubCategorySearch}
                                    options={sub_category}
                                    validate={[validate.required, isEmpty]}
                                    isRequired={true}
                                    tooltipText={
                                        <div className="info-popup">
                                            <span className="desc">
                                                Mention the cuisines/dishes you like to serve.
                                            </span>
                                        </div>
                                    }
                                />

                                <Col xs={24} sm={8} lg={14} xl={12}>
                                    <Field
                                        name="store_cover_picture"
                                        component={FileUpload}
                                        label="Store Cover Picture"
                                        context="store"
                                        props={{ resize: true, width: 988, height: 233 }}
                                        tooltipText={
                                            <div className="info-popup">
                                                <span className="desc">
                                                    Please upload a cover picture for your store.
                                                </span>
                                            </div>
                                        }
                                    />
                                </Col>

                                <Col xs={24} sm={8} lg={14}>
                                    <div className='mb-3'>
                                        <strong>What services does your store offer?</strong>
                                    </div>

                                    <Field
                                        name="provider_attributes.serving_options.delivery"
                                        component={Checkbox}
                                        label={
                                            <span>
                                                We make food and deliver it to foodies.
                                                <Popover
                                                    content='Select this option if you are a chef/cook/caterer/baker/grocer or dairy farmer who prepares dishes/meals and delivers them to the customers.'
                                                    trigger="hover"
                                                    placement='topRight'
                                                >
                                                    <span className='ml-1'><AiFillQuestionCircle size={20} /></span>
                                                </Popover>
                                            </span>
                                        }
                                        className='mb-2 ml-3'
                                    />
                                    <Field
                                        name="provider_attributes.serving_options.pickup"
                                        component={Checkbox}
                                        className='ml-3 mb-2'
                                        label={
                                            <span>
                                                We make food and provide curbside pickup.
                                                <Popover
                                                    content='Select this option if you are a chef/cook/caterer/baker/grocer or dairy farmer who cooks dishes/meals and let the foodies pick their orders'
                                                    trigger="hover"
                                                    placement='topRight'
                                                >
                                                    <span className='ml-1'><AiFillQuestionCircle size={20} /></span>
                                                </Popover>
                                            </span>
                                        }
                                    />
                                    <Field
                                        name="provider_attributes.serving_options.on_demand_cooking"
                                        component={Checkbox}
                                        className='ml-3 mb-2'
                                        label={
                                            <span>
                                                We provide on-demand cooking and private chef service.
                                                <Popover
                                                    content='Select this option if you can travel on-site to cook food for foodies’ gig and gathering'
                                                    trigger="hover"
                                                    placement='topRight'
                                                >
                                                    <span className='ml-1'><AiFillQuestionCircle size={20} /></span>
                                                </Popover>
                                            </span>
                                        }
                                    />
                                    <Field
                                        name="provider_attributes.serving_options.on_site_catering"
                                        component={Checkbox}
                                        className='ml-3 mb-2'
                                        label={
                                            <span>
                                                We provide full-service on-site catering solutions.
                                                <Popover
                                                    content='Select this option if you are a full-service catering company for small and large events'
                                                    trigger="hover"
                                                    placement='topRight'
                                                >
                                                    <span className='ml-1'><AiFillQuestionCircle size={20} /></span>
                                                </Popover>
                                            </span>
                                        }
                                    />
                                    <Field
                                        name="provider_attributes.serving_options.food_truck"
                                        component={Checkbox}
                                        className='ml-3 mb-2'
                                        label={
                                            <span>
                                                We provide specialty food trucks.
                                                <Popover
                                                    content='Select this option if you provide specialty food trucks.'
                                                    trigger="hover"
                                                    placement='topRight'
                                                >
                                                    <span className='ml-1'><AiFillQuestionCircle size={20} /></span>
                                                </Popover>
                                            </span>
                                        }
                                    />

                                </Col>

                                {/* <Field
                                    name="provider_attributes.service_area"
                                    placeholder="Serving Area"
                                    component={SelectBox}
                                    label="Serving Area"
                                    mode="multiple"
                                    onSearch={this.props.onServiceSearch}
                                    options={this.props.serviceArea}
                                    validate={[validate.required, isEmpty]}
                                    isRequired={true}
                                    tooltipText={(
                                        <div className="info-popup">
                                            <span className="desc">
                                                Provide the name of the cities where you work.
                                            </span>
                                        </div>
                                    )}
                                    className='mt-2'
                                /> */}
                            </Col>
                            <Col span={24}>
                                <Button shape='round'
                                    className="btn-primary btn-submit ml-auto"
                                    type="primary"
                                    size={"large"}
                                    htmlType="submit"
                                    loading={submitting}
                                >
                                    Submit
                                </Button>
                            </Col>
                        </Row>

                        <div className="form-error">{error}</div>
                    </form>
                </div>
            </div>
        );
    }
}


export default reduxForm({
    form: "providerForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true
})(BasicInfo);
