import React from 'react';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import './style.scss';
import { Col, Row } from 'antd';
import welcome from '../../images/how-it-works4.svg';
import Avatar from 'antd/lib/avatar/avatar';

export default function Section2() {

    const breakpoint = useBreakpoint();

    return (
        <React.Fragment>
            <Row type="flex" justify="center" align="middle" id='buy_services_online'>
                <Col xs={0} md={2} xl={3}>
                </Col>

                <Col xs={24} md={10} xl={9}>
                    <img className="img img-fluid"
                        src={welcome} alt="Welcome To Foodelys" />
                </Col>

                <Col xs={24} md={10} xl={9}>

                    <div className="my-5 p-3 d-flex">
                        <div>
                            <Avatar
                                size={breakpoint.lg ? 50 : 30}
                                style={{ backgroundColor: '#c1395f', color: 'white', fontSize: breakpoint.lg ? '1.6rem' : '0.9rem' }}
                                className='mr-3'
                            >
                                5
                            </Avatar>
                        </div>
                        <div>
                            <h4 className='mb-3 d-flex align-items-center section-heading'>
                                Publish The Food Items
                            </h4>
                            <div className={`${breakpoint.lg ? 'section-content' : 'section-content-mobile'}`}>
                                Publish the food items/Publish Cooks-on-Demand or catering service
                                By publishing the food items/Cooks-on-Demand or catering services, they will be visible to the consumers on the website.
                            </div>
                        </div>
                    </div>

                </Col>

                <Col xs={0} md={2} xl={3}>
                </Col>
            </Row>
        </React.Fragment>
    );
}