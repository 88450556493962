import React from 'react';
import { Col, Row } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

import welcome from '../images/landingPage/desktop/Source-07.svg';
import CloudImg from '../images/landingPage/desktop/Source-17.svg';

import welcome_mobile from '../images/landingPage/mobile/Source-12.svg';
import CloudImg_mobile from '../images/landingPage/mobile/Source-13.svg';

import './style.scss';

export default function Section5() {

    const breakpoint = useBreakpoint();

    return (
        <Row type="flex" justify="center" align="middle" className='position-relative section-top-margin'
            id='buy_services_online'
        >

            <img
                className="position-absolute"
                src={breakpoint.lg ? CloudImg : CloudImg_mobile} alt="Welcome To Foodelys"
                style={{ width: '9%', top: 0, right: 0 }}
            />

            <Col xs={20} lg={10} xl={{ span: 9, offset: 1 }} xxl={{ span: 6, offset: 4 }} className='text-center'>
                <img className="img img-fluid"
                    src={breakpoint.lg ? welcome : welcome_mobile} alt="Welcome To Foodelys" />
            </Col>

            <Col xs={24} lg={{ span: 10, offset: 1 }} xl={{ span: 10, offset: 1 }} xxl={{ span: 8, offset: 2 }} className="p-3">
                <h2 className="section-heading">
                    You have bean on Customer's minds
                </h2>
                <div className={breakpoint.md ? "section-content mt-4" : "section-content-mobile mt-3"}>
                    We apple-solutely want you to list your sofishticated farm-grown produce and dairy from 
                    items of Foodelys. Become an entrepreneur with zero investment for the marketplace to
                    list items, find consumers, and ordering systems to start your business.
                </div>

            </Col>

            <Col sm={0} lg={3} xxl={4}>
            </Col>

        </Row>
    );
}