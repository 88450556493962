import { Fetch, getErrorMessage } from "./common";
import { toast } from 'react-toastify';
import { reset } from "redux-form";

export const setInventory = (payload = {}) => ({
    type: "SET_INVENTORY",
    payload,
});



export const getInventoryByProductId = (productId) => (dispatch) => {
    // eslint-disable-next-line max-len
    const url = `/inventory`;
    dispatch(setInventory({ isLoading: true }));
    return Fetch(url, "GET", { params: { productId } })
        .then(({ status, data = {} }) => {
            if (status === 200) {
                dispatch(
                    setInventory({
                        isLoading: false,
                    })
                );
                return data;
            }

            throw new Error(data.message);
        })
        .catch((error) => {
            dispatch(
                setInventory({ isLoading: false })
            );
        });
};


export const createInventory = (formData = {}) => (dispatch, getState) => {
    const url = `/inventory`;
    const options = {
        data: JSON.stringify({ ...formData }),
    };
    dispatch(setInventory({ isLoading: true }));
    return Fetch(url, "POST", options)
        .then(({ status, data = {} }) => {
            if (status === 200 || status === 201) {
                toast.success(data.message || "Inventory created successfully");
                dispatch(reset("inventory"));
                dispatch(setInventory({ isLoading: false }));
                return data;
            }

            toast.warning(data.message);
            throw new Error(data.message);
        })
        .catch((error) => {
            toast.error(getErrorMessage(error));
            dispatch(setInventory({ isLoading: false }));
        });
};

export const updateInventory = (formData = {}) => (dispatch, getState) => {
    const url = `/inventory`;
    const options = {
        data: JSON.stringify({ ...formData }),
    };
    dispatch(setInventory({ isLoading: true }));
    return Fetch(url, "PATCH", options)
        .then(({ status, data = {} }) => {
            if (status === 200 || status === 201) {
                toast.success(data.message || "Inventory updated successfully");
                dispatch(reset("inventory"));
                dispatch(setInventory({ isLoading: false }));
                return data;
            }

            toast.warning(data.message);
            throw new Error(data.message);
        })
        .catch((error) => {
            toast.error(getErrorMessage(error));
            dispatch(setInventory({ isLoading: false }));
        });
};

export const resetInventoryForm = () => (dispatch) => {
    dispatch(reset("inventory"));
};