import React from 'react';
import { BiError  } from "react-icons/bi";
import { useLocation } from 'react-router-dom';
import { decode } from 'base-64';
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function ErrorComponent(){

    const title = useQuery().get('title');
    const message  =  useQuery().get('message');

    return(
        <div 
            style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                minHeight: "600px",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <BiError style={{fontSize: "300px", color: "#0aa883"}} />    
            <h2 style={{textAlign: "center", fontSize: "50px", marginTop: "50px"}}>{title ? decode(title) : 'Something went wrong.' }</h2>
            <p>
                { message ? decode(message) : '' }
            </p>
        </div>
    );
}