import React from 'react';
import {  Button } from 'antd';
import { AiOutlineExclamationCircle  } from "react-icons/ai";
import { useState } from 'react';
import { logError } from './actions/logger';

export default function ErrorComponent({err, info}){

    const [loading, setLoading] = useState(false);
    async function sendInfo(){
        try{
            setLoading(true);
            await logError(err, info);
        } catch (e){
            console.log(e);
        } finally {
            setLoading(false);
            window.location.replace('/');
        }
    }
    return(
        <div 
            style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                minHeight: "600px",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <AiOutlineExclamationCircle style={{fontSize: "300px", color: "#0aa883"}} />    
            <h2 style={{textAlign: "center", fontSize: "50px", marginTop: "50px"}}>OH SNAP!</h2>
            <p>
                Something went wrong. 
            </p>
            <p>
                Would you like to share the error log to help improve the application ?
            </p>
            <div>
                <Button shape='round' 
                    type="primary" style={{marginRight: "20px", width: 200, textAlign: "center"}} 
                    onClick={sendInfo} loading={loading}
                >
                    Yes, I would like to help
                </Button>
                <Button shape='round' 
                    type="danger" style={{marginLeft: "20px", width: 200, textAlign: "center"}}
                    onClick={()=>window.location.replace('/')} loading={loading}
                >
                    No Thanks
                </Button>
            </div>
        </div>
    );
}