export const allowedActions = (status, action) => {
    let actions;
    switch (status) {
        case 'ORDER_PLACED':
            actions = ['REJECT', 'ACCEPT'];
            break;
        case 'ACCEPTED':
            actions = ['CONTACT', 'CANCEL', 'READY'];
            break;
        case 'READY':
            actions = ['CONTACT', 'FULFILLED'];
            break;
        case 'FULFILLED':
            actions = [];
            break;
        default:
            actions = [];
    }

    return actions.includes(action);
};
