import React, { Component } from "react";
import {
    Upload,
    Popover,
    
    message
} from "antd";
import { AiFillQuestionCircle, AiOutlineLoading, AiOutlineInbox, AiOutlineClose } from "react-icons/ai";

import { fileUpload as onFileUpload } from "../../actions/common";

const Dragger = Upload.Dragger;

const defaultFileTypes = [
    "application/pdf"
];

class DraggerFileUpload extends Component {

    state = {
        isLoading: false,
        uplodeFile:''
    };
  
    handleBeforeUpload = (file) => {
        const {
            fileSizeType,
            maxFileSize,
            fileTypes
        } = this.props;
        const isValidFileType = fileTypes.includes(file.type);
        let size = file.size / 1024;
        if (fileSizeType === "mb") {
            size = size / 1024;
        }
        const isValidSize = (size <= maxFileSize);

        if (!isValidFileType) {
            message.error("Invalid file type");
        }

        if (!isValidSize) {
            message.error(`File must smaller than ${maxFileSize}${fileSizeType}!`);
        }

        const isValidFile = isValidSize && isValidFileType;
        this.setState({ isValidFile });

        return isValidSize && isValidFileType;
    };

    handleAction = async (file) => {
        this.setState({uplodeFile:file.name});
        const { input: { onChange }, onUpload, context } = this.props;
        const isValidFile = this.handleBeforeUpload(file);

        if(!isValidFile) {
            return true;
        }

        try {
            const formData = new FormData();
            formData.append("file", file);
            this.setState({ isLoading: true });
            const { Location } = await onFileUpload(formData, context);
            this.setState({ isLoading: false });
            onChange(Location);
            file.url = Location;

            if(typeof onUpload === "function") {
                onUpload(file);
            }

            return file;
        } catch(e) {
            message.error("File not uploaded, please try again");
            this.setState({ isLoading: false });
            return false;
        }
    };

    handleRemove = () => {
        const { input: { onChange } } = this.props;
        onChange("");
    };

    handleChange = () => {};

    render() {

        const {
            input: { name, value },
            meta: { touched, error, warning },
            className,
            errorClass,
            label,
            labelClass,
            isRequired,
            tooltipText,
            tooltipPlacement,
            listType
        } = this.props;

        const isError = touched && (error || warning);
        const errorText = error || warning;
        const fieldError = isError ? "field-error" : "";
        const noError = !isError ? "no-error" : "";
        const isValidFile = value? true : false;
       
        const splitUrl = value? value.split('/') : [];
        const docName = this.state.uplodeFile || splitUrl[splitUrl.length - 1];

        return (
            <div className={`form-field file-upload-field ${className} ${fieldError}`}>
                {
                    label &&
                    <label className={labelClass}>
                        {label}
                        { isRequired ? <span className="required-label"><sup>{" * "}</sup></span> : "" }
                        {' '}
                        {
                            tooltipText &&
                            <Popover content={tooltipText} trigger="hover" placement={tooltipPlacement}>
                                <span><AiFillQuestionCircle  size={20}/></span>
                            </Popover>
                        }
                    </label>
                }
                <div className="clearfix">
                    {
                        !isValidFile &&
                        <Dragger
                            name={name}
                            listType={listType}
                            showUploadList={false}
                            action={this.handleAction}
                            onChange={this.handleChange}
                        >
                            <div>
                                <p className="ant-upload-drag-icon">
                                    {
                                        this.state.isLoading ?
                                            <AiOutlineLoading size={20}/>
                                            :
                                            <AiOutlineInbox size={20}/>
                                    }
                                </p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                <small>only <b>PDF</b>, other File Formats are not Allowed</small>
                            </div>
                        </Dragger>
                    }
                    {
                        isValidFile && !this.state.isLoading &&
                        <div className="file-link">
                            <a 
                                href={window._env.REACT_APP_PUBLIC_URL_HOST + value} 
                                target="_blank" title="click here view or download" 
                                rel="noopener noreferrer">{docName}
                            </a>
                            <AiOutlineClose className="remove" onClick={this.handleRemove} title="Remove File"  size={20}/>
                        </div>
                    }
                </div>
                {
                    <span className={`error ${errorClass} ${noError}`}>
                        {isError && errorText}
                    </span>
                }
            </div>
        );
    }
}

DraggerFileUpload.defaultProps = {
    input: {},
    meta: {},
    className: "",
    errorClass: "",
    label: "",
    labelClass: "",
    listType: "text",
    isRequired: false,
    tooltipPlacement: "rightTop",
    maxFileSize: 500,
    fileSizeType: "kb",
    fileTypes: defaultFileTypes,
    context: ""
};

export default DraggerFileUpload;
