import React, { Fragment } from "react";
import { Avatar } from "antd";
import { ProviderLink, SeekerLink, MenuLink } from "./authHeader";

const AuthMobileMenu = ({
    onLogout,
    onClose,
    userImage = {},
    firstName = "",
    scope,
    location = {},
    userStatus
}) => {

    const handleClick = (e) => {
        if(e && typeof e.preventDefault === "function") { e.preventDefault(); }
        onLogout();
        onClose();
    };

    const firstChar = firstName.substring(0, 1);
    let userAvatar = (
        <Avatar
            className="user-first-char"
        >
            {firstChar}
        </Avatar>
    );
    if(userImage.url) {
        userAvatar = (
            <Avatar
                className="user-avatar-img"
                src={window._env.REACT_APP_PUBLIC_URL_HOST + userImage.url}
            />
        );
    }
    
    const forbidden_status = ["REGISTRATION_PENDING", "DELETED", "ACCOUNT_CLOSED", "ADMIN_DELETED"];
    const UserView = scope === "PROVIDER" ? ProviderLink : scope === "CONSUMER"? SeekerLink : null;

    return (
        <Fragment>
            <li className={`menu-item user-detail`}>
                <div className="user-icon">{userAvatar}</div>
                <span className="name">{firstName}</span>
            </li>
            <UserView
                currentPath={location.pathname}
                isRegistrationDone={!forbidden_status.includes(userStatus)}
                isMobile={true}
                onClose={onClose}
            />
            <MenuLink
                name='Profile'
                path={`/${scope === "PROVIDER" ? "seller" : "buyer"}/profile`}
                iconName='profile'
                currentPath={location.pathname}
                isMobile={true}
                onClose={onClose}
            />
            <MenuLink
                name='Logout'
                path='logout'
                iconName='logout'
                currentPath={location.pathname}
                isMobile={true}
                onClose={handleClick}
            />
        </Fragment>
    );
};

export default AuthMobileMenu;
