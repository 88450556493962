import React, { Component } from 'react';
import { TimePicker as AntTimePicker, Popover } from 'antd';
import { AiFillQuestionCircle } from "react-icons/ai";
import moment from 'moment';

class TimePicker extends Component {
    static defaultProps = {
        dateformat: "h:mm a",
        use12Hours: true,
        input: {},
        meta: {},
        className: "",
        errorClass: "",
        label: "",
        labelClass: "",
        isRequired: false,
        tooltipPlacement: "bottomLeft"
    };

    onChange = (time, timeString) => {
        const { input: { onChange } } = this.props;
        onChange(timeString);
    };

    render() {

        const {
            input,
            meta,
            className,
            errorClass,
            label,
            labelClass,
            isRequired,
            tooltipText,
            tooltipPlacement,
            dateformat,
            use12Hours,
            defaultValue: value
        } = this.props;

        const { error, warning } = meta;
     
        const isError = error || warning;
        const errorText = error || warning;
        const fieldError = isError ? "field-error" : "";
        const noError = !isError ? "no-error" : "";
        const extraProps = {
            format: dateformat,
            use12Hours,
        };

        if (input.value) {
            extraProps.defaultValue = (moment(input.value, dateformat));
        }
        if (value) {
            extraProps.defaultValue = (moment(value, dateformat));
        }

        return (
            <>
                <div className={`form-field ${className} ${fieldError}`}>
                    {
                        label &&
                        <label className={labelClass}>
                            {label}
                            {isRequired ? <span className="required-label">
                                <sup>{" * "}</sup>
                            </span> : ""}
                            {' '}
                            {
                                tooltipText &&
                                <Popover content={tooltipText} trigger="hover" placement={tooltipPlacement}>
                                    <span><AiFillQuestionCircle  size={20}/></span>
                                </Popover>
                            }
                        </label>
                    }
                    <AntTimePicker
                        {...extraProps}
                        onChange={this.onChange}
                        size="large"
                    />
                    {
                        <span className={`error ${errorClass} ${noError}`}>
                            {isError && errorText}
                        </span>
                    }
                </div>

            </>
        );
    }
}

export default TimePicker;
