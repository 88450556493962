import { Fetch } from "./common";

export const incStoreViews = (body) => {
    const options = {
        data: JSON.stringify(body),
    };
    const url = `/metrics/store_view`;
    return Fetch(url, "POST", options)
        .then(({ status, data = {} }) => {
        }).catch((error) => {
            console.log(error);
        });
};

export const incProductViews = (body) => {
    const options = {
        data: JSON.stringify(body),
    };
    const url = `/metrics/product_view`;
    return Fetch(url, "POST", options)
        .then(({ status, data = {} }) => {
        }).catch((error) => {
            console.log(error);
        });
};
