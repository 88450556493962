import React from "react";
import { Input as AntInput, Popover } from 'antd';
import { AiFillQuestionCircle } from 'react-icons/ai';
class NumericInput extends React.Component {
    onChange = e => {
        const { value } = e.target;
        const reg = /[0-9]*$/;
        if ((!Number.isNaN(value) && reg.test(value)) || value === '') {
            if(this.props.input?.onChange)
                this.props.input.onChange(value);
            else if(this.props.onChange)
                this.props.onChange(value);
        }
    };

    render() {
        const {
            placeholder,
            size,
            meta: { touched, error, warning },
            className,
            errorClass,
            label,
            labelClass,
            maxLength,
            isRequired,
            tooltipText,
            tooltipPlacement,
            tooltipIcon,
            readOnly
        } = this.props;

        const isError = touched && (error || warning);
        const errorText = error || warning;
        const fieldError = isError ? "field-error" : "";
        const noError = !isError ? "no-error" : "";
        const ViewType = AntInput;
        const { size: iconSize, color, Icon } = tooltipIcon;
        return (
            <div className={`form-field ${className} ${fieldError}`}>
                {
                    label &&
                    <label className={labelClass}>
                        {label}
                        {isRequired ? <span className="required-label"><sup>{" * "}</sup></span> : ""}
                        {' '}
                        {
                            tooltipText &&
                            <Popover content={tooltipText} trigger="hover" placement={tooltipPlacement}>
                                <span>
                                    <Icon color={color} size={iconSize}/>
                                </span>
                            </Popover>
                        }
                    </label>
                }
                <ViewType
                    {...this.props.input}
                    onChange={this.onChange}
                    size={size}
                    placeholder={placeholder}
                    maxLength={maxLength}
                    readOnly={readOnly}
                />
                {
                    <span className={`error ${errorClass} ${noError}`}>
                        {isError && errorText}
                    </span>
                }
            </div>
        );
    }
}

NumericInput.defaultProps = {
    input: {},
    meta: {},
    placeholder: "",
    size: "large",
    className: "",
    errorClass: "",
    label: "",
    labelClass: "",
    type: "text",
    isRequired: false,
    autosize: { minRows: 5, maxRows: 5 },
    tooltipPlacement: "rightTop",
    readOnly: false,
    maxLength: 25,
    tooltipIcon:{
        size: 20,
        color: '',
        Icon: AiFillQuestionCircle
    }
};

export default NumericInput;