import axios from 'axios';
import { toast } from 'react-toastify';
import { Cookies } from 'react-cookie';
const $cookie = new Cookies();
const CancelToken = axios.CancelToken;
const source = CancelToken.source();

/**
 * Common axios fetch
 *
 * @param {string} uri url
 * @param {string} method get, post and put etc..
 * @param {object} options fetch options like headers
 * @returns {{<Promise>}} promise
 */

axios.interceptors.response.use(res =>{
    return res;
}, error => {

    // When session expire redirect to login
    if(error.message.includes(401)){
        window.location = '/login';
        return;
    }

    return Promise.reject(error);
});

export const Fetch = async(uri, method = 'get', options = {}, cancelable = true) => {
    const { headers } = options;
    const token = localStorage.getItem('token');
    const csrf_token = $cookie.get('xsrf-tk');
    const defaultOptions = {
        headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    };

    const REACT_APP_CSRF_HEADER_ENABLED = JSON.parse(
        window._env.REACT_APP_CSRF_HEADER_ENABLED
    );

    if(csrf_token && REACT_APP_CSRF_HEADER_ENABLED){
        defaultOptions.headers['X-XSRF-TOKEN'] = csrf_token;
    }

    options = {
        ...options,
        headers: {
            ...defaultOptions.headers,
            ...headers
        }
    };

    method = method.toLocaleLowerCase();

    const baseUrl = window._env.REACT_APP_API_BASE_URL;
    const url = `${baseUrl}/v1${uri}`;
    return await axios(
        {
            url,
            method,
            ...options,
            withCredentials:true,
            cancelToken: cancelable ? source.token : null
        })
        .catch(res => {
            const tokenError = getErrorMessage(res) || "";
            if (axios.isCancel(res)) {
                console.log('Request canceled ', res.message);
            } else if (tokenError === "Invalid token.") {
                window.location = "/login";
            }
            throw new Error(tokenError);
        });
};

/**
 * Get error
 * @param {Error} error error object
 * @param {string} defaultMessage default error message
 * @returns {string} error message
 */
export const getErrorMessage = (error, defaultMessage) => {

    if (error.response) {
        const {
            data: { message } = {}
        } = error.response || {};

        return message || defaultMessage;
    } else if (error.message) {
        return error.message;
    }

    return defaultMessage;
};


/**
 * Common file upload
 *
 * @param {fileObject} file file
 * @returns {{<Promise>}} promise
 * context define the upload ref.
 */
export const fileUpload = async(file, context) => {

    const url = `/common/upload-file?context=${context}`;
    const options = {
        data: file,
        headers: {
            "Content-Type": "multipart/form-data; boundary=something"
        }
    };

    try {
        const { status, data } = await Fetch(url, "POST", options);
        if (status === 200 || status === 201) {
            return data;
        }
    } catch (e) {
        toast.error("File not uploaded, please try again");
        throw new Error(e.message);
    }
};

export const cancelRequest = (msg)=>source.cancel(msg);