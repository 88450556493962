import React, { Component } from "react";
import {
    InputNumber as AntInputNumber,
    Popover
} from "antd";
import { AiFillQuestionCircle } from "react-icons/ai";

class InputNumber extends Component {

    handleParser = (value) => {
        const { min, precision } = this.props;
        try {
            // remove special character 
            if(precision === 0){
                value = value.replace(/[^0-9]+/g, "");
                value = parseInt(value);
            }
            else{
                value = value.replace(/[^0-9.]+/g, "");
            }
            return !value && value !== 0 ? value = null : value;
        } catch (e) {
            return min;
        }
    };

    formatter = value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    render() {

        const {
            input: { name, value, onChange },
            size,
            meta: { dirty, touched, error, warning },
            className,
            errorClass,
            label,
            labelClass,
            isRequired,
            tooltipText,
            tooltipPlacement,
            min,
            max,
            step,
            disabled,
            precision,
            separateWithComma,
            defaultValue
        } = this.props;

        const isError = (dirty || touched) && (error || warning);
        const errorText = error || warning;
        const fieldError = isError ? "field-error" : "";
        const noError = !isError ? "no-error" : "";
        const extraProps = {};

        if (min !== "undefined") {
            extraProps.min = min;
        }

        if (max !== "undefined") {
            extraProps.max = max;
        }

        if (step !== "undefined") {
            extraProps.step = step;
        }

        if(separateWithComma){
            extraProps.formatter = this.formatter;
        }

        return (
            <div className={`form-field ${className} ${fieldError}`}>
                {
                    label &&
                    <label className={labelClass}>
                        {label}
                        {isRequired ? <span className="required-label"><sup>{" * "}</sup></span> : ""}
                        {' '}
                        {
                            tooltipText &&
                                <Popover content={tooltipText} trigger="hover" placement={tooltipPlacement}>
                                    <span><AiFillQuestionCircle  size={20}/></span>
                                </Popover>
                        }
                    </label>
                }
                <AntInputNumber
                    {...extraProps}
                    value={value}
                    name={name}
                    size={size}
                    onChange={onChange}
                    parser={this.handleParser}
                    disabled={disabled}
                    precision={precision}
                    defaultValue={defaultValue}
                />
                {
                    <span className={`error ${errorClass} ${noError}`}>
                        {isError && errorText}
                    </span>
                }
            </div>
        );
    }
}

InputNumber.defaultProps = {
    input: {},
    meta: {},
    placeholder: "",
    size: "large",
    className: "",
    errorClass: "",
    label: "",
    labelClass: "",
    isRequired: false,
    tooltipPlacement: "rightTop",
    min: 0,
    disabled : false,
    precision: 0,
    separateWithComma: true
};

export default InputNumber;
