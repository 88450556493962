import React, { Component } from "react";
import {
    Popover
} from "antd";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { sanitize } from "../htmlSanitizer";
import { AiFillQuestionCircle } from "react-icons/ai";

const modules = {
    toolbar: [
        ['bold'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }]
    ]
};

class RichText extends Component {

    handleChange = (value) => {
        const { input: { onChange } } = this.props;
        onChange(sanitize(value).__html);
    };

    render() {

        const {
            input: { value },
            placeholder,
            meta: { touched, error, warning },
            className,
            errorClass,
            label,
            labelClass,
            isRequired,
            tooltipText,
            tooltipPlacement,
            readOnly,
        } = this.props;

        const isError = touched && (error || warning);
        const errorText = error || warning;
        const fieldError = isError ? "field-error" : "";
        const noError = !isError ? "no-error" : "";
        const extraProps = {};

        return (
            <div className={`form-field ${className} ${fieldError}`}>
                {
                    label &&
                    <label className={labelClass}>
                        {label}
                        {isRequired ? <span className="required-label"><sup>{" * "}</sup></span> : ""}
                        {' '}
                        {
                            tooltipText &&
                            <Popover content={tooltipText} trigger="hover" placement={tooltipPlacement}>
                                <span><AiFillQuestionCircle  size={20}/></span>
                            </Popover>
                        }
                    </label>
                }
                <ReactQuill
                    onChange={this.handleChange}
                    {...extraProps}
                    placeholder={placeholder}
                    readOnly={readOnly}
                    formats={["bold", "font", "italic", "underline", "size", "list", "align", "header"]}
                    modules={modules}
                    defaultValue={value}
                    value={value}
                />
                {
                    <span className={`error ${errorClass} ${noError}`}>
                        {isError && errorText}
                    </span>
                }
            </div>
        );
    }
}

RichText.defaultProps = {
    input: {},
    meta: {},
    placeholder: "",
    className: "",
    errorClass: "",
    label: "",
    labelClass: "",
    isRequired: false,
    tooltipPlacement: "rightTop",
    readOnly: false
};

export default RichText;
