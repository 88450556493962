import React, { useEffect, useState } from "react";
import {
    AutoComplete,
    Button
} from "antd";
import { HiPlus } from "react-icons/hi";
import { AiOutlineCloseCircle } from "react-icons/ai";

function TagInput({ maxLimit, meta: { error, pristine, submitFailed }, options = [], input: { value, onChange } }) {

    const [tag, setTag] = useState('');
    const [tags, setTags] = useState([]);

    useEffect(() => {

        if (!value) return;
        let tags = value.split(';');

        if (Array.isArray(tags) && tags.length > 0) {
            if (tags.length === 1 && !tags[0]) return;
            setTags(tags);
        }

    }, [value]);

    
    function appendTag(ev) {

        if (ev.stopPropagation) {
            ev.stopPropagation();
            ev.preventDefault();
        }

        let _tag = tag.trim();
        if (!_tag) return;

        onChange(value ? value + ';' + tag : tag);
        setTag('');
        return;
    }

    function removeTag(i) {
        if (!value) return;

        let _value = value.split(';');
        if (i < _value.length) {
            _value.splice(i, 1);
            onChange(_value.join(';'));
        }
    }


    return (
        <React.Fragment>
            <div className='d-flex flex-wrap mb-2'>
                {
                    tags.map((e, i) => (
                        <div
                            style={{ background: '#f9f9f9', borderColor: '#d9d9d9', borderWidth: 1, borderStyle: 'solid' }}
                            className='px-1 d-flex align-items-center mr-1 mb-1 rounded' key={i}
                        >
                            {e} <AiOutlineCloseCircle color='rgba(0, 0, 0, 0.45)' size={15} className='m-1' onClick={() => removeTag(i)} />
                        </div>
                    ))
                }
            </div>
            <div className='d-flex'>
                <AutoComplete
                    style={{ width: 570 }} options={options} onChange={(ev) => setTag(ev)} value={tag}
                    placeholder='Enter tag'
                    filterOption={(inputValue, option) =>option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1} 
                    onSubmit={appendTag}
                />
                <Button shape='round'
                    onClick={appendTag} disabled={maxLimit ? maxLimit <= tags.length : false}
                    icon={<HiPlus size={25} color='#666' />} className='ml-2'
                >
                    Add Tag
                </Button>
            </div>
            {error && (!pristine || submitFailed) && <p className='text-danger'>{error}</p>}
        </React.Fragment>
    );
}

export default TagInput;
