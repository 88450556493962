import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import './App.scss';
import './index.scss';
import './styles/common.scss';
import { AppStore } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { HelmetProvider } from 'react-helmet-async';

const { store, persist_store } = AppStore();

ReactDOM.render((
    <Provider store={store}>
        <PersistGate loading={null} persistor={persist_store}>
            <HelmetProvider>
                <App />
            </HelmetProvider>
        </PersistGate>
    </Provider>
), document.getElementById('root'));