import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Signup from '../../components/auth/signup';
import {
    login,
    signup,
    resetSignUpSeekerForm,
    emailDisposal,
    resend_confirmation_email
} from '../../actions/user';
import { page_visit } from '../../actions/analytics';

const mapStateToProps = ({
    user: { isAuthenticated, isLoading },
    app: { googleCaptchaKey, googleCaptchaKeyV3, recaptchaEnabled  }
}) => ({
    isAuthenticated,
    isLoading,
    googleCaptchaKey,
    googleCaptchaKeyV3, 
    recaptchaEnabled
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    login,
    signup,
    resetSignUpSeekerForm,
    emailDisposal,
    page_visit,
    resend_confirmation_email
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
