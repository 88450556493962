import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { formValueSelector } from 'redux-form';

import Registration from "../components/registration";
import { setRegistrationForm, updateRegistration } from "../actions/registration";
import { updateUserDetail } from "../actions/user";
import { getServiceArea, onServiceSearch } from "../actions/serviceArea";
import { onSubCategorySearch } from "../actions/subCategory";
import { serviceOptionSelector } from '../selector';
import { getManualDataByName } from '../actions/manual';
import { page_visit } from '../actions/analytics';
import { getAllCategories } from "../actions/category";

const selector = formValueSelector('providerForm');

const mapStateToProps = (state) => {

    const { formValue, isLoading, proOrgModal } = state.registration;
    const { scope, status, customer_id, isSaveCard } = state.user;
    const { cardDetail } = state.order;
    const { manualData } = state.manual;
    const { subCategories, categories } = state.category;
    return ({
        cardDetail,
        userscope: scope,
        userstatus: status,
        customerId: customer_id,
        isSaveCard,
        isLoading,
        initialValues: formValue,
        registerFormValues: formValue,
        payment: selector(state, 'paymentdetail'),
        subscription: selector(state, 'subscription'),
        serviceArea: serviceOptionSelector(state),
        categories,
        subCategories,
        proOrgModal,
        manualData,
        user: state.user
    });
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setRegistrationForm,
    updateRegistration,
    getServiceArea,
    onServiceSearch,
    onSubCategorySearch,
    updateUserDetail,
    getManualDataByName,
    page_visit,
    getAllCategories
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
