import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ContactUs from "../components/contactUs/contactUs";
import { contactUsEmail, emailDisposal } from "../actions/user";
import { page_visit } from '../actions/analytics';

const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            contactUsEmail,
            emailDisposal,
            page_visit
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
