const initialState = {
    formValue: {},
    isLoading: false,
};

const registration = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case "SET_REGISTRATION":
            return { ...state, ...payload };

        case "SET_REGISTRATION_FORM":
            const formValue = { ...state.formValue, ...payload };
            return { ...state, formValue };

        default:
            return state;
    }
};

export default registration;
