import React, { Fragment, useEffect, useState } from "react";
import { Menu, Avatar, Dropdown, Tooltip } from "antd";
import { Link } from "react-router-dom";
import { AiOutlineMessage, AiOutlineUser, AiOutlineShoppingCart, AiOutlineHome, AiOutlineShop } from "react-icons/ai";
import { CgList } from 'react-icons/cg';
import { RiFileList3Line } from 'react-icons/ri';
import { useCookies } from "react-cookie";
import { decode } from "../../common/order_utilities";
import { useSelector } from "react-redux";

const MenuLink = ({
    name,
    MenuIcon,
    path,
    currentPath,
    onClose,
    count,
    disabled,
    toolTipDesc
}) => {

    const activeClass = path === currentPath ? 'active' : '';
    const handleClick = () => {
        onClose();
    };

    const disableCss = disabled ? "disableLink" : "enableLink";

    return (
        <li className={`menu-item d-flex align-items-end pb-1 ${activeClass}`}>
            {!disabled ?
                <Link to={path} className={`header-link ${disableCss}`} onClick={handleClick} >
                    {MenuIcon && <MenuIcon style={{ fontSize: '25px' }} />}
                    <div className="link-text text-decoration-none">{name}</div>
                    {
                        (count && !isNaN(count)) ? <div className="count-item rounded-circle badge">{count > 9 ? '9+' : count}</div> : ""
                    }
                </Link>
                :
                <Tooltip title={toolTipDesc}>
                    <div className="header-link">
                        {MenuIcon && <MenuIcon style={{ fontSize: '25px' }} />}
                        <div className="link-text">{name}</div>
                    </div>
                </Tooltip>
            }
        </li>
    );
};

const ProviderLink = ({
    currentPath,
    isRegistrationDone,
    isMobile,
    onClose,
    unreadCount,
    display_name,
    newOrders
}) => isRegistrationDone ? (
    <Fragment>
        <MenuLink
            name='My Store'
            path={`/store/${display_name}`}
            MenuIcon={AiOutlineShop}
            currentPath={currentPath}
            isMobile={isMobile}
            onClose={onClose}
        />
        <MenuLink
            name='My Menu'
            path='/seller/my-products'
            MenuIcon={RiFileList3Line}
            currentPath={currentPath}
            isMobile={isMobile}
            onClose={onClose}
        />
        <MenuLink
            name='Orders'
            path='/seller/orders'
            MenuIcon={CgList}
            currentPath={currentPath}
            onClose={onClose}
            count={newOrders}
        />
        <MenuLink
            name='Messages'
            path='/seller/messages'
            MenuIcon={AiOutlineMessage}
            currentPath={currentPath}
            onClose={onClose}
            count={unreadCount > -1 ? unreadCount : 0}
        />

    </Fragment>
) : (<MenuLink
    name='Registration'
    path='/seller/registration'
    MenuIcon={AiOutlineUser}
    currentPath={currentPath}
    onClose={onClose}
/>);

const SeekerLink = ({ currentPath, onClose, unreadCount }) => {

    const [cookie] = useCookies(['order']);
    const [orderCount, setOrderCount] = useState(0);

    useEffect(() => {
        if (cookie.order) {
            let order = decode(cookie.order);
            setOrderCount(Array.isArray(order?.lineItems) ? order.lineItems.length : 0);
        } else setOrderCount(0);
    }, [cookie.order]);

    return (
        <Fragment>
            <MenuLink
                name='Home'
                path='/search'
                MenuIcon={AiOutlineHome}
                currentPath={currentPath}
                onClose={onClose}
            />
            <MenuLink
                name='Messages'
                path='/buyer/messages'
                MenuIcon={AiOutlineMessage}
                currentPath={currentPath}
                onClose={onClose}
                count={unreadCount > -1 ? unreadCount : 0}
            />
            <MenuLink
                name='My Orders'
                path='/buyer/my-order'
                MenuIcon={CgList}
                currentPath={currentPath}
                onClose={onClose}
            />
            <MenuLink
                name='Checkout'
                path='/buyer/checkout'
                MenuIcon={AiOutlineShoppingCart}
                currentPath={currentPath}
                onClose={onClose}
                count={orderCount}
            />
        </Fragment>
    );
};

const AuthHeader = ({
    onLogout,
    onClose,
    userImage = {},
    firstName = "",
    scope = "",
    location,
    unreadCount,
    getUnreadMessageCount,
    isRegistrationDone
}) => {

    const { display_name } = useSelector(st => st.user);
    const { newOrders } = useSelector(st => st.order);

    useEffect(() => {
        if (scope)
            getUnreadMessageCount();
    }, [scope, getUnreadMessageCount]);

    const firstChar = firstName.substring(0, 1);

    let userAvatar = (
        <Avatar
            className="user-first-char"
        >
            {firstChar}
        </Avatar>
    );

    if (userImage.url) {
        userAvatar = (
            <Avatar
                className="user-avatar-img"
                src={window._env.REACT_APP_PUBLIC_URL_HOST + userImage.url}
            />
        );
    }

    const menu = (
        <Menu>
            {scope !== 'ADMIN' &&
                <Menu.Item>
                    <Link
                        onClick={onClose}
                        to={`/${scope === "PROVIDER" ? "seller" : "buyer"}/profile`}
                    >
                        Profile
                    </Link>
                </Menu.Item>}
            <Menu.Item onClick={onLogout}>Logout</Menu.Item>
        </Menu>
    );

    let UserView = null;
    if (scope === "PROVIDER") {
        UserView = ProviderLink;
    }
    if (scope === 'CONSUMER') {
        UserView = SeekerLink;
    }

    return (
        <Fragment>
            {UserView ?
                <UserView
                    onClose={onClose}
                    currentPath={location.pathname}
                    isRegistrationDone={isRegistrationDone}
                    unreadCount={unreadCount}
                    display_name={display_name}
                    newOrders={newOrders}
                />
                : null}

            <li className={`menu-item user-dropdown user-${scope}`}>
                <Dropdown overlay={menu}>
                    {userAvatar}
                </Dropdown>
            </li>
        </Fragment>
    );
};

export {
    ProviderLink,
    SeekerLink,
    MenuLink
};

export default AuthHeader;
