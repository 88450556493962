import React, { Component } from "react";
import { Row, Col, Modal } from 'antd';
import { Field, reduxForm, FormSection, SubmissionError } from 'redux-form';
import moment from "moment";
import { AiOutlineEdit, AiOutlineIdcard } from "react-icons/ai";

import {
    Input,
    DatePicker,
    validate,
    DraggerFileUpload
} from '../../common/redux-form';
import { FaRegIdCard } from "react-icons/fa";

class EditWorkLicense extends Component {

    render() {

        const {
            isVisible,
            onSubmit,
            onClose,
            error,
            isLoading
        } = this.props;

        const title = (
            <div>
                <AiOutlineIdcard style={{ paddingRight: '10px' }} size={20} />
                Professional License
            </div>
        );

        return (
            <Modal
                title={title}
                visible={isVisible}
                okButtonProps={{ htmlType: "submit", loading: isLoading }}
                cancelButtonProps={{ disabled: isLoading }}
                okText="Save"
                onCancel={onClose}
                onOk={onSubmit}
                keyboard={false}
                className="profile-modal"
            >
                <form onSubmit={onSubmit}>
                    <Row type='flex' justify='center' className="tab-body">
                        <Col span={24}>
                            <FormSection name="provider_attributes">
                                <Field
                                    name={`verify_docs[1].issueBy`}
                                    placeholder=""
                                    component={Input}
                                    label="Issuing State or Authority"
                                    tooltipText={(
                                        <div className="info-popup">
                                            <span className="desc">Provide license issuing state or authority.</span>
                                        </div>)}
                                    isRequired={true}
                                />
                                <Field
                                    name={`verify_docs[1].number`}
                                    placeholder=""
                                    component={Input}
                                    label="License Number"
                                    tooltipText={(
                                        <div className="info-popup">
                                            <span className="desc">Provide the license number.</span>
                                        </div>)}
                                    isRequired={true}
                                />
                                <Field
                                    name={`verify_docs[1].validTill`}
                                    component={DatePicker}
                                    label="Expiration Date"
                                    dateformat="MM/DD/YYYY"
                                    disabledDate={d => !d || d.isBefore(moment(), "day")}
                                    validate={[validate.preventPastDate]}
                                    isRequired={true}
                                />
                                <Field
                                    name={`verify_docs[1].doc_url[0]`}
                                    component={DraggerFileUpload}
                                    context='documents'
                                    maxFileSize={4}
                                    fileSizeType="mb"
                                    isRequired={true}
                                />
                            </FormSection>
                            <div className="form-error">{error}</div>
                        </Col>
                    </Row>
                </form>
            </Modal>
        );
    }
}

class WorkLicense extends Component {

    state = {
        isUpdateMode: false
    };

    handleToggleMode = (isUpdateMode) => {
        this.setState({ isUpdateMode });
    };

    handleUpdate = () => {
        this.handleToggleMode(true);
    };

    handleSubmit = async (values) => {
        const { updateUserDetail, initialize, reset } = this.props;

        try {
            await updateUserDetail(values);
            reset();
            initialize(values);
            this.handleToggleMode(false);
        } catch (e) {
            throw new SubmissionError({ _error: e.message });
        }
    };

    handleCancel = () => {
        this.handleToggleMode(false);
    };

    render() {

        const { isUpdateMode } = this.state;
        const {
            handleSubmit,
            user,
            error
        } = this.props;
        const { provider_attributes } = user;
        const docName = provider_attributes.verify_docs &&
            provider_attributes.verify_docs.length >= 2 &&
            provider_attributes?.verify_docs[1] &&
            provider_attributes?.verify_docs[1]?.doc_url[0] ?
            provider_attributes?.verify_docs[1]?.doc_url[0].split('/') :
            [];
        return (
            <div className="personal-detail driving-license">
                <form onSubmit={handleSubmit(this.handleSubmit)} >
                    <Row className="tab-header">
                        <Col span={24}>
                            <h3 className="title">
                                <span>Professional License</span>
                                <AiOutlineEdit onClick={this.handleUpdate} size={20} />
                            </h3>
                        </Col>
                    </Row>

                    <Row type='flex' justify='center' className="tab-body">
                        {
                            (
                                Array.isArray(provider_attributes.verify_docs) &&
                                provider_attributes.verify_docs.length > 1 &&
                                provider_attributes.verify_docs[1]
                            )
                                ?
                                <Col span={22}>
                                    <div className="profile-field">
                                        <div className="profile-item">
                                            <label>Issuing State or Authority:</label>
                                            <span className="text">{provider_attributes.verify_docs[1].issueBy}</span>
                                        </div>

                                        <div className="profile-item">
                                            <label>License Number:</label>
                                            <span className="text">{provider_attributes.verify_docs[1].number}</span>
                                        </div>

                                        <div className="profile-item">
                                            <label>Expiration Date:</label>
                                            <span className="text">
                                                {moment(provider_attributes.verify_docs[1].validTill).format("ll")}
                                            </span>
                                        </div>
                                        <div className="profile-item">
                                            <label>Document:</label>
                                            <span className="text">
                                                {provider_attributes.verify_docs[1].doc_url[0] &&
                                                    <a
                                                        href={
                                                            window._env.REACT_APP_PUBLIC_URL_HOST +
                                                            provider_attributes.verify_docs[1].doc_url[0]
                                                        }
                                                        target="_blank"
                                                        title="click here view or download"
                                                        rel="noopener noreferrer">
                                                        {docName[docName.length - 1]}
                                                    </a>
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </Col>

                                :

                                <div className='text-center'>
                                    <FaRegIdCard size={40} color='#ff4760'/>
                                    <h6 className="text-center">
                                        {window._env.REACT_APP_UPDATE_LICESNCE_INFORMATION_MSG_PROVIDER}
                                    </h6>
                                </div>
                        }
                    </Row>
                </form>
                <EditWorkLicense
                    onSubmit={handleSubmit(this.handleSubmit)}
                    onClose={this.handleCancel}
                    isVisible={isUpdateMode}
                    error={error}
                    isLoading={user.isLoading}
                />
            </div>
        );
    }
}

export default reduxForm({
    form: 'work-license',
    enableReinitialize: true
})(WorkLicense);
