
/**
 * Set app data
 * @param {object} payload app data
 * @returns {object} updated payload
 */
export const setApp = (payload = {}) => ({
    type: 'SET_APP',
    payload
});

/**
 * Toggel side menu
 * @returns {object} payload
 */
export const toggleSideMenu = () => (dispatch, getState) => {
    const { app: { isOpenSideMenu } } = getState();
    return dispatch(setApp({ isOpenSideMenu: !isOpenSideMenu }));
};
