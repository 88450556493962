import React from "react";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { Spin } from 'antd';

import { Login, Signup, ForgotPassword, SignupPro } from "./containers/auth";
import Home from "./containers/home";
// import AccountManagement from "./containers/accountManagement";

// import registration from "./containers/registration";
import SearchDetail from './components/searchDetail';
import Provider from "./components/provider";
import Consumer from "./components/consumer";
import ExpiredPage from "./components/expiredPage/expiredPage";
import FrequentlyAskedQuestions from './containers/frequentlyAskedQuestions';
import TermsAndCondition from './containers/termsAndCondition';
import PrivacyPolicy from './containers/privacyPolicy';
import AboutUs from './containers/aboutUs';
import PageNotFound from './components/pageNotFound';
import BetaReleaseDisclaimer from './containers/betaReleaseDisclaimer';
import HowItWorksForProvider from "./containers/howItWorksForProvider";
import HowItWorksForSeeker from "./components/how_it_works_for_seeker";
import ContactUs from './containers/contactUs';
import CareerPage from './containers/careers';
import ErrorComponent from "./components/errorPage";
import Header from "./components/header";
import Footer from "./components/Footer";

import { logout } from './actions/user';
import { toggleSideMenu } from "./actions/app";
import { setSearchDetail, getSearch, searchStore } from "./actions/searchDetail";
import { getUnreadMessageCount } from './actions/message';

import ProProfile from "./components/proProfile";

function Router({
    logout,
    toggleSideMenu,
    onSetSearch,
    getSearch,
    searchStore,
    getUnreadMessageCount
}) {

    let user = useSelector(st => st.user);
    let history = useHistory();
    let location = useLocation();
    let props = { history, location };
    const { isAuthenticated, scope } = user;
    let { isAppLoader } = useSelector(st => st.app);


    async function onLogout() {
        await logout();
        history.push('/');
    }

    history.listen((location, action) => {
        if (action === 'PUSH' && window.HubSpotConversations)
            window.HubSpotConversations.widget.refresh();
    });

    if (isAppLoader) {
        return (
            <Spin style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100%' }} size="large"></Spin>
        );
    }

    function LayoutWrapper({ Component, ...props }) {
        return (
            <div className="flex-fill container p-2 p-md-3 p-lg-4">
                <Component {...props} />
            </div>
        );
    }

    const restrictedHeader = ["/", "/ourVision", '/how-it-works-for-provider', '/how-it-works-for-seeker'];

    return (
        <div className="main-layout d-flex flex-column" style={{ minHeight: '100vh' }}>
            {!(restrictedHeader.includes(location.pathname)) &&
                < Header
                    onLogout={onLogout}
                    toggleSideMenu={toggleSideMenu}
                    onSetSearch={onSetSearch}
                    getSearch={getSearch}
                    searchStore={searchStore}
                    {...{
                        getUnreadMessageCount
                    }}
                />
            }

            <Switch>
                <Route path="/" exact>
                    {
                        isAuthenticated
                            ?
                            scope === 'PROVIDER'
                                ?
                                <Redirect to='/seller/orders' />
                                :
                                <Redirect to='/search' />
                            :
                            <Home {...props} />
                    }
                </Route>
                <Route path="/homepage" exact><Home {...props} /></Route>
                <Route path="/expired" exact><ExpiredPage {...props} /></Route>
                <Route path="/how-it-works-for-provider" exact><HowItWorksForProvider {...props} /></Route>
                <Route path="/how-it-works-for-seeker" exact><HowItWorksForSeeker {...props} /></Route>
                <Route path="/search" exact><SearchDetail {...props} /></Route>
                <Route path="/ourVision" exact><AboutUs {...props} /></Route>
                <Route path="/career" exact><CareerPage {...props} /></Route>
                <Route path="/beta-release-disclaimer" exact><BetaReleaseDisclaimer {...props} /></Route>
                <Route path="/faq" exact><FrequentlyAskedQuestions {...props} /></Route>
                <Route path="/contact-us" exact><ContactUs {...props} /></Route>
                <Route path="/termsandcondition" exact><TermsAndCondition {...props} /></Route>
                <Route path="/privacypolicy" exact><PrivacyPolicy {...props} /></Route>
                <Route path="/login" exact><Login {...props} /></Route>
                <Route path="/signup" exact><Signup {...props} /></Route>
                <Route path="/pro-signup" exact><SignupPro {...props} /></Route>
                <Route path="/forgot-password" exact><ForgotPassword {...props} /></Route>
                <Route path="/error" exact><LayoutWrapper Component={ErrorComponent} {...props} /></Route>
                <Route path="/store/:username"><LayoutWrapper Component={ProProfile} /></Route>

                {
                    // cookie is for checking user info during initial load, and redux state is for all other scenarios
                    (isAuthenticated)
                        ?
                        (scope === 'CONSUMER')
                            ?
                            <Switch>
                                <Route path="/buyer"><Consumer /></Route>
                                <Route path='*'><PageNotFound {...props} /></Route>
                            </Switch>
                            :
                            <Switch>
                                <Route path="/seller"><Provider /></Route>
                                <Route path='*'><PageNotFound {...props} /></Route>
                            </Switch>
                        :
                        <Redirect to='/login' />
                }
                <Route path='*'>
                    {
                        // cookie is for checking user info during initial load, and redux state is for all other scenarios
                        (isAuthenticated)
                            ?
                            <PageNotFound {...props} />
                            :
                            <Login />
                    }

                </Route>
            </Switch>
            {!(isAuthenticated) && <Footer history={history} />}
        </div>
    );
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    logout,
    toggleSideMenu,
    onSetSearch: setSearchDetail,
    getSearch,
    getUnreadMessageCount,
    searchStore
}, dispatch);

export default connect(null, mapDispatchToProps)(Router);
