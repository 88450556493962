import React from "react";
import { Button, Col, Popconfirm, Popover, Row, Switch } from "antd";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { IoFastFoodOutline } from 'react-icons/io5';
import { FcInTransit } from 'react-icons/fc';
import { useDispatch } from "react-redux";
import { updateAddons } from '../../../actions/addons';

function AddonItem({
    _id,
    name,
    description,
    price,
    addon_image,
    delivery_addon,
    user_enabled,
    deleteAddonsById,
    setSelectedAddon,
    setVisible,
    refresh
}) {

    const dispatch = useDispatch();
    function getImage() {
        let image = "";
        if (addon_image) {
            image = addon_image;
        }

        return image;
    }

    async function handleChangeRadio(status) {
        let product = { _id, name, description, price, user_enabled: status, addon_image };
        await dispatch(updateAddons(product));
        await refresh();
    }


    return (

        <Col xs={24} md={12} xl={10}>
            <Row type="flex" align="stretch" justify="space-between" className="border rounded h-100">
                <Col span={8} className='d-flex align-items-center justify-content-center'>
                    {
                        getImage() ?
                            <img src={window._env.REACT_APP_PUBLIC_URL_HOST + getImage()}
                                alt="Product main img" className='img-fluid rounded-left'
                                style={{ maxHeight: 225 }}
                            />
                            :
                            delivery_addon
                                ?
                                <FcInTransit size={90} color='#1c98d6' />
                                :
                                <IoFastFoodOutline size={90} color='#1c98d6' />
                    }
                </Col>

                <Col span={16} className='d-flex flex-column pl-2 pt-1'>
                    <h6 className="card-title mb-1">{name}</h6>
                    <div className='text-muted mb-2' style={{ fontSize: 14 }}>${price}</div>
                    <div className='mb-2' style={{ width: '80%', overflow: 'hidden', fontSize: 14, textOverflow: 'ellipsis' }}>{description}</div>

                    <div className='mt-auto mb-2'>

                        <Popover
                            content={
                                <div className="info-popup">
                                    <span className="desc">
                                        Edit this add-on
                                    </span>
                                </div>
                            }
                            trigger="hover" placement='rightTop'
                        >
                            <Button type="default" icon={<AiOutlineEdit />}
                                onClick={() => {
                                    setSelectedAddon({ name, _id, price, description, addon_image, delivery_addon, user_enabled });
                                    setVisible(true);
                                }} shape="circle" className='mx-2'
                            />
                        </Popover>

                        <Popconfirm
                            placement="top"
                            title={"Are you sure you want to delete this addon ?"}
                            onConfirm={async () => {
                                let res = await deleteAddonsById(_id);
                                if (res) refresh();
                            }}
                            okText="Yes"
                            cancelText="No"
                            className="delete"
                        >
                            <Button type="default" icon={<AiOutlineDelete />} shape="circle" className='ml-2' />
                        </Popconfirm>

                        <Popover
                            content={
                                <div className="info-popup">
                                    <span className="desc">
                                        enable/disable this add-on
                                    </span>
                                </div>
                            }
                            trigger="hover" placement='rightTop'
                        >
                            <Switch
                                defaultChecked={user_enabled}
                                size='small' className='mx-2'
                                onChange={(checked) => handleChangeRadio(checked)}
                            />
                        </Popover>
                    </div>
                </Col>

            </Row>
        </Col>
    );
}

export default AddonItem;