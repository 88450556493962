import React from 'react';
import { Col, Row } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

import welcome from '../images/landingPage/desktop/Source-05.svg';
import welcome_mobile from '../images/landingPage/mobile/Source-09.svg';

import './style.scss';

export default function Section3() {

    const breakpoint = useBreakpoint();

    return (
        <Row type="flex" justify="center" align="middle" className='position-relative section-top-margin'
            id='buy_services_online'>

            <Col xs={24} lg={11} xl={{ span: 9, offset: 1 }} xxl={{ span: 6, offset: 4 }} className='text-center'>
                <img className="img img-fluid"
                    src={breakpoint.lg ? welcome : welcome_mobile} alt="Welcome To Foodelys" />
            </Col>

            <Col xs={24} lg={{ span: 12, offset: 1 }} xl={{ span: 11, offset: 2 }} xxl={{ span: 8, offset: 2 }} className="p-3">
                <h2 className="section-heading">
                    Let cooking be the language of love!
                </h2>
                <div className={breakpoint.md ? "section-content mt-4" : "section-content-mobile mt-3"}>
                    Find cooking gigs in your vicinity and be the most favourite <strong>cook-on-demand</strong>.
                    Get along with your friends to form shared kitchens and find your first client on Foodelys!
                </div>

            </Col>

            <Col xs={0} xl={1} xxl={4}></Col>

        </Row>
    );
}