import React from "react";
import { Row, Col } from 'antd';
import Modal from "antd/lib/modal/Modal";
import { AiOutlineCloseCircle } from "react-icons/ai";
import moment from 'moment';
import { useSelector } from "react-redux";
import './styles.scss';

export default function OrderDetail({ handleClose, isVisible, order }) {

    const {
        invoice_total, lineItems = [], payment_method, delivery_method, da, pa, allergies, delivery_date_time, buyer, distance
    } = order;

    const addonsLength = lineItems.filter(e => e.type === 'ADDON' && !e.is_delivery_addon).length;
    const deliveryLength = lineItems.filter(e => e.type === 'ADDON' && e.is_delivery_addon).length;
    const productsLength = lineItems.filter(e => e.type === 'PRODUCT').length;

    const { scope } = useSelector(st => st.user);

    return (
        <Modal
            title='Order Detail'
            visible={isVisible}
            closable
            centered
            onCancel={handleClose}
            closeIcon={<AiOutlineCloseCircle size={20} color='#fff' />}
            destroyOnClose={true}
            footer={null}
            width={450}
            className='order-detail-modal'
        >
            <div>

                {
                    scope === 'PROVIDER' ?
                        <React.Fragment>
                            <h5 className='mb-1'>
                                Buyer Contact Info
                            </h5>
                            <p className='mb-4'>
                                {buyer.name}
                            </p>
                        </React.Fragment>
                        :
                        null
                }

                {
                    delivery_method === 'DELIVERY' ?
                        <React.Fragment>
                            <h5 className={scope === 'PROVIDER' ? "mt-4 mb-1" : 'mb-1'}>
                                Delivery Address
                            </h5>
                            <p>
                                {`${da.street_1}${da.street_2 ? `, ${da.street_2}` : ''}${da.city ? `, ${da.city}` : ''}, ${da.state}, ${da.postal_code} ${distance ? `(approx ${distance} miles)` : ''}`}
                            </p>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <h5 className="mt-4 mb-1">
                                Pickup Loc.
                            </h5>
                            <p>
                                {pa.display_address}
                            </p>
                        </React.Fragment>
                }

                {
                    delivery_date_time ?
                        <React.Fragment>
                            <h5 className="mt-4 mb-1">
                                Delivery Date (Plan Ahead Order)
                            </h5>
                            <p>
                                {moment(delivery_date_time).format('lll')}
                            </p>
                        </React.Fragment>
                        :
                        null
                }

                {
                    payment_method ?
                        <React.Fragment>
                            <h5 className="mt-4 mb-1">
                                Payment
                            </h5>
                            <p style={{ textTransform: 'capitalize' }}>
                                {payment_method}
                            </p>
                        </React.Fragment>
                        :
                        null
                }

                <h5 className='mb-1'>
                    Items
                </h5>

                {
                    productsLength && lineItems.filter(e => e.type === 'PRODUCT').map((e, key) => (
                        <Row key={key} className={key === productsLength ? 'mb-1' : ''}>
                            <Col xs={18} sm={13}>
                                {e.title}
                            </Col>
                            <Col xs={3} sm={4}>
                                {e.quantity} qty.
                            </Col>
                            <Col xs={3} sm={7}>
                                ${e.amount}
                            </Col>
                        </Row>
                    ))
                }

                {
                    addonsLength
                        ?
                        <React.Fragment>
                            <h5 className="mt-4 mb-1">
                                Extras
                            </h5>
                            {
                                lineItems.filter(e => e.type === 'ADDON' && !e.is_delivery_addon).map((e, key) => (
                                    <Row key={key} className={key === addonsLength - 1 ? 'mb-1' : ''}>
                                        <Col xs={18} sm={13}>
                                            {e.title}
                                        </Col>
                                        <Col xs={3} sm={4}>
                                            {e.quantity} qty.
                                        </Col>
                                        <Col xs={3} sm={7}>
                                            ${e.amount}
                                        </Col>
                                    </Row>

                                ))
                            }
                        </React.Fragment>
                        :
                        null
                }

                {
                    deliveryLength
                        ?
                        <React.Fragment>
                            <h5 className="mt-4 mb-1">
                                Delivery
                            </h5>
                            {
                                lineItems.filter(e => e.type === 'ADDON' && e.is_delivery_addon).map((e, key) => (
                                    <Row key={key} className={key === deliveryLength - 1 ? 'mb-1' : ''}>
                                        <Col xs={18} sm={13}>
                                            {e.title}
                                        </Col>
                                        <Col xs={3} sm={4}>
                                        </Col>
                                        <Col xs={3} sm={7}>
                                            ${e.amount}
                                        </Col>
                                    </Row>

                                ))
                            }
                        </React.Fragment>
                        :
                        null
                }

                {
                    allergies ?
                        <React.Fragment>
                            <h5 className="mt-4 mb-1">
                                Allergies
                            </h5>
                            <p>
                                {allergies}
                            </p>
                        </React.Fragment>
                        :
                        null
                }

                <Row className='mb-1 mt-4'>
                    <Col xs={18} sm={13}>
                        <h5>
                            Total (Est.)
                        </h5>
                    </Col>
                    <Col xs={3} sm={4}>
                    </Col>
                    <Col xs={3} sm={7}>
                        ${invoice_total}
                    </Col>
                </Row>

            </div>
        </Modal >
    );
}
