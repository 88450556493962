import React, { PureComponent } from "react";
import Slider from "react-slick";

import img1 from "../../images/banners-1.png";
import img2 from "../../images/banners-2.png";
import img3 from "../../images/banners-3.png";
import img4 from "../../images/banners-4.png";
import img5 from "../../images/banners-5.png";
const banner_delay = parseInt(window._env.REACT_APP_BANNER_SCROLL_DELAY);

const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: banner_delay,
    rtl: false,
    arrows: false,
    appendDots: (dots) => <ul>{dots}</ul>,
    customPaging: (i) => <div className="slick-dot-item" />,
};

const data = [
    {
        head: window._env.REACT_APP_BANNER_ONE_TITLE,
        body: window._env.REACT_APP_BANNER_ONE_MESSAGE,
        img: img1
    }, {
        head: window._env.REACT_APP_BANNER_TWO_TITLE,
        body: window._env.REACT_APP_BANNER_TWO_MESSAGE,
        img: img2
    }, {
        head: window._env.REACT_APP_BANNER_THREE_TITLE,
        body: window._env.REACT_APP_BANNER_THREE_MESSAGE,
        img: img3
    }, {
        head: window._env.REACT_APP_BANNER_FOUR_TITLE,
        body: window._env.REACT_APP_BANNER_FOUR_MESSAGE,
        img: img4
    }, {
        head: window._env.REACT_APP_BANNER_FIVE_TITLE,
        body: window._env.REACT_APP_BANNER_FIVE_MESSAGE,
        img: img5
    }
];

class BannerSlider extends PureComponent {
    render() {
        return (
            <div className="search-banner-slider">
                <Slider {...settings}>
                    {
                        data.map((e, i) => (
                            <div className="banner-item position-relative" key={i}>
                                <img src={e.img} alt="slide 1" className='d-none d-md-inline-block' />
                                <div className='d-flex flex-column align-items-center justify-content-center position-absolute p-3 w-100 h-100' style={{ top: 0, left: 0 }}>
                                    <h5 className="banner-style1">
                                        {e.head}
                                    </h5>
                                    <h6 className="banner-msg-style1">
                                        {e.body}
                                    </h6>
                                </div>
                            </div>
                        ))
                    }


                </Slider>
            </div>
        );
    }
}

export default BannerSlider;
