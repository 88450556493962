import React, { Component } from "react";
import { reduxForm, Field, SubmissionError, FormSection } from "redux-form";
import { Row, Col, Button, Modal } from "antd";
import { AiOutlineCloseCircle, AiOutlineUserAdd } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import { load } from "recaptcha-v3";
import { Input, Checkbox, validate } from "../../common/redux-form";
import "./styles.scss";
import messages from "../../constants/constants";
import { validatePostalCodeAndState } from "../../services/utility";
import sanitize from "sanitize-html";
import { toast } from 'react-toastify';

const scope = "CONSUMER";

class Signup extends Component {

    state = {
        captchaScript: null,
        isNotValidStateCode: false,
        isLoad: false,
        postal_code: "",
        isReadyToKeep: false,
        isNotValidEmail: "",
    };

    async componentDidMount() {
        const { recaptchaEnabled, page_visit } = this.props;
        page_visit('/signup');
        if (recaptchaEnabled) {
            let captchaScript = await load(window._env.REACT_APP_CAPTCHAV3_SITE_KEY);
            this.setState({ captchaScript });
        }
    }

    componentDidUpdate() {
        const { isAuthenticated, history } = this.props;
        if (isAuthenticated) {
            history.push('/');
        }
    }

    saveAsLead = async (values) => {
        const { AddLead, resetSignUpSeekerForm } = this.props;
        const lead = {
            status: "DRAFT",
            lead_info: {
                type: scope,
                first_name: values.first_name,
                last_name: values.last_name,
                email: values.email,
                phone_number: values.phone_number,
            },
        };
        try {
            const suc = await AddLead(lead);
            if (suc) {
                this.setState({ isNotValidStateCode: false, isLoad: false });
                await resetSignUpSeekerForm();
            }
        } catch (error) {
            this.setState({ isNotValidStateCode: false, isLoad: false });
            throw new SubmissionError({ _error: error.message });
        }
    };

    handleCaptcha = async () => {
        if (this.state.captchaScript) {
            let token = await this.state.captchaScript.execute("signup");
            return token;
        } else {
            toast.info('Please wait for captcha to load');
            throw Error('Please wait for captcha to load');
        }
    };

    handleSubmit = async (values) => {
        const {
            signup,
            recaptchaEnabled,
        } = this.props;

        try {
            const { postal_code, isKeep } = values;

            this.setState({email: values.email});

            if (this.state.isNotValidStateCode && isKeep) {
                this.setState({ isLoad: true });
                this.saveAsLead(values);
                this.props.history.push('/');
                return true;
            }

            const isNotValidStateCode = await validatePostalCodeAndState(
                true,
                "NC",
                postal_code
            );

            if (!isNotValidStateCode) {
                return this.setState({
                    isNotValidStateCode: true,
                    postal_code,
                });
            } else {
                this.setState({ isNotValidStateCode: false });
            }

            if (
                !values.provider_attributes ||
                (
                    values.provider_attributes &&
                    !values.provider_attributes.registration_consents.term_condition.termsAndAgreement
                )
            ) {
                throw new Error("Terms and agreement is required.");
            }

            if (recaptchaEnabled && this.state.captchaScript) {
                let token = await this.handleCaptcha();
                values.RecaptchaToken = token;
            }

            if (!values.postal_code) {
                throw new Error("Zip code is required.");
            }

            const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
            if (values.email && !regex.test(values.email)) {
                this.setState({ isNotValidEmail: 1 });
            } else {
                const res = await this.props.emailDisposal(values.email);
                if (res) {
                    this.setState({ isNotValidEmail: 2 });
                } else {
                    this.setState({ isNotValidEmail: 0 });
                }
            }

            if (this.state.isNotValidEmail === 2) {
                const { recaptchaIssue } = await signup({
                    ...values,
                    username: values.email,
                    scope,
                });
                if (!recaptchaIssue) {
                    this.setState({ isLoad: false, isVisible: true });
                } else {
                    await this.handleSubmit(values);
                }
            }

        } catch (error) {
            if (error.name === 'SubmissionError')
                throw new SubmissionError(error.errors);
            throw new SubmissionError({ _error: error.message });
        }
    };

    handleChange = (event) => {
        let isReadyToKeep = event.target.checked;
        this.setState({ isReadyToKeep });
    };

    resendConfirmationEmail = async () => {

        const {
            resend_confirmation_email,
            recaptchaEnabled,
        } = this.props;

        let body = { username: this.state.email };

        if (recaptchaEnabled && this.state.captchaScript) {
            let token = await this.handleCaptcha();
            body.RecaptchaToken = token;
        }

        try {
            await resend_confirmation_email(body);
        } catch (err) {
        }
    };

    render() {
        const {
            submitting,
            handleSubmit,
            error,
            // googleCaptchaKey,
            isLoading,
            recaptchaEnabled
        } = this.props;
        const { isReadyToKeep, isLoad } = this.state;

        return (
            <Row className="auth-wrapper mb-5" type="flex" justify="center">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title> Sign Up As A Foodie | Foodelys </title>{" "}
                </Helmet>{" "}
                <Col
                    span={6}
                    xs={22}
                    sm={17}
                    md={15}
                    lg={10}
                    xl={9}
                    className="form-wrapper"
                >
                    <h4 className="title">
                        <AiOutlineUserAdd size={20} />
                        Sign Up As A Foodie{" "}
                    </h4>{" "}
                    <div className="form-content">
                        <form onSubmit={handleSubmit(this.handleSubmit)}>
                            <Row>
                                <Col span={11}>
                                    <Field
                                        name="first_name"
                                        placeholder="Enter first name"
                                        component={Input}
                                        label="First Name"
                                        validate={[
                                            validate.required,
                                            validate.name,
                                        ]}
                                        isRequired={true}
                                    />{" "}
                                </Col>{" "}
                                <Col span={12} offset={1}>
                                    <Field
                                        name="last_name"
                                        placeholder="Enter last name"
                                        component={Input}
                                        label="Last Name"
                                        validate={[
                                            validate.required,
                                            validate.name,
                                        ]}
                                        isRequired={true}
                                    />{" "}
                                </Col>{" "}
                            </Row>{" "}
                            <Field
                                name="email"
                                placeholder="Enter email"
                                component={Input}
                                label="Email Address"
                                type="email"
                                validate={[validate.required]}
                                isRequired={true}
                            />{" "}
                            {
                                this.state.isNotValidEmail === 1 ?
                                    <div
                                        style={{
                                            color: "#ff0000",
                                            textAlign: "center",
                                        }}
                                    >
                                        Please Enter Valid Email
                                    </div>
                                    :
                                    this.state.isNotValidEmail === 0 ?
                                        <div
                                            style={{
                                                color: "#ff0000",
                                                textAlign: "center",
                                            }}
                                        >
                                            This email is not allowed, please use a
                                            valid email
                                        </div>
                                        :
                                        <div></div>
                            }
                            <Field
                                name="password"
                                placeholder="Enter password"
                                component={Input}
                                label="Password"
                                type="password"
                                validate={[
                                    validate.required,
                                    validate.password,
                                ]}
                                isRequired={true}
                            />{" "}
                            <Field
                                name="postal_code"
                                placeholder="Enter zip code"
                                component={Input}
                                label="Zip Code"
                                validate={[validate.required, validate.zipcode]}
                                maxLength={5}
                                isRequired={true}
                            />{" "}
                            {!this.state.isNotValidStateCode ?
                                <FormSection name="provider_attributes">
                                    <Field
                                        name="registration_consents.term_condition.termsAndAgreement"
                                        component={Checkbox}
                                        label={
                                            <span dangerouslySetInnerHTML={{
                                                __html: sanitize(
                                                    window._env.REACT_APP_SIGNUP_CONSENT_MESSAGE,
                                                    { allowedTags: ['a'], allowedAttributes: { 'a': ['href'] } }
                                                )
                                            }}></span>
                                        }
                                        isRequired={true}
                                    />
                                </FormSection>
                                :
                                <div>
                                    <div className="error-container">
                                        <small className="error-small-msgs">
                                            {" "}
                                            {messages.InvalidState}{" "}
                                        </small>{" "}
                                    </div>{" "}
                                    <br />{" "}
                                    <Field
                                        name="isKeep"
                                        component={Checkbox}
                                        label={window._env.REACT_APP_CONSENT_TO_STORE_INFO_MESSAGE}
                                        isRequired={true}
                                        onChange={this.handleChange}
                                    />{" "}
                                </div>
                            }
                            {" "}
                            <div className="form-error"> {error} </div>{" "}
                            <Button shape='round'
                                className="btn-primary btn-submit"
                                type="primary"
                                size={"large"}
                                htmlType="submit"
                                icon={<AiOutlineUserAdd size={20} />}
                                loading={submitting || isLoading || isLoad}
                                disabled={recaptchaEnabled && !this.state.captchaScript}
                            >
                                {!isReadyToKeep ? "Sign Up" : "Submit"}
                            </Button>
                        </form>
                        <Row className="other-options">
                            <Col span={24}>
                                {" "}
                                {" "}
                                <p>
                                    Already Registered ?{" "}
                                    <Link to='/login'>
                                        {" "}
                                        Login{" "}
                                    </Link>{" "}
                                </p>{" "}
                            </Col>{" "}
                        </Row>{" "}
                    </div>{" "}
                </Col>{" "}

                <Modal
                    title='Almost There !!'
                    visible={this.state.isVisible}
                    closable
                    centered
                    onCancel={() => this.setState({ isVisible: false })}
                    closeIcon={<AiOutlineCloseCircle size={20} color='#fff' />}
                    destroyOnClose={true}
                    footer={
                        <React.Fragment>
                            <Button onClick={() => this.resendConfirmationEmail()} type='primary' shape='round' >
                                Resend Confirmation Email
                            </Button>
                            <Button onClick={() => this.props.history.push('/login')} type='primary' shape='round' >
                                Login
                            </Button>
                        </React.Fragment>
                    }
                    width={450}
                >
                    <p>Please verify email address by clicking on the link sent in the confirmation email.</p>
                    <p><strong>Note: Please check your spam and promotional folder.</strong></p>
                </Modal >
            </Row>
        );
    }
}

export default reduxForm({
    form: "signup",
    enableReinitialize: true,
})(Signup);
