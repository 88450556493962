import React, { useEffect, useState } from "react";
import { Row, Spin } from "antd";
import "./career.scss";
import sanitize from "sanitize-html";
import PageNotFound from '../pageNotFound';

const career_page_enabled = JSON.parse(window._env.REACT_APP_ENABLE_CAREER_PAGE);
const CareerPage = (props) => {

    const { getManualDataByName, manualData, isManualLoading, page_visit } = props;
    const [loadContent, setLoadContent] = useState(true);
    
    page_visit('/career');

    useEffect(() => {
        const fetchData = async () => {
            let res = await getManualDataByName(window._env.REACT_APP_STATIC_MANUAL_NAME_CAREER);
            if (res) {
                setLoadContent(false);
            }
        };
        fetchData();
    }, [getManualDataByName]);
    if (loadContent) {
        return (
            <Spin
                size="large"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
                spinning={isManualLoading}
            ></Spin>
        );
    } else {
        return (  
            <Row>
                <div className="career_container">
                    <div className="page-title">
                        <h4>Open Jobs</h4> 
                    </div>
                    <div className="col s12 l8 faqs-content">
                        {manualData.chapters.map((chapter, index) => {
                            return (
                                <div key={index}>
                                    <div className="chapter-header">
                                        <h6 className="career-manuals-h4" id={chapter.chapter_title}>
                                            {chapter.chapter_title}
                                        </h6>
                                    </div>
                                    <span className="career-description career-span-style" 
                                        dangerouslySetInnerHTML={{ __html: sanitize(chapter.chapter_description,
                                            { allowedTags: ['p','strong','a','li','ul','ol','br','h1','h2','h3','h4','h5','h6'], 
                                                allowedAttributes: {'a': ['href']}})}}>
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Row>
        );
    }

    
};


const render_page = (props) => {
    return (
        career_page_enabled ? <CareerPage {...props}/>:<PageNotFound/>
    );

}; 


export default render_page;