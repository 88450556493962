import React from 'react';
import { Row, Col } from 'antd';

export default function ReviewOrderDetail({ order }) {
    const {
        seller: {
            store_name
        },
        invoice_detail: {
            lineItems,
        },
        order_picture
    } = order;

    return (
        <Row gutter={[16, 16]}>

            <Col xs={24} sm={6} md={4}>
                {
                    order_picture ?
                        <img src={window._env.REACT_APP_PUBLIC_URL_HOST + order_picture} alt='' className='img img-fluid' />
                        :
                        ''
                }
            </Col>
            <Col xs={24} sm={18} md={12}>
                <h4 className='mb-4'>{store_name}</h4>

                <Row>
                    <Col xs={24} sm={12}>

                        <h6 className='mb-0 h6'>
                            Items
                        </h6>

                        {
                            lineItems && Array.isArray(lineItems) && lineItems.filter(e => e.type === 'PRODUCT').map((e, key) => (
                                <Row key={key} className='mt-2'>
                                    <Col xs={18} sm={13}>
                                        {e.title}
                                    </Col>
                                    <Col xs={6} sm={11}>
                                        {e.quantity} qty.
                                    </Col>
                                </Row>
                            ))
                        }

                    </Col>
                    <Col xs={24} sm={12}>
                        {
                            Array.isArray(lineItems) && lineItems.filter(e => e.type === 'ADDON').length > 0
                                ?
                                <React.Fragment>
                                    <h6 className="mb-0 h6">
                                        Add-Ons
                                    </h6>
                                    {
                                        lineItems && Array.isArray(lineItems) && lineItems.filter(e => e.type === 'ADDON').map((e, key) => (

                                            <Row key={key} className='mt-2'>
                                                <Col xs={18} sm={13}>
                                                    {e.title}
                                                </Col>
                                                <Col xs={6} sm={11}>
                                                    {e.quantity} qty.
                                                </Col>
                                            </Row>

                                        ))
                                    }
                                </React.Fragment>
                                :
                                null
                        }
                    </Col>
                </Row>

            </Col>
        </Row>
    );
}