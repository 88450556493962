import React, { Component } from "react";
import { Row, Col, Modal } from "antd";
import { Field, reduxForm, SubmissionError, FormSection } from "redux-form";
import { AiOutlineUser, AiOutlineEdit } from "react-icons/ai";

import { Input, validate } from "../../common/redux-form";

class EditBio extends Component {
    render() {
        const { isVisible, onSubmit, onClose, error, isLoading } = this.props;

        const title = (
            <div>
                <AiOutlineUser style={{ paddingRight: "10px" }} size={20} />
                Intro/Bio
            </div>
        );

        return (
            <Modal
                title={title}
                visible={isVisible}
                okButtonProps={{ htmlType: "submit", loading: isLoading }}
                cancelButtonProps={{ disabled: isLoading }}
                okText="Save"
                onCancel={onClose}
                onOk={onSubmit}
                keyboard={false}
            >
                <form onSubmit={onSubmit}>
                    <Row type="flex" justify="center" className="tab-body">
                        <Col span={24}>
                            <FormSection name="provider_attributes">
                                <Field
                                    component={Input}
                                    name="bio"
                                    placeholder=""
                                    label="Intro/Bio"
                                    validate={[
                                        validate.required,
                                        validate.maxLength1000,
                                    ]}
                                    type="textarea"
                                    isRequired={true}
                                    tooltipText={
                                        <div className="info-popup">
                                            <span className="desc">
                                                A short description or story of
                                                business.
                                            </span>
                                        </div>
                                    }
                                />
                            </FormSection>
                            <div className="form-error">{error}</div>
                        </Col>
                    </Row>
                </form>
            </Modal>
        );
    }
}

class Bio extends Component {
    state = {
        isUpdateMode: false,
    };

    handleToggleMode = (isUpdateMode) => {
        this.setState({ isUpdateMode });
    };

    handleUpdate = () => {
        this.handleToggleMode(true);
    };

    handleSubmit = async (values) => {
        const {
            updateUserDetail,
            initialize,
            reset,
            initialValues,
        } = this.props;

        try {
            await updateUserDetail(values);
            reset();
            initialize({ ...initialValues, ...values });
            this.handleToggleMode(false);
        } catch (e) {
            throw new SubmissionError({ _error: e.message });
        }
    };

    handleCancel = () => {
        this.handleToggleMode(false);
    };

    render() {
        const { isUpdateMode } = this.state;
        const { handleSubmit, user, error } = this.props;
        const { provider_attributes: providerAttributes = {} } = user;

        return (
            <div className="personal-detail mb-5 mt-5">
                <form onSubmit={handleSubmit(this.handleSubmit)}>
                    <Row className="tab-header">
                        <Col span={24}>
                            <h3 className="title">
                                <span>About Store</span>
                                <AiOutlineEdit
                                    onClick={this.handleUpdate}
                                />
                            </h3>
                        </Col>
                    </Row>

                    <Row
                        type="flex"
                        justify="space-around"
                        align="middle"
                        className="tab-body"
                    >
                        <Col md={18}>
                            {providerAttributes.bio
                                ? providerAttributes.bio
                                : "You haven't added a bio yet"}
                        </Col>
                        <Col md={3}></Col>
                    </Row>
                </form>
                <EditBio
                    onSubmit={handleSubmit(this.handleSubmit)}
                    onClose={this.handleCancel}
                    isVisible={isUpdateMode}
                    error={error}
                    isLoading={user.isLoading}
                />
            </div>
        );
    }
}

Bio.defaultProps = {
    user: {},
    onSubmit: (values) => values,
};

export default reduxForm({
    form: "Bio",
    enableReinitialize: true,
})(Bio);
