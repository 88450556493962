import React, { useState } from "react";
import { Row, Spin, Tooltip } from 'antd';
import EditPaymentOption from "./editPaymentOptions";
import PaymentOptionBlock from "./paymentOptionBlock";
import { HiOutlinePlus } from 'react-icons/hi';

import { useSelector } from 'react-redux';

import { addPaymentOption, removePaymentOption, updatePaymentOption } from '../../../actions/user';

const MAX_PAYMENT_OPTIONS_ALLOWED = window._env.REACT_APP_MAX_PAYMENT_OPTIONS_ALLOWED;


const initialPaymentOptionVal = {
    tag: "",
    detail: "",
};

const PaymentOption = () => {

    const [isUpdateMode, setIsUpdateMode] = useState(false);
    const [paymentOptionDetail, setPaymentOptionDetail] = useState(undefined);


    const handleToggleMode = (isUpdateMode) => {
        setIsUpdateMode(isUpdateMode);
    };

    const handleAdd = () => {
        handleAddUpdate(initialPaymentOptionVal);
    };

    const handleAddUpdate = (paymentOptionDetail) => {

        setIsUpdateMode(true);
        setPaymentOptionDetail(paymentOptionDetail);
    };

    const handleCancel = () => {
        setPaymentOptionDetail(undefined);
        handleToggleMode(false);
    };

    let user = useSelector(st => st.user);

    const { paymentOption = [], isLoadingAddress, isLoadingPaymentOption, scope } = user;
    return (
        <div className="address">
            <div>
                <div className="tab-header">
                    <h3 className="title">
                        <span>Payment Option</span>
                        <Tooltip title={paymentOption.length >= MAX_PAYMENT_OPTIONS_ALLOWED && 'Limit Reached'}>
                            <HiOutlinePlus style={paymentOption.length >= MAX_PAYMENT_OPTIONS_ALLOWED && { opacity: 0.5 }} onClick={paymentOption.length < MAX_PAYMENT_OPTIONS_ALLOWED ? handleAdd : undefined} size={20} />
                        </Tooltip>
                    </h3>
                </div>

                <div className="tab-body">
                    <Spin spinning={isLoadingAddress || user.isLoading}>
                        <Row className="address-list" type='flex' gutter={16}>
                            {
                                Array.isArray(paymentOption) &&
                                paymentOption.map((add, key) =>
                                    <PaymentOptionBlock
                                        index={key}
                                        key={key}
                                        item={add}
                                        onUpdate={handleAddUpdate}
                                        onRemove={removePaymentOption}
                                        UpdatePaymentOption={updatePaymentOption}
                                    />
                                )
                            }
                        </Row>
                    </Spin>
                </div>
            </div>
            <EditPaymentOption
                addPaymentOption={addPaymentOption}
                updatePaymentOption={updatePaymentOption}
                onClose={handleCancel}
                isVisible={isUpdateMode}
                isLoading={isLoadingPaymentOption || user.isLoading}
                initialValues={paymentOptionDetail}
                userscope={scope}
            />
        </div>
    );
};

export default PaymentOption;
