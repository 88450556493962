import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ReviewAndRating from '../../components/consumer/reviewAndRating';
import * as reviewAndRatingActions from '../../actions/reviewAndRating';

const mapStateToProps = ({
    reviewAndRating,
    user
}) => ({
    ...reviewAndRating,
    user
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    ...reviewAndRatingActions
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ReviewAndRating);
