import React, { Component } from "react";
import Logo from "../../images/logo.png";
import { Row, Col } from "antd";
import DefaultHeader from "../header/defaultHeader";

class ExpiredPage extends Component {
    render() {
        return (
            <>
                <header>
                    <div className="wrapper">
                        <div className="logo">
                            <img src={window._env.REACT_APP_PUBLIC_URL_HOST + Logo} alt="Logo" />
                        </div>
                        <ul className={`menu side-menu `}>{<DefaultHeader />}</ul>
                        <div className="clear-both" />
                    </div>
                </header>
                <Row type="flex" justify="center">
                    <Col span={25}>
                        <div style={{ paddingTop: "200px" }}>
                            <h2 style={{ color: "black", fontWeight: "bold" }}>
                                Email Verification Failure
                            </h2>
                            <h4>
                                Uh oh, it looks like the email verification link is expired.
                                Please contact support.
                            </h4>
                        </div>
                    </Col>
                </Row>
            </>
        );
    }
}
export default ExpiredPage;
