import React from 'react';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { Row, Col, Avatar } from 'antd';

import './style.scss';
import welcome from '../../images/how-it-works2.svg';
import CloudImg from '../../images/how-it-works-cloud2.svg';

export default function Section3() {
    const breakpoint = useBreakpoint();

    return (
        <div className='position-relative'>
            <img src={CloudImg} alt="Welcome To Foodelys" className='position-absolute'
                style={{ width: breakpoint.xl ? `11%` : breakpoint.lg ? `7%` : 0, top: 0, left: 0, height: 'auto' }}
            />
            <Row type="flex" justify="center" align="middle">
                <Col xs={0} md={{ span: 1, order: 0 }} xl={3}>
                </Col>

                <Col xs={{ span: 24, order: 1 }} md={{ span: 12, order: 1 }} xl={9}>

                    <div className="my-5 p-3 d-flex">
                        <div>
                            <Avatar
                                size={breakpoint.lg ? 50 : 30}
                                style={{ backgroundColor: '#c1395f', color: 'white', fontSize: breakpoint.lg ? '1.6rem' : '0.9rem' }}
                                className='mr-3'
                            >
                                4
                            </Avatar>
                        </div>
                        <div>
                            <h4 className='mb-3 d-flex align-items-center section-heading' >
                            Add-Ons And Extras
                            </h4>
                            <div className={`${breakpoint.lg ? 'section-content' : 'section-content-mobile'}`}>
                            Specify add on products, delivery charges (if applicable).
                            </div>
                        </div>
                    </div>

                </Col>
                <Col xs={{ span: 24, order: 0 }} md={{ span: 10, order: 2 }} xl={9}>
                    <img className="img img-fluid"
                        src={welcome} alt="Welcome To Foodelys" />
                </Col>
                <Col xs={0} md={{ span: 1, order: 3 }} xl={3}>
                </Col>
            </Row>
        </div>
    );
}