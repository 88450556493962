import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as reviewAndRatingActions from '../../actions/reviewAndRating';
import ProReviewAndRating from '../../components/provider/reviewAndRating/reviewAndRating';

const mapStateToProps = ({
    reviewAndRating,
    user
}) => ({
    ...reviewAndRating,
    user
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    ...reviewAndRatingActions
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProReviewAndRating);
