import { Fetch } from "./common";
/**
 * Set search detail data
 * @param {object} payload search detail data
 * @returns {object} updated payload
 */
export const setSearchDetail = (payload = {}) => ({
    type: 'SET_SEARCH_DETAIL',
    payload
});

export const getSearch = (params = {}) => async (dispatch, getState) => {
    const { page } = params;
    const { searchDetail, user } = getState();
    const {
        searchText: keyword,
        type,
        limit,
        currentPage,
        filter
    } = searchDetail;
    const skip = page || currentPage;
    const searchOptions = {
        keyword,
        skip,
        limit,
        filter,
        selected: '',
        type,
    };

    const browser_address = localStorage.getItem('address');

    if (browser_address && !user.isAuthenticated) {
        searchOptions.lat = JSON.parse(browser_address).latitude;
        searchOptions.lng = JSON.parse(browser_address).longitude;
    }

    const query = Object.keys(searchOptions).map(function (k) {
        return encodeURIComponent(k) + '=' + encodeURIComponent(searchOptions[k]);
    }).join('&');
    const url = `/search?${query}`;

    dispatch(setSearchDetail({
        isLoading: true,
    }));
    try {
        const { status, data: { data = [], total = 0 } = {} } = await Fetch(url, 'GET');
        if (status === 200) {
            const searchResults = data.map(item => {
                if (Array.isArray(item.cover_photos)) {
                    item.cover_photos = item.cover_photos.filter(i => i && i.url);
                } else {
                    item.cover_photos = [];
                }

                return item;
            });

            return dispatch(setSearchDetail({
                searchResults,
                isLoading: false,
                total,
                currentPage: skip
            }));
        }
    } catch (e) {
        dispatch(setSearchDetail({ isLoading: false }));
    }
};

export const searchStore = (params = {}) => async (dispatch, getState) => {
    const { page } = params;
    const { searchDetail, user } = getState();
    const {
        searchText: keyword,
        type,
        limit,
        currentPage,
        filter
    } = searchDetail;
    const skip = page || currentPage;
    const searchOptions = {
        keyword,
        skip,
        limit,
        filter,
        selected: '',
        type,
    };

    const browser_address = localStorage.getItem('address');

    if (browser_address && !user.isAuthenticated) {
        searchOptions.lat = JSON.parse(browser_address).latitude;
        searchOptions.lng = JSON.parse(browser_address).longitude;
    }

    const query = Object.keys(searchOptions).map(function (k) {
        return encodeURIComponent(k) + '=' + encodeURIComponent(searchOptions[k]);
    }).join('&');
    const url = `/search/store?${query}`;

    dispatch(setSearchDetail({
        isLoading: true,
    }));
    try {
        const { status, data: { data = [], total = 0 } = {} } = await Fetch(url, 'GET');
        if (status === 200) {
            const searchResults = data.map(item => {
                if (Array.isArray(item.cover_photos)) {
                    item.cover_photos = item.cover_photos.filter(i => i && i.url);
                } else {
                    item.cover_photos = [];
                }

                return item;
            });

            return dispatch(setSearchDetail({
                searchResults,
                isLoading: false,
                total,
                currentPage: skip
            }));
        }
    } catch (e) {
        dispatch(setSearchDetail({ isLoading: false }));
    }
};