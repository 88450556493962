import React, { Component } from 'react';
import { Avatar, Layout, Row, Col } from "antd";
import { parse, stringify } from 'qs';

const { Content } = Layout;
const ContactItem = ({ item, selectedId, onClick, isSelected }) => {

    const handleClick = () => {
        onClick(item._id);
    };

    return (
        <li onClick={handleClick} className={isSelected ? "active" : ""}>
            <Row type="flex" align="middle" size="small">
                <Col xs={18} md={20}>
                    <Row type="flex" justify="space-around" align="middle">
                        <Col xs={24} sm={24} md={6} className="avatar">
                            <Avatar src={window._env.REACT_APP_PUBLIC_URL_HOST + item[selectedId].image_urls[0].url}>
                                {item[selectedId].display_name[0]}
                            </Avatar>
                        </Col>
                        <Col xs={24} sm={24} md={17} className="name">
                            {item[selectedId].first_name} {item[selectedId].display_name}
                        </Col>
                    </Row>  
                </Col>
                <Col xs={6} md={4}>
                    {item.unreadCount > 0 && <div className="unread-bubble">{item.unreadCount}</div>}
                </Col>
            </Row>
        </li>
    );
};

class ContactList extends Component {

    componentDidMount() {
        const { location: { search } } = this.props;
        const queryString = parse(search.replace('?', ''));
        if (queryString.chat) {
            this.props.getChatsByIdRequest(queryString.chat);
        }
    }

    onClickUserCard = (id) => {
        const { location: { path }, markAsRead } = this.props;
        markAsRead(id);
        this.props.getChatsByIdRequest(id);
        const queryString = stringify({ chat: id });
        this.props.history.push({
            path,
            search: queryString
        });
    };

    render() {
        const { messages, user } = this.props;
        const { scope = "" } = user;
        const { contactList, selectedUsers } = messages;
        const selected_id = scope === 'PROVIDER' ? 'buyer' : 'seller';

        return (
            <Layout className="contact-list-block">
                <Content className="bg-white">
                    <ul className="contact-list">
                        {contactList.length !== -1 && contactList.map(item => (
                            <React.Fragment key={item._id}>
                                {item[selected_id] && (
                                    <ContactItem
                                        key={item._id}
                                        item={item}
                                        selectedId={selected_id}
                                        onClick={this.onClickUserCard}
                                        isSelected={(selectedUsers && selectedUsers._id === item._id)}
                                    />
                                )}
                            </React.Fragment>
                        ))}
                    </ul>
                </Content>
            </Layout>
        );
    }
}

export default ContactList;
