import React, { useEffect, useState } from "react";
import { Row, Col, Spin, Divider } from "antd";
import "./style.scss";
import sanitize from "sanitize-html";

const TermsAndCondition = (props) => {
    const { getManualDataByName, manualData, isManualLoading, page_visit } = props;
    page_visit('termsandconditions');

    const [loadContent, setLoadContent] = useState(true);
    
    useEffect(() => {
        const fetchData = async () => {
            let res = await getManualDataByName(window._env.REACT_APP_STATIC_MANUAL_NAME_TERMS_CONDITIONS);
            if (res) {
                setLoadContent(false);
            }
        };
        fetchData();
    }, [getManualDataByName]);

    if (loadContent) {
        return (
            <Spin
                size="large"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
                spinning={isManualLoading}
            ></Spin>
        );
    } else {
        return (
            <React.Fragment>
                <Row justify="center" align="middle" style={{ backgroundColor: "white" }}>
                    <Col span={15}>
                        <div className="terms-heading" style={{textAlign: "center"}}> Terms of Use </div>
                    </Col>
                </Row>
                <Row justify="center" align="middle" style={{ backgroundColor: "white" }}>
                    <Col span={18}>
                        {manualData.chapters.map((chapter, index) => {
                            return chapter.sections.map((section, idx) => {
                                return (
                                    <div key={idx} className="terms-container">
                                        <strong className="terms-title">
                                            {section.section_title}
                                        </strong>
                                        <span className="manual-description span-style" 
                                            dangerouslySetInnerHTML={{ __html: sanitize(section.section_description,
                                                { allowedTags: ['p','strong','a'], allowedAttributes: {'a': ['href']}})}}>
                                        </span>
                                        <Divider />
                                    </div>
                                );
                            });
                        })}
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
};

export default TermsAndCondition;
