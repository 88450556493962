import React, { useEffect, useState } from "react";
import { Row, Col, Spin, Select, Popover } from 'antd';
import { useSelector } from "react-redux";
import { updatePrivacySettings } from "../../actions/user";
import { AiFillQuestionCircle } from "react-icons/ai";

export default function PrivacySettings() {

    const [loading, setLoading] = useState(false);
    const [privacy_setting, setPrivacySetting] = useState({
        show_full_name: '',
        show_full_address: ''
    });

    const { privacy_setting: setting, scope } = useSelector(st => st.user);

    useEffect(() => {
        if (setting)
            setPrivacySetting(setting);
    }, [setting]);

    async function handleSubmit(val, name) {
        setLoading(true);
        try {
            setPrivacySetting(old_ps => ({
                ...old_ps,
                [name]: val
            }));
            await updatePrivacySettings({
                ...privacy_setting,
                [name]: val
            });
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="notifications">
            <Row type="flex" className="tab-header" justify="space-between">
                <Col span={20}>
                    <h3 className="title">
                        Privacy Settings
                    </h3>
                </Col>
            </Row>

            <Row type='flex' justify='center' className='tab-body'>
                <Col span={24} lg={22}>
                    <Spin spinning={loading}>

                        <h6>Full Name</h6>

                        <Select
                            style={{ minWidth: 300 }}
                            value={privacy_setting.show_full_name}
                            onChange={(val) => handleSubmit(val, 'show_full_name')}
                        >
                            <Select.Option value='ALWAYS'>
                                Always show full name
                            </Select.Option>
                            <Select.Option value='NEVER'>
                                Never show full name
                            </Select.Option>
                        </Select>

                        {
                            scope === 'PROVIDER' &&
                            <React.Fragment>
                                <h6 className='mt-4'>Pickup Location Address</h6>
                                <Select
                                    style={{ minWidth: 300 }}
                                    value={privacy_setting.show_full_address}
                                    onChange={(val) => handleSubmit(val, 'show_full_address')}
                                >
                                    <Select.Option value='ALWAYS'>Show full address</Select.Option>
                                    <Select.Option value='NEVER'>Never show full address</Select.Option>
                                </Select>
                            </React.Fragment>
                        }

                        {
                            scope === 'PROVIDER' &&
                            <React.Fragment>
                                <h6 className='mt-4'>
                                    Enable Contact Form on Store Info page
                                    <Popover content={window._env.REACT_APP_CONTACT_FORM_PRIVACY_TOOLTIP_MSG} trigger="hover">
                                        <span className='ml-2'><AiFillQuestionCircle size={20} /></span>
                                    </Popover>
                                </h6>
                                <Select
                                    style={{ minWidth: 300 }}
                                    value={privacy_setting.allow_customer_contact}
                                    onChange={(val) => handleSubmit(val, 'allow_customer_contact')}
                                >
                                    <Select.Option value='AUTHENTICATED_USERS_ONLY'>Only to authenticated users</Select.Option>
                                    <Select.Option value='ALL_USERS'>To all authenticated and unauthenticated users</Select.Option>
                                    <Select.Option value='NEVER'>Do not show the form</Select.Option>
                                </Select>
                            </React.Fragment>
                        }

                    </Spin>
                </Col>
            </Row>
        </div>
    );
}