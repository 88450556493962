const initialState = {
    isLoading: false,
    categories: [],
    subCategories: {},
    tag_suggestions: {}
};

const category = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case "SET_CATEGORY":
            return { ...state, ...payload };
        default:
            return state;
    }
};

export default category;
