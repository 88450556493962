import { Fetch } from "./common";

export const fetchServiceAreaList = (payload = {}) => ({
    type: 'FETCHAll_SERVICE_AREA',
    payload
});

export const onServiceSearch = (payload = "") => ({
    type: "SERVICE_AREA_SEARCH",
    payload
});


export const getServiceArea = () => async (dispatch, getState) => {
    const url = `/servicearea`;
    try {
        dispatch(fetchServiceAreaList({ isLoading: true }));
        const { status, data = [] } = await Fetch(url, 'GET');
        if (status === 200) {
            return dispatch(fetchServiceAreaList({ data }));
        }
    } catch (e) {
        dispatch(fetchServiceAreaList({ isLoading: false }));
    }
};
