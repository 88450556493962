import React, { Component } from "react";
import { Row, Col, Modal } from 'antd';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { AiOutlineEdit, AiOutlineUser } from "react-icons/ai";

import {
    Input,
    SelectBox,
    validate
} from '../../common/redux-form';

class EditProfile extends Component {

    render() {

        const {
            isVisible,
            onSubmit,
            onClose,
            error,
            isLoading,
            userScope
        } = this.props;
        const data = window._env.REACT_APP_AVAILABLE_LANGUAGES;
        const temp = data.split(",");
        const languages_data = [];
        for (var i in temp) {
            languages_data.push({ "value": temp[i], "label": temp[i] });
        }

        const title = (
            <div>
                <AiOutlineUser style={{ paddingRight: '10px' }} size={20} />
                Personal detail
            </div>
        );

        return (
            <Modal
                title={title}
                visible={isVisible}
                okButtonProps={{ htmlType: "submit", loading: isLoading }}
                cancelButtonProps={{ disabled: isLoading }}
                okText="Save"
                onCancel={onClose}
                onOk={onSubmit}
                keyboard={false}
            >
                <form onSubmit={onSubmit}>
                    <Row type='flex' justify='center' className="tab-body">
                        <Col span={24}>
                            <Field
                                component={Input}
                                validate={validate.required}
                                label="First Name"
                                name="first_name"
                                placeholder="First Name"
                                isRequired={true}
                            />
                            <Field
                                component={Input}
                                validate={validate.required}
                                label="Last Name"
                                name="last_name"
                                placeholder="Last Name"
                                isRequired={true}
                            />
                            {
                                userScope === "PROVIDER" &&
                                <Field
                                    component={Input}
                                    label="Store Name"
                                    name="store_name"
                                    placeholder="Store Name"
                                />
                            }
                            <div className="form-error">{error}</div>
                            {
                                userScope === "PROVIDER" &&
                                <Field
                                    name="languages"
                                    placeholder="Languages"
                                    component={SelectBox}
                                    label="Languages I can Speak"
                                    mode="multiple"
                                    defaultValue={"English"}
                                    max={5}
                                    options={languages_data}
                                    tooltipText={(
                                        <div className="info-popup">
                                            <span className="desc">Choose your preferred Languages(max 5)</span>
                                        </div>)}
                                />
                            }
                        </Col>
                    </Row>
                </form>
            </Modal>
        );
    }
}

class Profile extends Component {

    state = {
        avatar: "",
        isUpdateMode: false
    };

    handleUpload = (file) => {
        if (!file || !file.url) {
            return false;
        }
        try {
            this.handleSubmit({ img: file.url });

        } catch (e) { }
    };

    handleToggleMode = (isUpdateMode) => {
        this.setState({ isUpdateMode });
    };

    handleUpdate = () => {
        this.handleToggleMode(true);
    };

    handleSubmit = async (values) => {
        const { updatePersonalDetail, initialize, reset, initialValues } = this.props;

        try {
            await updatePersonalDetail(values);
            reset();
            initialize({ ...initialValues, ...values });
            this.handleToggleMode(false);
        } catch (e) {
            throw new SubmissionError({ _error: e.message });
        }
    };

    handleCancel = () => {
        const { reset } = this.props;
        reset();
        this.handleToggleMode(false);
    };

    render() {

        const { isUpdateMode } = this.state;
        const {
            handleSubmit,
            user,
            error
        } = this.props;

        return (
            <>
                <div className="personal-detail">
                    <form onSubmit={handleSubmit(this.handleSubmit)} >
                        <Row className="tab-header">
                            <Col span={24}>
                                <h3 className="title">
                                    <span>Personal Detail</span>
                                    <AiOutlineEdit onClick={this.handleUpdate} size={20} />
                                </h3>
                            </Col>
                        </Row>

                        <Row type='flex' justify='center' className="tab-body">
                            <Col sm={24} md={15} >
                                <div className="profile-field">
                                    <div className="profile-item">
                                        <label>Name:</label>
                                        <span className="text">{user.first_name} {user.last_name}</span>
                                    </div>
                                    <div className="profile-item">
                                        <label>Display Name:</label>
                                        <span className="text"
                                            ref={(textarea) => this.textArea = textarea}>
                                            {user.display_name?.toUpperCase()}
                                        </span>
                                    </div>
                                    {
                                        user.scope === "PROVIDER" &&
                                        <div className="profile-item">
                                            <label>Store Name:</label>
                                            <span className="text">
                                                {user?.store_name}
                                            </span>
                                        </div>
                                    }
                                    <div className="profile-item">
                                        <label>Email Address:</label>
                                        <span className="text">{user.username}</span>
                                    </div>
                                    { /*
                                        user.scope === "PROVIDER" ?
                                            <div className="profile-item">
                                                <label>Partner Id:</label>
                                                <span className="text"
                                                    ref={(textarea) => this.textArea = textarea}>
                                                    {user.uid}
                                                </span>
                                                &emsp;
                                                <MdContentCopy size={15} onClick={(e) => {
                                                    let range;
                                                    range = document.createRange();
                                                    range.selectNode(this.textArea);
                                                    window.getSelection().removeAllRanges();
                                                    window.getSelection().addRange(range);
                                                    document.execCommand("copy");
                                                    message.info("Copied !", 1);
                                                }} />
                                            </div>
                                            :
                                            undefined
                                            */
                                    }
                                    {user.scope === "PROVIDER" &&
                                        <div className="profile-item">
                                            <label>Languages:</label>
                                            {user.languages !== undefined && user.languages?.length > 0 ?
                                                <span className="text">{user.languages?.join(',')}</span> :
                                                <span> English</span>
                                            }
                                        </div>
                                    }
                                    { /*
                                        user.scope === "PROVIDER" ?
                                            <Row justify='start' align='middle' gutter={[16, 16]}>
                                                <Col xs={24} md={6} lg={5} className="profile-item">
                                                    {
                                                        Array.isArray(provider_attributes.verify_docs) &&
                                                        provider_attributes.verify_docs.length > 1 &&
                                                        <label>My Badges:</label>
                                                    }
                                                </Col>
                                                <Col xs={8} md={6} lg={2} className='text-center'>
                                                    <Tooltip title='Professional License'>
                                                        <span>
                                                            {
                                                                (
                                                                    Array.isArray(provider_attributes.verify_docs) &&
                                                                    provider_attributes.verify_docs.length > 1 &&
                                                                    provider_attributes.verify_docs[1]
                                                                )
                                                                &&
                                                                <FaRegIdCard size={30} color='#15C39A' />


                                                            }
                                                        </span>
                                                    </Tooltip>
                                                </Col>
                                                <Col xs={8} md={6} lg={2} className='text-center'>
                                                    <Tooltip title='Liability Insurance'>
                                                        <span>
                                                            {
                                                                (
                                                                    Array.isArray(provider_attributes.verify_docs) &&
                                                                    provider_attributes.verify_docs.length > 1 &&
                                                                    provider_attributes.verify_docs[2]
                                                                )
                                                                &&
                                                                <HiShieldCheck size={30} color='#15C39A' />
                                                            }
                                                        </span>
                                                    </Tooltip>
                                                </Col>
                                            </Row>
                                            :
                                            undefined
                                                        */
                                    }
                                </div>
                            </Col>
                        </Row>

                        {/* <Row className="tab-header">
                            <Col span={24}>
                                <h3 className="title">
                                    <span>My Badges</span>
                                </h3>
                            </Col>
                        </Row>
                        <Row justify='center' gutter={[16, 16]}>
                            <Col xs={24} sm={8} md={6} lg={4} className='text-center'>
                                <Tooltip title='Background Checked'>
                                    <span>
                                        {
                                            provider_attributes.background_check_status
                                                ?
                                                <FaThumbsUp size={40} className='mb-2' color='#15C39A' />
                                                :
                                                <FaThumbsDown size={40} className='mb-2' color='#ff4760' />
                                        }
                                    </span>
                                </Tooltip>
                            </Col>
                            <Col xs={24} sm={8} md={6} lg={4} className='text-center'>
                                <Tooltip title='Professional License'>
                                    <span>
                                        {
                                            (
                                                Array.isArray(provider_attributes.verify_docs) &&
                                                provider_attributes.verify_docs.length > 1 &&
                                                provider_attributes.verify_docs[1]
                                            )
                                                ?
                                                <FaRegIdCard size={40} color='green' />

                                                :

                                                <FaRegIdCard size={40} color='#ff4760' />
                                        }
                                    </span>
                                </Tooltip>
                            </Col>
                            <Col xs={24} sm={8} md={6} lg={4} className='text-center'>
                                <Tooltip title='Liability Insurance'>
                                    <span>
                                        {
                                            (
                                                Array.isArray(provider_attributes.verify_docs) &&
                                                provider_attributes.verify_docs.length > 1 &&
                                                provider_attributes.verify_docs[2]
                                            )
                                                ?
                                                <HiShieldCheck size={40} color='green' />

                                                :
                                                <HiShieldExclamation size={40} color='#ff4760' />
                                        }
                                    </span>
                                </Tooltip>
                            </Col>
                        </Row> */}

                    </form>
                    <EditProfile
                        onSubmit={handleSubmit(this.handleSubmit)}
                        onClose={this.handleCancel}
                        isVisible={isUpdateMode}
                        error={error}
                        isLoading={user.isLoadingProfile}
                        userScope={user.scope}
                    />
                </div>
            </>
        );
    }
}

Profile.defaultProps = {
    user: {},
    onSubmit: (values) => values
};

export default reduxForm({
    form: 'profile',
    enableReinitialize: true
})(Profile);
