const initialState = {
    isLoading: false,
    searchText: "",
    data: []
};

const serviceArea = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case "FETCHAll_SERVICE_AREA":
            return { ...state, ...payload };
        case "SERVICE_AREA_SEARCH":
            return { ...state, searchText: payload };
        default:
            return state;
    }
};

export default serviceArea;