import React, { Component } from "react";
import { Button } from "antd";
import { reduxForm, FieldArray, SubmissionError } from "redux-form";
import ReviewAndRatingFields from "./rateFields";

class ReviewAndRatingForm extends Component {

    handleSubmit = async (values) => {
        const { uid, product, ProSubmitReviewRating, goBack, ProSubmitServiceGigReviewRating } = this.props;
        const { reviews } = values;

        try {
            const data = {
                reviews,
                context: { uid: uid }
            };
            if(product === 'SR')
                await ProSubmitReviewRating(data);
            else
                await ProSubmitServiceGigReviewRating(data);
            goBack();
        } catch (error) {
            throw new SubmissionError({ _error: error.message });
        }
    };

    render() {

        const {
            handleSubmit,
            submitting,
            error,
            reviewAndRating
        } = this.props;

        return (
            <div className="rating-form">
                <form onSubmit={handleSubmit(this.handleSubmit)}>
                    <FieldArray
                        component={ReviewAndRatingFields}
                        disabled={reviewAndRating ? true : false}
                        name="reviews"
                    />
                    {
                        error &&
                        <div className="form-error">
                            {error}
                        </div>
                    }
                    <div className="form-action">
                        {
                            !reviewAndRating &&
                            <Button shape='round'
                                className="btn-primary btn-submit"
                                type="primary"
                                size={"large"}
                                htmlType="submit"
                                loading={submitting}
                            >
                                Submit
                            </Button>
                        }
                    </div>
                </form>
            </div>
        );
    }
}

export default reduxForm({
    form: "pro-review-and-rating",
    enableReinitialize: true
})(ReviewAndRatingForm);
