import React, { useEffect } from "react";
import { Input, Row, Col } from "antd";
import { AiOutlineSearch, AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { setSearchDetail } from "../../actions/searchDetail";

const Search = Input.Search;

export default function SearchBox({
    handleToggleMobSearch,
    showSearchBar,
    searchText,
    getSearch,
    searchStore
}) {


    const { scope, isAuthenticated } = useSelector(st => st.user);
    const { searchType } = useSelector(st => st.searchDetail);


    let history = useHistory();
    let dispatch = useDispatch();

    useEffect(() => {
        if (history.location.pathname !== '/search')
            dispatch(setSearchDetail({ searchText: '' }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!isAuthenticated || (scope !== 'CONSUMER')) {
        return "";
    }

    return (
        <Row align="middle" justify='space-between' style={{ height: 60 }}>
            <Col xs={showSearchBar ? 0 : 24} md={0} className='text-right'>
                <AiOutlineSearch size={25} onClick={handleToggleMobSearch} />
            </Col>
            <Col className="search_box" xs={showSearchBar ? 20 : 0} md={24}>
                <div className="search-container" id='__search'>
                    <Search
                        enterButton={<AiOutlineSearch size={20} />}
                        size='large'
                        placeholder="Search..."
                        defaultValue={searchText}
                        onSearch={(val) => {
                            
                            dispatch(setSearchDetail({ searchText: val }));
                            
                            if(searchType === 'STORE'){
                                searchStore();
                            } else {
                                getSearch();
                            }

                            history.push('/search');
                        }}
                    />
                </div>
            </Col>
            <Col xs={showSearchBar ? 4 : 0} md={0} className='text-right'>
                <AiOutlineClose size={20} className='pointer' onClick={handleToggleMobSearch} />
            </Col>
        </Row>
    );
}
