import React, { Component } from "react";
import { Checkbox as AntCheckbox, Popover } from "antd";
import { AiFillQuestionCircle } from "react-icons/ai";

class Checkbox extends Component {

    handleChange = (value) => {
        const { input: { onChange } } = this.props;
        onChange(value);
    };

    render() {

        const {
            input: { value, ...restInput },
            size,
            meta,
            className,
            errorClass,
            label,
            isRequired,
            disabled,
            tooltipText,
            tooltipPlacement
        } = this.props;

        const isError = meta.touched && meta.error;
        const fieldError = isError ? "field-error" : "";
        const noError = !isError ? "no-error" : "";
        const checkboxProps = {
            ...restInput,
            disabled,
            onChange: this.handleChange
        };

        return (
            <div className={`form-field checkbox ${className} ${fieldError}`}>
                <AntCheckbox
                    {...checkboxProps}
                    size={size}
                    checked={Boolean(value)}
                    className='d-flex flex-row'
                >
                    {label}
                    {isRequired ? <span className="required-label"><sup>*</sup></span> : ""}
                    {' '}
                    {
                        tooltipText &&
                        <Popover content={tooltipText} trigger="hover" placement={tooltipPlacement}>
                            <span><AiFillQuestionCircle size={20} /></span>
                        </Popover>
                    }
                </AntCheckbox>
                {
                    <span className={`error ${errorClass} ${noError}`}>
                        {isError && meta.error}
                    </span>
                }
            </div>
        );
    }
}

Checkbox.defaultProps = {
    input: {},
    meta: {},
    size: "large",
    className: "",
    errorClass: "",
    label: "",
    isRequired: false,
    tooltipText: "",
    tooltipPlacement: "rightTop",
};

export default Checkbox;
