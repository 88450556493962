const initialState = {
    contractId: '5d17af62ae580d38e79b4c30',
    isLoadingPayment: false,
    cardDetail: null,
    loadingCard: false,
    contractOrderDetail: {},
    orderDetail: {},
    isLoading: false,
    selectedDay: 15,
    payOutOrders: [],
    ordersList: [],
    newOrders: 0
};

const order = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case "SET_ORDER":
            return { ...state, ...payload };
        case "SET_ORDER_SELECTED_DAY":
            return { ...state, selectedDay: payload };

        default:
            return state;
    }
};

export default order;
