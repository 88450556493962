import React, { Component } from "react";
import ImageGallery from 'react-image-gallery';

class TwrCarousel extends Component {

    state = {
        images: []
    };

    static getDerivedStateFromProps({ images = [] }, state) {
        if (images.length !== state.images.length) {
            return { images: images.filter(i => i != null).map(i => { return { "original": window._env.REACT_APP_PUBLIC_URL_HOST + i.url, "thumbnail": window._env.REACT_APP_PUBLIC_URL_HOST + i.url, thumbnailClass: 'custom-image-gallery-thumbnail' }; }) };
        }
        return null;
    }

    render() {

        const { images } = this.state;
        const { showThumbnails = true, showNav = true, imageClass } = this.props;

        return (images.length === 1 ?
            <img
                key={"1-key"}
                src={images[0].original}
                alt={images[0].thumbnail || "contract"}
                className={imageClass}
            />
            : 
            <div className='w-100'>
                <ImageGallery
                    items={images}
                    showPlayButton={false}
                    showFullscreenButton={false}
                    showThumbnails={showThumbnails}
                    showNav={showNav}
                />
            </div>
        );
    }
}

export default TwrCarousel;
