import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Divider, Row } from "antd";
import { AiOutlineLogin } from 'react-icons/ai';
import { useDispatch } from "react-redux";
import { createOrder } from "../../../actions/order";
import { useCookies } from "react-cookie";

const PaymentDetail = ({
    order = {},
    disableCheckout,
    addons: allAddons,
    setStep
}) => {

    const {
        lineItems, addons, allergies, delivery_date_time, payment_method,
        delivery_method, pickup_address, delivery_address, seller
    } = order;

    const [totalPrice, setTotalPrice] = useState(0);
    const [loading, setLoading] = useState(false);
    const [preparationTime, setPreparationTime] = useState(0);
    const dispatch = useDispatch();
    const [, setCookie] = useCookies(['order', 'ch']);

    useEffect(() => {
        console.log(lineItems);
        if (Array.isArray(lineItems) && lineItems.length)
            setTotalPrice(() => {
                if (lineItems[0]?.product?.ordering_restriction?.type === 'PREPARED_ASAP') {
                    setPreparationTime(lineItems.reduce((prev, e) => e.product.ordering_restriction?.prep_time_hrs, 0));
                }
                let price = lineItems.reduce((prev, e) => prev + e.quantity * (e.product?.price.unit_price || 0), 0);
                if (allAddons && Array.isArray(allAddons) && addons && Array.isArray(addons) && addons.length > 0) {
                    price = Number(price + addons.map(e => ({ ...allAddons.find(a => a._id === e._id), quantity: e.quantity })).reduce((p, e) => p + e.price * e.quantity, 0)).toFixed(2);
                }
                return price;
            });
    }, [lineItems, addons, allAddons]);

    const handleSubmit = async () => {

        setLoading(true);

        let orderBody = {
            allergies, delivery_date_time, delivery_method, seller: seller._id, payment_method, lineItems: []
        };

        if (orderBody.delivery_method === 'PICKUP') orderBody.pickup_address = pickup_address;
        else orderBody.delivery_address = delivery_address;

        lineItems.map(e => orderBody.lineItems.push({ _id: e.product._id, quantity: e.quantity, type: 'PRODUCT' }));
        addons.map(e => orderBody.lineItems.push({ _id: e._id, quantity: e.quantity, type: 'ADDON' }));

        let success = await createOrder(orderBody)(dispatch);

        if (success) {
            setStep(1);
            setCookie('order', '');
            setCookie('ch', '');
        }

        setLoading(false);

    };

    return (
        <div className="payment-detail border rounded">

            <h5>Summary</h5>

            <Divider className='my-3' />
            <h6>Items</h6>
            {
                lineItems && Array.isArray(lineItems) && lineItems.map((item, key) =>
                    <Row key={key} gutter={16}>
                        <Col span={18}>{item.product?.title} (qty. {item.quantity})</Col>
                        <Col span={6}>${item.product?.price.unit_price * item.quantity}</Col>
                    </Row>
                )
            }

            {
                addons && Array.isArray(addons) && addons.filter(e => !e.delivery_addon).length ?
                    <React.Fragment>

                        <Divider className='my-3' />

                        <h6>Extras</h6>
                        {
                            addons.filter(e => !e.delivery_addon).map(e => ({ ...allAddons.find(a => a._id === e._id), quantity: e.quantity })).map((item, key) =>
                                <Row key={key} gutter={16}>
                                    <Col span={18}>{item.name} (qty. {item.quantity})</Col>
                                    <Col span={6}>${item.price * item.quantity}</Col>
                                </Row>
                            )
                        }
                    </React.Fragment>
                    :
                    null
            }

            {
                addons && Array.isArray(addons) && addons.filter(e => e.delivery_addon).length ?
                    <React.Fragment>

                        <Divider className='my-3' />

                        <h6>Delivery</h6>
                        {
                            addons.filter(e => e.delivery_addon).map(e => ({ ...allAddons.find(a => a._id === e._id), quantity: e.quantity })).map((item, key) =>
                                <Row key={key} gutter={16}>
                                    <Col span={18}>{item.name}</Col>
                                    <Col span={6}>${item.price * item.quantity}</Col>
                                </Row>
                            )
                        }
                    </React.Fragment>
                    :
                    null
            }

            <Divider className='my-3' />

            <Row gutter={16}>
                <Col span={18} style={{ fontWeight: 500 }}>Total:</Col>
                <Col span={6}>${totalPrice}</Col>
            </Row>

            <p style={{ fontSize: 12, fontStyle: 'italic' }}>
            </p>

            {/* <Alert type='info' message='Additional taxes may apply' showIcon /> */}

            <div className="my-3 w-100">
                <Button shape='round'
                    className="w-100"
                    type="primary"
                    size={"large"}
                    htmlType="button"
                    icon={<AiOutlineLogin size={20} />}
                    onClick={handleSubmit}
                    disabled={disableCheckout}
                    loading={loading}
                >
                    Order Now
                </Button>
            </div>

            {
                preparationTime ?
                    <Alert type='info' message={`The order will take at least ${preparationTime} hours to get ready.`} />
                    :
                    null
            }

        </div >
    );
};
export default PaymentDetail;
