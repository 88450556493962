import { Fetch, getErrorMessage } from "./common";
import { toast } from 'react-toastify';

/**
 * Set order data
 * @param {object} payload order data
 * @returns {object} updated payload
 */
export const setOrder = (payload = {}) => ({
    type: "SET_ORDER",
    payload
});

export const setOrderSelectedDay = (payload) => ({
    type: 'SET_ORDER_SELECTED_DAY',
    payload
});

export const createOrder = (body) => (dispatch, getState) => {

    dispatch(setOrder({ isLoading: true }));
    const url = `/order`;
    const options = {
        data: JSON.stringify(body)
    };
    return Fetch(url, "POST", options)
        .then(({ status, data = {} }) => {
            if (status === 201) {
                dispatch(setOrder({ data, isLoading: false }));
                toast.success('Order placed successfully.');
                return data;
            }

            throw new Error(data.message);
        })
        .catch((error) => {
            dispatch(setOrder({ isLoading: false }));
            toast.error(error.message || 'some error occurred while creating order');
        });
};

export const getOrder = (params) => (dispatch) => {

    dispatch(setOrder({ isLoading: true }));
    const url = `/order`;
    return Fetch(url, "GET", {params})
        .then(({ status, data = {} }) => {
            if (status === 200) {
                const { orders = [], totalOrders } = data;
                dispatch(setOrder({ orders, isLoading: false, totalOrders }));
                return orders;
            }

            dispatch(setOrder({ isLoading: false, orders: [], totalOrders: 0 }));
            throw new Error(data.message);
        })
        .catch((error) => {
            dispatch(setOrder({ isLoading: false, orders: [], totalOrders: 0 }));
            throw error;
        });
};

export const getOrderCount = () => (dispatch) => {

    dispatch(setOrder({ isLoading: true }));
    const url = `/order/count`;
    return Fetch(url, "GET")
        .then(({ status, data = {} }) => {
            if (status === 200) {
                const { newOrders } = data;
                dispatch(setOrder({ newOrders }));
                return newOrders;
            }

            dispatch(setOrder({ newOrders: 0 }));
            throw new Error(data.message);
        })
        .catch((error) => {
            dispatch(setOrder({ newOrders: 0 }));
            throw error;
        });
};

export const updateOrder = ({ id, status, reason }) => (dispatch, getState) => {

    dispatch(setOrder({ isLoading: true }));
    const url = `/order`;
    const options = {
        data: JSON.stringify({ id, status, reason })
    };
    return Fetch(url, "PATCH", options)
        .then(({ status, data = {} }) => {
            if (status === 200) {
                toast.success(data.message);
                dispatch(setOrder({ isLoading: false }));
                return true;
            }

            throw new Error(data.message);
        }).catch((error) => {
            console.log(error);
            dispatch(setOrder({ isLoading: false }));
            throw new Error(getErrorMessage(error, "Something went wrong, please try again."));
        });
};

export const cancelOrder = ({ id, reason }) => (dispatch) => {
    dispatch(setOrder({ isLoading: true }));
    const url = `/order`;
    const options = {
        data: JSON.stringify({ id, reason })
    };
    return Fetch(url, "DELETE", options)
        .then(({ status, data = {} }) => {
            if ((status === 200)) {
                if (data.success) {
                    toast.success(data.message);
                } else {
                    toast.error(data.message);
                }
                dispatch(setOrder({ isLoading: false }));
                return true;
            }
            throw new Error(data.message);
        }).catch((error) => {
            dispatch(setOrder({ isLoading: false }));
            throw new Error(getErrorMessage(error, "Something went wrong, please try again."));
        });
};