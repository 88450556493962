import React, { Component } from "react";
import { toast } from 'react-toastify';
import { Row, Col,  Modal, Button, Spin } from "antd";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { Checkbox, validate, NumericInput } from "../../common/redux-form";
import { Fetch } from "../../actions/common";
import { AiOutlinePhone, AiOutlineEdit } from "react-icons/ai";

const PHONE_VERIFICATION_REQUIRED = JSON.parse(
    window._env.REACT_APP_PHONE_VERIFICATION_REQUIRED
);
const TEST_OTP = window._env.REACT_APP_TEST_OTP;
const phoneValidate = validate.isVerified;

class EditContactPhone extends Component {
    state = {
        loading: false,
        visible: false,
        isVerified: false,
        isVerifiedNumber: false,
        phoneNumber: "",
        currentPhoneNumber: "",
        otp: "",
        otpSubmitEnable: false,
        sendingOTP: false
    };

    onChange = value => {
        this.setState({ otp: value, otpSubmitEnable: (value + "").length === 6 });
    };

    isVerifyDisabled = (event, currentValue, prevValue) => {
        const { initialValues, formChangeValue } = this.props;
        this.setState({ currentPhoneNumber: currentValue });
        if (
            initialValues.phone_number[0].number &&
            initialValues.phone_number[0].is_verified
        ) {
            const oldNumber = initialValues.phone_number[0].number;
            this.setState({ phoneNumber: oldNumber });
        }

        if (this.state.phoneNumber !== currentValue) {
            formChangeValue(`phone_number[0].is_verified`, false);
        } else if (this.state.phoneNumber) {
            formChangeValue(`phone_number[0].is_verified`, true);
        }
    };

    handleVerifyMobile = async () => {
        const { currentPhoneNumber } = this.state;
        const { initialValues, error } = this.props;

        if(error && error.length > 0) return;

        if (/^([0-9]{10})$/.test(currentPhoneNumber)) {
            const phoneNumber =
                currentPhoneNumber || initialValues.phone_number[0].number;

            const phoneComponents = {
                IDDCC:  '+1', // phoneNumber.substring(0, phoneNumber.length - 10),
                NN: phoneNumber.substring(0, phoneNumber.length)
            };

            const options = {
                data: JSON.stringify({
                    countryCode: phoneComponents.IDDCC,
                    phoneNumber: phoneComponents.NN
                })
            };

            if (PHONE_VERIFICATION_REQUIRED) {
                this.setState({ sendingOTP: true });
                const url = `/user/verifyPhone/start`;
                await Fetch(url, "POST", options)
                    .then(res => {
                        this.setState({ sendingOTP: false });
                        if (res.status === 200) {
                            this.setState({
                                visible: true,
                                phoneNumber: phoneNumber
                            });
                        }
                    })
                    .catch(error => {
                        this.setState({ sendingOTP: false });
                        toast.error("Invalid Code");
                    });
            } else {
                if (currentPhoneNumber) {
                    this.setState({
                        visible: true,
                        phoneNumber: currentPhoneNumber
                    });
                }
            }
        }
    };

    handleVerifyCode = async () => {

        this.setState({ loading: true });
        const { phoneNumber, otp } = this.state;

        const phoneComponents = {
            IDDCC:  '+1', // phoneNumber.substring(0, phoneNumber.length - 10),
            NN: phoneNumber.substring(0, phoneNumber.length)
        };

        const options = {
            data: JSON.stringify({
                countryCode: phoneComponents.IDDCC,
                phoneNumber: phoneComponents.NN,
                verificationCode: otp
            })
        };

        if (PHONE_VERIFICATION_REQUIRED) {
            const url = `/user/verifyPhone/check`;
            await Fetch(url, "POST", options)
                .then(res => {
                    if (res.status === 200) {
                        this.props.formChangeValue(`phone_number[0].is_verified`, true);
                        this.setState({
                            loading: false,
                            visible: false,
                            isVerifiedNumber: true
                        });
                    }
                })
                .catch(error => {
                    toast.error("Invalid Code");
                    this.setState({ loading: false, isVerifiedNumber: false });
                });
        } else {
            if (otp === TEST_OTP) {
                this.props.formChangeValue(`phone_number[0].is_verified`, true);
                this.setState({
                    loading: false,
                    visible: false,
                    isVerifiedNumber: true
                });
            }
        }
    };

    handleCancel = () => {
        this.setState({
            visible: false,
            loading: false,
            otp: ""
        });
    };

    handleSubmitForm = e => {
        e.preventDefault();
        const { isVerifiedNumber } = this.state;
        const { onSubmit } = this.props;
        if (isVerifiedNumber) {
            onSubmit();
        }
    };

    render() {
        const { isVisible, onSubmit, onClose, error, isLoading } = this.props;
        const {
            visible,
            loading,
            phoneNumber,
            otpSubmitEnable,
            sendingOTP
        } = this.state;

        const title = (
            <div>
                <AiOutlinePhone style={{ paddingRight: "10px" }}  size={20}/>
        Contact Phone
            </div>
        );

        return (
            <Modal
                title={title}
                visible={isVisible}
                okButtonProps={{
                    htmlType: "button",
                    loading: isLoading,
                    disabled: !otpSubmitEnable
                }}
                cancelButtonProps={{ disabled: isLoading }}
                okText="Save"
                onCancel={onClose}
                onOk={onSubmit}
                keyboard={false}
                className="profile-modal"
            >
                <form onSubmit={this.handleSubmitForm}>
                    <Row type="flex" justify="center" className="tab-body">
                        <Col span={24}>
                            <div>
                                <Field
                                    name={`phone_number[0].number`}
                                    type="text"
                                    component={NumericInput}
                                    label="Phone Number"
                                    validate={[validate.required, validate.phone]}
                                    isRequired={true}
                                    onChange={this.isVerifyDisabled}
                                    maxLength={10}
                                />{" "}
                                <div className="phn_verify">
                                    <Button shape='round'
                                        disabled={this.state.isVerified || error ? true : false}
                                        type="primary"
                                        onClick={this.handleVerifyMobile}
                                        loading={sendingOTP}
                                    >
                                        Verify
                                    </Button>
                                    <div className="phn_verify_checkbox">
                                        <Field
                                            name={`phone_number[0].is_verified`}
                                            component={Checkbox}
                                            label="Verified"
                                            disabled={true}
                                            validate={phoneValidate}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-error"> {error} </div>
                        </Col>
                    </Row>
                </form>
                <div>
                    <Modal
                        visible={visible}
                        title="Verify Mobile"
                        onOk={this.handleVerifyCode}
                        onCancel={this.handleCancel}
                        footer={[
                            <Button shape='round' key="back" onClick={this.handleCancel}>
                                Return
                            </Button>,
                            <Button shape='round'
                                key="submit"
                                type="primary"
                                loading={loading}
                                onClick={this.handleVerifyCode}
                                disabled={!otpSubmitEnable}
                            >
                                Submit
                            </Button>
                        ]}
                        destroyOnClose={true}
                    >
                        <span> We have sent you an OTP on {phoneNumber} </span> <br />
                        <br />
                        <NumericInput
                            maxLength={6}
                            value={this.state.otp}
                            onChange={this.onChange}
                            allow_start_from_zero={true}
                        />
                    </Modal>
                </div>
            </Modal>
        );
    }
}

class ContactPhone extends Component {
    state = {
        isUpdateMode: false
    };

    handleToggleMode = isUpdateMode => {
        this.setState({ isUpdateMode });
    };

    handleUpdate = () => {
        this.handleToggleMode(true);
    };

    handleSubmit = async values => {
        const { updateUserDetail, initialize, reset } = this.props;

        try {
            let phone_number = values.phone_number;
            phone_number[0].number = `+1${values.phone_number[0].number}`;
            await updateUserDetail({...values, phone_number});
            reset();
            initialize(values);
            this.handleToggleMode(false);
        } catch (e) {
            throw new SubmissionError({ _error: e.message });
        }
    };

    handleCancel = () => {
        const { reset } = this.props;
        reset();
        this.handleToggleMode(false);
    };

    render() {
        const { isUpdateMode } = this.state;
        const { handleSubmit, user, error, initialValues } = this.props;
        const { phone_number } = user;

        return (
            <Spin spinning={user.isLoading}>
                <div className="personal-detail driving-license">
                    <form onSubmit={handleSubmit(this.handleSubmit)}>
                        <Row className="tab-header">
                            <Col span={24}>
                                <h3 className="title">
                                    <span> Mobile </span>
                                    <AiOutlineEdit onClick={this.handleUpdate}  size={20}/>
                                </h3>
                            </Col>
                        </Row>
                        <Row type="flex" justify="center" className="tab-body">
                            
                            {Array.isArray(phone_number) && phone_number.length > 0 && (
                                <Col span={15}>
                                    <div className="profile-field">
                                        <div className="profile-item">
                                            <label> Mobile Number: </label>
                                            <span className="text"> {phone_number[0].number} </span>
                                        </div>
                                    </div>
                                </Col>
                            )}
                        </Row>
                    </form>
                    <EditContactPhone
                        onSubmit={handleSubmit(this.handleSubmit)}
                        onClose={this.handleCancel}
                        isVisible={isUpdateMode}
                        error={error}
                        isLoading={user.isLoading}
                        formChangeValue={this.props.change}
                        initialValues={initialValues}
                    />
                </div>
            </Spin>
        );  
    }
}

export default reduxForm({
    form: "contact-phone",
    enableReinitialize: true
})(ContactPhone);
