export const TARGET_TYPE_OPTIONS = [
    { label: "Freelancer", value: "FREELANCER" },
    { label: "Professional", value: "PROFESSIONAL" },
];

export const SERVICE_DAY = [
    { label: "Sunday", value: "Sunday" },
    { label: "Monday", value: "Monday" },
    { label: "Tuesdays", value: "Tuesdays" },
    { label: "Wednesday", value: "Wednesday" },
    { label: "Thursday", value: "Thursday" },
    { label: "Friday", value: "Friday" },
    { label: "Saturday", value: "Saturday" },
];

export const SERVICE_TIME_ZONE = [
    { label: "Central", value: "Central" },
    { label: "Mountain", value: "Mountain" },
    { label: "Eastern", value: "Eastern" },
    { label: "Pacific", value: "Pacific" },
];

export const RADIO_BOOLEAN = [
    { label: "true", value: true },
    { label: "false", value: false },
];

export const RADIO_BOOLEAN_PAYMENT = [
    { label: "Yes", value: true },
    { label: "No", value: false },
];


export const COMMUNICATION_METHOD = [
    { label: "Email", value: "EMAIL" },
    { label: "Phone", value: "PHONE" },
    { label: "Text", value: "TEXT" },
];

export const PHONE_NUMBER_TYPE = [
    { label: "Home", value: "HOME" },
    { label: "Work", value: "WORK" },
    { label: "Mobile", value: "MOBILE" }
];

export const PROVIDER_FORM_FIELD_LIST = [
    'first_name',
    'last_name',
    'username',
    'phone_number',
    'address',
    'image_urls',
    'timezone',
    'provider_attributes',
    'subscription'
];

export const CONSUMER_FORM_FIELD_LIST = [
    'first_name',
    'last_name',
    'username',
    'phone_number',
    'address',
    'image_urls',
    'consumer_attribute',
];

export const USER_SCOPE = {
    "PROVIDER": "PROVIDER",
    "CONSUMER": "CONSUMER",
};

export const PAYMENT_OPTIONS = [
    { label: "Credit/Debit Card", value: "CARD" },
    { label: "Paypal", value: "PAYPAL" },
];


export const ACCOUNT_HOLDER_TYPE = [
    { label: "Individual", value: "individual" },
    { label: "Business", value: "company" },
];

export const STRIPE_SELECT_COUNTRY = [
    { label: 'US', value: 'US' },
];

export const STRIPE_SELECT_CURRENCY = [
    { label: 'USD', value: 'usd' },
];

export const targetConsumerOptions = [
    { label: "Residential", value: "RESIDENTIAL" },
    { label: "Commercial", value: "COMMERCIAL" }
];