import React from 'react';
import { Avatar, Col, Row } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

import './style.scss';

import welcome from '../../images/how-it-works8.svg';

export default function Section2() {

    const breakpoint = useBreakpoint();

    return (
        <Row type="flex" justify="center" align="middle" id='buy_services_online'>
            <Col xs={0} md={2} xl={3}>
            </Col>

            <Col xs={24} md={10} xl={9}>
                <img className="img img-fluid"
                    src={welcome} alt="Welcome To Foodelys" />
            </Col>

            <Col xs={24} md={10} xl={9}>

                <div className="my-5 p-3 d-flex">
                    <div>
                        <Avatar
                            size={breakpoint.lg ? 50 : 30}
                            style={{ backgroundColor: '#ff9f0d', color: 'white', fontSize: breakpoint.lg ? '1.6rem' : '0.9rem' }}
                            className='mr-3'
                        >
                            3
                        </Avatar>
                    </div>
                    <div>
                        <h4 className='mb-3 d-flex align-items-center section-heading' >
                            Select extras and delivery add-on, if available
                        </h4>
                    </div>
                </div>

            </Col>

            <Col xs={0} md={2} xl={3}>
            </Col>
        </Row>
    );
}