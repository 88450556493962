import { Cookies } from 'react-cookie';
const $cookie = new Cookies();
const REACT_APP_USER_INFO_KEY = window._env.REACT_APP_USER_INFO_KEY;

export const CheckAccessToken = ()=>{
    let userInfo = $cookie.get(REACT_APP_USER_INFO_KEY)? $cookie.get(REACT_APP_USER_INFO_KEY) : {};
    return userInfo.isAuthenticated? true : false;
};

export const getUserInfo = () =>{
    return $cookie.get(REACT_APP_USER_INFO_KEY);
};

export const IsSeeker = (scopeVal) =>{
    let userInfo = $cookie.get(REACT_APP_USER_INFO_KEY)? $cookie.get(REACT_APP_USER_INFO_KEY) : {};
    return userInfo.scope === "CONSUMER" ? true :false;
};

export const IsHomePath = ()=>{
    return window.location.pathname === "/" ||  
    window.location.pathname === "/how-it-works"||
    window.location.pathname === "/how-it-works-for-provider"||
    window.location.pathname === "/how-it-works-for-seeker"||
    window.location.pathname === "/ourVision"  ? true : false;
};

export const  ValidateEmail = (email) =>{
    // eslint-disable-next-line max-len
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export const ValidateZip = (zip) => {
    let pattern = /^[0-9]{5}?$/;
    return pattern.test(zip);
};