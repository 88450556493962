import React from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { Modal } from 'antd';
import { MdPayment } from "react-icons/md";
import {
    SelectBox,
    Input,
    validate
} from "../../../common/redux-form";
import { useDispatch } from 'react-redux';

const APP_PAYMENT_OPTIONS = window._env.REACT_APP_PAYMENT_OPTIONS.split(";");

const paymentOptions = APP_PAYMENT_OPTIONS.map(x => {
    return { label: x, value: x };
});

const title = (
    <div>
        <MdPayment style={{ paddingRight: '10px' }} size={20} />
         Payment Option
    </div>
);

function EditPaymentOption({
    isVisible,
    onClose,
    error,
    isLoadingProfile,
    handleSubmit,
    initialValues = {},
    addPaymentOption,
    updatePaymentOption
}) {
    const dispatch = useDispatch();

    const _handleSubmit = async (value = {}) => {
        try {
            const {
                _id,
                tag,
                detail
            } = value;
            const paymentOptionData = {
                tag: tag,
                detail: detail
            };
            if (_id) {
                paymentOptionData._id = _id;
                await dispatch(updatePaymentOption(paymentOptionData));
            }
            else {
                await dispatch(addPaymentOption(paymentOptionData));
            }
            onClose();
        } catch (e) {
            throw new SubmissionError({ _error: e.message });
        }
    };

    return (
        <Modal
            title={title}
            visible={isVisible}
            okButtonProps={{ htmlType: "submit", loading: isLoadingProfile }}
            cancelButtonProps={{ disabled: isLoadingProfile }}
            okText={initialValues._id ? "Update" : "Add"}
            onCancel={onClose}
            onOk={handleSubmit(_handleSubmit)}
            keyboard={false}
            className="billing-modal"
        >
            <form onSubmit={handleSubmit(_handleSubmit)}>
                <Field
                    name="tag"
                    type="text"
                    component={SelectBox}
                    options={paymentOptions}
                    label="Title"
                    validate={validate.required}
                    isRequired={true}
                />

                <Field
                    name="detail"
                    type="textarea"
                    component={Input}
                    label="Detail"
                />
                <div className="form-error">{error}</div>
            </form>
        </Modal>

    );
}

export default reduxForm({
    form: 'add-update-payment-options',
    enableReinitialize: true,
    destroyOnUnmount: true
})(EditPaymentOption);