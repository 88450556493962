import React from 'react';
import { Col, Row } from "antd";
import './styles.scss';
import { VscTools } from 'react-icons/vsc';

export default function DataCard({
    header_data, data, image, Action, Footer
}) {
    return (
        <div className="data-card rounded">
            {
                Array.isArray(header_data) &&
                <Row justify='space-around' data-id='header' className='p-2 bg-light border-bottom'>
                    {
                        header_data.map((e, i) => e.visible ?
                            (
                                <div className='d-flex flex-column' key={i}>
                                    <div>
                                        <strong>{e.label}</strong>
                                    </div>
                                    <div>
                                        {e.value}
                                    </div>
                                </div>
                            )
                            :
                            null
                        )
                    }
                </Row>
            }
            <Row data-id='body' type="flex" align="middle" justify="space-around" gutter={16}>

                <Col xs={24} md={6} lg={6} xl={7} className='text-center'>
                    <div className='p-0 p-md-2 p-lg-4 text-center'>
                        {
                            image ?
                                <img src={window._env.REACT_APP_PUBLIC_URL_HOST + image}
                                    alt="contract main img" className='img img-fluid rounded'
                                />
                                :
                                <VscTools size={90} color='#1c98d6' />
                        }
                    </div>
                </Col>
                <Col xs={24} md={18} lg={11} xl={11} className='px-3 px-md-0 py-2'>

                    {
                        data.map((e, i) => e.visible ?
                            (
                                <Row gutter={16} key={i} className='pb-1'>
                                    <Col xs={0} md={8} className="text-right">
                                        <strong>{e.label}</strong>
                                    </Col>
                                    <Col xs={24} md={0}>
                                        <strong>{e.label}</strong>
                                    </Col>
                                    <Col xs={24} md={16} lg={13}>
                                        {e.value}
                                    </Col>
                                </Row>
                            )
                            :
                            null
                        )
                    }
                </Col>
                <Col xs={24} lg={7} xl={6} className="d-flex flex-wrap flex-lg-column align-items-center justify-content-center">

                    {Action && <Action />}

                </Col>
            </Row>
        </div>
    );
}