import React from "react";
import { Col, Rate } from "antd";
import { Link, useHistory } from "react-router-dom";
import "./styles.scss";

function TwrCard({
    picture = [],
    display_name,
    store_name,
    rating,
    total_reviews,
    distance
}) {

    let history = useHistory();

    return (
        <Col>
            <div className="twr-card pointer bg-white position-relative" onClick={() => history.push(`/store/${display_name}`)}>
                {
                    Array.isArray(picture) && picture.length
                        ?
                        <img
                            src={window._env.REACT_APP_PUBLIC_URL_HOST + picture[0].url}
                            alt={store_name}
                            className='img img-fluid'
                        />
                        :
                        null
                }
                <div className="detail position-absolute">
                    <div className="owner-detail d-flex flex-row align-items-center">
                        <h6 className="info mb-0">
                            <Link to={`/store/${display_name}`} className="name">{`${store_name}`}</Link>
                        </h6>
                    </div>
                    <div className="d-flex flex-row align-items-center">
                        <Rate allowHalf disabled defaultValue={rating} style={{ fontSize: 20 }} className='text-warning' />&nbsp;
                        <span>({total_reviews === 0 ? 1 : total_reviews})</span>
                        <div className='ml-auto'>
                            {distance ? `${Number(distance / 1600).toFixed(2)} mi.` : '0 mi.'}
                        </div>
                    </div>
                </div>
            </div>
        </Col>
    );
}

export default TwrCard;
