const initialState = {
    loading: false,
    adminProviderReviewList: [],
    adminSeekerReviewList: [],
    adminProviderReviewCount: 0,
    adminSeekerReviewCount: 0,
    adminReviewModal: false,
    selectedUser: null
};

const users = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case "USERS_GENERIC_ACTION":
            return {
                ...state,
                ...payload
            };
        case "ADMIN_REVIEW_MODAL":
            return {
                ...state,
                adminReviewModal: payload
            };
        default:
            return state;
    }
};

export default users;