import React from 'react';
import { Row, Col } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

import welcome from '../../images/how-it-works11.svg';
import welcome_mobile from '../../images/hiw-buyer/Source-37.webp';

import CloudImg from '../../images/how-it-works-cloud1.svg';
import CloudImg2 from '../../images/how-it-works-cloud3.svg';

import HeaderAlt from "../header/header-alt";

import './style.scss';
import Avatar from 'antd/lib/avatar/avatar';

export default function Section1() {

    const breakpoint = useBreakpoint();

    return (
        <div className='position-relative'>

            <img className="d-none d-lg-inline-block  position-absolute" style={{ left: 0, top: 0, width: breakpoint.xl ? '25vw' : '23vw' }}
                src={CloudImg} alt="Welcome To Foodelys" />

            <img className="d-none d-lg-inline-block  position-absolute" style={{ right: 0, top: 0, width: breakpoint.xl ? '20vw' : '23vw' }}
                src={CloudImg2} alt="Welcome To Foodelys" />

            <HeaderAlt type='DARK' />

            <Row type="flex" justify="center" align="middle">


                <Col xs={0} md={1} xl={3}>
                </Col>

                <Col xs={24} md={0} className='p-3'>
                    <img className="img img-fluid"
                        src={welcome_mobile} alt="Welcome To Foodelys" />
                </Col>

                <Col xs={24} md={12} xl={9}>

                    <div className="my-5 p-3 d-flex">
                        <div>
                            <Avatar
                                size={breakpoint.lg ? 50 : 30}
                                style={{ backgroundColor: '#ff9f0d', color: 'white', fontSize: breakpoint.lg ? '1.6rem' : '0.9rem' }}
                                className='mr-3'
                            >
                                1
                            </Avatar>
                        </div>
                        <div>
                            <h4 className='mb-3 d-flex align-items-center section-heading'>
                                Sign Up As A Foodie
                            </h4>
                            <div className={`${breakpoint.lg ? 'section-content' : 'section-content-mobile'}`}>
                                By providing your name, email id, mobile number, address and pin code.
                            </div>
                        </div>
                    </div>

                </Col>
                <Col xs={0} md={10} xl={9} className='text-center'>
                    <img className="img img-fluid"
                        src={welcome} alt="Welcome To Foodelys" />
                </Col>
                <Col xs={0} md={1} xl={3}>
                </Col>

            </Row>
        </div>
    );
}