import React from 'react';
import { Spin, Row, Col } from 'antd';
import { IoFastFoodOutline } from 'react-icons/io5';
import { FcInTransit } from 'react-icons/fc';

import './styles.scss';
import { AiOutlineInbox } from 'react-icons/ai';

export default function AddonTab({ loading, addons }) {

    if (loading)
        return (
            <div
                style={{ height: 500 }}
                className='w-100 d-flex justify-content-center align-items-center'
            >
                <Spin size='large'></Spin>
            </div>
        );

    return (

        <div>
            {
                Array.isArray(addons) && addons.filter(e => !e.delivery_addon).length ?
                    <div className='d-flex flex-column'>
                        {
                            loading ?
                                <Spin className='m-auto' size='large'></Spin>
                                :
                                <div>
                                    <Row gutter={[16, 16]}>
                                        {
                                            addons.filter(e => !e.delivery_addon).map((e, i) => (
                                                <Col xs={24} lg={12} className='pointer' key={i}>
                                                    <Row data-id='body' type="flex" align="stretch" justify="space-between" className="border rounded">
                                                        <Col span={6} className='text-center'>
                                                            {
                                                                e.addon_image ?
                                                                    <img src={window._env.REACT_APP_PUBLIC_URL_HOST + e.addon_image}
                                                                        alt="Product main img" className='img-fluid rounded-left'
                                                                        style={{ maxHeight: 180 }}
                                                                    />
                                                                    :
                                                                    e.delivery_addon
                                                                        ?
                                                                        <FcInTransit size={90} color='#1c98d6' />
                                                                        :
                                                                        <IoFastFoodOutline size={90} color='#1c98d6' />
                                                            }
                                                        </Col>
                                                        <Col span={18} className='d-flex flex-column px-2 py-1'>

                                                            <h6 className="card-title mb-0">
                                                                {e.name}
                                                                <span className='text-muted' style={{ fontSize: 12 }}>&nbsp;(${e.price.unit_price})</span>
                                                            </h6>

                                                            {
                                                                e.description
                                                                    ?
                                                                    <p className="card-title my-2">
                                                                        {e.description.substr(0, 65)}{e.description.length > 65 ? '...' : ''}
                                                                    </p>
                                                                    :
                                                                    null
                                                            }

                                                            {
                                                                e.delivery_addon
                                                                    ?
                                                                    <div className='text-muted ml-auto'>Delivery Addon</div>
                                                                    :
                                                                    null
                                                            }
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                </div>
                        }
                    </div>
                    :
                    <div className='h5 mt-5 w-100 d-flex flex-column align-items-center justify-content-center'>
                        <AiOutlineInbox size={75} />
                        The food-partner does not have any extras yet.
                    </div>
            }

        </div>
    );
}