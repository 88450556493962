import React from "react";
import { reduxForm, Field } from "redux-form";
import { Row, Col, Button } from "antd";
import { Input, SelectBox, validate } from "../../common/redux-form";

const categories = [
    { label: "General Question", value: "General" },
    { label: "Improvement", value: "Imporovement" },
    { label: "Suggestion", value: "Suggestion" },
    { label: "Dispute", value: "Dispute" },
    { label: "Others", value: "Others" }
];

const Support = props => {
    const {
        handleSubmit,
        user: { first_name, last_name, username, isLoading, scope },
        reset
    } = props;
    const name = first_name + " " + last_name;
    const handleFinalSubmit = async values => {
        values.name = first_name + " " + last_name;
        values.email_address = username;
        values.customer_type = scope;
        const res = await props.supportEmail(values);
        if (res) {
            reset();
        }
    };

    return (
        <div className="form-wrapper">
            <Row className="tab-header">
                <Col span={24}>
                    <h3 className="title">
                        <span>Support</span>
                    </h3>
                </Col>
            </Row>
            <Row type="flex" justify="center" className="tab-body">
                <Col span={18}>
                    <form onSubmit={handleSubmit(handleFinalSubmit)}>
                        <Field
                            name="name"
                            component={Input}
                            defaultVal={name}
                            disabled={true}
                        />
                        <Field
                            name="email_address"
                            component={Input}
                            defaultVal={username}
                            disabled={true}
                        />

                        <Field
                            name="reason"
                            placeholder="Reason"
                            component={SelectBox}
                            options={categories}
                            validate={validate.required}
                        />
                        <Field
                            name="subject"
                            placeholder="Subject"
                            component={Input}
                            validate={validate.required}
                        />
                        <Field
                            name="message"
                            placeholder="Message"
                            component={Input}
                            type="textarea"
                            rows={5}
                            validate={[validate.required, validate.maxLength2000]}
                        />
                        <Button shape='round'
                            className="btn-primary "
                            type="primary"
                            size={"large"}
                            htmlType="submit"
                            loading={isLoading}
                        >
                            Send
                        </Button>
                    </form>
                </Col>
            </Row>
        </div>
    );
};

export default reduxForm({
    form: "support"
})(Support);
