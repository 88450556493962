import { toast } from 'react-toastify';
import { Fetch } from "./common";
import { reset } from "redux-form";

export const handleMessageGenericAction = (payload) => ({
    type: 'MESSAGE_GENERIC_ACTION',
    payload,
});

export const handleContactUsModal = (payload = false) => ({
    type: 'HANDLE_CONTACT_US_MODAL',
    payload
});

export const handleChatInputChange = (payload = '') => ({
    type: 'HANDLE_CHAT_INPUT_CHANGE',
    payload
});

export const createContactUsRequest = (formData) => (dispatch, getState) => {
    const url = "/chat";
    const options = {
        data: JSON.stringify({ ...formData })
    };

    dispatch(handleMessageGenericAction({ loading: true }));
    return Fetch(url, "POST", options).then(({ status, data = {} }) => {
        if (status === 200 || status === 201) {
            toast.success("Message sent successfully");
            dispatch(reset('contactUsForm'));
            dispatch(handleMessageGenericAction({ loading: false, contactUsModalVisible: false, formValue: {} }));
            return data;
        }

        throw new Error(data.message);
    }).catch((error) => {
        dispatch(handleMessageGenericAction({ loading: false }));
    });
};

export const fetchContactRequest = (formData) => (dispatch, getState) => {
    const url = "/chat/getcontacts";
    const options = {};

    dispatch(handleMessageGenericAction({ loading: true, contactList: [], selectedUsers: null }));
    return Fetch(url, "GET", options).then(({ status, data = {} }) => {
        if (status === 200) {
            dispatch(handleMessageGenericAction({ loading: false, contactList: data }));
            return data;
        }

        throw new Error(data.message);
    }).catch((error) => {
        dispatch(handleMessageGenericAction({ loading: false, contactList: [] }));
    });
};

export const getChatsByIdRequest = (id) => (dispatch, getState) => {

    const url = `/chat/${id}`;
    const options = {};

    dispatch(handleMessageGenericAction({ loadingChats: true, selectedUsers: null }));
    return Fetch(url, "GET", options).then(({ status, data = {} }) => {
        if (status === 200) {
            dispatch(handleMessageGenericAction({ loadingChats: false, selectedUsers: data }));
            return data;
        }

        throw new Error(data.message);
    }).catch((error) => {
        dispatch(handleMessageGenericAction({ loadingChats: false, selectedUsers: null }));
    });
};

export const sendMessage = (formData) => (dispatch, getState) => {

    const url = `/chat/sendmessage`;
    const options = {
        data: JSON.stringify({ ...formData })
    };

    dispatch(handleMessageGenericAction({ loadingChats: true }));
    return Fetch(url, "PUT", options).then(({ status, data = {} }) => {
        if (status === 202) {
            dispatch(handleMessageGenericAction({ loadingChats: true, chatInput: '' }));
            dispatch(getChatsByIdRequest(formData.id));
            return data;
        }

        throw new Error(data.message);
    }).catch((error) => {
        dispatch(handleMessageGenericAction({ loadingChats: false, chatInput: '' }));
    });
};

/**
 * 
 * @param {string} seeker_id  Mongo id of seeker user 
 * @param {string} provider_id Mongo id of provider user
 */
export const getChatsByUserids = (seeker_id, provider_id) => (dispatch) => {
    dispatch(handleMessageGenericAction({ loading: true }));
    return Fetch(`/chat/${seeker_id}/${provider_id}`, "GET")
        .then(({ status, data = {} }) => {
            if (status === 200) {
                if (data._id) {
                    dispatch(handleMessageGenericAction({ loading: false, selectedUsers: data }));
                } else {
                    dispatch(handleMessageGenericAction({ 
                        loading: false, contactUsModalVisible: true, selectedUsers: null 
                    }));
                }
                return data;
            }
            throw new Error(data.message);
        }).catch((error) => {
            dispatch(handleMessageGenericAction({ loading: false, selectedUsers: null }));
        });
};

export const markAsRead = (id) => (dispatch, getState) => {
    let msg = Object.assign({}, getState().messages), contactList = msg.contactList;
    let chat_index = -1, obj;

    contactList.some((e, i) => {
        if (e._id === id) {
            chat_index = i;
            obj = Object.assign({}, e);
            return true;
        }
        return false;
    });

    let unread = msg.unreadCount - obj.unreadCount;

    obj.unreadCount = 0;
    contactList.splice(chat_index, 1, obj);
    dispatch(handleMessageGenericAction({ contactList, unreadCount : unread }));
};

export const getUnreadMessageCount = () => (dispatch, getState) => {
    return Fetch(`/chat/getcount`, "GET")
        .then(({ status, data = {} }) => {
            if (status === 200) {
                dispatch(handleMessageGenericAction({ unreadCount : data.count }));
                return data;
            }
            throw new Error(data.message);
        }).catch((error) => {
            dispatch(handleMessageGenericAction({ unreadCount : 0 }));
        });
};

export const resetContactForm = () => (dispatch, getState) => {
    return dispatch(reset('contactUsForm'));
};