import React from 'react';
import { Field, formValueSelector } from "redux-form";
import { Col, Row, Modal } from 'antd';
import { AiOutlineCustomerService } from "react-icons/ai";
import {
    validate,
    TimePicker,
    Input,
    Checkbox
} from "../../../common/redux-form";
import { useSelector } from 'react-redux';

const title = (
    <div>
        <AiOutlineCustomerService style={{ paddingRight: '10px' }} size={20} />
        Store Hours
    </div>
);

const dayArray = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const dayStoreHourValidation = dayArray.map(e => validate.compareTime(e.toLowerCase()));

function EditStoreHours({
    isVisible,
    onSubmit,
    onClose,
    error,
    isLoading,
    form
}) {

    const store_hours = useSelector(st => formValueSelector(form)(st, 'store_hours'));

    return (
        <Modal
            title={title}
            visible={isVisible}
            okButtonProps={{ htmlType: "submit", loading: isLoading }}
            cancelButtonProps={{ disabled: isLoading }}
            okText="Save"
            onCancel={onClose}
            onOk={onSubmit}
            keyboard={false}
        >
            <form onSubmit={onSubmit}>

                <Field
                    name="store_hours.open"
                    component={Checkbox}
                    label="Store open"
                    tooltipText={
                        <div className="info-popup">
                            <span className="desc">Check to open your store</span>
                        </div>
                    }
                    unCheckedChildren='no'
                    checkedChildren='yes'
                />

                {
                    !store_hours?.open ?
                        <Field
                            name="store_hours.reason"
                            component={Input}
                            label="Reason"
                            tooltipText={
                                <div className="info-popup">
                                    <span className="desc">Would you like to provide a reason for temporarily closing the store?</span>
                                </div>
                            }
                        />
                        :
                        dayArray
                            .map((e, i) => (
                                <Row gutter={16} key={i}>
                                    <Col span={8}>
                                        <Field
                                            name={`store_hours.${e.toLowerCase()}.open`}
                                            component={Checkbox}
                                            label={`${e}`}
                                            unCheckedChildren='no'
                                            checkedChildren='yes'
                                        />
                                    </Col>
                                    {
                                        store_hours && store_hours[e.toLowerCase()]?.open
                                            ?
                                            <React.Fragment>
                                                <Col span={8}>
                                                    <Field
                                                        name={`store_hours.${e.toLowerCase()}.opens_at`}
                                                        component={TimePicker}
                                                        label="Start Time"
                                                        dateformat="h:mm a"
                                                        use12Hours={true}
                                                        validate={[validate.required, dayStoreHourValidation[i]]}
                                                        isRequired={true}
                                                    />
                                                </Col>
                                                <Col span={8}>
                                                    <Field
                                                        name={`store_hours.${e.toLowerCase()}.closes_at`}
                                                        component={TimePicker}
                                                        label="End Time"
                                                        dateformat="h:mm a"
                                                        use12Hours={true}
                                                        validate={[validate.required, dayStoreHourValidation[i]]}
                                                        isRequired={true}
                                                    />
                                                </Col>
                                            </React.Fragment>
                                            :
                                            null
                                    }
                                </Row>
                            ))
                }

                <div className="form-error">{error}</div>
            </form>
        </Modal>
    );
}



export default EditStoreHours;
