import React from 'react';
import { useHistory } from "react-router-dom";
import { Row, Col, Button, Input } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { AiOutlineSearch } from "react-icons/ai";
import { bindActionCreators } from "redux";
import { connect, useDispatch } from 'react-redux';

import welcome from '../images/landingPage/desktop/Source-02.svg';
import { getSearch, setSearchDetail } from "../actions/searchDetail";
import './style.scss';
import HeaderAlt from './header/header-alt';

const { Search } = Input;

function Section0({ getSearch }) {

    let history = useHistory();
    let dispatch = useDispatch();
    const breakpoint = useBreakpoint();

    return (
        <div
            style={{
                position: 'relative',
                height: breakpoint.xxl ? '100vh' : breakpoint.xl ? '90vh' : breakpoint.lg ? '80vh' : breakpoint.md ? '100vh' : '110vh',
                marginBottom: breakpoint.lg ? 0 : '3rem',
                backgroundPositionX: breakpoint.sm ? 0 : -20,
                backgroundPositionY: breakpoint.md ? -170 : breakpoint.sm ? -50 : 0
            }} className={breakpoint.lg ? '' : 'mobile-bg'}
        >

            <img className="position-absolute d-none d-lg-inline-block"
                style={{
                    height: breakpoint.xxl ? '100vh' : breakpoint.xl ? '90vh' : '80vh',
                    width: 'auto',
                    top: 0,
                    right: 0
                }}
                src={welcome} alt="Welcome To Foodelys"
            />

            <HeaderAlt />

            <Row style={{ marginTop: breakpoint.xxl ? '16rem' : breakpoint.xl ? '12rem' : '7rem' }} className='d-none d-lg-flex'>
                <Col xs={24} sm={24} md={18} lg={{ span: 13, offset: 1 }} xl={{ span: 11, offset: 1 }} xxl={{ span: 9, offset: 3 }} className="p-3" style={{ zIndex: 99 }}>
                    <div className="landing-search">
                        <Search enterButton={<AiOutlineSearch size={20} />} size='large' onSearch={(val) => {
                            dispatch(setSearchDetail({ searchText: val }));
                            getSearch();
                            history.push('/search');
                        }} />
                    </div>
                    <br />
                    <h2 className="section-heading">
                        Howdy Foodie!
                    </h2>
                    <div className="section-content mt-4">
                        Discover thousands of Chefs, Cooks-on-Demand, Caterers,
                        Bakers, Grocers and more. We satiate your hunger for
                        authentic regional recipes made with love!
                    </div>

                    <br />
                    <div className="sign-up-button-container mt-4">
                        <Button
                            shape='round' type='primary' size='large'
                            className="sign-up-partner-button m-2"
                            onClick={() => history.push('/pro-signup')}
                        >
                            <strong>Sign Up As A Food Partner</strong>
                        </Button>
                        <Button
                            shape='round' className="sign-up-button m-2"
                            size='large' onClick={() => history.push('/signup')}
                        >
                            <strong>Sign Up As A Foodie</strong>
                        </Button>
                    </div>

                </Col>
            </Row>


            <Row style={{ marginTop: '2rem' }} className='d-lg-none text-white'>
                <Col xs={24}>
                    <div className="landing-search mx-auto">
                        <Search enterButton={<AiOutlineSearch size={20} />} size='large'
                            onSearch={(val) => {
                                dispatch(setSearchDetail({ searchText: val }));
                                getSearch();
                                history.push('/search');
                            }}
                        />
                    </div>
                    <br />
                    <h2 className="section-heading-main-mobile text-white mb-1 text-center">
                        Howdy Foodie!
                    </h2>
                    <div className="section-content-main-mobile mt-4 text-white text-center px-2">
                        Discover thousands of Chefs, Cooks-on-Demand, Caterers,
                        Bakers, Grocers and more. We satiate your hunger for
                        authentic regional recipes made with love!
                    </div>

                    <br />
                    <div className="sign-up-button-container mt-1 text-center">
                        <Button shape='round' className="sign-up-partner-button-mobile m-1" onClick={() => history.push('/pro-signup')}>
                            Sign Up As A Food Partner
                        </Button>
                        <Button shape='round' className="sign-up-button-mobile m-1" onClick={() => history.push('/signup')}>
                            Sign Up As A Foodie
                        </Button>
                    </div>

                </Col>
            </Row>

        </div>
    );
}


const mapDispatchToProps = (dispatch) => bindActionCreators({
    getSearch
}, dispatch);

export default connect(null, mapDispatchToProps)(Section0);