import React, { Component } from "react";
import { Row, Col,  Button } from "antd";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import { primaryColor } from "../../../common/theme";

class PaymentSuccess extends Component {

    render() {

        return (
            <div>
                <Row type="flex" justify="center">
                    <Col span={24} md={16}>
                        <h2>Order Sent</h2>
                        <div className="payment-success">
                            <div className="detail">
                                <AiOutlineCheckCircle  size={100} color={primaryColor}/>
                                <h2>We have sent your order to seller</h2>
                                <p>Please wait for seller's response</p>
                                <Link to="/buyer/my-order">
                                    <Button shape='round' className="btn-primary" type="primary" size={"large"} >
                                        My Orders
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default PaymentSuccess;
