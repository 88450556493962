import { Fetch } from "../actions/common";
import { toast } from 'react-toastify';

export const SubscribeToSendGrid = async (email) => {
    const sendGridBody = {
        list_ids: [],
        contacts: [
            {
                email: email,
                address_line_1: "",
                address_line_2: "",
                alternate_emails: [],
                city: "",
                country: "",
                first_name: "",
                id: "",
                last_name: "",
                postal_code: "",
                state_province_region: "",
                custom_fields: {},
            },
        ],
    };
    const url = "/sendGridMarketCampaigns/addContact";
    const method = "PUT";
    const options = {
        data: sendGridBody,
    };

    return await Fetch(url, method, options);
};

export const UpdateStripeSubsciption = (body) => {
    const options = {
        data: JSON.stringify({ ...body }),
    };
    return Fetch(`/payment/upgrade_downgrade_subscription`, "POST", options);
};

export const CreateSystemEvent = async (event = {}) => {
    const url = "/systemEvent";
    const options = {
        data: JSON.stringify({ ...event }),
    };

    return Fetch(url, "POST", options);
};

// Get proration cost
export const GetProrationCost = async (body) => {
    const options = {
        data: JSON.stringify({ ...body }),
    };
    return await Fetch(`/payment/proration-cost`, "POST", options);
};

export const getCsrfToken = async () => {
    try {
        const result = await Fetch(`/_csrf`, "GET");
        return result;
    } catch (err) {
        toast.error(err.message);
    }
};
