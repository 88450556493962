import React from "react";
import { Input } from 'antd';

class NumericInput extends React.Component {

    onChange = e => {
        let { allow_start_from_zero } = this.props;
        const { value } = e.target;
        const reg = /^[1-9][0-9]*$/;
        const reg1 = /^[0-9]*$/;

        if (allow_start_from_zero) {
            if ((!Number.isNaN(value) && reg1.test(value)) || value === '') {
                this.props.onChange(value);
            }
        }
        else if ((!Number.isNaN(value) && reg.test(value)) || value === '') {
            this.props.onChange(value);
        }
    };


    render() {
        const { maxLength = 25, allow_start_from_zero, ...other_props } = this.props;

        return (
            <Input
                {...other_props}
                onChange={this.onChange}
                onBlur={this.onBlur}
                placeholder="Input a number"
                maxLength={maxLength}
            />
        );
    }
}

export default NumericInput;