import React, { Component } from 'react';
import { reduxForm, Field, SubmissionError } from "redux-form";
import { Row, Col, Button } from "antd";
import { AiOutlineLock, AiOutlineMail } from "react-icons/ai";

import {
    Input,
    validate
} from "../../../common/redux-form";
import "../styles.scss";

class ResetPassword extends Component {

    componentDidUpdate() {
        const { isAuthenticated, history } = this.props;
        if (isAuthenticated) {
            history.push('/');
        }
    }

    handleSubmit = async ({ password, confirmPassword, code }) => {
        const { resetPassword, history } = this.props;

        try {
            if (password !== confirmPassword) {
                throw new Error("Password not matched.");
            }
            const { status } = await resetPassword({ password, code });
            if (status) {
                history.push('/login');
            }
        } catch (error) {
            throw new SubmissionError({ _error: error.message });
        }
    };

    handleCancel = () => {
        const { history, cancelResetPassword } = this.props;
        cancelResetPassword();
        history.push('/');
    };

    render() {
        const {
            submitting,
            handleSubmit,
            error,
            isLoading,
            cancelResetPassword
        } = this.props;

        return (
            <Row className="auth-wrapper" type="flex" justify="center">
                <Col span={6} xs={22} sm={17} md={15} lg={10} xl={9} className="form-wrapper">
                    <h4 className="title">
                        <AiOutlineLock size={20} />
                        Password Reset
                    </h4>
                    <p className="description">
                        We have sent a password reset code to your email. Please check your email.
                    </p>
                    <div className="form-content">
                        <form onSubmit={handleSubmit(this.handleSubmit)}>
                            <Field
                                name="code"
                                placeholder="Password Reset code"
                                component={Input}
                                label="Password Reset Code"
                                validate={validate.required}
                                isRequired={true}
                            />
                            <Field
                                name="password"
                                placeholder="Password"
                                component={Input}
                                label="Password"
                                validate={[validate.required, validate.password]}
                                type="password"
                                isRequired={true}
                            />
                            <Field
                                name="confirmPassword"
                                placeholder="Confirm password"
                                component={Input}
                                label="Confirm Password"
                                validate={[validate.required, validate.password]}
                                type="password"
                                isRequired={true}
                            />
                            <div className="form-error">{error}</div>
                            <Row gutter={[16, 16]} justify='center'>
                                <Col xs={24} md={9}  xxl={5}>
                                    <Button shape='round'
                                        className="btn-primary btn-submit"
                                        type="primary"
                                        size={"large"}
                                        htmlType="submit"
                                        icon={<AiOutlineLock size={20} />}
                                        loading={submitting || isLoading}
                                    >
                                        Save
                                    </Button>
                                </Col>
                                <Col xs={24} md={9} xxl={5}>
                                    <Button shape='round'
                                        className="btn-primary btn-cancel"
                                        type="primary"
                                        icon={<AiOutlineMail size={20} />}
                                        size={"large"}
                                        htmlType="button"
                                        onClick={cancelResetPassword}
                                    >
                                        Resend code
                                    </Button>
                                </Col>
                                <Col xs={24} md={6} xxl={3}>
                                    <Button shape='round'
                                        className="btn-default btn-cancel"
                                        type="default"
                                        size={"large"}
                                        htmlType="button"
                                        onClick={this.handleCancel}
                                    >
                                        Cancel
                                    </Button>
                                </Col>
                            </Row>
                        </form>
                    </div>
                </Col>
            </Row>
        );
    }
}

export default reduxForm({
    form: "reset-password",
    enableReinitialize: true
})(ResetPassword);
