import React, { Component } from "react";
import { Select as AntSelect, Popover } from "antd";
import { AiFillQuestionCircle } from "react-icons/ai";
import debounce from 'lodash/debounce';

const Option = AntSelect.Option;

const multiTypes = ["multiple", "tags"];

class SelectBox extends Component {

    handleValidValue = (inputVal) => {
        const { mode, isRequired, max } = this.props;
        let value = inputVal;
        if (multiTypes.includes(mode)) {

            if (max && Array.isArray(value) && value.length > max) {
                value.pop();
            }

            value = Array.isArray(value) ? value : [];
            if (isRequired && value && !value.length) {
                value = null;
            }

            if (!isRequired && !value.length) {
                value = [];
            }
        } else {
            if (!isRequired && !value) {
                value = "";
            }
        }

        return value;
    };

    handleChange = (value = null) => {
        const { input: { onChange } } = this.props;
        value = this.handleValidValue(value);
        onChange(value);
    };

    filterOption = (input, option) => {
        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    render() {
        const {
            input: {
                value: inputVal,
                name
            },
            placeholder,
            size,
            meta: { error, warning, dirty, touched },
            className,
            errorClass,
            label,
            labelClass,
            mode,
            isRequired,
            options,
            allowClear,
            tooltipText,
            tooltipPlacement,
            onSearch,
            isLoading,
            showSearch,
            disabled,
            defaultValue
        } = this.props;

        const isError = (dirty || touched) && (error || warning);
        const errorText = error || warning;
        const fieldError = isError ? "field-error" : "";
        const noError = !isError ? "no-error" : "";
        // undefined for show placeholder
        let value = inputVal || undefined;
        if (multiTypes.includes(mode)) {
            value = Array.isArray(value) ? value : [];
            if (isRequired && value && !value.length) {
                value = undefined;
            }
        }

        const selectProps = {
            value,
            onChange: this.handleChange,
            name,
            loading: isLoading,
            showSearch
        };

        // If search is active add filter option
        if (showSearch) {
            selectProps.filterOption = this.filterOption;
        }

        /** this is for set initial value */
        if (defaultValue) {
            selectProps.defaultValue = defaultValue;
        }
        if (value) {
            selectProps.defaultValue = value;
        }


        /** on search props  */
        if (onSearch) {
            selectProps.onSearch = debounce(onSearch, 800);
        }

        return (
            <div className={`form-field ${className} ${fieldError}`}>
                {
                    label &&
                    <label className={labelClass}>
                        {label}
                        {isRequired ? <span className="required-label"><sup>{" * "}</sup></span> : ""}
                        {' '}
                        {
                            tooltipText &&
                            <Popover content={tooltipText} trigger="hover" placement={tooltipPlacement}>
                                <span><AiFillQuestionCircle size={20} /></span>
                            </Popover>
                        }
                    </label>
                }
                <AntSelect
                    {...selectProps}
                    size={size}
                    placeholder={placeholder}
                    mode={mode}
                    allowClear={allowClear}
                    disabled={disabled} style={{ width: '100%' }}
                >
                    {
                        options.map(({ value, label: text }, key) =>
                            <Option
                                key={key}
                                value={value}
                                data-id={`option-${key}`}
                            >{text}</Option>
                        )
                    }
                </AntSelect>
                {
                    <span className={`error ${errorClass} ${noError}`}>
                        {isError && errorText}
                    </span>
                }
            </div>
        );
    }
}

SelectBox.defaultProps = {
    input: {},
    meta: {},
    placeholder: "",
    size: "large",
    className: "",
    errorClass: "",
    label: "",
    labelClass: "",
    mode: "default",
    isRequired: false,
    options: [],
    allowClear: true,
    tooltipPlacement: "rightTop",
    isLoading: false,
    max: undefined,
    disabled: false,
    showSearch: true
};

export default SelectBox;
