import React, { useState } from "react";
import {
    Input,
    Button
} from "antd";
import { HiPlus } from "react-icons/hi";
import { AiOutlineCloseCircle } from "react-icons/ai";

export default function IngredientInput({ fields, maxLimit, meta: { error, pristine, submitFailed } }) {

    const [tag, setTag] = useState('');

    function appendTag(ev) {
        if (ev.stopPropagation) {
            ev.stopPropagation();
            ev.preventDefault();
        }
        let _tag = tag.trim();

        if(!_tag) return;

        fields.push(_tag);
        setTag('');
        return;
    }

    return (
        <React.Fragment>
            <div className='d-flex flex-wrap mb-2'>
                {
                    fields.map((e, i) => (
                        <div
                            style={{ background: '#f9f9f9', borderColor: '#d9d9d9', borderWidth: 1, borderStyle: 'solid' }}
                            className='px-1 d-flex align-items-center mr-1 mb-1 rounded' key={i}
                        >
                            {fields.get(i)} <AiOutlineCloseCircle color='rgba(0, 0, 0, 0.45)' size={15} className='m-1' onClick={() => fields.remove(i)} />
                        </div>
                    ))
                }
            </div>
            <div className='d-flex'>
                <Input type='text' onChange={(ev) => setTag(ev.target.value)} placeholder='Enter tag' value={tag} onPressEnter={appendTag} onSubmit={appendTag} />
                <Button shape='round'
                    onClick={appendTag} disabled={maxLimit ? maxLimit <= fields.length : false}
                    icon={<HiPlus size={25} color='#666'/>} className='ml-2'
                >
                    Add Tag
                </Button>
            </div>
            {error && (!pristine || submitFailed) && <p className='text-danger'>{error}</p>}
        </React.Fragment>
    );
}
