import React, { Component } from 'react';
import { Field, FormSection } from "redux-form";
import { Modal } from 'antd';
import { AiOutlineCustomerService } from "react-icons/ai";
import {
    Checkbox
} from "../../../common/redux-form";

class EditMyPledges extends Component {


    render() {

        const {
            isVisible,
            onSubmit,
            onClose,
            error,
            isLoading,
            licensed,
            insured,
            safety_mantra
        } = this.props;

        const title = (
            <div>
                <AiOutlineCustomerService style={{ paddingRight: '10px' }} size={20} />
                Service Detail
            </div>
        );

        return (
            <Modal
                title={title}
                visible={isVisible}
                okButtonProps={{ htmlType: "submit", loading: isLoading }}
                cancelButtonProps={{ disabled: isLoading }}
                okText="Save"
                onCancel={onClose}
                onOk={onSubmit}
                keyboard={false}
            >
                <form onSubmit={onSubmit}>
                    <FormSection name="provider_attributes">

                        <Field
                            name="licensed"
                            component={Checkbox}
                            label="I pledge that I have a valid professional license"
                            checked={licensed}
                        />

                        <Field
                            name="insured"
                            component={Checkbox}
                            label="I pledge that I have valid professional liability insurance"
                            checked={insured}
                        />

                        <Field
                            name="safety_mantra"
                            component={Checkbox}
                            label={
                                <div>
                                    <p>I pledge that I reviewed CDC safety guidelines for COVID-19 and promised to:</p>
                                    <ul>
                                        <li>Maintain 6 feet of distance from customers</li>
                                        <li>Wear masks during the job</li>
                                        <li>Wear gloves during the job</li>
                                        <li>Disinfect surfaces touched during the job</li>
                                    </ul>
                                </div>
                            }
                            checked={safety_mantra}
                        />

                    </FormSection>

                    <div className="form-error">{error}</div>
                </form>
            </Modal>
        );
    }
}



export default EditMyPledges;
