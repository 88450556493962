/* eslint-disable */
import moment from "moment";

const commonRegex = {
    NotAvailable: 'N/A',
    email: /^\w+([\.-]?\w+)*@\w+(\.\w{2,3})(\s?)+$/,
    name: /^[A-Za-z ,.'-]+$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,32})/
};

export const required = (value = '') => {
    return value ?
        (typeof value === 'object' && !Array.isArray(value)) ||
            typeof value === 'number' ||
            (typeof value === 'object' && Array.isArray(value) && value.length > 0) ||
            (typeof value === 'string' && value.trim()) ?
            undefined :
            'Required' :
        'Required';
};



export const maxLength = max => value => value && value.length > max ? `Must be ${max} characters or less` : undefined;
export const maxLength40 = maxLength(40);
export const maxLength100 = maxLength(100);
export const maxLength150 = maxLength(150);
export const maxLength20 = maxLength(20);
export const maxLength200 = maxLength(200);
export const maxLength250 = maxLength(250);
export const maxLength300 = maxLength(300);
export const maxLength50 = maxLength(50);
export const maxLength500 = maxLength(500);
export const maxLength750 = maxLength(750);
export const maxLength1000 = maxLength(1000);
export const maxLength2000 = maxLength(2000);
export const maxLength3000 = maxLength(3000);
export const maxLength5000 = maxLength(5000);


export const minLength = min => value => value && value.length < min ? `Must be ${min} characters or more` : undefined;
export const minLength10 = minLength(10);

export const richTextRequired = (value) => {
    return value && value.replace(/<(.|\n)*?>/g, "").trim().length !== 0 ? undefined : 'Required';
};

export const requireNumber = value =>
    value && Number(value) ? undefined : "Required";

export const number = value =>
    value && isNaN(Number(value)) ? 'Must be a number' : undefined;

export const phone = (value) => {
    const phoneNumberRegex = /^[0-9]{10}$/;
    if (value) {
        return phoneNumberRegex.test(value) ? undefined : "The phone number must be 10 digits long.";
    }
    return undefined
};

export const phoneNumber = (value) => {
    const phoneNumberRegex = /^[0-9]{10}$/;
    return phoneNumberRegex.test(value) ? undefined : "Phone number must be 10 digits long";
};

export const isAmericanCountryCode = (value) => {
    return String(value).startsWith("+1") ? undefined : "Only US country code is supported";
}

export const zipcode = (value) => {
    const zipcodeRegex = /^[0-9]{5}$/;
    return zipcodeRegex.test(value) ? undefined : "A zip code must be 5 digits only";
};

export const validate6DigitLimit = (value) => {
    const sexDigit = /^[0-9]{6}$/;
    return sexDigit.test(value) ? undefined : `Please enter 6 valid digit number`;
};

export const refNumber = (value) => {
    const RefRegex = /^[A-Z, a-z]{1,6}$/;
    return RefRegex.test(value) ? undefined : "Please enter valid string value";
};

export const email = (value) => value && !commonRegex.email.test(value) ? "Please enter valid email address" : undefined;

export const password = (value) => value && !commonRegex.password.test(value) ? "Password must be at least 8 characters long with a sequence of uppercase, lowercase, numbers, and special characters." : undefined;

export const name = (value) => value && !commonRegex.name.test(value) ? "This field must be string" : undefined;

export const isVerified = (value = false) => value ? undefined : "Mobile Number is not verified"

export const preventFutureDate = (value) => {
    if (value) {
        return moment().isSameOrAfter(value) ? undefined : "A future date is not allowed"
    } else {
        return undefined
    }
}

export const preventPastDate = (value) => {
    if (value) {
        return moment().isSameOrBefore(value) ? undefined : "A past date is not allowed"
    } else {
        return undefined
    }
}

/**
 * 
 * @param {number} minDifference minimum difference between current and schedule time
 */
export const preventPastTime = (minDifference = 0) => (value, allValue) => {
    if (allValue.schedule_date && allValue.start_time) {
        let date = moment(allValue.schedule_date, "YYYY-MM-DD"),
            time = moment(allValue.start_time, "HH:mm a"),
            now = moment(),
            current_time = moment(moment().format("HH:mm a"), "HH:mm a");

        if (
            date.year() === now.year() &&
            date.month() === now.month() &&
            date.date() === now.date() &&
            time.diff(current_time, "minutes") < minDifference
        ) {
            return "A schedule can be rescheduled to a future date and time only.";
        }
        return undefined
    }
    return undefined
}

export const compareTime = (day) => (value, allvalue) => {
    const { store_hours } = allvalue;
    if (store_hours[day].opens_at && store_hours[day].closes_at) {
        const startTime = moment(store_hours[day].opens_at, "HH:mm a");
        const endTime = moment(store_hours[day].closes_at, "HH:mm a");
        if (!startTime.isSameOrBefore(endTime)) {
            return "The end time is not valid"
        }
    }
    return undefined
}

/**
 * 
 * @param {number} minDifference minimum difference between start and end time
 */
export const compareStartAndEndTime = (minDifference = 0) => (value, allvalue) => {
    const { start_time, end_time } = allvalue;

    if (start_time && end_time) {
        const startTime = moment(start_time, "HH:mm a");
        const endTime = moment(end_time, "HH:mm a");

        if (endTime.diff(startTime, "minutes") >= minDifference) {
            return undefined
        } else {
            return "The end time should be at least 2 hours from the start time.";
        }
    }
}

export const compareStartTime = (value, allvalue) => {
    const { start_time, end_time } = allvalue;

    if (start_time && end_time) {
        const startTime = moment(start_time, "HH:mm a");
        const endTime = moment(end_time, "HH:mm a");
        if (!startTime.isSameOrBefore(endTime)) {
            return "The start time should be less than the end time.";
        } else {
            return undefined;
        }
    }
}

export const isLink = (value) => {
    const linkRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

    return linkRegex.test(value);
}
