const initialState = {
    isLoading: false,
    isOkLoading: false,
    systemevents: [],
    system_event: {},
    total: 0
};

const SystemEvents = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case "SYSTEM_EVENTS":
            return { ...state, ...payload };
        case "LOAD_SPINNER":
            return { ...state, ...payload };
        default:
            return state;
    }
};

export default SystemEvents;
