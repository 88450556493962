import React, { Component } from 'react';
import { Field, FieldArray, FormSection } from "redux-form";
import { Button, Col, Modal } from 'antd';
import { AiOutlineCloseCircle, AiOutlineCustomerService, AiOutlinePlusCircle } from "react-icons/ai";
import {
    Input, validate
} from "../../../common/redux-form";
import { toast } from 'react-toastify';

const FAQForm = ({ fields, meta: { error, submitFailed } }) => (
    <React.Fragment>
        <Col span={24} className='mb-3'>
            <Button
                icon={<AiOutlinePlusCircle size={15} />}
                onClick={() => {
                    if(fields.length < 3){
                        fields.push({});
                    } else {
                        toast.error('You can only add a maximum of 3 questions');
                    }
                }}
            >
                <span className='pl-1 align-middle'>Add FAQ</span>
            </Button>
        </Col>
        {
            fields.map((faq, index) => (
                <React.Fragment key={index}>

                    {index !== 0 && <Col span={24} className='border-bottom-bottom'></Col>}

                    <Col span={24}>
                        <label style={{ fontWeight: 600 }}>
                            FAQ {index + 1} <AiOutlineCloseCircle size={15} onClick={() => fields.remove(index)} />
                        </label>
                    </Col>

                    <Field
                        component={Input}
                        name={`${faq}.question`}
                        validate={[validate.maxLength100, validate.required]}
                        isRequired={true}
                        id='ques'
                        label='Question'
                    />

                    <Field
                        component={Input}
                        name={`${faq}.answer`}
                        validate={[validate.maxLength250, validate.required]}
                        id='ans'
                        label='Answer'
                    />

                </React.Fragment>
            ))
        }
    </React.Fragment>
);

class EditFaq extends Component {


    render() {

        const {
            isVisible,
            onSubmit,
            onClose,
            error,
            isLoading,
        } = this.props;

        const title = (
            <div>
                <AiOutlineCustomerService style={{ paddingRight: '10px' }} size={20} />
                Store FAQs
            </div>
        );

        return (
            <Modal
                title={title}
                visible={isVisible}
                okButtonProps={{ htmlType: "submit", loading: isLoading }}
                cancelButtonProps={{ disabled: isLoading }}
                okText="Save"
                onCancel={onClose}
                onOk={onSubmit}
                keyboard={false}
            >
                <form onSubmit={onSubmit}>
                    <FormSection name="provider_attributes">

                        <FieldArray
                            name="faq"
                            component={FAQForm}
                        />

                    </FormSection>

                    <div className="form-error">{error}</div>
                </form>
            </Modal>
        );
    }
}

export default EditFaq;
