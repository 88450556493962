import React, { useEffect, useState } from "react";
import { Row, Spin } from "antd";
import "./style.scss";
import sanitize from "sanitize-html";

const FrequentAnswers = (props) => {
    const { getManualDataByName, manualData, isManualLoading, page_visit } = props;
    const [loadContent, setLoadContent] = useState(true);
    
    page_visit('/faq');

    useEffect(() => {
        const fetchData = async () => {
            let res = await getManualDataByName(window._env.REACT_APP_STATIC_MANUAL_NAME_FAQ);
            if (res) {
                setLoadContent(false);
            }
        };
        fetchData();
    }, [getManualDataByName]);

    if (loadContent) {
        return (
            <Spin
                size="large"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
                spinning={isManualLoading}
            ></Spin>
        );
    } else {
        return (
            <Row style={{ backgroundColor: "white" }}>
                <div className="faq-container faq-row" style={{ marginTop: 30 }}>
                    <h2 className="manual-title bold">Frequently Asked Questions</h2>
                    <div className="col s12 l12 faq-intro-text">
                        <div className="bold">
                            Please see below for a list of our most frequently asked
                            questions. We hope this is helpful.
                        </div>
                    </div>
                </div>
                <div className="faq-container faq-row">
                    <div className="faq-content-full">
                        <div className="col s12 l4 faqs-nav">
                            {manualData.chapters.map((chapter, index) => {
                                return (
                                    <div key={index} className="faqs-nav-category">
                                        <span className="chapter-sidebar-title span-style">
                                            <strong>{chapter.chapter_title}</strong>
                                        </span>
                                        <span className="span-style"></span>
                                        {chapter.sections.map((section, idx) => {
                                            return (
                                                <span key={idx} className="span-style">
                                                    <a
                                                        href={
                                                            "#" + section.section_title.replace(/\s/g, "")
                                                        }
                                                        className="section-link"
                                                    >
                                                        {section.section_title}
                                                    </a>
                                                </span>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        </div>
                        <div className="col s12 l8 faqs-content">
                            {manualData.chapters.map((chapter, index) => {
                                return (
                                    <div key={index}>
                                        <div className="chapter-header">
                                            <h4 className="manuals-h4" id={chapter.chapter_title}>
                                                {chapter.chapter_title}
                                            </h4>
                                        </div>
                                        {chapter.sections.map((section, idx) => {
                                            return (
                                                <div key={idx} className="section-part">
                                                    <h6 className="manuals-h6" id={section.section_title.replace(/\s/g, "")}>
                                                        {section.section_title}
                                                    </h6>
                                                    <span className="manual-description span-style" 
                                                        dangerouslySetInnerHTML={{ __html: sanitize(section.section_description,
                                                            { allowedTags: ['p','strong','a'], 
                                                                allowedAttributes: {'a': ['href']}})}}>
                                                                          
                                                    </span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </Row>
        );
    }
};

export default FrequentAnswers;
