const initialState = {
    formValue: {},
    isLoading: false,
    isCategoryLoading: false,
    isEditLoading: false,
    categories: [],
    subCategories: {},
    selectedCategory: null,
    isProductDetail: false,
    productDetail: {},
    services: [],
    products: [],
    isLoadingSubRoute: false,
    filter: {
        status: ""
    }
};

const service = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case "SET_SERVICE_SKU":
            return { ...state, ...payload };
        case "SET_SERVICE_SKU_FORM":
            const formValue = { ...state.formValue, ...payload };
            return { ...state, formValue };
        case "FETCH_SERVICE_SKU":
            return { ...state, ...payload };
        case "SET_SERVICE_SKU_FILTER":
            const filter = { ...state.filter, ...payload };
            return { ...state, filter };
        case "RESET_SERVICE_FORM":
            return { ...state, formValue: {} };
        default:
            return state;
    }
};

export default service;
