import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ForgotPassword from "../../components/auth/forgotPassword";
import {
    forgotPasswordRequest,
    resetPassword,
    cancelResetPassword
} from "../../actions/user";

const mapStateToProps = ({
    user: { isAuthenticated, isLoading },
    app: { isResetPasswordMode }
}) => ({
    isAuthenticated,
    isLoading,
    isResetPasswordMode
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    forgotPasswordRequest,
    resetPassword,
    cancelResetPassword
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
