import React from 'react';
import { Col, Row } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

import './style.scss';

import welcome from '../images/landingPage/desktop/Source-08.png';
import CloudImg from '../images/landingPage/desktop/Source-16.png';

import welcome_mobile from '../images/landingPage/mobile/Source-14.svg';

export default function Section6() {

    const breakpoint = useBreakpoint();

    return (
        <Row
            className={`position-relative section-top-margin ${breakpoint.lg ? '' : 'mobile-footer-image'}`}
            style={{ paddingTop: breakpoint.lg ? '3rem' : breakpoint.md ? '9rem' : '5rem' }}
        >
            <img className="position-absolute d-none d-lg-inline-block" style={{ width: '20%', top: 0, left: 0 }}
                src={CloudImg} alt="Welcome To Foodelys"
            />

            <Col xs={0} lg={3}>
            </Col>
            <Col xs={0} lg={18}>
                <h2 className="section-heading text-center">
                    Tring Tring...<br /> Are you a chef/baker?
                </h2>
                <div className="section-content mt-2 mb-5 text-center">
                    Sign-up as a food-partner with us and reach thousands of hungry customers 
                    from your cloud/ghost kitchen set up and start your food business with 
                    Foodelys with zero investment at the convenience of a click
                </div>
            </Col>
            <Col xs={24} lg={0} className='mt-auto'>
                <div className="section-heading tring-tring text-center">Tring Tring...</div>
                <h2 className="section-heading text-center">
                    Are you a chef/baker?
                </h2>
                <div className={breakpoint.md ? "section-content mt-2 text-center" : "section-content-mobile mt-2 text-center"}>
                    Sign-up as a food-partner with us and reach thousands of hungry customers 
                    from your cloud/ghost kitchen set up and start your food business with 
                    Foodelys with zero investment at the convenience of a click
                </div>
            </Col>
            <Col xs={0} lg={3}></Col>
            <Col span={24} className='text-center'>
                <img style={{ width: '80vw', height: 'auto' }} src={breakpoint.lg ? welcome : welcome_mobile} alt="Welcome To Foodelys" />
            </Col>
        </Row>
    );
}