import React, { useState } from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { Row, Col, Modal } from 'antd';
import { AiOutlineHome } from "react-icons/ai";
import {
    Input,
    validate,
    SelectBox,
    Radio,
    Checkbox as ReduxCheckbox
} from "../../../common/redux-form";
import { STATE } from "../../../constants/commonContext";
import { validatePostalCodeAndState } from "../../../services/utility";
import { USER_SCOPE, TargetConsumerOptions } from '../../../constants/commonContext';

const matchState = (stateValue, formValues) => {
    const zip = formValues.postal_code || "";
    const isValid = validatePostalCodeAndState(true, stateValue, zip);
    return (!isValid && zip) ? "State not match with postal code." : undefined;
};

const matchZip = (zip, formValues) => {
    const stateValue = formValues.state || "";
    const isValid = validatePostalCodeAndState(true, stateValue, zip);
    return (!isValid && stateValue) ? "Zip code didn't match with state." : undefined;
};

const title = (
    <div>
        <AiOutlineHome style={{ paddingRight: '10px' }} size={20} />
        Address
    </div>
);

function EditAddress({
    change,
    isVisible,
    onClose,
    error,
    isLoadingProfile,
    handleSubmit,
    initialValues = {},
    userscope,
    address = [],
    consumer_attribute,
    addUpdateAddress,
    updateUserDetail
}) {

    const [postalCode, setPostalCode] = useState(0);
    const [isCommercial, setIsCommercial] = useState(false);
    const [loading, setLoading] = useState(false);

    const _handleSubmit = async (value = {}) => {
        setLoading(true);
        try {
            const {
                _id,
                street_1,
                street_2,
                city,
                state,
                postal_code,
                consumer_attribute,
                is_primary
            } = value;
            const addressData = {
                _id,
                street_1,
                street_2,
                city,
                state,
                postal_code,
                is_primary,
                is_verified: true,
            };
            await addUpdateAddress(addressData);
            await updateUserDetail({ consumer_attribute });
            onClose();
        } catch (e) {
            throw new SubmissionError({ _error: e.message });
        } finally {
            setLoading(false);
        }
    };

    const handleConsumer = val => {
        if (val === TargetConsumerOptions[1].value) {
            setIsCommercial(true);
        } else {
            setIsCommercial(false);
        }
    };

    const validatePostalWithState = state => {
        const isValid = validatePostalCodeAndState(
            true,
            state,
            postalCode
        );

        if (!isValid) {
            change('state', "null");
        } else {
            change('state', state);
        }
    };

    return (
        <Modal
            title={title}
            visible={isVisible}
            okButtonProps={{ htmlType: "submit", loading: isLoadingProfile || loading }}
            cancelButtonProps={{ disabled: isLoadingProfile || loading }}
            okText={initialValues._id ? "Update" : "Add"}
            onCancel={onClose}
            onOk={handleSubmit(_handleSubmit)}
            keyboard={false}
            className="billing-modal"
        >
            <form onSubmit={handleSubmit(_handleSubmit)}>
                <Field
                    name="street_1"
                    type="text"
                    component={Input}
                    label="Address"
                    validate={validate.required}
                    isRequired={true}
                />

                <Field
                    name="street_2"
                    type="text"
                    component={Input}
                    label="Address Line 2 (Unit#/Apartment#)"
                />
                <Field
                    name="city"
                    type="text"
                    component={Input}
                    label="City"
                    validate={validate.required}
                    isRequired={true}
                />
                <Row gutter={8}>
                    <Col span={12}>
                        <Field
                            name="state"
                            type="text"
                            component={SelectBox}
                            label="State"
                            validate={[validate.required, matchState]}
                            isRequired={true}
                            options={STATE}
                            showSearch={true}
                            onChange={validatePostalWithState}
                        />
                    </Col>
                    <Col span={12}>
                        <Field
                            name="postal_code"
                            type="text"
                            component={Input}
                            label="Zip Code"
                            validate={[validate.required, matchZip]}
                            isRequired={true}
                            onChange={(ev) => setPostalCode(ev.target.value)}
                        />
                    </Col>
                </Row>
                {isCommercial ?
                    <Row gutter={8}>
                        <Col span={12}>
                            <Field
                                name="consumer_attribute.firm_name"
                                placeholder="Business Name"
                                component={Input}
                                label="Business Name"
                                validate={validate.required}
                                isRequired={true}
                            />
                        </Col>
                        <Col span={12}>
                            <Field
                                name="consumer_attribute.wetsite_address"
                                placeholder="Website Address"
                                component={Input}
                                label="Website Address"
                            />
                        </Col>
                    </Row>
                    : null}
                <Row gutter={8}>
                    {userscope === USER_SCOPE.CONSUMER ?
                        <Col span={12}>
                            <Field
                                name="consumer_attribute.type"
                                component={Radio}
                                label="Consumer"
                                validate={validate.required}
                                isRequired={true}
                                onChange={handleConsumer}
                                options={TargetConsumerOptions}
                                tooltipText={(
                                    <div className="info-popup">
                                        <span className="desc">Please select a target consumer type</span>
                                    </div>)
                                }
                                defaultValue={consumer_attribute?.type}
                            />
                        </Col>
                        : null}
                </Row>
                {!(userscope === USER_SCOPE.CONSUMER) &&
                    <Row gutter={8}>
                        <Col span={12}>
                            <Field
                                name="is_primary"
                                component={ReduxCheckbox}
                                label="Billing address"
                                disabled={
                                    address.length === 0 ||
                                    (initialValues._id && address.length === 1)
                                }
                            />
                        </Col>
                    </Row>
                }
                <div className="form-error">{error}</div>
            </form>
        </Modal>

    );
}

export default reduxForm({
    form: 'add-update-address',
    enableReinitialize: true,
    destroyOnUnmount: true
})(EditAddress);